import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Skeleton,
  Menu,
  MenuItem,
} from "@mui/material";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ReorderRoundedIcon from "@mui/icons-material/ReorderRounded";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

function Categories(props) {
  const { classes, setSelectedCategory } = props;
  const [categoryMenu, setCategoryMenu] = useState(false);
  const { allKnowledgeBaseData, loading } = useSelector(
    (state) => state.knowledgeBase.search
  );
  const { categories } = allKnowledgeBaseData;

  const [currentSlide, setCurrentSlide] = useState(0); // Track the current slide

  const sliderSettings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5, // Show 5 full slides and half of the 6th
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: currentSlide > 0 ? <PrevArrow /> : null, // Only show if currentSlide > 0
    variableWidth: true,
    swipeToSlide: true,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex), // Update current slide
  };

  return (
    <Box className={classes.categoriesSection}>
      <Box className={classes.categoriesHeader}>
        <Typography className={classes.categoriesHeading}>
          <GridViewRoundedIcon /> Categories
        </Typography>
        <Button
          className={classes.categoriesHeaderButtonActive}
          size="small"
          variant="contained"
          color="inherit"
          onClick={(e) => setCategoryMenu(e.currentTarget)}
        >
          <ReorderRoundedIcon />
        </Button>
      </Box>
      {loading ? (
        <Box className={classes.categoriesItems}>
          {Array.from(Array(7).keys()).map(() => (
            <Skeleton
              className={classes.categoriesListItemLoading}
              animation="wave"
              variant="rectangular"
            />
          ))}
        </Box>
      ) : (
        <Box sx={{ position: "relative" }}>
          <Slider {...sliderSettings} className={classes.categoriesSlider}>
            {categories?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  width:
                    item?.title?.length > 18
                      ? "230px  !important"
                      : "194px  !important",
                }}
                className={classes.categoriesListItem}
                onClick={() => setSelectedCategory(item)}
              >
                <Typography className={classes.categoriesListItemContent}>
                  {item?.title}
                </Typography>
              </Box>
            ))}
          </Slider>
        </Box>
      )}

      <Menu
        anchorEl={categoryMenu}
        id="account-menu"
        open={Boolean(categoryMenu)}
        onClose={() => {
          setCategoryMenu(false);
        }}
        sx={{ zIndex: 10000000 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {categories?.map((item, index) => (
          <MenuItem
            onClick={() => setSelectedCategory(item)}
            key={index}
            sx={{ minWidth: "250px" }}
          >
            {item?.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

// Custom Next Arrow Component
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Button
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center !important",
        alignItems: "center",
        position: "absolute",
        right: "-6px",
        top: "50%",
        transform: "translateY(-50%)",
        backgroundColor: "#fff",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        color: "#000",
        padding: "10px",
        zIndex: 10,
        width: "45px",
        minWidth: "45px",
        height: "40px",
        paddingLeft: "0px",
        borderRadius: "15px",
      }}
      onClick={onClick}
    >
      <ArrowForwardIosRoundedIcon sx={{ ml: "-6px" }} />
    </Button>
  );
};

// Custom Prev Arrow Component
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Button
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center !important",
        alignItems: "center",
        position: "absolute",
        left: "-6px",
        top: "50%",
        transform: "translateY(-50%)",
        backgroundColor: "#fff",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        color: "#000",
        zIndex: 10,
        width: "45px",
        minWidth: "45px",
        height: "40px",
        paddingLeft: "0px",
        borderRadius: "15px",
        "&:before": {
          display: "none",
        },
      }}
      onClick={onClick}
    >
      <ArrowBackIosNewRoundedIcon sx={{ ml: "-12px" }} />
    </Button>
  );
};

export default Categories;
