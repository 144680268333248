import React, { useContext, useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import Avatar from "../uiElements/avatar/index";
import CircleIcon from "@mui/icons-material/Circle";
import MenuItem from "@mui/material/MenuItem";
import ListItemButton from "@mui/material/ListItemButton";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Menu from "@mui/material/Menu";
import ChatIcon from "@mui/icons-material/Chat";
import FormControl from "@mui/material/FormControl";
import { updateData } from "../../utils/updateData";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import PhoneIphoneRoundedIcon from "@mui/icons-material/PhoneIphoneRounded";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AppContext } from "../callPanel/callContext";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CallPanel from "../callPanel";
import Divider from "@mui/material/Divider";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import NumberFormat from "react-number-format";
import { logoutUser } from "../../redux/auth";
import { CustomTooltip } from "../shared/tooltip";
import MicRoundedIcon from "@mui/icons-material/MicRounded";
import MicOffRoundedIcon from "@mui/icons-material/MicOffRounded";
import OnCallKeypadPanel from "../callPanel/onCallKeypadPanel";
import Timer from "./timer";
import { setOffset } from "../../redux/calls/getRecentCalls";
import Select from "@mui/material/Select";
import { getRecentCalls } from "../../redux/calls/getRecentCalls";
import { checkUnreadNotificationStatus } from "../../redux/messageAndAlerts/checkUnreadNotification.js";
import { getOnDutyDepartmentList } from "../../redux/common/userStaffDepartment";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CircularProgress from "@mui/material/CircularProgress";
import PowerSearch from "./powerSearch";
import {
  updateSearchParams,
  getPowerSearchData,
  getSearchPropertyData,
} from "../../redux/powerSearch/index";
import Skeleton from "@mui/material/Skeleton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import WidgetsMenu from "./widgetsMenu";
import SendEmailModal from "../widgets/modals/sendEmail";
import SendTextModal from "../widgets/modals/sendText";
import CreateProjectModal from "../widgets/modals/createProject";
import AddAppointmentModal from "../widgets/modals/addAppointment";
import AddAgentRequestModal from "../widgets/modals/addAgentRequest";
import AddLeadModal from "../widgets/modals/addLead";
import ResponseAlert from "../responseAlert";
import TextMessages from "./textMessage";
import AlertWidget from "./alerts";
import { addNewAlert, addNewMessage } from "../../redux/messageAndAlerts";
import establishSocketConnection from "../../config/socket.js";
import ShortAlertWidget from "./shortAlert";
import ShortMessageWidget from "./shortMessage";
import LockResetRoundedIcon from "@mui/icons-material/LockResetRounded";
import UpdatePassword from "./updatePassword.js";
import StaffWidget from "./staffWidget.jsx";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const StyledBadge = styled(Badge)(
  ({ theme, customColor, disableShadow }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: customColor,
      color: customColor,
      boxShadow: disableShadow
        ? ""
        : `0 0 0 2px ${theme.palette.background.paper}`,
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  })
);

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
  height: "35px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  height: "35px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.6) !important",
    },
    color: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function TopNavBar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [phoneModalTab, setPhoneModalTab] = React.useState(0);
  const powerRef = React.useRef(null);
  const [openWidgets, setOpenWidgets] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [callStatus, setCallStatus] = React.useState("Available");
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [unreadAlertCount, setUnreadAlertCount] = React.useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState(0);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  // departments state
  const [departmentBox, openDepartmentBox] = React.useState(false);

  // On call Modal opened states
  const [warnInprogress, setWarnInprogress] = useState(false);
  const [activeButton, setActiveButton] = useState("");
  const [hold, setHold] = useState(false);
  const [warmCall, setWarmCall] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [callTransTimer, setCallTransTimer] = useState(false);
  const [timer, setTimer] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [openTextMessage, setOpenTextMessage] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openShortAlert, setOpenShortAlert] = useState(false);
  const [alertDetail, setAlertDetail] = useState({});
  const [openShortMessage, setOpenShortMessage] = useState(false);
  const [messageDetail, setMessageDetail] = useState({});
  const [openSearch, setOpenSearch] = useState(false);
  const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
  const {
    device,
    setUpDevice,
    mute,
    callTime,
    incomingCall,
    secondIncomingCall,
    secondIncomingCallerName,
    secondIncomingCallerLeadId,
    secondIncomingCallerRealtorAgentId,
    incomingCallerName,
    incomingCallerLeadId,
    incomingCallerRealtorAgentId,
    outgoingCall,

    setAdminDetail,
    selectedOutgoingUser,
    setCallOnMute,
    setDisplayOnCallPanel,
    displayOnCallPanel,
    enqueuedCall,
    isActive,
    enqueueCall,
    adminDetail,
  } = useContext(AppContext);
  const [activeCallForModal, setActiveCallForModal] = useState(null);

  useEffect(() => {
    if (!incomingCall) {
      setWarmCall(null);
      setSelectedUser(null);
      setHold(false);
      setActiveButton("");
      setTimer(0);
      setCallTransTimer(false);
    }
  }, [incomingCall]);

  useEffect(() => {
    if (!outgoingCall) {
      setWarmCall(null);
      setSelectedUser(null);
      setHold(false);
      setActiveButton("");
      setTimer(0);
      setCallTransTimer(false);
    }
  }, [outgoingCall]);
  const recentCalls = useSelector((state) => state.recentCalls);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [anchorCall, setAnchorCall] = React.useState(null);
  const openCallMenu = Boolean(anchorCall);
  const handleClickCall = (event) => {
    dispatch(
      getRecentCalls({
        id: adminAuth.adminDetail.twilio_call_extension.id,
        offset: recentCalls.offset,
      })
    );
    setAnchorCall(event.currentTarget);
  };
  const handleCloseCall = () => {
    setAnchorCall(null);
  };

  const adminAuth = useSelector((state) => state.adminAuth);

  const powerSearch = useSelector((state) => state.powerSearch);

  useEffect(() => {
    if (!openSearch && powerRef?.current?.value) {
      powerRef.current.value = "";
    }
  }, [openSearch]);
  // call  area //
  useEffect(() => {
    if (
      device === null &&
      adminAuth.adminDetail &&
      adminAuth?.adminDetail?.twilio_call_extension?.id &&
      adminAuth?.adminDetail?.permissions?.find((item) => item === "phone")
    ) {
      setUpDevice(adminAuth.adminDetail);
      setCallStatus(
        `${adminAuth?.adminDetail?.twilio_call_extension.status
          .slice(0, 1)
          .toUpperCase()}${adminAuth?.adminDetail?.twilio_call_extension.status.slice(
          1
        )}`
      );
    }
    if (!adminDetail?.id && adminAuth.adminDetail) {
      setAdminDetail(adminAuth.adminDetail);
    }
  }, [adminAuth.adminDetail]);

  // for messages and alerts
  useEffect(() => {
    if (adminAuth?.adminDetail?.id && localStorage.userId) {
      const socket = establishSocketConnection(adminAuth?.adminDetail?.id);
      socket?.connect();
      socket?.on("connect", function () {
        console.log("connected successfully");
      });
      socket?.on("connect", () => {
        console.log(socket?.connected, "connected"); // true.
      });
      socket?.on("disconnect", () => {
        console.log(socket?.connected, "disconnect"); // false.
      });
      socket?.on(`messages`, (data) => {
        dispatch(addNewMessage(data));
        let element = document.getElementById("message-button");
        if (element) {
          element.click();
          setMessageDetail(data);
          setTimeout(() => {
            setOpenShortMessage(false);
            setMessageDetail({});
          }, 5000);
        }
        setUnreadMessageCount((count) => count + 1);
      });
      socket?.on(`alerts_notification_count`, (data) => {
        if (data?.notification_count > 0) {
          setUnreadAlertCount(data?.notification_count);
        }
      });
      socket?.on(`messages_notification_count`, (data) => {
        if (data?.notification_count > 0) {
          setUnreadMessageCount(data?.notification_count);
        }
      });

      socket?.on(`alert`, (data) => {
        console.log(data, "alert receiving data");
        dispatch(addNewAlert(data));
        let element = document.getElementById("alert-button");
        if (element) {
          element.click();
          setAlertDetail(data);
          setTimeout(() => {
            setOpenShortAlert(false);
            setAlertDetail({});
          }, 5000);
        }
        setUnreadAlertCount((count) => count + 1);
      });

      // CLEAN UP THE EFFECT
      return () => socket?.disconnect();
    }
    //
  }, [adminAuth?.adminDetail?.id, localStorage.userId]);

  useEffect(() => {
    // calling on duty departments and duty users
    dispatch(getOnDutyDepartmentList());
  }, []);

  const handleSuccess = (data) => {
    if (data?.isUnreadNotification) {
      setUnreadAlertCount(1);
    }
  };
  useEffect(() => {
    if (adminAuth?.adminDetail?.id) {
      dispatch(checkUnreadNotificationStatus({ handleSuccess }));
    }
  }, [adminAuth?.adminDetail]);

  // end //

  const handleUpdateStatus = async (statusValue) => {
    updateData(
      `${process.env.REACT_APP_BASE_URL}api/twilio/change-extension-status/${adminAuth?.adminDetail?.twilio_call_extension?.id}`,
      {
        status: statusValue,
      }
    );
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    openDepartmentBox(false);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    setOpen(true);
    dispatch(logoutUser());
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const acceptCall = (e) => {
    incomingCall.accept();
  };

  const menuId = "primary-search-account-menu";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          minWidth: "220px",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          paddingLeft: "0px",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {departmentBox && (
        <StaffWidget
          {...props}
          openDepartmentBox={openDepartmentBox}
          classes={props.classes}
          adminAuth={adminAuth}
        />
      )}

      <List sx={{ width: "100%", maxWidth: 360 }}>
        <ListItem key={1} disablePadding>
          <ListItemButton>
            <ListItemIcon classes={{ root: props.classes.profileMenuIcon }}>
              <PhoneRoundedIcon />
            </ListItemIcon>
            <FormControl
              variant="standard"
              sx={{ minWidth: 120, margin: "0px" }}
              className={props.classes.customerNavSelect}
            >
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={callStatus}
                label="callStatus"
                onChange={(e) => {
                  setCallStatus(e.target.value);
                  handleUpdateStatus(e.target.value?.toLowerCase());
                }}
              >
                <MenuItem value={"Available"}>
                  {" "}
                  <CircleIcon
                    sx={{
                      color: "#2E7D32",
                      fontSize: "12px",
                      marginRight: "10px",
                    }}
                  />{" "}
                  Available
                </MenuItem>
                <MenuItem value={"Busy"}>
                  <CircleIcon
                    sx={{
                      color: "#F3B30E",
                      fontSize: "12px",
                      marginRight: "10px",
                    }}
                  />
                  Busy
                </MenuItem>
                <MenuItem value={"Away"}>
                  <CircleIcon
                    sx={{
                      color: "rgba(219, 10, 10, 0.6)",
                      fontSize: "12px",
                      marginRight: "10px",
                    }}
                  />
                  Away
                </MenuItem>
              </Select>
            </FormControl>
            {/* <ListItemText primary="Available" /> */}
          </ListItemButton>
        </ListItem>
        <Divider component="li" />
        {adminAuth?.adminDetail?.role?.name !== "Agents" &&
          adminAuth?.adminDetail?.role?.name !== "Team Leader" && (
            <ListItem
              onClick={() => {
                openDepartmentBox(!departmentBox);
              }}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon classes={{ root: props.classes.profileMenuIcon }}>
                  <BusinessCenterIcon />
                </ListItemIcon>
                <ListItemText primary="Departments" />
              </ListItemButton>
            </ListItem>
          )}
        <Divider component="li" />
        <ListItem
          onClick={() => {
            handleMenuClose();
            navigate("/preferences");
          }}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon classes={{ root: props.classes.profileMenuIcon }}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Preferences" />
          </ListItemButton>
        </ListItem>{" "}
        <Divider component="li" />
        <ListItem
          onClick={() => {
            setOpenUpdatePassword(true);
            setAnchorEl(null);
          }}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon classes={{ root: props.classes.profileMenuIcon }}>
              <LockResetRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Update Password" />
          </ListItemButton>
        </ListItem>
        <Divider component="li" />
        <ListItem
          onClick={() => {
            handleLogout();
            handleMenuClose();
          }}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon classes={{ root: props.classes.profileMenuIcon }}>
              <LogoutRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="success">
            <ChatIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={2} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <PhoneIphoneRoundedIcon />
          </Badge>
        </IconButton>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <Avatar
            alt={`${adminAuth?.adminDetail?.first_name.slice(
              0,
              1
            )}${adminAuth?.adminDetail?.last_name.slice(0, 1)}`}
            src={adminAuth?.adminDetail?.full_name || ""}
            sx={{ textTransform: "uppercase" }}
          />
        </StyledBadge>

        <div className="profile-label-mb">
          {adminAuth?.adminDetail?.full_name}
        </div>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }} className="top-nav-bar">
      <AppBar
        position="static"
        color={"default"}
        sx={{ backgroundColor: "white", boxShadow: "none" }}
      >
        <Toolbar>
          {adminAuth.isLoading ? (
            <Skeleton
              variant="rectangular"
              width="200px"
              height={"35px"}
              sx={{ borderRadius: "5px" }}
            />
          ) : (
            <Search>
              <StyledInputBase
                inputRef={powerRef}
                placeholder="Power Search"
                inputProps={{ "aria-label": "search" }}
                disabled={
                  powerSearch.isLoading || powerSearch.isPropertyLoading
                }
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && powerRef.current.value) {
                    setOpenSearch(true);
                    dispatch(
                      updateSearchParams({ elementTarget: e.currentTarget })
                    );
                    dispatch(
                      getPowerSearchData({
                        search: powerRef.current.value,
                        top: 6,
                      })
                    );
                    dispatch(
                      getSearchPropertyData({
                        search: powerRef.current.value,
                        top: 6,
                      })
                    );
                  }
                }}
              />
              {powerSearch.isLoading || powerSearch.isPropertyLoading ? (
                <CircularProgress
                  size={"16px"}
                  color="inherit"
                  sx={{ position: "absolute", top: "8px", right: "10px" }}
                />
              ) : (
                ""
              )}
            </Search>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {adminAuth.isLoading ? (
              <Skeleton
                className="custom-badge-back"
                variant="rectangular"
                width="80px"
                height={"37px"}
                sx={{ borderRadius: "5px" }}
              />
            ) : (
              <IconButton
                onClick={(e) => setOpenWidgets(e.currentTarget)}
                className="custom-badge-back"
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
              >
                <AddCircleIcon sx={{ width: "25px", height: "25px" }} />
              </IconButton>
            )}
            {adminAuth.isLoading ? (
              <Skeleton
                className="custom-badge-back"
                variant="rectangular"
                width="80px"
                height={"37px"}
                sx={{ borderRadius: "5px" }}
              />
            ) : (
              <>
                <IconButton
                  onClick={(e) => setOpenTextMessage(e.currentTarget)}
                  className="custom-badge-back"
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <Badge
                    badgeContent={unreadMessageCount > 0 ? "" : 0}
                    variant="dot"
                    color="success"
                  >
                    <ChatIcon sx={{ width: "25px", height: "25px" }} />
                  </Badge>
                </IconButton>
                <Box
                  onClick={(e) => setOpenShortMessage(e.currentTarget)}
                  id="message-button"
                ></Box>
              </>
            )}
            {adminAuth.isLoading ? (
              <Skeleton
                className="custom-badge-back"
                variant="rectangular"
                width="80px"
                height={"37px"}
                sx={{ borderRadius: "5px" }}
              />
            ) : (
              <>
                <IconButton
                  onClick={(e) => setOpenAlert(e.currentTarget)}
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  className="custom-badge-back"
                >
                  <Badge
                    badgeContent={unreadAlertCount > 0 ? "" : 0}
                    overlap="circular"
                    variant="dot"
                    color="error"
                  >
                    <NotificationsIcon sx={{ width: "27px", height: "27px" }} />
                  </Badge>
                </IconButton>
                <Box
                  onClick={(e) => setOpenShortAlert(e.currentTarget)}
                  id="alert-button"
                ></Box>
              </>
            )}
            {secondIncomingCall && (
              <div id={"audio-section"} className="call-detail-incoming-area">
                {" "}
                {secondIncomingCall &&
                  secondIncomingCall._status === "pending" && (
                    <CustomTooltip title="Accept call">
                      <IconButton
                        size="large"
                        onClick={async (e) => {
                          if (incomingCall) {
                            let enqueComp = await enqueueCall(
                              incomingCall.customParameters.get(
                                "confFriendlyName"
                              ) || incomingCall.customParameters.get("call_id")
                            );
                            if (enqueComp) {
                              secondIncomingCall.accept();
                            }
                          } else if (outgoingCall) {
                            let enqueComp = await enqueueCall(
                              outgoingCall.parameters.CallSid
                            );
                            if (enqueComp) {
                              secondIncomingCall.accept();
                            }
                          } else {
                            secondIncomingCall.accept();
                          }
                        }}
                        color="inherit"
                        className={`custom-badge-call-accept`}
                      >
                        <Badge badgeContent={""}>
                          <PhoneIphoneRoundedIcon />
                        </Badge>
                      </IconButton>
                    </CustomTooltip>
                  )}
                {secondIncomingCall &&
                  secondIncomingCall._status === "open" && (
                    <IconButton
                      size="large"
                      onClick={setCallOnMute}
                      disabled={isActive ? false : true}
                      color="inherit"
                      className={`custom-badge-call-mice ${
                        mute ? "muted" : ""
                      }`}
                    >
                      {" "}
                      <Badge badgeContent={""}>
                        <CustomTooltip
                          title={mute ? "unmute call" : "Mute call"}
                        >
                          {mute ? <MicOffRoundedIcon /> : <MicRoundedIcon />}
                        </CustomTooltip>
                      </Badge>
                    </IconButton>
                  )}
                <div
                  onClick={(event) => {
                    setDisplayOnCallPanel(event.currentTarget);
                    setActiveCallForModal(secondIncomingCall);
                    setAnchorCall(null);
                  }}
                  style={{
                    backgroundColor: isActive ? "rgba(46, 125, 50, 0.12)" : "",
                  }}
                  className="call-detail-incoming-outgoing"
                >
                  <CustomTooltip title="Open call detail">
                    <div className="call-detail-incoming-outgoing-title">
                      <Link
                        className="call-detail-incoming-outgoing-name"
                        to={
                          secondIncomingCallerRealtorAgentId
                            ? "/tools/recruiting/details/" +
                              secondIncomingCallerRealtorAgentId
                            : secondIncomingCallerLeadId
                            ? "/leads/people/details/" +
                              secondIncomingCallerLeadId
                            : null
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {secondIncomingCallerName
                          ? secondIncomingCallerName
                          : ""}
                      </Link>{" "}
                      {secondIncomingCall &&
                        secondIncomingCall._status === "open" && (
                          <div className="timer-area">
                            {" "}
                            <div className="online-time-dot"></div>{" "}
                            <Timer time={callTime} />
                          </div>
                        )}
                    </div>
                  </CustomTooltip>
                  <div className="call-detail-incoming-outgoing-des">
                    <span>
                      {secondIncomingCall &&
                      secondIncomingCall.customParameters &&
                      secondIncomingCall.customParameters?.get("prefix_label")
                        ? secondIncomingCall.customParameters?.get(
                            "prefix_label"
                          )
                        : ""}
                    </span>
                    <span>
                      {secondIncomingCall && (
                        <NumberFormat
                          format="###-###-####"
                          displayType="text"
                          value={
                            secondIncomingCall.customParameters &&
                            secondIncomingCall.customParameters.get("from")
                              ? secondIncomingCall.customParameters.get("from")
                                  .length > 9
                                ? secondIncomingCall.customParameters
                                    .get("from")
                                    .slice(2)
                                : secondIncomingCall.customParameters.get(
                                    "from"
                                  )
                              : secondIncomingCall.parameters.From
                          }
                        />
                      )}
                    </span>
                  </div>
                </div>
                <CustomTooltip title="End call">
                  <IconButton
                    size="large"
                    onClick={(e) => {
                      if (
                        secondIncomingCall &&
                        secondIncomingCall._status === "pending"
                      ) {
                        secondIncomingCall.reject();
                      } else if (secondIncomingCall) {
                        secondIncomingCall.disconnect();
                      }
                      dispatch(setOffset(0));
                      dispatch(
                        getRecentCalls({
                          id: adminAuth?.adminDetail.twilio_call_extension.id,
                          offset: 0,
                        })
                      );
                    }}
                    color="inherit"
                    className={`custom-badge-call-end`}
                  >
                    <Badge badgeContent={""}>
                      <PhoneIphoneRoundedIcon />
                    </Badge>
                  </IconButton>
                </CustomTooltip>
              </div>
            )}
            {incomingCall || outgoingCall ? (
              <div id={"audio-section"} className="call-detail-incoming-area">
                {" "}
                {incomingCall && incomingCall._status === "pending" && (
                  <CustomTooltip title="Accept call">
                    <IconButton
                      size="large"
                      onClick={acceptCall}
                      color="inherit"
                      className={`custom-badge-call-accept`}
                    >
                      <Badge badgeContent={""}>
                        <PhoneIphoneRoundedIcon />
                      </Badge>
                    </IconButton>
                  </CustomTooltip>
                )}
                {((incomingCall && incomingCall._status === "open") ||
                  (outgoingCall && outgoingCall._status === "open")) && (
                  <IconButton
                    size="large"
                    onClick={setCallOnMute}
                    disabled={isActive ? false : true}
                    color="inherit"
                    className={`custom-badge-call-mice ${mute ? "muted" : ""}`}
                  >
                    {" "}
                    <Badge badgeContent={""}>
                      <CustomTooltip title={mute ? "unmute call" : "Mute call"}>
                        {mute ? <MicOffRoundedIcon /> : <MicRoundedIcon />}
                      </CustomTooltip>
                    </Badge>
                  </IconButton>
                )}
                <div
                  onClick={(event) => {
                    setDisplayOnCallPanel(event.currentTarget);
                    if (incomingCall) {
                      setActiveCallForModal(incomingCall);
                    } else {
                      setActiveCallForModal(outgoingCall);
                    }
                    setAnchorCall(null);
                  }}
                  style={{
                    backgroundColor: isActive ? "rgba(46, 125, 50, 0.12)" : "",
                  }}
                  className="call-detail-incoming-outgoing"
                >
                  <CustomTooltip title="Open call detail">
                    <div className="call-detail-incoming-outgoing-title">
                      <Link
                        className="call-detail-incoming-outgoing-name"
                        to={
                          incomingCall
                            ? incomingCallerRealtorAgentId
                              ? "/tools/recruiting/details/" +
                                incomingCallerRealtorAgentId
                              : incomingCallerLeadId
                              ? "/leads/people/details/" + incomingCallerLeadId
                              : null
                            : outgoingCall && outgoingCall.customParameters
                            ? outgoingCall.customParameters.get(
                                "realtor_agent_id"
                              )
                              ? "/tools/recruiting/details/" +
                                outgoingCall.customParameters.get(
                                  "realtor_agent_id"
                                )
                              : outgoingCall.customParameters.get("lead_id")
                              ? "/leads/people/details/" +
                                outgoingCall.customParameters.get("lead_id")
                              : null
                            : null
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {" "}
                        {incomingCall && incomingCallerName
                          ? incomingCallerName
                          : outgoingCall &&
                            outgoingCall?.customParameters &&
                            outgoingCall?.customParameters?.get("tempName")
                          ? outgoingCall.customParameters?.get("tempName")
                          : selectedOutgoingUser
                          ? selectedOutgoingUser?.first_name
                          : "" + selectedOutgoingUser
                          ? " " + selectedOutgoingUser?.last_name
                          : ""}
                      </Link>{" "}
                      {((incomingCall && incomingCall._status === "open") ||
                        (outgoingCall && outgoingCall._status === "open")) && (
                        <div className="timer-area">
                          {" "}
                          <div className="online-time-dot"></div>{" "}
                          <Timer time={callTime} />
                        </div>
                      )}
                    </div>
                  </CustomTooltip>
                  <div className="call-detail-incoming-outgoing-des">
                    <span>
                      {incomingCall &&
                      incomingCall.customParameters &&
                      incomingCall.customParameters?.get("prefix_label")
                        ? incomingCall.customParameters?.get("prefix_label")
                        : ""}
                    </span>
                    <span>
                      {" "}
                      {outgoingCall && (
                        <NumberFormat
                          format="###-###-####"
                          displayType="text"
                          value={
                            outgoingCall.customParameters
                              .get("To")
                              .startsWith("+1")
                              ? outgoingCall.customParameters.get("To").slice(2)
                              : outgoingCall.customParameters.get("To")
                          }
                        />
                      )}
                      {incomingCall && (
                        <NumberFormat
                          format="###-###-####"
                          displayType="text"
                          value={
                            incomingCall.customParameters &&
                            incomingCall.customParameters.get("from")
                              ? incomingCall.customParameters.get("from")
                                  .length > 9
                                ? incomingCall.customParameters
                                    .get("from")
                                    .slice(2)
                                : incomingCall.customParameters.get("from")
                              : incomingCall.parameters.From?.length > 9
                              ? incomingCall.parameters.From.slice(2)
                              : incomingCall.parameters.From
                          }
                        />
                      )}
                    </span>
                  </div>
                </div>
                <CustomTooltip title="End call">
                  <IconButton
                    size="large"
                    onClick={(e) => {
                      if (incomingCall && incomingCall._status === "pending") {
                        incomingCall.reject();
                      } else if (incomingCall) {
                        incomingCall.disconnect();
                      } else if (outgoingCall) {
                        outgoingCall.disconnect();
                      }
                      dispatch(setOffset(0));
                      dispatch(
                        getRecentCalls({
                          id: adminAuth?.adminDetail.twilio_call_extension.id,
                          offset: 0,
                        })
                      );
                    }}
                    color="inherit"
                    className={`custom-badge-call-end`}
                  >
                    <Badge badgeContent={""}>
                      <PhoneIphoneRoundedIcon />
                    </Badge>
                  </IconButton>
                </CustomTooltip>
              </div>
            ) : adminAuth.isLoading ? (
              <Skeleton
                className="custom-badge-back"
                variant="rectangular"
                width="80px"
                height={"37px"}
                sx={{ borderRadius: "5px" }}
              />
            ) : (
              <IconButton
                size="large"
                ria-describedby={"call-menu"}
                onClick={handleClickCall}
                color="inherit"
                className={`custom-badge-back ${
                  openCallMenu ? "active-black" : ""
                }`}
              >
                <Badge badgeContent={""}>
                  <PhoneIphoneRoundedIcon />
                </Badge>
              </IconButton>
            )}
            {enqueuedCall && (
              <div id={"audio-section"} className="call-detail-incoming-area">
                <IconButton
                  size="large"
                  // onClick={setCallOnMute}
                  // onClick={(e) => enqueueCall(incomingCall.customParameters.get("confFriendlyName"))}
                  disabled={isActive ? false : true}
                  color="inherit"
                  className={`custom-badge-call-mice muteD`}
                >
                  {" "}
                  <Badge badgeContent={""}>
                    <CustomTooltip title={"Call on Hold"}>
                      <MicOffRoundedIcon />
                    </CustomTooltip>
                  </Badge>
                </IconButton>
                <div
                  style={{
                    backgroundColor: isActive ? "rgba(46, 125, 50, 0.12)" : "",
                  }}
                  className="call-detail-incoming-outgoing"
                >
                  <CustomTooltip title="Open call detail">
                    <div className="call-detail-incoming-outgoing-title">
                      <span className="call-detail-incoming-outgoing-name">
                        {" "}
                        {enqueuedCall.name}
                      </span>{" "}
                      {/* {((incomingCall && incomingCall._status === "open") || (outgoingCall && outgoingCall._status === "open")) && (
                        <div className="timer-area">
                          {" "}
                          <div className="online-time-dot"></div> <Timer time={callTime} />
                        </div>
                      )} */}
                    </div>
                  </CustomTooltip>
                  <div className="call-detail-incoming-outgoing-des">
                    <span>"CALLER SET TO HOLD"</span>
                  </div>
                </div>
                <CustomTooltip title="End call">
                  <IconButton
                    size="large"
                    onClick={(e) => {}}
                    color="inherit"
                    className={`custom-badge-call-end`}
                  >
                    <Badge badgeContent={""}>
                      <PhoneIphoneRoundedIcon />
                    </Badge>
                  </IconButton>
                </CustomTooltip>
              </div>
            )}
            {adminAuth.isLoading ? (
              <ListItem
                secondaryAction={
                  <Skeleton
                    variant="rectangular"
                    width="40px"
                    height={"40px"}
                    sx={{ borderRadius: "5px" }}
                  />
                }
              >
                <ListItemText
                  primary={
                    <Skeleton
                      variant="rectangular"
                      width="90%"
                      height={"15px"}
                      sx={{ borderRadius: "5px", mb: "5px" }}
                    />
                  }
                  secondary={
                    <Skeleton
                      variant="rectangular"
                      width="70px"
                      height={"10px"}
                      sx={{ borderRadius: "5px" }}
                    />
                  }
                />
              </ListItem>
            ) : (
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                sx={{ paddingBottom: "0px", marginBottom: "12px" }}
                color="inherit"
              >
                <div className="profile-label">
                  {adminAuth?.adminDetail?.full_name}
                  <br />
                  {adminAuth?.adminDetail?.role?.name}
                </div>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                    color: "#2E7D32",
                  }}
                  variant={"dot"}
                  color={isActive ? "success" : "error"}
                  customColor={
                    isActive || callStatus === "Available"
                      ? "#2E7D32"
                      : callStatus === "Busy"
                      ? "#F3B30E"
                      : "rgba(219, 10, 10, 0.6)"
                  }
                >
                  <Avatar
                    variant="square"
                    sx={{
                      width: 40,
                      height: 38,
                      borderRadius: "6px",
                      textTransform: "uppercase",
                    }}
                    alt={`${adminAuth?.adminDetail?.first_name.slice(
                      0,
                      1
                    )}${adminAuth?.adminDetail?.last_name.slice(0, 1)}`}
                    src={adminAuth?.adminDetail?.profile_img || ""}
                  >
                    {adminAuth?.adminDetail?.first_name
                      ? `${adminAuth?.adminDetail?.first_name.slice(
                          0,
                          1
                        )}${adminAuth?.adminDetail?.last_name.slice(0, 1)}`
                      : null}
                  </Avatar>
                </StyledBadge>
              </IconButton>
            )}
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}

      <Snackbar
        open={open}
        autoHideDuration={"2000"}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={"success"}
          sx={{ width: "100%" }}
        >
          You have success Logout
        </Alert>
      </Snackbar>
      <Popover
        id={"call-menu"}
        open={openCallMenu}
        anchorEl={anchorCall}
        onClose={handleCloseCall}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{ zIndex: 10000000 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <CallPanel
          value={phoneModalTab}
          setValue={setPhoneModalTab}
          handleCloseCall={handleCloseCall}
          adminDetail={adminAuth.adminDetail}
          {...props}
        />
      </Popover>
      <Menu
        anchorEl={displayOnCallPanel}
        id="account-menu"
        open={Boolean(displayOnCallPanel)}
        onClose={() => {
          setDisplayOnCallPanel(false);
          setActiveCallForModal(null);
        }}
        sx={{ zIndex: 10000000 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <OnCallKeypadPanel
          {...props}
          {...{
            warnInprogress,
            setWarnInprogress,
            activeButton,
            setActiveButton,
            hold,
            setHold,
            warmCall,
            setWarmCall,
            selectedUser,
            setSelectedUser,
            callTransTimer,
            setCallTransTimer,
            timer,
            setTimer,
            pageNumber,
            setPageNumber,
            search,
            setSearch,
            activeCallForModal,
          }}
        />
      </Menu>
      {/*  For Text messages */}
      <Menu
        anchorEl={openTextMessage}
        id="account-menu"
        open={Boolean(openTextMessage)}
        onClose={() => {
          setOpenTextMessage(false);
        }}
        sx={{ zIndex: 10000000 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <TextMessages
          openTextMessage={openTextMessage}
          setOpenTextMessage={setOpenTextMessage}
          unreadMessageCount={unreadMessageCount}
          setUnreadMessageCount={setUnreadMessageCount}
          {...props}
        />
      </Menu>
      {/* end text message popup code */}
      {/*  For ALert widgets */}
      <Menu
        anchorEl={openAlert}
        id="account-menu"
        open={Boolean(openAlert)}
        onClose={() => {
          setOpenAlert(false);
        }}
        sx={{ zIndex: 10000000 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <AlertWidget
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          adminAuth={adminAuth}
          unreadAlertCount={unreadAlertCount}
          setUnreadAlertCount={setUnreadAlertCount}
          {...props}
        />
      </Menu>
      {/* end alert widgets popup code */}

      {/* Short alert notification for   few seconds */}

      <Menu
        anchorEl={openShortAlert}
        id="account-menu"
        open={Boolean(openShortAlert)}
        onClose={() => {
          setOpenShortAlert(false);
        }}
        sx={{ zIndex: 10000000 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 37,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {openShortAlert ? (
          <ShortAlertWidget
            openAlert={openShortAlert}
            setOpenAlert={setOpenShortAlert}
            alertDetail={alertDetail}
            adminAuth={adminAuth}
            {...props}
          />
        ) : null}
      </Menu>

      {/* Short message notification for  few seconds */}

      <Menu
        anchorEl={openShortMessage}
        id="openShortMessage"
        open={Boolean(openShortMessage)}
        onClose={() => {
          setOpenShortMessage(false);
        }}
        sx={{ zIndex: 10000000 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 37,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {openShortMessage ? (
          <ShortMessageWidget
            openShortMessage={openShortMessage}
            setOpenShortMessage={setOpenShortMessage}
            messageDetail={messageDetail}
            adminAuth={adminAuth}
            {...props}
          />
        ) : null}
      </Menu>
      {/*  for create global widgets  */}
      <Menu
        anchorEl={openWidgets}
        id="account-menu"
        open={Boolean(openWidgets)}
        onClose={() => {
          setOpenWidgets(false);
        }}
        sx={{ zIndex: 10000000 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.05)",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <WidgetsMenu
          openWidgets
          setOpenWidgets={setOpenWidgets}
          setErrorAlert={setErrorAlert}
          {...props}
        />
      </Menu>
      {/*  Dropdown menu for power search */}
      <Menu
        anchorEl={powerSearch.elementTarget}
        id="power-search-menu"
        open={powerSearch.isOpenSearch && openSearch}
        onClose={() => {
          dispatch(
            updateSearchParams({ isOpenSearch: false, elementTarget: null })
          );
          setOpenSearch(false);
          powerRef.current.value = "";
        }}
        sx={{ zIndex: 10000000 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <Box sx={{ width: "657px" }}>
          <PowerSearch
            search={powerRef?.current?.value}
            setOpenSearch={setOpenSearch}
            {...props}
          />
        </Box>
      </Menu>
      <SendEmailModal {...props} />
      <SendTextModal {...props} />
      <CreateProjectModal {...props} />
      <AddAppointmentModal {...props} />
      <AddAgentRequestModal {...props} />
      <AddLeadModal {...props} />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <UpdatePassword
        open={openUpdatePassword}
        setOpen={setOpenUpdatePassword}
        {...props}
      />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "TopNavBarStyle" })(TopNavBar);
