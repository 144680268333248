import React from "react";
import { Container, Box, Button, Typography } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import moment from "moment";
import Coverage from "./coverage";
import Schedule from "./schedule";
import { useNavigate } from "react-router-dom";
function StaffManagementView(props) {
  const { classes } = props;
  const navigates = useNavigate();
  return (
    <Box className={classes.pageRoot}>
      <Container>
        <Box className={classes.staffHeader}>
          <Box>
            <Typography className={classes.staffHeaderTitle}>
              Staff Management
            </Typography>
            <Typography className={classes.staffHeaderDate}>
              Today is {moment().format("dddd, MMMM Do YYYY")}
            </Typography>
          </Box>
          <Button
            className={classes.staffHeaderButton}
            size="small"
            variant="contained"
            onClick={() => navigates(`/staff_management/manage`)}
          >
            manage staff members
          </Button>
        </Box>
        <Coverage {...props} />
        <Schedule {...props} />
      </Container>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "StaffManagementStyle" })(
  StaffManagementView
);
