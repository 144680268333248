import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  categoryLoading: false,
  allKnowledgeBaseData: [],
  errMsg: null,
  searchData: [],
  overAllKnowledgeBaseData: [],
  categoryData: [],
  postDetail: {
    isLoading: true,
    data: {},
    errMsg: null,
  },
};

export const getOverAllKnowledgeBaseData = createAsyncThunk(
  "knowledgeBaseSearch/getOverAllKnowledgeBaseData",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/knowledgebase/post?all=true`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Cache-Control": "no-cache",
          },
        }
      );
      return resp.data?.data?.posts;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getKnowledgeBasePostDetail = createAsyncThunk(
  "knowledgeBaseSearch/getKnowledgeBasePostDetail",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/knowledgebase/post/${data.id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data?.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getAllKnowledgeBasedDataList = createAsyncThunk(
  "docsDetails/getAllKnowledgeBasedDataList",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_BASE_URL}api/knowledgebase/get-main-screen-data`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "get",
        url,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getAllKnowledgeBasedDataListUsingSearch = createAsyncThunk(
  "docsDetails/getAllKnowledgeBasedDataListUsingSearch",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_BASE_URL}api/knowledgebase/post/search?search=${data}`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "get",
        url,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getAllCategoryDataList = createAsyncThunk(
  "categoryDetails/getAllCategoryDataList",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_BASE_URL}api/knowledgebase/post-category?pageNumber=1&pageSize=25&sortBy=id&sortOrder=desc`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "get",
        url,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const createNewCategory = createAsyncThunk(
  "categoryDetails/createNewCategory",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_BASE_URL}api/knowledgebase/post-category`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "post",
        url,
        data: data,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            thunkAPI.dispatch(getAllCategoryDataList());
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const updateCategory = createAsyncThunk(
  "categoryDetails/=>",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_BASE_URL}api/knowledgebase/post-category/${data.id}`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "put",
        url,
        data: { title: data.title },
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            thunkAPI.dispatch(getAllCategoryDataList());
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const knowledgeBaseSearch = createSlice({
  name: "knowledgeBaseSearch",
  initialState,
  extraReducers: {
    [getAllKnowledgeBasedDataList.pending]: (state) => {
      state.loading = true;
    },
    [getAllKnowledgeBasedDataList.fulfilled]: (state, action) => {
      state.loading = false;
      state.allKnowledgeBaseData = action.payload.data;
    },
    [getAllKnowledgeBasedDataList.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },

    [getAllKnowledgeBasedDataListUsingSearch.pending]: (state) => {
      state.loading = true;
    },
    [getAllKnowledgeBasedDataListUsingSearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.searchData = action.payload.data;
    },
    [getOverAllKnowledgeBaseData.fulfilled]: (state, action) => {
      state.overAllKnowledgeBaseData = action.payload;
    },
    [getAllKnowledgeBasedDataListUsingSearch.rejected]: (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    },

    [getAllCategoryDataList.pending]: (state) => {
      state.categoryLoading = true;
    },
    [getAllCategoryDataList.fulfilled]: (state, action) => {
      state.categoryLoading = false;
      state.categoryData = action.payload.data;
    },
    [getAllCategoryDataList.rejected]: (state, action) => {
      state.categoryLoading = false;
      state.errMsg = action.payload;
    },
    [createNewCategory.pending]: (state) => {
      state.categoryLoading = true;
    },
    [createNewCategory.fulfilled]: (state, action) => {
      state.categoryLoading = false;
      state.categoryData = action.payload.data;
    },
    [createNewCategory.rejected]: (state, action) => {
      state.categoryLoading = false;
      state.errMsg = action.payload;
    },
    /* Knowledge base post detail */
    [getKnowledgeBasePostDetail.pending]: (state) => {
      state.postDetail.isLoading = true;
      state.postDetail.errMsg = null;
    },
    [getKnowledgeBasePostDetail.fulfilled]: (state, action) => {
      state.postDetail.isLoading = false;
      state.postDetail.errMsg = null;
      state.postDetail.data = action.payload;
    },
    [getKnowledgeBasePostDetail.rejected]: (state, action) => {
      state.postDetail.isLoading = false;
      state.postDetail.errMsg = action.payload;
    },
  },
});

export default knowledgeBaseSearch.reducer;
