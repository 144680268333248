import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, Tabs, Tab, Card, Button, Divider, ListItem, ListItemButton, ListItemIcon, Menu, List, ListItemText } from "@mui/material";
import { TabContext } from "@mui/lab";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import { Link, Navigate, useLocation } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { Container } from "@mui/system";
import { Route, Routes, useParams } from "react-router-dom";
import Documents from "./documents";
import TransactionsDetailOverview from "./details";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import TransactionDetailTasks from "./tasks";
import GavelRoundedIcon from "@mui/icons-material/GavelRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import EditIcon from "@mui/icons-material/Edit";
import CloseContract from "../modals/closeContract";
import TerminateContract from "../modals/terminateContract";
import AddTask from "../modals/addTask";
import { useDispatch, useSelector } from "react-redux";
import { getParticularContract } from "../../../redux/transactions";
import { getCommissionPlans } from "../../../redux/finance/commissionPlans";
import UploadPdfSplit from "../modals/splitpdfDocument";
import Dropzone from "react-dropzone";
import { CustomTooltip } from "../../../components/shared/tooltip";
import TransactionsDetailHud from "./hud";
import ResponseAlert from "../../../components/responseAlert";
import EditAddress from "../modals/editAddress";
import EditPrice from "../modals/editPrice";
import ListingDetailSkelton from "../../listings/listingDetail/skelton";
import TransactionHeaderCard from "./cards/TransactionHeaderCard";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import DrawIcon from "@mui/icons-material/Draw";
import CommissionBreakdown from "../modals/commissionBreakdown";
import AdditionalPaymentBreakdown from "../modals/additionalPaymentBreakdown";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
  height: "36px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  marginLeft: 0,
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  height: "35px",
  "@media (max-width: 1170px)": {
    display: "none",
  },
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.6) !important",
    },
    color: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function TransactionDetail(props) {
  const { classes } = props;
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [closeContract, setCloseContract] = React.useState(false);
  const [terminateContract, setTerminateContract] = React.useState(false);
  const [addTask, setAddTask] = React.useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [file, setFile] = React.useState("");
  const [openSplitPdf, setOpenSplitPdf] = React.useState(false);
  const [openAddress, setOpenAddress] = React.useState(false);
  const [openPrice, setOpenPrice] = React.useState(false);
  const [openCommission, setOpenCommission] = React.useState(false);
  const [openAdditionalPaymentBreakDown, setOpenAdditionalPaymentBreakDown] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const uploadBUttonRef = useRef();
  const dragRef = useRef();
  const ContractData = useSelector((state) => state.transactions.TransactionsContract.ContractData);

  useEffect(() => {
    if (!ContractData.isLoading) {
      dispatch(getParticularContract({ id }));
    }
    dispatch(getCommissionPlans({ pageNumber: 1, pageSize: 500000 }));
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFileChange = (Files) => {
    if (Files[0]?.type?.split("/")[1] === "pdf") {
      setFile(Files[0]);
      setOpenSplitPdf(true);
    } else {
      setErrorAlert({
        errorMsg: "Only Pdf file allow",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  const handleValidate = async () => {
    let checkedDocuments = await ContractData?.data?.transaction_documents?.filter((item) => item.status === "approved" || item.status === "exempt");
    if (checkedDocuments.length === ContractData?.data?.transaction_documents?.length) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckForCloseContract = async () => {
    if (ContractData?.data?.status === "active") {
      let checkFlag = await handleValidate();
      if (checkFlag) {
        setCloseContract(true);
      } else {
        setErrorAlert({
          errorMsg: `All documents should be approved then you can close the contract (if document not approved then it can exempted)`,
          errorType: "warning",
          isOpen: true,
        });
      }
    } else {
      setErrorAlert({
        errorMsg: `Contract status should be active!`,
        errorType: "warning",
        isOpen: true,
      });
    }
  };
  const handleCheckForTerminateContract = async () => {
    if (ContractData?.data?.status === "active") {
      let checkFlag = await handleValidate();
      if (checkFlag) {
        setTerminateContract(true);
      } else {
        setErrorAlert({
          errorMsg: `All documents should be approved then you can terminate the contract (if document not approved then it can exempted)`,
          errorType: "warning",
          isOpen: true,
        });
      }
    } else {
      setErrorAlert({
        errorMsg: `Contract status should be active!`,
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  const handleEditAddress = () => {
    setOpenAddress(true);
    handleMenuClose();
  };

  const handleEditPrice = () => {
    setOpenPrice(true);
    handleMenuClose();
  };
  const handleDragEnter = () => {
    uploadBUttonRef.current.style.backgroundColor = "#0B0909";
    uploadBUttonRef.current.style.color = "white";
    dragRef.current.style.display = "flex";
  };
  const handleDragLeave = () => {
    uploadBUttonRef.current.style.backgroundColor = "";
    uploadBUttonRef.current.style.color = "";
    dragRef.current.style.display = "none";
  };

  return (
    <Box>
      <Dropzone
        accept={".pdf"}
        noClick={true}
        noKeyboard={true}
        onDragEnter={() => handleDragEnter()}
        onDragLeave={() => handleDragLeave()}
        onDrop={(acceptedFiles) => {
          handleFileChange(acceptedFiles);
          handleDragLeave();
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <Box {...getRootProps()} sx={{ position: "sticky", top: "64px", zIndex: "100" }}>
            <Card variant="outlined">
              {" "}
              <Box className={classes.TopTransactionsBarArea}>
                <Box className={classes.TopTransactionsBarAreaLeft}>
                  <Box>
                    <TabContext>
                      <Box className={classes.TransactionsTabsUI}>
                        <Tabs
                          value={location.pathname}
                          TabIndicatorProps={{
                            sx: { background: "black", color: "black" },
                          }}
                          aria-label="transactions-tabs"
                          sx={{ justifyContent: "space-around" }}
                        >
                          <Tab
                            label="Detail"
                            value={`/transaction/contracts/contract-detail/${id}/detail-overview`}
                            to={`/transaction/contracts/contract-detail/${id}/detail-overview`}
                            component={Link}
                            sx={{ padding: "0px 5x" }}
                          />{" "}
                          <Tab
                            label="Documents"
                            value={`/transaction/contracts/contract-detail/${id}/documents`}
                            to={`/transaction/contracts/contract-detail/${id}/documents`}
                            component={Link}
                            sx={{ padding: "0px 5px" }}
                          />
                          <Tab
                            label="tasks"
                            value={`/transaction/contracts/contract-detail/${id}/tasks`}
                            to={`/transaction/contracts/contract-detail/${id}/tasks`}
                            component={Link}
                            sx={{ padding: "0px 5px" }}
                          />
                          <Tab
                            label="HUD"
                            value={`/transaction/contracts/contract-detail/${id}/hud`}
                            to={`/transaction/contracts/contract-detail/${id}/hud`}
                            component={Link}
                            sx={{ padding: "0px 5px" }}
                          />
                        </Tabs>
                      </Box>
                    </TabContext>
                  </Box>
                </Box>

                <Box className={classes.TopTransactionsBarAreaRight}>
                  <Box>
                    <Button
                      onClick={(e) => setAnchorEl(e.target)}
                      sx={{ marginLeft: "30px" }}
                      className={classes.uploadTransactionButton}
                      variant="outlined"
                      startIcon={<GavelRoundedIcon />}
                      color="inherit"
                    >
                      actions
                    </Button>
                  </Box>
                  <CustomTooltip title="Drag and drop file here or simple upload by click">
                    <Button
                      className={classes.uploadTransactionButton}
                      variant="outlined"
                      startIcon={<UploadFileIcon />}
                      color="inherit"
                      component={"label"}
                      ref={uploadBUttonRef}
                    >
                      <input onChange={(e) => handleFileChange(e.target.files)} hidden accept=".pdf" multiple type="file" />{" "}
                      <section>
                        <div>
                          <input {...getInputProps()} /> Upload
                        </div>
                      </section>
                    </Button>
                  </CustomTooltip>
                  <Button
                    onClick={() => setAddTask(true)}
                    className={classes.addTransactionButton}
                    variant="contained"
                    startIcon={<CreateNewFolderRoundedIcon />}
                  >
                    New Task
                  </Button>{" "}
                  <Search>
                    <StyledInputBase placeholder="Search" inputProps={{ "aria-label": "search" }} />
                  </Search>
                </Box>
              </Box>
            </Card>
          </Box>
        )}
      </Dropzone>
      {ContractData.isLoading ? (
        <ListingDetailSkelton />
      ) : (
        <Container className={classes.transactionsStatsArea} maxWidth="lg">
          <Dropzone
            accept={".pdf"}
            noClick={true}
            noKeyboard={true}
            onDragEnter={() => handleDragEnter()}
            onDragLeave={() => handleDragLeave()}
            onDrop={(acceptedFiles) => {
              handleFileChange(acceptedFiles);
              handleDragLeave();
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps()} sx={{ position: "relative" }}>
                {/* <Box ref={dragRef} className={classes.dropzoneArea}>
                  <Box className={classes.dropzoneAreaInner}>
                    <UploadFileIcon sx={{ fontSize: "34px" }} /> <Box>Drop file to upload</Box>
                  </Box>
                </Box> */}
                <TransactionHeaderCard id={id} ContractData={ContractData.data} {...props} />
              </Box>
            )}
          </Dropzone>
          <Routes>
            <Route path="/documents" element={<Documents ContractData={ContractData.data} />} />
            <Route path="/detail-overview" element={<TransactionsDetailOverview id={id} ContractData={ContractData.data} />} />
            <Route path="/tasks" element={<TransactionDetailTasks ContractData={ContractData.data} id={id} setErrorAlert={setErrorAlert} />} />
            <Route path="/hud" element={<TransactionsDetailHud id={id} ContractData={ContractData.data} />} />
            <Route exact path="/" element={<Navigate replace to="documents" />} />
          </Routes>
        </Container>
      )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        id={"transaction-detail-actions"}
        keepMounted
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            minWidth: "220px",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            paddingLeft: "0px",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <List disablePadding sx={{ width: "100%", maxWidth: 360 }}>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleCheckForTerminateContract()}>
              <ListItemIcon classes={{ root: classes.TransactionMenuIcon }}>
                <MonetizationOnRoundedIcon sx={{ color: "#EF5350" }} />
              </ListItemIcon>
              <ListItemText primary="Terminate Contract" />
            </ListItemButton>
          </ListItem>
          <Divider component="li" />
          <ListItem disablePadding>
            <ListItemButton disabled>
              <ListItemIcon classes={{ root: classes.TransactionMenuIcon }}>
                <MonetizationOnRoundedIcon sx={{ color: "#FFB400" }} />
              </ListItemIcon>
              <ListItemText primary="Pre-Close Contract" />
            </ListItemButton>
          </ListItem>
          <Divider component="li" />
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleCheckForCloseContract()}>
              <ListItemIcon classes={{ root: classes.TransactionMenuIcon }}>
                <MonetizationOnRoundedIcon sx={{ color: "#4CAF50" }} />
              </ListItemIcon>
              <ListItemText primary="Close Contract" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleEditAddress()}>
              <ListItemIcon classes={{ root: classes.TransactionMenuIcon }}>
                <EditIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
              </ListItemIcon>
              <ListItemText primary="Edit Address" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleEditPrice()}>
              <ListItemIcon classes={{ root: classes.TransactionMenuIcon }}>
                <DrawIcon sx={{ color: "#4CAF50" }} />
              </ListItemIcon>
              <ListItemText primary="Edit Price/Amounts" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setOpenCommission(true);
                handleMenuClose();
              }}
            >
              <ListItemIcon classes={{ root: classes.TransactionMenuIcon }}>
                <DesignServicesIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
              </ListItemIcon>
              <ListItemText primary="Commission Breakdown" />
            </ListItemButton>
          </ListItem>
          {ContractData?.data?.status?.startsWith("closed") && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setOpenAdditionalPaymentBreakDown(true);
                  handleMenuClose();
                }}
              >
                <ListItemIcon classes={{ root: classes.TransactionMenuIcon }}>
                  <DesignServicesIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
                </ListItemIcon>
                <ListItemText primary="Recieve Additional Payment" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Menu>
      <CloseContract open={closeContract} id={id} setOpen={setCloseContract} />
      <TerminateContract open={terminateContract} id={id} setOpen={setTerminateContract} />
      <AddTask open={addTask} id={id} setOpen={setAddTask} />
      <CommissionBreakdown open={openCommission} setOpen={setOpenCommission} />
      <AdditionalPaymentBreakdown open={openAdditionalPaymentBreakDown} setOpen={setOpenAdditionalPaymentBreakDown}  />
      <UploadPdfSplit ContractData={ContractData} open={openSplitPdf} id={id} file={file} setOpen={setOpenSplitPdf} />
      <EditAddress ContractData={ContractData.data} open={openAddress} setOpen={setOpenAddress} />
      <EditPrice ContractData={ContractData.data} open={openPrice} setOpen={setOpenPrice} />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "TransactionDetailStyles" })(TransactionDetail);
