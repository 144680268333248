import React, { useState } from "react";
import { Box, Typography, FormControlLabel, Switch } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import TimeChart from "./TimeChart";
function Schedule(props) {
  const { classes } = props;
  const [activeView, setActiveView] = useState("departments");
  return (
    <Box className={classes.ScheduleSection}>
      <Box className={classes.ScheduleSectionTop}>
        <Typography className={classes.ScheduleSectionHeading}>
          Today’s Schedule
        </Typography>
        <FormControlLabel
          value="start"
          control={
            <Switch
              checked={activeView === "individual" ? true : false}
              color="primary"
              onChange={(e) => {
                if (e.target?.checked) {
                  setActiveView("individual");
                } else {
                  setActiveView("departments");
                }
              }}
            />
          }
          label="Individual View"
          labelPlacement="start"
          sx={{
            "& .MuiTypography-root": {
              fontSize: "14px",
            },
          }}
        />
      </Box>
      <br />
      <TimeChart activeView={activeView} {...props} />
    </Box>
  );
}

export default withStyles(styleSheet, { name: "ScheduleStyle" })(Schedule);
