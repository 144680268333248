export const styleSheet = () => ({
  pageRoot: {
    minHeight: "100vh !important",
    padding: "70px 0px !important",
    backgroundColor: "white !important",
  },
  knowledgeBaseHeading: {
    fontSize: "26px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext !important",
    color: "black !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px !important",
  },
  knowledgeBaseHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    paddingBottom: "30px !important",
    borderBottom: "1px solid rgba(0, 0, 0, 0.10) !important",
  },
  /* categories section */
  categoriesSection: {
    padding: "30px 0px !important",
  },
  categoriesHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  categoriesHeading: {
    fontSize: "22px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px !important",
  },
  categoriesHeaderButton: {
    backgroundColor: "#B9B9B9 !important",
    color: "white !important",
    width: "45px !important",
    minWidth: "45px !important",
    height: "40px !important",
    borderRadius: "4px !important",
  },
  categoriesHeaderButtonActive: {
    backgroundColor: "#595959 !important",
    color: "white !important",
    width: "45px !important",
    minWidth: "45px !important",
    height: "40px !important",
    borderRadius: "4px !important",
  },
  categoriesListItem: {
    height: "70px !important",
    backgroundColor: "black !important",
    color: "white !important",
    borderRadius: "10px !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    cursor: "pointer !important",
    marginRight: "10px !important",
  },

  categoriesListItemContent: {
    fontWeight: "700 !important",
    fontFamily: "AvenirNext !important",
    fontSize: "16px !important",
    whiteSpace: "nowrap !important",
  },
  categoriesSlider: {
    padding: "30px 0px !important",
  },
  /* end categories section */
  /* category post section */
  categoriesPostsSection: {
    paddingBottom: "30px !important",
  },
  postViewButton: {
    borderRadius: "10px !important",
    fontSize: "12px !important",
    border: "1px solid rgba(0, 0, 0, 0.50) !important",
  },
  categoryPostItem: {
    width: "300px !important",
    marginRight: "15px !important",
    position: "relative",
  },
  categoryPostItemSearch: {
    width: "100% !important",
    position: "relative",
  },
  categoryPostImg: {
    borderRadius: "10px !important",
    overflow: "hidden !important",
    boxShadow: "2px 4px 4px 0px rgba(0, 0, 0, 0.25) !important",
    height: "180px !important",
    position: "relative",
    "&:hover": {
      "&:before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.40)",
        zIndex: 1,
      },
      cursor: "pointer !important",
      "& .playIcon": {
        display: "block !important",
      },
      "& .postAuthor01": {
        display: "block !important",
        zIndex: 999,
      },
      "& .createdBy01": {
        display: "block !important",
        zIndex: 999,
      },
    },
  },
  postAuthor: {
    position: "absolute !important",
    bottom: "8px !important",
    right: "10px !important",
    color: "white !important",
    fontFamily: "AvenirNext !important",
    fontSize: "12px !important",
    zIndex: 999,
  },
  createdBy: {
    position: "absolute !important",
    bottom: "8px !important",
    left: "10px !important",
    color: "white !important",
    fontFamily: "AvenirNext !important",
    fontSize: "12px !important",
    zIndex: 999,
  },
  categoryPostIcon: {
    position: "absolute !important",
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%, -50%) !important",
    color: "white !important",
    zIndex: 10,
    fontSize: "40px !important",
  },
  categoryPostContent: {
    fontWeight: "500 !important",
    fontFamily: "AvenirNext !important",
    fontSize: "14px !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "flex-start !important",
    gap: "6px !important",
    color: "black",
    marginTop: "14px !important",
  },
  categoriesItems: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "flex-start !important",
    gap: "10px !important",
    overflow: "hidden !important",
    padding: "30px 0px !important",
  },
  categoriesListItemLoading: {
    minWidth: "230px !important",
    height: "74px !important",
    color: "white !important",
    borderRadius: "10px !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    cursor: "pointer !important",
  },
  categoryBackLink: {
    color: "rgba(0, 0, 0, 0.60) !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext !important",
    fontSize: "14px !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "flex-start !important",
    gap: "6px !important",
    textTransform: "none !important",
  },
  categoryDetailSection: {
    padding: "30px 0px !important",
  },
  categoryHeaderRight: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    alignItems: "10px !important",
    gap: "10px !important",
  },
  /* postsListView */
  postsListView: {
    marginTop: "30px !important",
    borderRadius: "10px !important",
    "& .MuiListItemText-primary": {
      color: "rgba(0, 0, 0) !important",
      fontWeight: "600 !important",
      fontFamily: "AvenirNext !important",
      fontSize: "20px !important",
    },
    "& .MuiListItemText-secondary": {
      color: "rgba(0, 0, 0) !important",
      fontWeight: "500 !important",
      fontFamily: "AvenirNext !important",
      fontSize: "15px !important",
      marginTop: "30px !important",
    },
  },
  listViewActionHeading: {
    color: "rgba(0, 0, 0) !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    "& span": { fontWeight: "500 !important" },
  },
  listViewActionBottom: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    marginTop: "30px !important",
  },
  postsListViewList: {
    width: "100% !important",
    bgcolor: "background.paper !important",
    height: "800px !important",
    overflow: "auto !important",
    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
      borderRadius: "20px",
      height: "12px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "white",
    },
    "&::-webkit-scrollbar": {
      width: "12px",
    },
  },
});
