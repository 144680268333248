import React, { useState, useRef } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Card,
  CardHeader,
  Typography,
} from "@mui/material";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { useSelector, useDispatch } from "react-redux";
import { addCommentOnLockboxDetail } from "../../../../redux/agents/agentRosterDetail/inventory/addComment";
import Avatar from "../../../../components/uiElements/avatar";
import moment from "moment";
function CommentSection(props) {
  let { classes, lockBoxDetail, height } = props;
  const [buttonTop, setButtonTop] = useState(23);
  const commentValue = useRef();
  const scrollTo = useRef();

  const dispatch = useDispatch();
  const adminAuth = useSelector((state) => state.adminAuth);
  const comments = useSelector(
    (state) => state.agentRoster.agentInventory.comments
  );

  const handleSendComment = () => {
    if (commentValue.current.value) {
      let obj = {
        comment: commentValue.current.value,
        InventoryId: lockBoxDetail?.InventoryId,
        commentData: {
          agent: {
            ...adminAuth?.adminDetail,
            profile_images: {
              profile_img: adminAuth?.adminDetail?.profile_img,
            },
          },
          Comment: commentValue.current.value,
          createdAt: new Date().toISOString(),
        },
      };
      dispatch(addCommentOnLockboxDetail(obj));
      commentValue.current.value = "";
    }
    setTimeout(() => {
      scrollTo.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }, 100);
    setButtonTop(23);
  };
  return (
    <Box className={classes.commentSectionBox} sx={{ height: height }}>
      <Box className={classes.commentSectionTop}>Comments</Box>
      <Box
        className={classes.messageSection}
        sx={{ height: `calc(100% - ${87 + buttonTop}px)`, overflow: "auto" }}
      >
        {comments?.length
          ? comments.map((item, index) => (
              <Card key={index} sx={{ boxShadow: "none" }}>
                <CardHeader
                  avatar={
                    <Avatar
                      src={
                        item.agent?.profile_images?.profile_img ||
                        item.agent?.profile_images?.profile_img_thumbnail
                      }
                      sx={{
                        width: "25px",
                        height: "25px",
                        fontSize: "12px",
                        mr: "-7px",
                      }}
                      aria-label="recipe"
                    >
                      {`${item.agent?.first_name?.slice(
                        0,
                        1
                      )}${item.agent?.last_name?.slice(0, 1)}`}
                    </Avatar>
                  }
                  classes={{
                    title: classes.messageSectionProfileName,
                  }}
                  sx={{ paddingLeft: "0px" }}
                  action={
                    <Typography className={classes.commentTime}>
                      {" "}
                      {moment(item.createdAt || "").format("LT")}{" "}
                      {moment(item.createdAt || "").format("MM/DD/YY")}{" "}
                    </Typography>
                  }
                  title={
                    <Box>
                      {item.agent?.first_name ||
                        item.agent?.full_name?.split(" ")[0]}{" "}
                      <Box sx={{ fontWeight: "400", display: "inline-block" }}>
                        commented
                      </Box>
                    </Box>
                  }
                />
                <Card variant="outlined" className={classes.commentContent}>
                  <Typography
                    className={classes.commentContentText}
                    variant="body2"
                    color="inherit"
                  >
                    {item.Comment}
                  </Typography>
                </Card>
              </Card>
            ))
          : null}
        <div ref={scrollTo} />
      </Box>
      <Box className={classes.writeCommentSection}>
        <OutlinedInput
          fullWidth
          size="small"
          multiline
          maxRows={10}
          id="comment-box-for-lockbox"
          inputRef={commentValue}
          onChange={(e) => {
            setTimeout(() => {
              if (e.target.clientHeight !== buttonTop) {
                setButtonTop(e.target.clientHeight);
              }
            }, 1);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              handleSendComment();
            }
          }}
          InputProps={{
            classes: {
              adornedEnd: classes.adornedEnd,
            },
          }}
          endAdornment={
            <InputAdornment
              sx={{ mt: "auto", mb: "12px", mr: "7px" }}
              position="end"
            >
              <IconButton
                onClick={handleSendComment}
                aria-label="toggle password visibility"
                edge="end"
                sx={{ padding: "4px 3px 4px 5px" }}
              >
                <SendRoundedIcon
                  fontSize="small"
                  sx={{ color: "rgba(0, 0, 0, 0.25)", fontSize: "18px" }}
                />
              </IconButton>
            </InputAdornment>
          }
          startAdornment={
            <Avatar
              sx={{
                width: "25px",
                height: "25px",
                fontSize: "12px",
                mr: "7px",
                ml: "-5px",
              }}
              alt="Remy Sharp"
              src={adminAuth?.adminDetail?.profile_img}
            />
          }
        />
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, {
  name: "CommentSectionStyle",
})(CommentSection);
