import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { saveSearch } from "./saveSearch";
import { searchKeys } from "../../../utils/propertyData";

export const sortQuery = (q) => {
  let newQArray = q
    ?.replaceAll("undefined:undefined", "")
    // ?.replace("q=", "")
    ?.split(" AND ");
  let orArray = [];
  let andArray = [];
  newQArray?.forEach((it, i) => {
    if (
      searchKeys?.includes(it?.split(":")?.[0]) ||
      searchKeys?.includes(it?.slice(1, -1)?.split(":")?.[0])
    ) {
      if (it?.slice(0, 1) === "(" && it?.slice(-1) === ")") {
        orArray.push(it?.slice(1, -1));
      } else {
        orArray.push(it);
      }
    } else {
      andArray.push(it);
    }
  });

  if (orArray?.length || andArray?.length) {
    return `${andArray?.join(" AND ")} ${
      orArray?.length ? ` AND (${orArray?.join(" OR ")})` : ""
    }`;
  } else {
    return "";
  }
};

export const getPropertyList = createAsyncThunk(
  "LeadSavedSearches/getPropertyList",
  async (data, thunkAPI) => {
    try {
      let searchParams = {
        ...thunkAPI.getState()?.leads?.savedSearches.searchParams,
      };

      let site_id =
        thunkAPI.getState()?.leads?.savedSearches.searchList.data?.site_id?._id;
      let token =
        thunkAPI.getState()?.leads?.savedSearches.searchList.data?.token;
      let query = "";
      let query1 = "";
      let newObj = {};
      Object.keys(searchParams).forEach((k) => {
        if (
          (!searchParams[k] && searchParams[k] !== 0) ||
          k === undefined ||
          k === null ||
          k === ""
        ) {
          delete searchParams[k];
        } else {
          if (
            query &&
            k &&
            k !== "sort" &&
            k !== "start" &&
            k !== "rows" &&
            searchParams[k]
          ) {
            query = query + ` AND ${k}:${searchParams[k]}`;
          } else if (
            k &&
            k !== "sort" &&
            k !== "start" &&
            k !== "rows" &&
            searchParams[k]
          ) {
            query = `q=${k}:${searchParams[k]}`;
          }
          if (
            (k === "sort" || k === "start" || k === "rows") &&
            searchParams[k]
          ) {
            newObj[k] = searchParams[k];
            query1 = query1 + `&${k}=${searchParams[k]}`;
          }
        }
      });

      let url = `${
        process.env.REACT_APP_IDX_BASE_URL
      }api/v1/properties/${site_id}?${
        data?.isAND ? query : sortQuery(query)
      }${query1}&editSource=iframe${
        data?.isAND ? `&geoOperator=AND` : `&geoOperator=OR`
      }`;
      if (data?.type === "collection") {
        url = `${
          process.env.REACT_APP_IDX_BASE_URL
        }api/v1/properties/${site_id}?q=ListingId:999999&editSource=iframe&type=${
          data?.type || "collection"
        }&name=${data?.name}`;
      }
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (resp.data?.meta?.searchId) {
        thunkAPI.dispatch(
          saveSearch({
            title: data?.title,
            searchId: resp.data?.meta?.searchId,
            emailNotification: data?.emailNotification,
            handleSuccess: data?.handleSuccess,
            handleError: data?.handleError,
            geoOperator:
              data?.isAND && data?.type !== "collection" ? "AND" : "OR",
            type: data?.type,
            name: data?.name,
            query: {
              q: query?.replace("q=", ""),
              ...newObj,
            },
          })
        );
      }
      return { ...resp.data };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
