import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import DeductionRules from "../../../components/deductionRules";
function AdditionalPaymentBreakdown(props) {
  let { open, setOpen, classes, id } = props;
  return (
    <React.Fragment>
      <Dialog
        open={open}
        scroll="body"
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        // classes={{ paper: classes.customDialog }}
      >
        <DialogTitle className={classes.CloseContractHeading} id="alert-dialog-titlesd">
          Additional Payment Breakdown
          <IconButton onClick={() => setOpen(false)} className={classes.closeButton}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DeductionRules closeAdditionalPaymentBreakageModal={(e) => setOpen(false)} amountTextFieldRequired={true} amountTextFieldLabel={"New income"} />
      </Dialog>
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddTaskStyle" })(AdditionalPaymentBreakdown);
