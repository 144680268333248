import { createSlice } from "@reduxjs/toolkit";
import { getCoverage } from "./getCoverage";
import { getStaffUsers } from "./getStaffUsers";
import { getSpecificStaff } from "./getSpecificStaff";
import { getTrainedTask } from "./getTrainedTask";
import { updateTrainedTask } from "./updateTrainedTask";
const initialState = {
  coverage: { success: false, data: undefined, isLoading: false, errMsg: null },
  staffUsers: {
    success: false,
    data: [],
    count: 20,
    isLoading: false,
    errMsg: null,
  },
  specificStaff: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  trainedTask: {
    success: false,
    data: {},
    isLoading: false,
    errMsg: null,
  },
  updateTrained: {
    isLoading: false,
    data: {},
    errMsg: null,
  },
};
const Staff = createSlice({
  name: "Staff",
  initialState,
  reducers: {},
  extraReducers: {
    [getCoverage.pending]: (state) => {
      state.coverage.isLoading = true;
      state.coverage.errMsg = null;
      state.coverage.success = false;
    },
    [getCoverage.fulfilled]: (state, action) => {
      state.coverage.success = true;
      state.coverage.isLoading = false;
      state.coverage.data = action.payload;
    },
    [getCoverage.rejected]: (state, action) => {
      state.coverage.isLoading = false;
      state.coverage.success = false;
      state.coverage.errMsg = action.payload;
    },
    // get staff users
    [getStaffUsers.pending]: (state) => {
      state.staffUsers.isLoading = true;
      state.staffUsers.errMsg = null;
      state.staffUsers.success = false;
    },
    [getStaffUsers.fulfilled]: (state, action) => {
      state.staffUsers.success = true;
      state.staffUsers.isLoading = false;
      if (action?.meta?.arg?.pageNumber > 1) {
        state.staffUsers.data = [
          ...state.staffUsers.data,
          ...action.payload.staffUsers,
        ];
      } else {
        state.staffUsers.data = action.payload.staffUsers;
      }
    },
    [getStaffUsers.rejected]: (state, action) => {
      state.staffUsers.isLoading = false;
      state.staffUsers.success = false;
      state.staffUsers.errMsg = action.payload;
    },
    // get specific staff
    [getSpecificStaff.pending]: (state) => {
      state.specificStaff.isLoading = true;
      state.specificStaff.errMsg = null;
      state.specificStaff.success = false;
    },
    [getSpecificStaff.fulfilled]: (state, action) => {
      state.specificStaff.success = true;
      state.specificStaff.isLoading = false;
      state.specificStaff.data = action.payload;
    },
    [getSpecificStaff.rejected]: (state, action) => {
      state.specificStaff.isLoading = false;
      state.specificStaff.success = false;
      state.specificStaff.errMsg = action.payload;
    },
    // get trained task
    [getTrainedTask.pending]: (state) => {
      state.trainedTask.isLoading = true;
      state.trainedTask.errMsg = null;
      state.trainedTask.success = false;
    },
    [getTrainedTask.fulfilled]: (state, action) => {
      state.trainedTask.success = true;
      state.trainedTask.isLoading = false;
      state.trainedTask.data = action.payload;
    },
    [getTrainedTask.rejected]: (state, action) => {
      state.trainedTask.isLoading = false;
      state.trainedTask.success = false;
      state.trainedTask.errMsg = action.payload;
    },
    // update trained task
    [updateTrainedTask.pending]: (state) => {
      state.updateTrained.isLoading = true;
      state.updateTrained.errMsg = null;
      state.updateTrained.success = false;
    },
    [updateTrainedTask.fulfilled]: (state, action) => {
      state.updateTrained.success = true;
      state.updateTrained.isLoading = false;
      state.updateTrained.data = action.payload;
    },
    [updateTrainedTask.rejected]: (state, action) => {
      state.updateTrained.isLoading = false;
      state.updateTrained.success = false;
      state.updateTrained.errMsg = action.payload;
    },
  },
});

export const {} = Staff.actions;
export default Staff.reducer;
export * from "./getCoverage";
export * from "./getStaffUsers";
export * from "./getSpecificStaff";
export * from "./updateUserStaff";
export * from "./getTrainedTask";
export * from "./updateTrainedTask";
