export const styleSheet = () => ({
  ScheduleSection: {
    backgroundColor: "white !important",
    borderRadius: "20px !important",
    padding: "16px 24px !important",
    marginTop: "24px !important",
  },
  ScheduleSectionHeading: {
    fontSize: "22px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    fontWeight: "600 !important",
  },
  ScheduleSectionTop: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  timeChangeSection: {
    overflowY: "hidden !important",
    overflowX: "auto !important",
    minHeight: "300px !important",
    paddingLeft: "40px !important",
    paddingBottom: "30px !important",
    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
      borderRadius: "20px",
      height: "12px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "white",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
      height: "12px !important",
    },
    position: "relative !important",
  },
  timeChangeSectionTop: {
    position: "relative !important",
    paddingBottom: "8px !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  timeChangeSectionTopItem: {
    width: "91px !important",
    minWidth: "91px !important",
    position: "relative !important",
  },
  paddingSection: {
    position: "absolute !important",
    bottom: "0px !important",
    left: "0px !important",
    right: "0px !important",
    width: "1365px !important",
    height: "20px  !important",
    background: "white !important",
  },
});
