import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Skeleton,
  Checkbox,
  IconButton,
  Stack,
  Grid,
  Avatar,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ResponseAlert from "../../../../components/responseAlert";
import DataNotFound from "../../../../components/notFound/dataNotFound";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Dropzone from "react-dropzone";
import dragIcon from "../../../../assets/dragIcon.png";
import { CustomTooltip } from "../../../../components/shared/tooltip";
import {
  uploadPublicDataImage,
  deletePublicDataImage,
  handleUpdateImages,
} from "../../../../redux/listings/publicData";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import StarTwoToneIcon from "@mui/icons-material/StarTwoTone";
import StarIcon from "@mui/icons-material/Star";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import axios from "axios";

const imgExtension = ["png", "gif", "jpeg", "jpg"];
function TransactionsDetailPhotos(props) {
  const [primaryPhoto, setPrimaryPhoto] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [selectedImages, setSelectedImages] = useState([]);
  let { classes, publicDataDetail, isLoading } = props;
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.listings.publicData.upload);
  const deleteImage = useSelector(
    (state) => state.listings.publicData.deleteImage
  );

  useEffect(() => {
    if (
      publicDataDetail.images?.length &&
      !primaryPhoto &&
      publicDataDetail.images.find((item) => item.isPrimary)
    ) {
      setPrimaryPhoto(
        publicDataDetail.images.find((item) => item.isPrimary)?.id
      );
    }
  }, [publicDataDetail?.id]);

  const handleDragEnter = () => {
    document.getElementById(`upload-file-for-listing-data`).style.border =
      "2px dotted black";
    document.getElementById(`upload-file-for-listing-data-icon`).style.display =
      "flex";
  };
  const handleDragLeave = () => {
    document.getElementById(`upload-file-for-listing-data`).style.border =
      "none";
    document.getElementById(`upload-file-for-listing-data-icon`).style.display =
      "none";
  };

  const handleSuccess = (update) => {
    setErrorAlert({
      errorMsg: `You have successfully uploaded the image`,
      errorType: "success",
      isOpen: true,
    });
  };
  const handleSuccessDelete = () => {
    setErrorAlert({
      errorMsg: `You have successfully deleted  the selected images`,
      errorType: "success",
      isOpen: true,
    });
    setSelectedImages([]);
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleFileChange = (files) => {
    const filesArray = Array.from(files);
    filesArray.sort((a, b) =>
      a.name.localeCompare(b.name, "en", { sensitivity: "base", numeric: true })
    );
    const Data = new FormData();
    filesArray?.map((file) => {
      Data.append("img[]", file);
    });
    let obj = {
      schema: Data,
      listing_public_data_id: publicDataDetail.id,
      handleSuccess,
      handleError,
    };
    dispatch(uploadPublicDataImage(obj));
  };

  const handleFileChangeDrop = (Files, data) => {
    const filesArray = Array.from(Files);
    let isValidate = true;
    filesArray?.map((file) => {
      if (!imgExtension.includes(file?.type?.split("/")[1]) && isValidate) {
        isValidate = false;
      }
    });
    if (isValidate) {
      handleFileChange(Files, data);
    } else {
      setErrorAlert({
        errorMsg: `Only image file allowed with following extension ${imgExtension.join(
          ","
        )}`,
        errorType: "warning",
        isOpen: true,
      });
    }
  };
  const handleUpdateSelect = (id) => {
    let newSelectedImages = [...selectedImages];
    if (selectedImages.includes(id)) {
      let index = newSelectedImages.findIndex((item) => item === id);
      newSelectedImages.splice(index, 1);
    } else {
      newSelectedImages.push(id);
    }
    setSelectedImages(newSelectedImages);
  };
  const handleSelectAll = () => {
    setSelectedImages(publicDataDetail.images.map((item) => item.id));
  };

  const handleDeleteImage = () => {
    if (selectedImages?.length) {
      let obj = {
        schema: { image_id: selectedImages },
        listing_public_data_id: publicDataDetail.id,
        handleSuccessDelete,
        handleError,
      };
      dispatch(deletePublicDataImage(obj));
    }
  };
  const handleUpdatePrimaryPhoto = async (id) => {
    setPrimaryPhoto(id);
    const url = `${process.env.REACT_APP_BASE_URL}api/transactions/listing/public-data/media/primary/${publicDataDetail.id}`;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      method: "put",
      url,
      data: JSON.stringify({ image_id: id }),
    };
    await axios(options);
  };
  const reorderDeliverables = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    let sourceIndex =
      4 * (Number(result.source.droppableId?.split("-")[1] || 0) - 1) +
      result.source.index;
    let destinationIndex =
      4 * (Number(result.destination.droppableId?.split("-")[1] || 0) - 1) +
      result.destination.index;
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let newImage = [...publicDataDetail.images];

    const reorderedItems = reorderDeliverables(
      newImage,
      sourceIndex,
      destinationIndex
    );
    dispatch(handleUpdateImages(reorderedItems));
    const orders = reorderedItems.map((item, index) => ({
      order: index + 1,
      id: item.id,
    }));
    const url = `${process.env.REACT_APP_BASE_URL}api/transactions/listing/public-data/media/re-order/${publicDataDetail.id}`;
    let options = {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
      method: "put",
      url,
      data: JSON.stringify([...orders]),
    };
    await axios(options);
  };

  return (
    <React.Fragment>
      <Card className={classes.photosCard}>
        <CardHeader
          classes={{ title: classes.overViewTabCardHeading }}
          action={
            <Stack direction="row" sx={{ mr: "10px", mt: "5px" }} spacing={0}>
              <IconButton
                sx={{
                  display:
                    selectedImages?.length &&
                    publicDataDetail.images?.length !== selectedImages?.length
                      ? ""
                      : "none",
                }}
                onClick={handleSelectAll}
                size="small"
              >
                <CustomTooltip title="select all photos">
                  <CheckCircleOutlineRoundedIcon />
                </CustomTooltip>
              </IconButton>
              <IconButton
                sx={{
                  display: selectedImages?.length ? "" : "none",
                  mr: "6.5px",
                }}
                onClick={handleDeleteImage}
                size="small"
              >
                <CustomTooltip title="Delete selected photos">
                  <DeleteRoundedIcon />
                </CustomTooltip>
              </IconButton>
              <LoadingButton
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#0B0909",
                  "&:hover": { backgroundColor: "#0f0a0a" },
                  color: "white",
                  fontWeight: "400",
                  height: "31px",
                }}
                startIcon={<AddRoundedIcon sx={{ mr: "-5px", mt: "-3px" }} />}
                component="label"
                loadingPosition="start"
                loading={upload.isLoading}
              >
                <input
                  onChange={(e) => handleFileChange(e.target.files)}
                  onClick={(event) => (event.target.value = "")}
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                />
                Add
              </LoadingButton>
            </Stack>
          }
          sx={{ paddingLeft: "0px", paddingRight: "8px", mb: "12px" }}
          title="Photos"
        />
        <Box
          id="upload-file-for-listing-data"
          className={classes.photosCardContent}
          sx={{ borderRadius: "15px !important" }}
        >
          <Dropzone
            accept="image/*"
            noClick={true}
            noKeyboard={true}
            onDragEnter={() => handleDragEnter()}
            onDragLeave={() => handleDragLeave()}
            onDrop={(acceptedFiles) => {
              handleFileChangeDrop(acceptedFiles);
              handleDragLeave();
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps()} sx={{ mt: "15px" }}>
                <Box
                  id={`upload-file-for-listing-data-icon`}
                  sx={{ top: "20px" }}
                  className={classes.dropzoneArea}
                >
                  <Box className={classes.dropzoneAreaInner}>
                    <img
                      src={dragIcon}
                      alt="dragIcon"
                      style={{ width: "25px", marginRight: "10px" }}
                    />{" "}
                    <Box>Drop file to upload.</Box>
                  </Box>
                </Box>
                {isLoading ? (
                  <Grid sx={{ mt: "0px" }} container spacing={2}>
                    {Array.from(Array(8).keys()).map(() => (
                      <Grid item lg={3} md={6} sm={12}>
                        <Skeleton
                          variant="rounded"
                          animation="wave"
                          width="100%"
                          height="113px"
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : publicDataDetail.images?.length ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    {publicDataDetail.images?.map((row, rowIndex) => {
                      if (
                        (rowIndex % 4 == 0 && rowIndex == 0) ||
                        (rowIndex + 1) % 4 == 0
                      ) {
                        return (
                          <Droppable
                            droppableId={`line-${
                              rowIndex == 0 ? "1" : `${(rowIndex + 1) / 4 + 1}`
                            }`}
                            direction="horizontal"
                          >
                            {(provided) => (
                              <Grid
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={classes.deliverables}
                                container
                                spacing={2}
                              >
                                {publicDataDetail.images
                                  .slice(
                                    rowIndex == 0 ? 0 : rowIndex + 1,
                                    (rowIndex == 0 ? 0 : rowIndex + 1) + 4
                                  )
                                  .map((item, index) => (
                                    <Grid
                                      key={item.id}
                                      item
                                      xs={3}
                                      sx={{ mb: "10px" }}
                                    >
                                      <Draggable
                                        key={item.id.toString()}
                                        draggableId={item.id.toString()}
                                        index={index}
                                      >
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <Box
                                              className={
                                                classes.publicImagesItem
                                              }
                                              sx={{
                                                "&:hover": {
                                                  "& .star_icon_primary": {
                                                    display: "block !important",
                                                  },
                                                  "& .checkbox_primary_listing":
                                                    {
                                                      display:
                                                        "flex !important",
                                                    },
                                                },
                                              }}
                                            >
                                              <Checkbox
                                                onClick={() =>
                                                  handleUpdateSelect(item.id)
                                                }
                                                checked={selectedImages.includes(
                                                  item.id
                                                )}
                                                sx={{
                                                  display:
                                                    selectedImages?.length
                                                      ? "flex !important"
                                                      : "",
                                                }}
                                                className={`${classes.publicImagesItemCheckbox} checkbox_primary_listing`}
                                              />
                                              <IconButton
                                                onClick={() =>
                                                  handleUpdatePrimaryPhoto(
                                                    item.id
                                                  )
                                                }
                                                sx={{
                                                  display:
                                                    item.id === primaryPhoto
                                                      ? "block !important"
                                                      : "",
                                                }}
                                                size="small"
                                                className={`${classes.startIconPhoto} star_icon_primary`}
                                              >
                                                {item.id === primaryPhoto ? (
                                                  <CustomTooltip title="Primary photo">
                                                    <StarIcon
                                                      sx={{
                                                        color:
                                                          "yellow !important",
                                                      }}
                                                    />
                                                  </CustomTooltip>
                                                ) : (
                                                  <CustomTooltip title="Set as primary">
                                                    <StarTwoToneIcon />
                                                  </CustomTooltip>
                                                )}
                                              </IconButton>
                                              <Avatar
                                                sx={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                                src={item.MediaUrl}
                                                variant="rounded"
                                              />
                                            </Box>
                                          </div>
                                        )}
                                      </Draggable>
                                    </Grid>
                                  ))}
                              </Grid>
                            )}
                          </Droppable>
                        );
                      }
                    })}
                  </DragDropContext>
                ) : (
                  <DataNotFound
                    color="#BDBDBD"
                    fontSize="20px"
                    icon={
                      <ImageRoundedIcon
                        sx={{ fontSize: "100px", color: "#BDBDBD" }}
                        fontSize="large"
                      />
                    }
                    title="No Photos Yet..."
                  />
                )}
              </Box>
            )}
          </Dropzone>
        </Box>
      </Card>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <Backdrop color="inherit" invisible={true} open={deleteImage.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}
export default withStyles(styleSheet, {
  name: "TransactionsDetailPhotosStyle",
})(TransactionsDetailPhotos);
