import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const checkUnreadNotificationStatus = createAsyncThunk(
  "MessagesAndAlerts/checkUnreadNotificationStatus",
  async (data, thunkAPI) => {
    const { handleSuccess } = data;
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/notifications/get-unreaded-status`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (resp.data && handleSuccess) {
        handleSuccess(resp.data);
      }
      return {
        ...resp.data,
      };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
