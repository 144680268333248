import React, { useState, useEffect } from "react";
import {
  Box,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Dialog,
} from "@mui/material";
import {
  getDraftProjects,
  deleteDraftProject,
} from "../../../../redux/projects/projects";
import DataGridComponent from "../../DataGridComponent";
import { useSelector, useDispatch } from "react-redux";
import Avatars from "../../../../components/uiElements/avatar/index";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import moment from "moment";
import TaskConfirmation from "../taskConfirm.tsx";
import { toast } from "react-toastify";
import CreateProject from "../createProject/index.jsx";

function DraftProject(props) {
  const { departmentID } = props;
  const [openProjectForm, setOpenProjectForm] = useState(false);
  const [draftDetail, setDraftDetail] = useState(false);
  const [projectDetail, setProjectDetail] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const dispatch = useDispatch();
  const draftProjects = useSelector((state) => state.project.draftProjects);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const searchText = useSelector((state) => state.project.searchText);
  useEffect(() => {
    dispatch(
      getDraftProjects({
        pageNumber: 1,
        departmentID,
      })
    );
  }, [searchText]);
  const columns = [
    {
      field: "project",
      headerName: "Project",
      renderCell: ({ row }) => {
        return (
          <div>
            <span
              style={{
                fontWeight: "700",
                display: "block",
                fontSize: "14px",
                lineHeight: "20.02px",
                letterSpacing: "0.5px",
              }}
            >
              {row?.project_name || "Untitled"}
            </span>
            <span
              style={{
                fontWeight: "400",
                display: "block",
                fontSize: "14px",
                lineHeight: "20.02px",
              }}
            >
              {row?.title}
            </span>
          </div>
        );
      },
      flex: 1,
      sortable: true,
      filterable: false,
    },
    {
      field: "agent",
      headerName: "Agent",
      renderCell: (params) => {
        return params?.row?.agent ? (
          <ListItem
            alignItems="flex-start"
            sx={{ width: "100%", maxWidth: 360, padding: 0, margin: 0 }}
          >
            <ListItemAvatar>
              <Avatars
                sx={{
                  marginRight: "10px",
                  borderRadius: "4px",
                  fontSize: "15px",
                }}
                src={params.value?.profile_images?.profile_img_thumbnail}
                variant="square"
              >{`${params?.value?.first_name?.slice(0, 1) || "N"}${
                params?.value?.last_name?.slice(0, 1) || "A"
              }`}</Avatars>
            </ListItemAvatar>
            <ListItemText
              primary={params?.value?.first_name}
              secondary={
                <React.Fragment>{params?.value?.last_name}</React.Fragment>
              }
            />
          </ListItem>
        ) : null;
      },
      width: 170,
      flex: 1.5,
      sortable: true,
      filterable: false,
    },
    {
      field: "created_by_id",
      headerName: "Created By",
      renderCell: (params) => {
        let agentDetail = null;
        if (params.value) {
          agentDetail = agentRosterList?.list.find(
            (item) => item.id === params.value
          );
        }
        return params.value && agentDetail ? (
          <ListItem
            alignItems="flex-start"
            sx={{ width: "100%", maxWidth: 360, padding: 0, margin: 0 }}
          >
            <ListItemAvatar>
              <Avatars
                sx={{
                  marginRight: "10px",
                  borderRadius: "4px",
                  fontSize: "15px",
                }}
                src={agentDetail?.profile_images?.profile_img_thumbnail}
                variant="square"
              >{`${agentDetail?.first_name?.slice(0, 1) || "N"}${
                agentDetail?.last_name?.slice(0, 1) || "A"
              }`}</Avatars>
            </ListItemAvatar>
            <ListItemText
              primary={agentDetail?.first_name}
              secondary={
                <React.Fragment>{agentDetail?.last_name}</React.Fragment>
              }
            />
          </ListItem>
        ) : null;
      },
      minWidth: 130,
      flex: 2,
      sortable: false,
      filterable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      renderCell: (params) => {
        return (
          <>{moment(params.value || new Date()).format("MM/DD/YY H:MMa")}</>
        );
      },
      flex: 2,
      sortable: true,
      filterable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      renderCell: ({ row }) => {
        return (
          <>
            <Box>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setProjectDetail(row);
                  setOpenConfirm(true);
                }}
              >
                {" "}
                <DeleteForeverIcon
                  fontSize="large"
                  sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                />
              </IconButton>
            </Box>
          </>
        );
      },
      width: 150,
      editable: false,
      sortable: false,
    },
  ];

  const handleSuccess = () => {
    setOpenConfirm(false);
    toast.dismiss();
    setProjectDetail({});
    toast.success("Delete successfully!");
  };

  const handleConfirm = () => {
    toast.loading("Deleting please wait...");
    if (projectDetail?.id) {
      dispatch(deleteDraftProject({ id: projectDetail?.id, handleSuccess }));
    }
  };

  return (
    <>
      <DataGridComponent
        rows={draftProjects?.data?.length ? draftProjects?.data : []}
        rowHeight={50}
        columns={columns}
        loading={draftProjects.isLoading}
        rowCount={draftProjects.count}
        hideFooter
        onRowsScrollEnd={(e) => {
          if (
            draftProjects.count > e.virtualRowsCount &&
            !draftProjects.isLoading
          ) {
            dispatch(
              getDraftProjects({
                pageNumber: Number(e.virtualRowsCount / 20) + 1,
              })
            );
          }
        }}
        filterMode="server"
        onCellClick={({ row }) => {
          setDraftDetail(row);
          setOpenProjectForm(true);
        }}
      />
      <TaskConfirmation
        open={openConfirm}
        setOpen={setOpenConfirm}
        handleConfirm={handleConfirm}
        title="Delete Project"
        description="Please confirm: Are you sure you want to delete this draft project?"
      />
      {openProjectForm && (
        <Dialog
          disablePortal
          open={openProjectForm}
          onClose={() => setOpenProjectForm(false)}
          fullWidth={true}
          maxWidth={"md"}
          sx={{
            "& .MuiPaper-root": { overflow: "visible" },
            "& .MuiDialog-container": {
              height: "95vh",
              mt: "50px",
            },
          }}
        >
          {" "}
          <CreateProject
            open={openProjectForm}
            setOpen={setOpenProjectForm}
            projectModule={true}
            draftDetail={draftDetail}
          />
        </Dialog>
      )}
    </>
  );
}

export default DraftProject;
