export const styleSheet = () => ({
  coverageSection: {
    backgroundColor: "white !important",
    borderRadius: "20px !important",
    padding: "16px 24px !important",
    marginTop: "15px !important",
  },
  coverageSectionHeading: {
    fontSize: "22px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    fontWeight: "600 !important",
  },
  departmentsSection: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    gap: "20px !important",
    margin: "16px 0px 8px 0px !important",
    flexWrap: "wrap !important",
  },
  departmentsSectionItem: {
    width: "204px !important",
    height: "204px !important",
    borderRadius: "20px !important",
    padding: "16px !important",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "space-between !important",
  },
  departmentsSectionItemTitle: {
    fontSize: "22px !important",
    color: "white !important",
    fontWeight: "500 !important",
  },
  departmentsSectionItemBottom: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  loadingItem: {
    width: "204px !important",
    height: "204px !important",
    borderRadius: "20px !important",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "space-between !important",
    overflow: "hidden !important",
  },
});
