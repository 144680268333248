import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import {
  Card,
  Typography,
  Container,
  Skeleton,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { statusFilterData } from "../../helpers";
import { getProjectStats } from "../../../../redux/projects/projects";
import {
  openProjectDetails,
  setSelectedProjectId,
} from "../../../../redux/projects/project";
import { useLocation } from "react-router-dom";
import DraftProject from "./draftProject";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import AllProjects from "./allProjects";
const projectStatusArray = [
  {
    title: "Total Projects",
    bgColor: "#4D4D4D",
    key: "total_count",
    value: "active",
  },
  {
    title: "Queued Projects",
    bgColor: "#89BDFA",
    key: "queued_count",
    value: "queued",
  },
  {
    title: "Pending Projects",
    bgColor: "#3B877B",
    key: "pending_count",
    value: "pending",
  },
  {
    title: "Ready to Complete ",
    bgColor: "#91C58A",
    key: "ready_to_complete_count",
    value: "ready_to_complete",
  },
  {
    title: "Overdue Projects",
    bgColor: "#DF564D",
    key: "overdue_tasks",
    value: "overdue",
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0px auto!important",
    height: "calc(100vh - 350px)",
  },
  detailCardSection: {
    justifyContent: "space-between !important",
    display: "flex!important",
    width: "100%!important",
    marginBottom: "40px!important",
    gap: "15px !important",
  },
  detailCard: {
    display: "flex!important",
    justifyContent: "unset!important",
    alignItems: "center!important",
    padding: "0px 19px!important",
    gap: "10px !important",
    height: "100px!important",
    width: "220px!important",
  },
  title: {
    fontSize: "32px!important",
    fontWeight: "400 !important",
    color: "white !important",
  },
  desc: {
    fontSize: "14px!important",
    fontWeight: "400 !important",
    lineHeight: "130%!important",
    color: "white !important",
  },
  avatar: {
    background: "#BDBDBD!important",
    color: "#fff!important",
    marginRight: "10px!important",
  },
  openButton: {
    width: "100%!important",
    border: "1px solid #0B0909!important",
    borderColor: "#0B0909!important",
    color: "#0B0909!important",
    "&:hover": {
      borderColor: "#0B0909!important",
    },
  },
}));

function ProjectGrid(props) {
  const [due_date_filter, setDue_date_filter] = useState("");
  /**
   * Variables and States
   */
  const {
    value: selectedTab,
    departmentsActiveView,
    setDepartmentsActiveView,
    departmentID,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  let { state } = useLocation();
  const [status, setStatus] = useState("active");

  const [draftProjects, setDraftProject] = useState(false);

  const departments = useSelector((state) => state.project.departments);
  const projectStats = useSelector((state) => state.project.projectStats);
  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const handleOpenProject = (id) => {
    dispatch(setSelectedProjectId(id));
    dispatch(openProjectDetails(true));
  };

  /**
   * End Region: Helpers
   */
  /**
   * useEffect Hooks
   */

  useEffect(() => {
    if (!projectStats?.isLoading) {
      dispatch(getProjectStats({ departmentID }));
    }
  }, []);

  useEffect(() => {
    if (state?.project_id) {
      handleOpenProject(state?.project_id);
    }
  }, [state?.project_id]);
  /**
   * End Region: useEffect Hooks
   */
  return (
    <>
      <Container className={classes.detailCardSection} maxWidth="lg">
        {projectStats?.isLoading
          ? projectStatusArray?.map((project, index) => (
              <Card className={classes.detailCard}>
                <Skeleton style={{ height: "100px", width: "100%" }} />
              </Card>
            ))
          : projectStatusArray?.map((project, index) => (
              <Card
                className={classes.detailCard}
                sx={{ background: project.bgColor, cursor: "pointer" }}
                onClick={() => {
                  if (project.value === "overdue") {
                    setDue_date_filter("overdue");
                  } else {
                    setDue_date_filter("");
                    setStatus(project.value);
                  }
                }}
              >
                <div>
                  <Typography className={classes.title}>
                    {projectStats?.data?.[project.key] || 0}
                  </Typography>
                  <Typography className={classes.desc}>
                    {project.title}
                  </Typography>
                </div>
              </Card>
            ))}
      </Container>
      <Container className={classes.container} maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "50px",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
            {draftProjects ? "Drafts" : "Projects"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mb: "10px",
              gap: "15px",
            }}
          >
            {draftProjects ? (
              <Button
                startIcon={<KeyboardBackspaceRoundedIcon />}
                onClick={() => setDraftProject(false)}
                color="inherit"
                size="small"
                sx={{ textTransform: "none" }}
              >
                Back to Projects{" "}
              </Button>
            ) : (
              <Button
                onClick={() => setDraftProject(true)}
                variant="outlined"
                color="inherit"
                size="small"
                sx={{
                  textTransform: "none",
                  height: "39px",
                  borderColor: "#c4c4c4",
                }}
              >
                Draft Projects{" "}
              </Button>
            )}

            {props.departmentID ? (
              <TextField
                select
                classes={{ root: classes.detailInput }}
                value={departmentsActiveView}
                onChange={(e) => setDepartmentsActiveView(e.target.value)}
                variant="outlined"
                sx={{ width: 180, background: "white" }}
                size="small"
              >
                <MenuItem value={"projects"}>Projects</MenuItem>
                <MenuItem value={"tasks"}>Tasks</MenuItem>
              </TextField>
            ) : null}
            {draftProjects ? null : (
              <TextField
                select
                classes={{ root: classes.detailInput }}
                // label="Status"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  setDue_date_filter("");
                }}
                variant="outlined"
                sx={{ width: 200, background: "white" }}
                size="small"
              >
                {statusFilterData.map((item) => (
                  <MenuItem key={item} value={item.value}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>
        </Box>
        {draftProjects ? (
          <DraftProject {...props} handleOpenProject={handleOpenProject} />
        ) : (
          <AllProjects
            {...props}
            due_date_filter={due_date_filter}
            status={status}
            handleOpenProject={handleOpenProject}
          />
        )}
      </Container>
    </>
  );
}

export default ProjectGrid;
