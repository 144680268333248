export const styleSheet = () => ({
  profileMenuIcon: {
    minWidth: "40px  !important",
    marginLeft: "10px  !important",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  "@media (max-width: 575px)": {
    TextMessageArea: {
      width: "316px",
      height: "420px",
      textMessagesList: {
        height: "400px !important",
        overflow: "auto !important",
        "&::-webkit-scrollbar-thumb": {
          background: "rgba(54, 131, 188, 0.6)",
        },
        "&::-webkit-scrollbar-track": {
          background: "#ccc",
        },
        "&::-webkit-scrollbar": {
          width: "6px",
        },
      },
    },
  },
  "@media (min-width: 575px)": {
    TextMessageArea: {
      width: "360px",
      height: "435px",
    },
    alertsArea: {
      width: "432px",
      height: "570px",
    },
    textMessagesList: {
      "& .MuiBadge-dot": {
        bottom: "12%  !important",
        right: "32%  !important",
      },
      height: "406px !important",
      overflow: "auto !important",
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(54, 131, 188, 0.6)",
      },
      "&::-webkit-scrollbar-track": {
        background: "#ccc",
      },
      "&::-webkit-scrollbar": {
        width: "6px",
      },
    },
  },

  TextMessageAreaHeader: {
    borderBottom: "1.25px solid rgba(0, 0, 0, 0.1)",
    padding: "0px 15px 8px 15px !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: "600 !important",
      fontSize: "18px !important",
      fontFamily: "AvenirNext !important",
    },
  },
  AlertAreaHeader: {
    padding: "0px 15px 8px 15px !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: "600 !important",
      fontSize: "18px !important",
      fontFamily: "AvenirNext !important",
    },
  },

  unreadBadgeText: {
    background: "rgba(255, 149, 149, 0.25)",
    borderRadius: "6px",
    color: "#B92727",
    fontWeight: "400 !important",
    fontSize: "11px !important",
    fontFamily: "AvenirNext !important",
    height: "25px",
    minWidth: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  readBadgeText: {
    background: "rgba(0, 181, 29, 0.15)",
    borderRadius: "6px",
    color: "#1F9F25",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    fontFamily: "AvenirNext !important",
    height: "25px",
    minWidth: "44px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  TextMessageAreaNoExist: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative !important",
    height: "90%",
    "& .MuiTypography-root": {
      fontWeight: "500 !important",
      fontSize: "13px !important",
      fontFamily: "AvenirNext !important",
      color: "rgba(0, 0, 0, 0.5)  !important",
    },
  },
  textMessagesListItemHeading: {
    fontWeight: "600 !important",
    fontSize: "13px !important",
    fontFamily: "AvenirNext !important",
    color: "rgba(0, 0, 0, 0.87)  !important",
    whiteSpace: "nowrap !important",
    maxWidth: "15ch !important",
    textOverflow: "ellipsis !important",
    overflow: "hidden !important",
  },
  alertListItemHeading: {
    fontWeight: "600 !important",
    fontSize: "14.5px !important",
    fontFamily: "AvenirNext !important",
    color: "rgba(0, 0, 0, 0.87)  !important",
    whiteSpace: "nowrap !important",
    flex: 1,
    textOverflow: "ellipsis !important",
    overflow: "hidden !important",
  },
  textMessagesListItemContent: {
    fontWeight: "400 !important",
    fontSize: "12.5px !important",
    fontFamily: "AvenirNext !important",
    color: "rgba(0, 0, 0, 0.8)  !important",
    width: "85%",
    display: "-webkit-box !important",
    "-webkit-line-clamp": "2 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
    wordBreak: "break-word !important",
  },
  textMessagesListItemTime: {
    fontWeight: "400 !important",
    fontSize: "11.5px !important",
    fontFamily: "AvenirNext !important",
    color: "rgba(0, 0, 0, 0.5)  !important",
    whiteSpace: "nowrap !important",
  },
  textMessagesListItemChip: {
    color: "#000000 !important",
    fontWeight: "500 !important",
    fontSize: "10px !important",
    fontFamily: "AvenirNext !important",
    height: "18px !important",
    border: "1px solid rgba(0, 0, 0, 0.6) !important",
  },
  customerNavSelect: {
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent  !important",
      border: "none !important",
    },
    "& .MuiSelect-select:active": {
      backgroundColor: "transparent  !important",
      border: "none !important",
    },
    "& .MuiSelect-select": {
      backgroundColor: "transparent  !important",
      border: "none !important",
      "&:before": {
        border: "none !important",
      },
      "&:after": {
        border: "none !important",
      },
    },
    "& .MuiInput-root": {
      border: "none !important",
      "&:before": {
        border: "none !important",
      },
      "&:after": {
        border: "none !important",
      },
    },
    "& .MuiSelect-select": {
      "&:before": {
        border: "none !important",
      },
    },
  },
  "@global": {
    ".top-nav-bar": {
      backgroundColor: "white   !important",
      zIndex: "100000000 !important",
    },
    ".top-nav-bar .profile-label": {
      fontSize: "12px",
      textAlign: "right",
      fontWeight: "600",
      color: "rgba(0, 0, 0, 0.87)",
      marginRight: "10px",
      lineHeight: "18px",
      fontFamily: "'AvenirNext'",
    },
    ".top-nav-bar .custom-badge-back": {
      background: "#f5f5f5 !important",
      borderRadius: "5px !important",
      height: "42px !important",
      marginRight: "23px !important",
      marginTop: "10px !important",
      width: "42px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    ".top-nav-bar .custom-badge-back .MuiBadge-colorError": {
      background: "rgba(219, 10, 10, 1)",
      height: "10px",
      width: "10px",
      borderRadius: "5px",
      top: "5px",
      right: "7px",
    },
    ".top-nav-bar .custom-badge-back .MuiBadge-colorSuccess": {
      background: "#2E7D32",
      height: "10px",
      width: "10px",
      top: "2px",
      right: "2px",
      borderRadius: "5px",
    },
    ".top-nav-bar .custom-badge-back.active-black": {
      background: "#0B0909 !important",
      borderRadius: "9px !important",
      height: "42px !important",
      marginRight: "23px !important",
      marginTop: "10px !important",
      color: "white",
      width: "42px",
      lineHeight: "18px",
    },
    ".top-nav-bar .MuiSvgIcon-root": {
      fontSize: "30px",
    },
    ".top-nav-bar .call-detail-incoming-area": {
      marginRight: "20px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      cursor: "pointer",
    },
    ".top-nav-bar .call-detail-incoming-area input": {
      border: "none",
      outline: "none",
      fontSize: "inherit",
      fontWeight: "inherit",
      width: "inherit",
    },
    ".top-nav-bar .call-detail-incoming-area .call-detail-incoming-outgoing": {
      display: "inline-block",
      minWidth: "220px",
      padding: "2px 13px",
      marginLeft: "-5px",
      borderRadius: "5px",
    },
    ".top-nav-bar .call-detail-incoming-area .call-detail-incoming-outgoing .call-detail-incoming-outgoing-title":
      {
        fontWeight: "600",
        fontSize: "14px",
        fontFamily: "'AvenirNext'",
        display: "flex",
        justifyContent: "space-between",
      },
    ".top-nav-bar .call-detail-incoming-area .call-detail-incoming-outgoing .call-detail-incoming-outgoing-title .call-detail-incoming-outgoing-name":
      {
        cursor: "pointer",
        width: "70%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    ".top-nav-bar .call-detail-incoming-area .call-detail-incoming-outgoing .call-detail-incoming-outgoing-title .timer-area":
      {
        fontSize: "12px",
        fontFamily: "'AvenirNext'",
        fontWeight: "600",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
    ".top-nav-bar .call-detail-incoming-area .call-detail-incoming-outgoing .call-detail-incoming-outgoing-title .timer-area .online-time-dot":
      {
        marginRight: "4px",
        display: "block",
        width: "12px",
        height: "10px",
        borderRadius: "50%",
        background: "#2E7D32",
      },
    ".top-nav-bar .call-detail-incoming-area .call-detail-incoming-outgoing .call-detail-incoming-outgoing-des":
      {
        fontWeight: "400 !important",
        fontSize: "12px",
        fontFamily: "'AvenirNext'",
        display: "flex",
        justifyContent: "space-between",
      },
    ".top-nav-bar .call-detail-incoming-area .call-detail-incoming-outgoing .call-detail-incoming-outgoing-des input":
      {
        fontWeight: "400 !important",
        fontSize: "12px",
        fontFamily: "'AvenirNext'",
      },
    ".top-nav-bar .call-detail-incoming-area .custom-badge-call-accept": {
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #66BB6A",
      borderRadius: "5px !important",
      height: "42px !important",
      marginRight: "10px !important",
      width: "42px",
      color: "black",
    },
    ".top-nav-bar .call-detail-incoming-area .custom-badge-call-mice": {
      background: "white",
      borderRadius: "5px !important",
      height: "42px !important",
      marginRight: "10px !important",
      width: "42px",
      color: "black",
      border: "0.3px solid #000000",
    },
    ".top-nav-bar .call-detail-incoming-area .custom-badge-call-mice.muted": {
      color: "red",
    },
    ".top-nav-bar .call-detail-incoming-area .custom-badge-call-end": {
      background: "rgba(219, 10, 10, 0.6)",
      borderRadius: "5px !important",
      height: "42px !important",
      marginLeft: "4px !important",
      width: "42px",
      color: "black",
    },
    ".profile-label-mb": {
      fontSize: "12px",
      textAlign: "right",
      fontWeight: "600",
      color: "rgba(0, 0, 0, 0.87)",
      marginLeft: "10px",
    },
    ".timer": {
      width: "100%",
      display: "flex",
      height: "12%",
      justifyContent: "center",
      alignItems: "center",
    },
    ".digits": {
      fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
      fontSize: "12px",
      fontWeight: "600",
      color: "#000000",
    },
  },
  saveButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },
  tabSubHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    padding: "2px 10px !important",
  },
  markAllAsRead: {
    textTransform: "capitalize !important",
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.60) !important",
  },
  markAsHeading: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.60) !important",
    fontWeight: "600 !important",
  },
});
