import React, { useState, useEffect } from "react";
import { Dialog, Box, IconButton, DialogContent } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../responseAlert";
import { getDepartmentList } from "../../../redux/projects/common/index";
import { getDepartmentProjectList } from "../../../redux/projects/common";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { handleAddMinimizeTab } from "../../../redux/widgets/index";
import { handleUpdateModalState } from "../../../redux/widgets";
import { uid } from "uid";
import CreateProject from "../../../container/projects/projects/createProject";
function CreateProjectModal(props) {
  let { classes } = props;
  const [minimizeFlag, setMinimizeFlag] = useState(false);
  const [formData, setFormData] = useState({
    department_id: "",
  });
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const open = useSelector((state) => state.widgets.createProjectOpen);
  const rightPositions = useSelector((state) => state.widgets.rightPositions);
  const maximizedTab = useSelector((state) => state.widgets.maximizedTab);
  useEffect(() => {
    dispatch(getDepartmentList());
  }, []);

  useEffect(() => {
    if (formData.department_id) {
      dispatch(getDepartmentProjectList(formData.department_id));
    }
  }, [formData.department_id]);

  const updateFormDataDetail = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };
  const handleClose = () => {
    dispatch(
      handleUpdateModalState({ type: "createProjectOpen", action: false })
    );
  };

  const handleAddMinimize = (data) => {
    dispatch(
      handleAddMinimizeTab({
        title:
          data?.tabTitle && data.department_id ? data.tabTitle : "New Project",
        type: "project",
        id: `${uid()}project`,
        modalType: "createProjectOpen",
        action: false,
        tabDetail: { ...data },
      })
    );
    setMinimizeFlag(false);
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        style={{ position: "absolute" }}
        open={open}
        // onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        PaperProps={{
          sx: {
            position: "fixed",
            right:
              rightPositions?.length &&
              rightPositions.find((item) => item.type === "createProjectOpen")
                ? rightPositions.find(
                    (item) => item.type === "createProjectOpen"
                  ).right
                : -20,
            bottom: -15,
            borderRadius: "6px",
            zIndex: 100000,
          },
        }}
        hideBackdrop
        disableEnforceFocus
        disableBackdropClick
      >
        <DialogContent sx={{ width: "800px" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton size="small" onClick={() => setMinimizeFlag(true)}>
              {" "}
              <RemoveIcon />
            </IconButton>
            <IconButton size="small" onClick={() => handleClose()}>
              {" "}
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <Box sx={{ mt: "-40px" }}>
            <CreateProject
              handleAddMinimize={handleAddMinimize}
              minimizeFlag={minimizeFlag}
              handleClosePopup={handleClose}
              maximizedTab={open ? maximizedTab : null}
              isWidget
              {...props}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "CreateProjectModalStyle" })(
  CreateProjectModal
);
