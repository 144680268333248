import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ConstructionIcon from "@mui/icons-material/Construction";
import HomeIcon from "@mui/icons-material/Home";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FeedIcon from "@mui/icons-material/Feed";
import GppGoodIcon from "@mui/icons-material/GppGood";
import WorkIcon from "@mui/icons-material/Work";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import { useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import Collapse from "@mui/material/Collapse";
import CircleIcon from "@mui/icons-material/Circle";
import { withStyles, makeStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import listingIcon from "../../assets/images/listingIcon.png";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import PaymentsRoundedIcon from "@mui/icons-material/PaymentsRounded";
import { useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import Skeleton from "@mui/material/Skeleton";
const useStyles = makeStyles((theme) => ({
  navCollapseItems: {
    position: "absolute",
    top: 0,
    left: "65px",
    background: "white",
    width: "180px",
  },
}));

function SideNavBar(props) {
  const { classes } = props;
  const muiClasses = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [shortMenu, setShortMenu] = useState({});

  const [open, setOpen] = useState(["MAIN" /* ,"TOOLS","LEADS" */]);

  const adminAuth = useSelector((state) => state.adminAuth);

  useEffect(() => {
    let path = location.pathname.split("/")[1].toUpperCase();
    setOpen([path]);
  }, []);
  const navBarMenu = [
    {
      title: "MAIN",
      icon: <HomeIcon />,
      route: "/main/dashboard",
      subRoutes: [
        {
          title: "Dashboard",
          route: "/main/dashboard",
        },
        {
          title: "Points",
          route: "/dashboard/points",
        },
        {
          title: "Payments",
          route: "/payments",
        },
      ],
    },
    {
      title: "Search",
      icon: <ImageSearchIcon />,
      route: "/search",
    },
    {
      title: "DOCS",
      icon: <FeedIcon />,
      route: "/docs",
    },
    {
      title: "TOOLS",
      icon: <ConstructionIcon />,
      subRoutes: [
        {
          title: "Reporting",
          route: "/tools/reporting/agent-activity",
        },
        {
          title: "Marketing Center",
          route: "/marketing_center/digital",
        },
        {
          title: "CMA",
          route: "/tools/cmas/list",
        },

        {
          title: "Video Marketing",
          route: "/video_marketing",
        },
        {
          title: "recruiting",
          route: "/tools/recruiting",
        },
      ],
    },
    {
      title: "LEADS",
      icon: <PeopleAltIcon />,
      subRoutes: [
        {
          title: "People",
          route: "/leads/people",
        },
        {
          title: "Inbox",
          route: "/leads/inbox",
        },
        {
          title: "Templates",
          route: "/templates/email-templates",
        },
        {
          title: "Reporting",
          route: "/reporting",
        },
        {
          title: "Smart List",
          icon: <ListAltIcon />,
          route: "/leads/smartlists",
        },
      ],
    },
    {
      title: "Listings",
      icon: (
        <img
          style={{ width: "18px", marginLeft: "3px" }}
          src={listingIcon}
          alt={"listingIcon"}
        />
      ),
      subRoutes: [
        {
          title: "Listings",
          route: "/listings/active",
        },
        {
          title: "Makeover",
          route: "/listings/makeover/outstanding",
        },
        {
          title: "Open House",
          route: "/listings/open-house/current",
        },
      ],
    },
    {
      title: "TRANSACTIONS",
      icon: <WorkIcon />,
      subRoutes: [
        {
          title: "Transactions",
          route: "/transaction/contracts/active-contracts",
        },
        {
          title: "Task Templates",
          route: "/transaction/task_templates",
        },
      ],
    },
    {
      title: "ADMIN",
      icon: <GppGoodIcon />,
      subRoutes: [
        {
          title: "Agent Roster",
          route: "/agent_roster",
        },
        {
          title: "Events and Alerts",
          route: "/events_and_alerts/events",
        },
        {
          title: "Agent Requests",
          route: "/agent_tickets/open",
        },
        {
          title: "Users",
          route: "/users",
        },
        {
          title: "Vendors",
          route: "/vendors",
        },
        {
          title: "Inventory",
          route: "/inventory/lockbox",
        },
        {
          title: "Lead Routing",
          route: "/lead_routing",
        },
        {
          title: "Send Email As",
          route: "/send_email_as",
        },
        {
          title: "Ponds",
          route: "/ponds",
        },
        {
          title: "Staff Management",
          route: "/staff_management",
        },
      ],
    },
    {
      title: "Phone Configuration",
      icon: <PhonelinkSetupIcon />,
      route: "/phone-configuration/phone-numbers",
    },
    {
      title: "Finance",
      icon: <PaymentsRoundedIcon />,
      route: "/finance/closings/unprocessed",
    },
    {
      title: "Projects",
      icon: <TaskAltRoundedIcon />,
      route: "/projects",
    },
    {
      title: "KNOWLEDGE BASE",
      icon: <HelpIcon />,
      route:
        adminAuth?.adminDetail?.role?.name === "Agents"
          ? "/knowledge-base/main"
          : null,
      subRoutes:
        adminAuth?.adminDetail?.role?.name === "Agents"
          ? null
          : [
              {
                title: "Main",
                route: "/knowledge-base/main",
              },
              {
                title: "Manage Library",
                route: "/knowledge-base/manage-library/posts",
              },
            ],
    },
  ];

  const handleChangeRoute = (route, title) => {
    setOpen([title]);
    setShortMenu((prev) => ({ ...prev, [title]: false }));
    navigate(route);
  };

  const handleClick = (title) => {
    setOpen([title]);
    setShortMenu((prev) => ({ ...prev, [title]: true }));
  };

  return (
    <React.Fragment>
      {adminAuth.isLoading ? (
        <List>
          {Array.from(Array(18).keys()).map(() => (
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  <Skeleton
                    width="22px"
                    height="32px"
                    sx={{ mt: "-6px", mb: "-6px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Skeleton
                      height="27px"
                      width="90%"
                      sx={{ mt: "-6px", mb: "-6px" }}
                    />
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ) : (
        <div className={classes.sideBarRoot}>
          {props.pcOpen ? (
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: "91vh",
                "& ul": { padding: 0 },
              }}
              classes={{ root: classes.sideBarRootList }}
            >
              {navBarMenu.map((item, index) =>
                item.subRoutes ? (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    {item.subRoutes.filter((sub) =>
                      adminAuth.adminDetail?.role?.role_permissions?.find(
                        (it) =>
                          sub.route.split("/").includes(it.permission.slug) &&
                          !it.mobile_only
                      )
                    )?.length ? (
                      <ListItemButton
                        selected={open.includes(item.title)}
                        onClick={() => handleClick(item.title)}
                      >
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.sideBarListText }}
                          primary={item.title}
                        />
                        {open.includes(item.title) ? (
                          <ExpandLess
                            sx={{ color: "#BDBDBD", fontSize: "22px" }}
                          />
                        ) : (
                          <ExpandMore
                            sx={{ color: "#BDBDBD", fontSize: "22px" }}
                          />
                        )}
                      </ListItemButton>
                    ) : null}

                    <Collapse
                      className={classes.navCollapseItems}
                      in={open.includes(item.title)}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {item.subRoutes.map((item1, index1) =>
                          adminAuth.adminDetail?.role?.role_permissions?.find(
                            (it) =>
                              item1.route
                                .split("/")
                                .includes(it.permission.slug) && !it.mobile_only
                          ) ? (
                            <ListItemButton
                              selected={location.pathname === item1.route}
                              key={index1}
                              onClick={() =>
                                handleChangeRoute(item1.route, item.title)
                              }
                              sx={{ pl: 4 }}
                            >
                              <ListItemIcon sx={{ minWidth: "40px" }}>
                                <CircleIcon
                                  fontSize="small"
                                  sx={{ color: "#BDBDBD", fontSize: "14px" }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                classes={{ primary: classes.sideBarListText }}
                                primary={item1.title}
                              />
                            </ListItemButton>
                          ) : null
                        )}
                      </List>
                    </Collapse>
                  </ListItem>
                ) : adminAuth.adminDetail?.role?.role_permissions?.find(
                    (it) =>
                      item.route.split("/").includes(it.permission.slug) &&
                      !it.mobile_only
                  ) ? (
                  <ListItemButton
                    onClick={() => handleChangeRoute(item.route, item.title)}
                    selected={location.pathname === item.route}
                    key={index}
                  >
                    <ListItemIcon sx={{ minWidth: "40px" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.sideBarListText }}
                      primary={item.title}
                    />
                  </ListItemButton>
                ) : null
              )}
            </List>
          ) : (
            <List sx={{ paddingTop: "0px" }}>
              {navBarMenu.map((item, index) =>
                item.subRoutes ? (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setShortMenu((prev) => ({
                        ...prev,
                        [item.title]: false,
                      }));
                    }}
                  >
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      {item.subRoutes.filter((sub) =>
                        adminAuth.adminDetail?.role?.role_permissions?.find(
                          (it) =>
                            sub.route.split("/").includes(it.permission.slug) &&
                            !it.mobile_only
                        )
                      )?.length ? (
                        <ListItemButton
                          selected={open.includes(item.title)}
                          onClick={() => handleClick(item.title)}
                        >
                          <ListItemIcon sx={{ minWidth: "40px" }}>
                            {item.icon}
                          </ListItemIcon>
                        </ListItemButton>
                      ) : null}
                      {open.includes(item.title) && shortMenu[item.title] && (
                        <div
                          className={muiClasses.navCollapseItems}
                          style={{ zIndex: `${999 + index}` }}
                        >
                          <List component="div" disablePadding>
                            {item.subRoutes.map((item1, index1) =>
                              adminAuth.adminDetail?.role?.role_permissions?.find(
                                (it) =>
                                  item1.route
                                    .split("/")
                                    .includes(it.permission.slug) &&
                                  !it.mobile_only
                              ) ? (
                                <ListItemButton
                                  selected={location.pathname === item1.route}
                                  key={index1}
                                  onClick={() =>
                                    handleChangeRoute(item1.route, item.title)
                                  }
                                  sx={{ pl: 4 }}
                                >
                                  <ListItemText
                                    classes={{
                                      primary: classes.sideBarListText,
                                    }}
                                    primary={item1.title}
                                  />
                                </ListItemButton>
                              ) : null
                            )}
                          </List>
                        </div>
                      )}
                    </ListItem>
                  </OutsideClickHandler>
                ) : (
                  <ListItem
                    onClick={() => handleChangeRoute(item.route, item.title)}
                    key={index}
                    selected={location.pathname === item.route}
                    disablePadding
                  >
                    {adminAuth.adminDetail?.role?.role_permissions?.find(
                      (it) =>
                        item.route.split("/").includes(it.permission.slug) &&
                        !it.mobile_only
                    ) ? (
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: props.pcOpen ? "initial" : "center",
                          px: 1.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 35,
                            mr: props.pcOpen ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                      </ListItemButton>
                    ) : null}
                  </ListItem>
                )
              )}
            </List>
          )}
        </div>
      )}
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "SideNavBarStyle" })(SideNavBar);
