import React, { useContext, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useDropzone } from "react-dropzone";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { styled } from "@mui/styles";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import {
  Grid,
  Typography,
  Box,
  Divider,
  TextareaAutosize,
  Button,
  MenuItem,
  Checkbox,
  IconButton,
  Tooltip,
  DialogTitle,
  Stack,
  Chip,
  Card,
  CardHeader,
  ListItemButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  ListItemSecondaryAction,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";
import TaskDetailSkelton from "../skelton/taskDetail";
import { calculateDueIn, getSignedURL, openDeliverable } from "../helpers";
import {
  getTask,
  changeTaskStatus,
  upload,
  deleteDeliverable,
  assignTask,
  unassignTask,
  getTaskHistory,
  getTaskList,
  setTaskCurrentPageNo,
} from "../../../redux/projects/tasks";
import {
  saveProjectTaskPublicComments,
  saveProjectTaskMessages,
} from "../../../redux/projects/common";
import { marketCustom } from "../../../utils/markets";
import AvatarComponent from "../AvatarComponent";
import DataGridComponent from "../DataGridComponent";
import StatusComponent from "../StatusComponent";
import {
  closeTaskDetails,
  deliveriableListUpdate,
  openProjectDetails,
  resetTask,
  setSelectedProjectId,
} from "../../../redux/projects/project";
import { toast } from "react-toastify";
import { Delete, Download } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileSaver from "file-saver";
import JSZip from "jszip";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FadeLoader } from "react-spinners";
import axios from "axios";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LocationCityRoundedIcon from "@mui/icons-material/LocationCityRounded";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import flowerIcon from "../../../assets/images/agents/flowerIcon.png";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import momentTZ from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../components/callPanel/callContext";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import CommentSection from "../../../components/shared/commentSection";
import { CustomTooltip } from "../../../components/shared/tooltip";
import Linkify from "react-linkify";
import TaskConfirmation from "../projects/taskConfirm.tsx";
import { useStyles } from "./style.js";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import PauseCircleFilledRoundedIcon from "@mui/icons-material/PauseCircleFilledRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import BackHandRoundedIcon from "@mui/icons-material/BackHandRounded";
function droppingAnimationStyle(style, snapshot) {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  const { moveTo, curve, duration } = snapshot.dropAnimation;
  // move to the right spot
  const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;

  // patching the existing style
  return {
    ...style,
    transform: `${translate}`,
    transition: `all ${curve} ${duration + 1}s`,
  };
}

const xgriColumns = [
  {
    field: "type",
    headerName: "Type",
    renderCell: (params) => {
      return <>{params.value}</>;
    },
    width: 120,
    sortable: true,
  },
  {
    field: "by",
    headerName: "By",
    renderCell: (params) => {
      return <>{params.value}</>;
    },
    width: 150,
    sortable: true,
  },
  {
    field: "description",
    headerName: "Description",
    renderCell: (params) => {
      return <>{params.value}</>;
    },
    minWidth: 250,
    flex: 1,
    sortable: true,
  },
  {
    field: "date",
    headerName: "Date",
    renderCell: (params) => {
      return <>{params.value}</>;
    },
    width: 180,
    sortable: true,
  },
  {
    field: "time",
    headerName: "Time",
    renderCell: (params) => {
      return <>{params.value}</>;
    },
    width: 200,
  },
];

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px #0B0909, inset 0 -1px 0 #0B0909",
  backgroundColor: "#fff",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#7E7E7E",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  boxShadow: "none",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#7E7E7E",
  },
});

export default function TaskDetails(props) {
  let { openComments, setOpenComments } = props;
  /**
   * Variables and States
   */
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dialNumber } = useContext(AppContext);
  const zip = new JSZip();
  const emailRef = useRef();
  const agentNameRef = useRef();

  const { open, setOpen, taskID } = props;
  const [rows, setRows] = useState([]);
  const [columns] = useState([...xgriColumns]);
  const [viewHistory, setViewHistory] = useState(false);
  const [teamMemberDropdown, openTeamMemberDropdown] = useState(false);
  const [taskDeliverables, setTaskDeliverables] = useState([]);
  const [overflowActive, setOverflowActive] = useState(false);
  const [agentNameOverflowActive, setAgentNameOverflowActive] = useState(false);
  const [creationNote, setCreationNote] = useState(null);
  const [publicNote, setPublicNote] = useState("");
  const [selectedDeliverables, setSelectedDeliverables] = useState({});
  const [statusAlert, setStatusAlert] = useState(false);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [activeTask, setActiveTask] = useState({});
  const [approveConfirmation, setApproveConfirmation] = useState(false);
  const [displayDeliverableCheckbox, setDisplayDeliverableCheckbox] = useState(
    {}
  );
  const [specialStips, setSpecialStips] = useState(null);
  // for comment box

  const isProjectDetailsOpen = useSelector(
    (state) => state.project.isProjectDetailsOpen
  );
  const task = useSelector((state) => state.project.task);
  const departmentID = useSelector((state) => state.project.selectedDepartment);
  const taskLoading = useSelector((state) => state.project.taskLoading);
  const taskHistory = useSelector((state) => state.project.taskHistory);
  const adminDetail = useSelector((state) => state.adminAuth.adminDetail);
  const taskHistoryLoading = useSelector(
    (state) => state.project.taskHistoryLoading
  );
  const uploadingDeliverable = useSelector(
    (state) => state.project.uploadingDeliverable
  );
  const users = useSelector((state) =>
    state.users.storedListOfUsers.list ? state.users.storedListOfUsers.list : []
  );
  const loggedInUser = useSelector((state) => state.adminAuth.adminDetail);
  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const getLastPartOfString = (str) => {
    const parts = str.split("/");
    const lastPart = parts.pop();
    const commaIndex = lastPart.indexOf(",");
    const dotIndex = lastPart.lastIndexOf(".");
    const firstPart =
      commaIndex !== -1 ? lastPart.substring(0, commaIndex) : lastPart;
    const secondPart = dotIndex !== -1 ? lastPart.substring(dotIndex) : "";
    return firstPart + secondPart;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    dispatch(
      changeTaskStatus({
        taskID,
        status: activeTask.status,
        shouldFetchTask: true,
        confirmed: true,
      })
    );
    setStatusAlert(false);
  };

  const handleConfirmCancel = () => {
    dispatch(
      changeTaskStatus({
        taskID,
        status: "canceled",
        shouldFetchTask: true,
      })
    );
    setCancelConfirmation(false);
  };
  const handleConfirmApprove = (comment) => {
    dispatch(
      changeTaskStatus({
        taskID,
        status: "approved",
        shouldFetchTask: true,
        approval_notes: comment,
      })
    );
    setApproveConfirmation(false);
  };

  const handleStatusChange = async (status) => {
    const resp = await dispatch(
      changeTaskStatus({ taskID, status, shouldFetchTask: true })
    );
    if (resp?.payload?.status === 409) {
      setActiveTask({ taskId: taskID, status });
      setStatusAlert(true);
    }
  };

  const startTask = async () => {
    let currentUser = users.find((user) => user.id === loggedInUser.id);
    await dispatch(
      assignTask({
        task_id: taskID,
        user_id: currentUser.id,
        user: currentUser,
      })
    );
    handleStatusChange("Working");
  };

  const reviewTask = () => {
    handleStatusChange("Review");
  };
  const handleError = (error) => {
    toast.error(JSON.stringify(error), {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const uploadDeliverableFile = (e) => {
    upload({ taskID, files: [...e.target.files] }, dispatch, handleError);
  };

  const onDrop = React.useCallback((files) => {
    upload({ taskID, files: [...files] }, dispatch);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const selectAllDeliverable = (selectAll) => {
    if (selectAll) {
      let deliverables = {};
      taskDeliverables.map((deliverable) => {
        deliverables = { ...deliverables, [deliverable.id]: true };
      });
      setSelectedDeliverables(deliverables);
    } else {
      setSelectedDeliverables({});
    }
  };

  const downloadDeliverable = async () => {
    const dArray = [];
    Object.keys(selectedDeliverables).map((id) => {
      if (selectedDeliverables[id]) {
        dArray.push(parseInt(id));
      }
    });
    if (dArray.length === 1) {
      toast.promise(
        Promise.all(
          task.task_deliverables.map((d, index) => {
            if (dArray.includes(d.id)) {
              return getSignedURL(d.file).then(async (url) => {
                let blobData = await fetch(url.data).then((r) => r.blob());
                FileSaver.saveAs(blobData, getLastPartOfString(d.file));
              });
            }
          })
        ),
        {
          pending: "Downloading selected deliverables is in progress",
          success: "Successfully Downloaded",
          error: "Something went wrong while downloading deliverables",
        }
      );
    } else {
      toast.promise(
        Promise.all(
          task.task_deliverables.map((d, index) => {
            if (dArray.includes(d.id)) {
              return getSignedURL(d.file).then(async (url) => {
                await fetch(url.data).then((resp) => {
                  let filename = getLastPartOfString(d.file);
                  zip.file(filename, resp.blob());
                });
              });
            }
          })
        ).then((z) => {
          zip.generateAsync({ type: "blob" }).then(function (blob) {
            FileSaver.saveAs(blob, "task_deliverables.zip");
          });
        }),
        {
          pending: "Downloading selected deliverables is in progress",
          success: "Successfully Downloaded",
          error: "Something went wrong while downloading deliverables",
        }
      );
    }
  };
  const deleteDeliverableFile = () => {
    const dArray = [];
    Object.keys(selectedDeliverables).map((id) => {
      if (selectedDeliverables[id]) {
        dArray.push(parseInt(id));
      }
    });
    dArray.map((deliverableID) => delete selectedDeliverables[deliverableID]);
    if (dArray.length === 0) return;

    const promises = dArray.map((deliverable) =>
      dispatch(deleteDeliverable({ taskID, deliverableID: deliverable }))
    );
    toast.promise(Promise.all([...promises]), {
      pending: "Deleting deliverable(s) is in progress",
      success: "Successfully Deleted",
      error: "Something went wrong while deleting deliverables",
    });

    const updatedDeliverables = task.task_deliverables.filter(
      (d) => !dArray.includes(d.id)
    );
    dispatch(deliveriableListUpdate({ updatedDeliverables, taskID: task.id }));

    dispatch(setTaskCurrentPageNo(1));
    dispatch(getTaskList({ departmentID }));
  };

  const assignTaskMember = (user) => {
    dispatch(assignTask({ task_id: taskID, user_id: user.id, user }));
  };

  const unassignTaskMember = (member_id) => {
    dispatch(unassignTask({ task_id: taskID, member_id }));
  };

  const reorderDeliverables = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorderDeliverables(
      taskDeliverables,
      result.source.index,
      result.destination.index
    );

    setTaskDeliverables([...reorderedItems]);
    const orders = reorderedItems.map((item, index) => ({
      order: index + 1,
      id: item.id,
    }));

    const token = localStorage.getItem("token");
    const url = `${process.env.REACT_APP_BASE_URL}api/projects/change-deliverable-order`;
    let options = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      method: "post",
      url,
      data: JSON.stringify({ orders: [...orders] }),
    };
    await axios(options);
  };

  function isOverflowActive(event) {
    return event?.offsetWidth < event?.scrollWidth;
  }
  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */
  React.useEffect(() => {
    if (emailRef.current && emailRef.current?.children[0]) {
      if (isOverflowActive(emailRef.current.children[0])) {
        setOverflowActive(true);
        return;
      }
      setOverflowActive(false);
    }
  }, [task?.agent?.email]);
  React.useEffect(() => {
    if (agentNameRef.current && agentNameRef.current) {
      if (isOverflowActive(agentNameRef.current)) {
        setAgentNameOverflowActive(true);
        return;
      }
      setAgentNameOverflowActive(false);
    }
  }, [task?.agent?.full_name]);

  React.useEffect(() => {
    if (Array.isArray(task?.task_deliverables)) {
      setTaskDeliverables(
        task?.task_deliverables
          ?.map((task) => ({ ...task }))
          .sort((a, b) => a.order - b.order)
      );
    }
  }, [task?.task_deliverables]);

  React.useEffect(() => {
    return () => {
      dispatch(resetTask());
      setCreationNote(null);
      setPublicNote("");
    };
  }, [open, taskID]);

  React.useEffect(() => {
    dispatch(getTask(taskID));
  }, [open, taskID]);

  React.useEffect(() => {
    if (task) {
      let foundComment = task.project?.project_other_data?.find((dataField) => {
        if (dataField.data_field.name === "Notes/Comments") {
          return dataField;
        }
      });

      task.project?.project_other_data?.map((dataField) => {
        if (
          dataField.data_field.name === "Special Stipulations" &&
          task.project.project_name === "New Offer"
        ) {
          setSpecialStips(dataField);
        }
      });

      if (foundComment) {
        setCreationNote(foundComment);
      } else {
        if (
          task.project &&
          !task.project?.project_other_data?.find(
            (item) => item.data_field.name === "Notes/Comments"
          )
        ) {
          let noteData = "";
          let project_other_data = [...task.project?.project_other_data];
          project_other_data.sort((a, b) => a.data_field.id - b.data_field.id);
          project_other_data?.forEach((dataField) => {
            if (noteData) {
              noteData = `${noteData} \n ${dataField.data_field.name}: ${dataField.value}`;
            } else {
              noteData = `${dataField.data_field.name}: ${dataField.value}`;
            }
          });
          setCreationNote({ value: noteData });
        } else {
          setCreationNote(null);
        }
      }

      setPublicNote(task.public_notes || "");
    }
  }, [task]);

  React.useEffect(() => {
    if (viewHistory) {
      dispatch(getTaskHistory(taskID));
    }
  }, [viewHistory]);

  React.useEffect(() => {
    let historyRows = [];
    taskHistory.map((history) => {
      let row = {
        id: history.id,
        type: history.type,
        description: history.description,
        by: history.user_reference,
        date: momentTZ
          .tz(
            history.updatedAt,
            adminDetail?.market?.timezone || "America/New_York"
          )
          .format("ll"),
        time: momentTZ
          .tz(
            history.updatedAt,
            adminDetail?.market?.timezone || "America/New_York"
          )
          .format("LT"),
      };
      historyRows.push(row);
    });
    setRows(historyRows);
  }, [taskHistory]);
  /**
   * End Region: useEffect Hooks
   */

  const handleSubmitComment = (data) => {
    let obj = {
      ...data,
      id: task?.id,
      type: "task",
    };
    dispatch(saveProjectTaskMessages(obj));
  };
  return (
    <Dialog
      disablePortal
      sx={{
        "& .MuiDialog-container": { mt: "30px" },
        "& .MuiDialog-paper": {
          backgroundColor: "#FBFBFD !important",
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#ddd",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#0B0909",
            borderRadius: 1,
          },
        },
      }}
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={openComments ? "lg" : "md"}
    >
      {taskLoading && (
        <DialogContent
          sx={{ minHeight: "83.6vh" }}
          className={classes.dialogContent}
        >
          <TaskDetailSkelton />
        </DialogContent>
      )}
      {!taskLoading && (
        <Grid container spacing={1} className={classes.taskDetailArea}>
          <Grid item xs={12} lg={openComments ? 8.6 : 12}>
            <DialogTitle
              className={classes.modalHeading}
              sx={{ mt: viewHistory ? "3px" : "0px" }}
            >
              <Typography className={classes.detailsHeader}>
                Task Detail
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                {task.status === "review" &&
                task?.approval_required === false ? (
                  <Box
                    sx={{
                      backgroundColor: "#292929",
                      width: "215px",
                      borderRadius: "6px",
                      padding: "6px 12px",
                      mr: "8px !important",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "13px",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ color: "#FF6F6F" }}>Correct</span> if
                      necessary, otherwise no further action is required.
                    </Typography>
                  </Box>
                ) : null}
                {viewHistory ? (
                  <Button
                    startIcon={<ArrowBackIcon sx={{ color: "grey" }} />}
                    color="inherit"
                    sx={{ textTransform: "capitalize", color: "grey" }}
                    onClick={() => setViewHistory(false)}
                    variant="text"
                  >
                    Back to Task Detail
                  </Button>
                ) : null}
                {viewHistory ? null : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space",
                      position: "relative",
                      mr: "10px !important",
                    }}
                  >
                    <StatusComponent
                      height="28px"
                      clickable
                      status={task?.status || "Future"}
                    />
                  </Box>
                )}
                {task?.status?.toLowerCase() === "working" ? (
                  <IconButton
                    onClick={() => handleStatusChange("paused")}
                    title="Pause Task"
                    sx={{ ml: "-2px !important" }}
                  >
                    <PauseCircleFilledRoundedIcon
                      sx={{ fontSize: "1.65rem" }}
                    />
                  </IconButton>
                ) : null}
                {task?.status?.toLowerCase() === "future" ||
                task?.status?.toLowerCase() === "queued" ||
                task?.status?.toLowerCase() === "working" ||
                task?.status?.toLowerCase() === "review" ||
                task?.status?.toLowerCase() === "paused" ||
                task?.status?.toLowerCase() === "correction" ? (
                  <IconButton
                    onClick={() => handleStatusChange("onhold")}
                    title="Hold Task"
                    sx={{ ml: "-2px !important" }}
                  >
                    <Box
                      sx={{
                        background: "#6f6f6f",
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BackHandRoundedIcon
                        sx={{ fontSize: "1rem", color: "white" }}
                      />
                    </Box>
                  </IconButton>
                ) : null}
                {task?.status !== "canceled" && task?.status !== "completed" ? (
                  <IconButton
                    sx={{ ml: "-2px !important" }}
                    onClick={() => setCancelConfirmation(true)}
                    title="Cancel Task"
                  >
                    <CancelRoundedIcon sx={{ fontSize: "1.65rem" }} />
                  </IconButton>
                ) : null}
                {viewHistory ? null : (
                  <IconButton
                    sx={{ ml: "-2px !important" }}
                    onClick={() => setViewHistory(true)}
                  >
                    <RestoreRoundedIcon sx={{ fontSize: "1.65rem" }} />
                  </IconButton>
                )}{" "}
                {viewHistory ? null : (
                  <IconButton
                    sx={{
                      ml: "-2px !important",
                      backgroundColor: openComments
                        ? "rgba(0, 0, 0, 0.04)"
                        : "",
                    }}
                    onClick={() => setOpenComments(!openComments)}
                  >
                    <ChatBubbleOutlineRoundedIcon sx={{ fontSize: "23px" }} />
                  </IconButton>
                )}
                <Divider orientation="vertical" variant="middle" flexItem />
                <IconButton size="small" onClick={() => setOpen(false)}>
                  <ClearRoundedIcon sx={{ fontSize: "1.65rem" }} />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              {viewHistory ? (
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        height: "819px",
                        width: "100%",
                      }}
                    >
                      <DataGridComponent
                        rows={rows}
                        rowHeight={50}
                        columns={columns}
                        hideFooter
                        loading={taskHistoryLoading}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid xs={12} md={5} lg={4.4} item>
                    <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                      <CardHeader
                        CardHeader
                        classes={{
                          title: classes.taskCardHeader,
                          subheader: classes.taskCardDes,
                        }}
                        avatar={
                          task?.visibility === "visible" ? (
                            <CustomTooltip
                              title={"Visible to Agent"}
                              placement="top"
                            >
                              <VisibilityRoundedIcon
                                sx={{
                                  fontSize: "1.65rem",
                                  color: "#1cc600",
                                  cursor: "help",
                                }}
                              />
                            </CustomTooltip>
                          ) : (
                            <CustomTooltip
                              title={"Hidden from Agent"}
                              placement="top"
                            >
                              <VisibilityOffRoundedIcon
                                sx={{
                                  fontSize: "1.65rem",
                                  color: "#ff5f5f",
                                  cursor: "help",
                                }}
                              />
                            </CustomTooltip>
                          )
                        }
                        title={task?.task_name}
                        subheader={task?.project?.title || " "}
                      />
                    </Card>
                    <Card
                      variant="outlined"
                      sx={{ borderRadius: "8px", mt: "10px" }}
                    >
                      <CardHeader
                        CardHeader
                        classes={{
                          title: classes.taskCardHeader,
                          subheader: classes.taskCardDes,
                        }}
                        avatar={
                          <AvatarComponent
                            variant="square"
                            src={
                              task.agent?.profile_images?.profile_img_thumbnail
                            }
                            nameInitial={task?.agent?.full_name}
                            sx={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "4px",
                            }}
                          />
                        }
                        title={
                          <CustomTooltip
                            placement="top"
                            title={
                              agentNameOverflowActive
                                ? task?.agent?.full_name
                                : ""
                            }
                          >
                            <Box
                              ref={agentNameRef}
                              className={classes.projectCardHeaderAgentName}
                            >
                              {task?.agent?.full_name}
                            </Box>
                          </CustomTooltip>
                        }
                        subheader={task?.agent?.license_type}
                      />
                      <Divider />
                      <List disablePadding>
                        <ListItem disablePadding sx={{ mt: "4px" }}>
                          <ListItemButton dense>
                            <ListItemIcon sx={{ minWidth: "43px" }}>
                              <PeopleRoundedIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Chip
                                  label={task?.agent?.team?.name}
                                  size="small"
                                  sx={{
                                    bgcolor:
                                      task?.agent?.team?.team_color ||
                                      "transparent",
                                    color: "white",
                                    padding: "6px",
                                    fontSize: "14px",
                                  }}
                                />
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton dense>
                            <ListItemIcon sx={{ minWidth: "43px" }}>
                              <LocationCityRoundedIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Chip
                                  label={task?.agent?.market?.name}
                                  size="small"
                                  sx={{
                                    bgcolor:
                                      task?.agent?.market_id &&
                                      marketCustom.find(
                                        (item) =>
                                          item.id === task?.agent?.market_id
                                      )
                                        ? marketCustom.find(
                                            (item) =>
                                              item.id === task?.agent?.market_id
                                          )?.backgroundColor
                                        : marketCustom.find(
                                            (item) =>
                                              item.id === task?.agent?.market_id
                                          )?.backgroundColor
                                        ? "#4FC3F7"
                                        : "transparent",
                                    color: "white",
                                    padding: "6px",
                                    fontSize: "14px",
                                  }}
                                />
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem
                          sx={{
                            "&:hover": {
                              "& .MuiListItemSecondaryAction-root": {
                                display: "block !important",
                              },
                            },
                          }}
                          disablePadding
                        >
                          <ListItemButton dense>
                            <ListItemIcon sx={{ minWidth: "43px" }}>
                              <LocalPhoneRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary={task?.agent?.phone} />
                            <ListItemSecondaryAction sx={{ display: "none" }}>
                              <CopyToClipboard
                                onCopy={() =>
                                  toast.success("Copied Successfully!", {
                                    position: "top-right",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  })
                                }
                                text={task?.agent?.phone}
                              >
                                <IconButton sx={{ padding: "3px" }}>
                                  <ContentCopyRoundedIcon fontSize="small" />
                                </IconButton>
                              </CopyToClipboard>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        </ListItem>
                        <ListItem
                          sx={{
                            "&:hover": {
                              "& .MuiListItemSecondaryAction-root": {
                                display: "block !important",
                              },
                            },
                            paddingBottom: "4px",
                            borderBottom:
                              "1px solid rgba(0, 0, 0, 0.30) !important",
                          }}
                          disablePadding
                          divider
                        >
                          <ListItemButton dense>
                            <ListItemIcon sx={{ minWidth: "43px" }}>
                              <EmailRoundedIcon />
                            </ListItemIcon>
                            <CustomTooltip
                              placement="top"
                              title={overflowActive ? task?.agent?.email : ""}
                            >
                              <ListItemText
                                sx={{
                                  "& .MuiTypography-root": {
                                    width: "85%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  },
                                }}
                                primary={task?.agent?.email}
                                ref={emailRef}
                              />
                            </CustomTooltip>
                            <ListItemSecondaryAction sx={{ display: "none" }}>
                              <CopyToClipboard
                                onCopy={() =>
                                  toast.success("Copied Successfully!", {
                                    position: "top-right",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  })
                                }
                                text={task?.agent?.email}
                              >
                                <IconButton sx={{ padding: "3px" }}>
                                  <ContentCopyRoundedIcon fontSize="small" />
                                </IconButton>
                              </CopyToClipboard>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Stack
                                onClick={() => {
                                  navigate(
                                    `/agent_roster/${task?.agent_id}/overview`
                                  );
                                }}
                                direction="row"
                                spacing={1}
                                sx={{ cursor: "pointer" }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "12.5px",
                                    "&:hover": { textDecoration: "underline" },
                                  }}
                                >
                                  View Agent Detail
                                </Typography>
                                <EastRoundedIcon fontSize="small" />
                              </Stack>
                            }
                          />
                          <ListItemSecondaryAction sx={{ mr: "-8px" }}>
                            <Stack direction="row" spacing={1}>
                              <Button
                                sx={{
                                  minWidth: "30px",
                                  width: "30px",
                                  height: "28.5px",
                                  borderRadius: "6px",
                                  padding: "0px 5px",
                                }}
                                variant="outlined"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  if (task?.agent?.phone) {
                                    dialNumber(
                                      task?.agent?.phone,
                                      null,
                                      task?.agent?.full_name,
                                      null,
                                      task?.agent_id
                                    );
                                  }
                                }}
                              >
                                {" "}
                                <LocalPhoneRoundedIcon
                                  sx={{ fontSize: "18px !important" }}
                                  fontSize="small"
                                />
                              </Button>
                              <Button
                                sx={{
                                  minWidth: "30px",
                                  height: "28.5px",
                                  borderRadius: "6px",
                                  padding: "3px 5px",
                                }}
                                variant="outlined"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  if (
                                    task?.agent?.admin_channel_id &&
                                    task?.agent?.slack_team_id
                                  ) {
                                    window.open(
                                      `slack://channel?team=${task?.agent?.slack_team_id}&id=${task?.agent?.admin_channel_id}`
                                    );
                                  }
                                }}
                              >
                                {" "}
                                <img
                                  style={{ width: "15.3px" }}
                                  src={flowerIcon}
                                  alt="badge icon"
                                />
                              </Button>
                            </Stack>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </Card>
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        openTeamMemberDropdown(false);
                      }}
                    >
                      <Card
                        onClick={() => {
                          openTeamMemberDropdown(!teamMemberDropdown);
                        }}
                        variant="outlined"
                        sx={{
                          borderRadius: "8px",
                          mt: "10px",
                          position: "relative",
                          overflow: "visible",
                        }}
                      >
                        <CardHeader
                          CardHeader
                          classes={{
                            title: classes.taskCardHeader,
                            subheader: classes.taskCardDes,
                          }}
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            openTeamMemberDropdown(!teamMemberDropdown)
                          }
                          avatar={
                            <Stack
                              direction="row"
                              spacing={1.7}
                              alignItems="center"
                            >
                              <AdminPanelSettingsRoundedIcon
                                sx={{
                                  color: "rgba(0, 0, 0, 0.54)",
                                  ml: "-3.5px",
                                  fontSize: "30px",
                                }}
                              />
                              <Avatar
                                src={
                                  task?.team_member?.profile_images
                                    ?.profile_img_thumbnail || null
                                }
                                title={task.team_member?.full_name}
                                sx={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "50%",
                                }}
                              />
                            </Stack>
                          }
                          title={
                            task?.team_member
                              ? task.team_member.full_name
                              : "Admin Unassigned"
                          }
                        />

                        {teamMemberDropdown && (
                          <Grid className={classes.taskMemberBox}>
                            {task.team_member && (
                              <MenuItem
                                onClick={() =>
                                  unassignTaskMember(task.team_member_id)
                                }
                              >
                                Unassign Task
                              </MenuItem>
                            )}
                            {users
                              .filter((user) => user.is_agent === false)
                              .map((user) => (
                                <MenuItem
                                  onClick={() => {
                                    assignTaskMember(user);
                                    openTeamMemberDropdown(!teamMemberDropdown);
                                  }}
                                  value={user.id}
                                >
                                  <AvatarComponent
                                    sx={{ marginRight: "14px" }}
                                    src={
                                      user.profile_images
                                        ? user.profile_images
                                            ?.profile_img_thumbnail
                                        : null
                                    }
                                    nameInitial={user.full_name}
                                  />
                                  {user.full_name}
                                </MenuItem>
                              ))}
                          </Grid>
                        )}
                      </Card>
                    </OutsideClickHandler>
                    <Card
                      variant="outlined"
                      sx={{ borderRadius: "8px", mt: "10px", mb: "10px" }}
                    >
                      <CardHeader
                        CardHeader
                        classes={{
                          title: classes.taskCardHeader,
                          subheader: classes.overDueDes,
                        }}
                        sx={{ ml: "2px !important" }}
                        avatar={
                          <AccessTimeRoundedIcon
                            sx={{ color: "grey", ml: "-1px", fontSize: "22px" }}
                          />
                        }
                        title={
                          <Stack direction={"row"} spacing={1}>
                            {calculateDueIn(task?.project?.due_date) ===
                            "Overdue"
                              ? ""
                              : "Due in "}
                            <Box
                              sx={{
                                ml:
                                  calculateDueIn(task?.project?.due_date) ===
                                  "Overdue"
                                    ? "0px"
                                    : "5px !important",
                                color:
                                  momentTZ(task?.project?.due_date).diff(
                                    momentTZ(),
                                    "hours"
                                  ) < 24 &&
                                  momentTZ(task?.project?.due_date).diff(
                                    momentTZ(),
                                    "hours"
                                  ) > 0
                                    ? "#FF7A00"
                                    : momentTZ(task?.project?.due_date).diff(
                                        momentTZ(),
                                        "hours"
                                      ) <= 0
                                    ? "red"
                                    : calculateDueIn(
                                        task?.project?.due_date
                                      ) === "Overdue"
                                    ? "red"
                                    : "black",
                                textTransform:
                                  calculateDueIn(task?.project?.due_date) ===
                                  "Overdue"
                                    ? "uppercase"
                                    : "",
                              }}
                            >
                              {calculateDueIn(task?.project?.due_date)}
                            </Box>
                          </Stack>
                        }
                        subheader={`Due Date: ${momentTZ
                          .tz(
                            task?.project?.due_date,
                            adminDetail?.market?.timezone || "America/New_York"
                          )
                          .format("L")} at ${momentTZ
                          .tz(
                            task?.project?.due_date,
                            adminDetail?.market?.timezone || "America/New_York"
                          )
                          .format("LT")}`}
                      />
                    </Card>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      maxRows={3}
                      placeholder="Publicly Visible Task Completion Comments"
                      className={classes.textArea2}
                      defaultValue={publicNote}
                      onBlur={(e) => {
                        setPublicNote(e.target.value);
                        dispatch(
                          saveProjectTaskPublicComments({
                            id: task.id,
                            value: e.target.value,
                            type: "task",
                            noteType: "public_notes",
                          })
                        );
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space",
                        marginTop: "3px",
                      }}
                    >
                      {/* New task flow will be here */}
                      {task?.status?.toLowerCase() === "future" ||
                      task?.status === "" ||
                      task?.status === "canceled" ? (
                        <Button
                          onClick={() => handleStatusChange("queued")}
                          variant="contained"
                          className={classes.button}
                        >
                          flip to queued
                        </Button>
                      ) : null}

                      {task?.status?.toLowerCase() === "completed" ||
                      task?.status === "approved" ? (
                        <Button
                          onClick={() => handleStatusChange("working")}
                          variant="contained"
                          className={classes.button}
                        >
                          Revert to Working
                        </Button>
                      ) : null}

                      {(task?.status?.toLowerCase() === "queued" ||
                        task?.status?.toLowerCase() === "onhold" ||
                        task?.status?.toLowerCase() === "paused" ||
                        task?.status?.toLowerCase() === "correction") && (
                        <Button
                          onClick={() => startTask()}
                          variant="contained"
                          className={classes.button}
                        >
                          {task?.status?.toLowerCase() === "onhold"
                            ? "REMOVE HOLD"
                            : task?.status?.toLowerCase() === "paused"
                            ? "UNPAUSE TASK"
                            : task?.status?.toLowerCase() === "correction"
                            ? "start corrections"
                            : "Start Task"}
                        </Button>
                      )}
                      {task?.status?.toLowerCase() === "working" && (
                        <Button
                          onClick={() => {
                            if (
                              task?.status?.toLowerCase() === "working" &&
                              task?.approval_required
                            ) {
                              reviewTask();
                            } else {
                              handleStatusChange("completed");
                            }
                          }}
                          variant="contained"
                          className={classes.button}
                        >
                          {task?.status?.toLowerCase() === "working" &&
                          task?.approval_required
                            ? " Internal Review"
                            : "Complete Task"}
                        </Button>
                      )}

                      {(task?.status?.toLowerCase() === "review" ||
                        task?.status?.toLowerCase() === "submitted") &&
                        task.approval_required && (
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <Button
                              onClick={() => handleStatusChange("correction")}
                              variant="outline"
                              className={classes.clearButton}
                            >
                              Correct{" "}
                              <ClearIcon
                                style={{ color: "red", fill: "red" }}
                              />
                            </Button>
                            {task?.status?.toLowerCase() === "submitted" ? (
                              <Button
                                onClick={() => setApproveConfirmation(true)}
                                variant="contained"
                                className={classes.approveButton}
                              >
                                Approve{" "}
                                <CheckRoundedIcon
                                  sx={{
                                    color: "white",
                                    fontSize: "1.4rem",
                                  }}
                                />
                              </Button>
                            ) : (
                              <Button
                                onClick={() => handleStatusChange("submitted")}
                                variant="outline"
                                className={classes.sendButton}
                              >
                                Submit{" "}
                                <SendIcon
                                  sx={{
                                    color: "green",
                                    fill: "#00BF41",
                                    fontSize: "1.26rem",
                                  }}
                                />
                              </Button>
                            )}
                          </Box>
                        )}

                      {/* end flow section */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space",
                        marginTop:
                          task?.status?.toLowerCase() === "future" ||
                          task?.status?.toLowerCase() === "" ||
                          task?.status?.toLowerCase() === "working" ||
                          task?.status?.toLowerCase() === "queued" ||
                          task?.status?.toLowerCase() === "future" ||
                          task?.status?.toLowerCase() === "onhold" ||
                          task?.status?.toLowerCase() === "paused" ||
                          task?.status?.toLowerCase() === "submitted" ||
                          task?.status?.toLowerCase() === "correction" ||
                          task?.status?.toLowerCase() === "canceled" ||
                          task?.status?.toLowerCase() === "completed" ||
                          task?.status?.toLowerCase() === "approved" ||
                          (task?.status?.toLowerCase() === "review" &&
                            task.approval_required) ||
                          (task.status === "review" &&
                            task?.approval_required === false)
                            ? "10px"
                            : "38.5px",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (isProjectDetailsOpen) {
                            toast.error("Project Details is already open!", {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                          } else {
                            dispatch(
                              setSelectedProjectId(task?.project?.id || null)
                            );
                            dispatch(openProjectDetails(true));
                            dispatch(closeTaskDetails(true));
                          }
                        }}
                        className={classes.viewProjectButton}
                      >
                        View Project
                      </Button>
                    </Box>
                  </Grid>
                  <Grid xs={12} md={7} lg={7.6} item>
                    <Box
                      sx={{
                        position: "relative",
                        // height: specialStips
                        //   ? "473px !important"
                        //   : task?.project?.title
                        //   ? "630px !important"
                        //   : "636px !important",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <LockRoundedIcon
                        sx={{
                          position: "absolute",
                          top: "14px",
                          right: "13px",
                          color: "grey",
                        }}
                      />
                      <Box
                        className={classes.textAreaForTopRight}
                        sx={{ flex: 1 }}
                      >
                        <Linkify
                          properties={{ target: "_blank" }}
                          componentDecorator={(
                            decoratedHref,
                            decoratedText,
                            key
                          ) => (
                            <a target="blank" href={decoratedHref} key={key}>
                              {decoratedText}
                            </a>
                          )}
                        >
                          <pre
                            style={{
                              margin: "0px",
                              whiteSpace: "pre-line",
                              wordBreak: "break-word  !important",
                            }}
                          >
                            {creationNote?.value}
                          </pre>
                        </Linkify>
                      </Box>

                      {task?.rejected_by_id ||
                      task?.approved_by_id ||
                      task?.public_rejected_notes ||
                      task?.approval_notes ? (
                        <Card
                          sx={{
                            mt: "10px",
                            padding: "10px 12px",
                            borderRadius: "8px",
                          }}
                          variant="outlined"
                        >
                          {/* rejected scenario */}
                          {task?.rejected_by_id && !task?.approved_by_id ? (
                            <Typography sx={{ fontSize: "13px" }}>
                              <span style={{ color: "#F00" }}>
                                Corrections Requested
                              </span>{" "}
                              {momentTZ
                                .tz(
                                  task?.rejected_at,
                                  adminDetail?.market?.timezone ||
                                    "America/New_York"
                                )
                                .format("L")}{" "}
                              at{" "}
                              {momentTZ
                                .tz(
                                  task?.rejected_at,
                                  adminDetail?.market?.timezone ||
                                    "America/New_York"
                                )
                                .format("LT")}{" "}
                              by {task?.rejected_by}
                              {task?.public_rejected_notes ? ":" : ""}
                            </Typography>
                          ) : null}

                          {task?.public_rejected_notes &&
                          !task?.approved_by_id ? (
                            <Typography
                              sx={{ fontStyle: "italic", fontSize: "13px" }}
                            >
                              ”{task?.public_rejected_notes}”
                            </Typography>
                          ) : null}
                          {/*  Approved scenario */}
                          {task?.approved_by_id ? (
                            <Typography sx={{ fontSize: "13px" }}>
                              <span style={{ color: "#04BC00" }}>Approved</span>{" "}
                              {momentTZ
                                .tz(
                                  task?.approved_at,
                                  adminDetail?.market?.timezone ||
                                    "America/New_York"
                                )
                                .format("L")}{" "}
                              at{" "}
                              {momentTZ
                                .tz(
                                  task?.approved_at,
                                  adminDetail?.market?.timezone ||
                                    "America/New_York"
                                )
                                .format("LT")}{" "}
                              by {task?.approved_by}
                              {task?.approval_notes ? ":" : ""}
                            </Typography>
                          ) : null}
                          {task?.approval_notes ? (
                            <Typography
                              sx={{ fontStyle: "italic", fontSize: "13px" }}
                            >
                              ”{task?.approval_notes}”
                            </Typography>
                          ) : null}
                        </Card>
                      ) : null}
                      {specialStips && (
                        <Box
                          sx={{
                            position: "relative",
                            marginTop: "9px",
                            height: "138px",
                          }}
                        >
                          <LockRoundedIcon
                            sx={{
                              position: "absolute",
                              top: "14px",
                              right: "13px",
                              color: "grey",
                            }}
                          />
                          <TextareaAutosize
                            aria-label="minimum height"
                            placeholder="Stipulation Notes"
                            className={classes.textAreaTopRIght}
                            defaultValue={specialStips.value}
                            disabled
                          />
                        </Box>
                      )}
                      <Box sx={{ position: "relative", marginTop: "10px" }}>
                        <Box
                          className={classes.deliverablesSection}
                          style={{
                            width:
                              Object.keys(selectedDeliverables).length > 0 ||
                              taskDeliverables?.length > 0
                                ? "91%"
                                : "100%",
                            padding:
                              taskDeliverables?.length === 0 ? 0 : "10px 13px",
                            opacity:
                              isDragActive || uploadingDeliverable ? "0.5" : 1,
                            border:
                              isDragActive || uploadingDeliverable
                                ? "1px dashed #000000"
                                : "",
                          }}
                        >
                          {/* Drop Area Starts */}
                          <div
                            {...getRootProps({
                              onClick: (event) => event.stopPropagation(),
                            })}
                            style={{ width: "100%" }}
                          >
                            <input {...getInputProps()} />

                            <Box>
                              {taskDeliverables?.length === 0 && (
                                <Box className={classes.uploadInput}>
                                  <Button
                                    variant="outline"
                                    component="label"
                                    sx={{
                                      display: "flex",
                                      gap: "3px",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                    className={classes.uploadAttachmentButton}
                                  >
                                    <FileUploadRoundedIcon
                                      sx={{ color: "rgba(0, 0, 0, 0.50)" }}
                                    />
                                    <span>Upload Attachments</span>
                                    <input
                                      multiple
                                      type="file"
                                      onChange={uploadDeliverableFile}
                                      hidden
                                    />
                                  </Button>
                                </Box>
                              )}

                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable
                                  droppableId="taskDeliverablesDroppable"
                                  direction="horizontal"
                                >
                                  {(provided, snapshot) => (
                                    <Box
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      className={classes.deliverables}
                                    >
                                      {taskDeliverables?.map(
                                        (deliverable, index) => (
                                          <Draggable
                                            key={deliverable.id.toString()}
                                            draggableId={deliverable.id.toString()}
                                            index={index}
                                          >
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                isDragging={
                                                  snapshot.isDragging &&
                                                  !snapshot.isDropAnimating
                                                }
                                                style={droppingAnimationStyle(
                                                  provided.draggableProps.style,
                                                  snapshot
                                                )}
                                              >
                                                <div
                                                  style={{
                                                    position: "relative",
                                                    top: "3%",
                                                  }}
                                                >
                                                  <Tooltip
                                                    placement="top"
                                                    title={getLastPartOfString(
                                                      deliverable.file
                                                    )}
                                                    arrow
                                                  >
                                                    <img
                                                      onClick={() =>
                                                        openDeliverable(
                                                          deliverable
                                                        )
                                                      }
                                                      onMouseEnter={() =>
                                                        setDisplayDeliverableCheckbox(
                                                          {
                                                            [deliverable.id]: true,
                                                          }
                                                        )
                                                      }
                                                      onMouseLeave={() =>
                                                        setDisplayDeliverableCheckbox(
                                                          (prev) => {
                                                            let updated = {
                                                              ...prev,
                                                            };
                                                            delete updated[
                                                              deliverable.id
                                                            ];
                                                            return updated;
                                                          }
                                                        )
                                                      }
                                                      alt="deliverable"
                                                      className={
                                                        classes.deliverablesImg
                                                      }
                                                      src={
                                                        deliverable.thumbnail
                                                      }
                                                    />
                                                  </Tooltip>
                                                  {Object.keys(
                                                    selectedDeliverables
                                                  ).length > 0 ||
                                                  Object.keys(
                                                    displayDeliverableCheckbox
                                                  ).find(
                                                    (d) => d == deliverable.id
                                                  ) ? (
                                                    <div
                                                      onMouseEnter={() =>
                                                        setDisplayDeliverableCheckbox(
                                                          {
                                                            [deliverable.id]: true,
                                                          }
                                                        )
                                                      }
                                                      onMouseLeave={() =>
                                                        setDisplayDeliverableCheckbox(
                                                          (prev) => {
                                                            let updated = {
                                                              ...prev,
                                                            };
                                                            delete updated[
                                                              deliverable.id
                                                            ];
                                                            return updated;
                                                          }
                                                        )
                                                      }
                                                    >
                                                      <Checkbox
                                                        className={
                                                          classes.deliverableCheckbox
                                                        }
                                                        checkedIcon={
                                                          <BpCheckedIcon />
                                                        }
                                                        iconStyle={{
                                                          fill: "red",
                                                        }}
                                                        icon={<BpIcon />}
                                                        onChange={(e) => {
                                                          e.stopPropagation();
                                                          setSelectedDeliverables(
                                                            (prev) => {
                                                              let updated = {
                                                                ...prev,
                                                              };
                                                              if (
                                                                e.target.checked
                                                              ) {
                                                                updated = {
                                                                  ...updated,
                                                                  [deliverable.id]: true,
                                                                };
                                                              } else {
                                                                delete updated[
                                                                  deliverable.id
                                                                ];
                                                              }
                                                              return updated;
                                                            }
                                                          );
                                                        }}
                                                        checked={
                                                          selectedDeliverables[
                                                            deliverable.id
                                                          ] || false
                                                        }
                                                      />
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        )
                                      )}
                                    </Box>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </Box>
                          </div>
                          {taskDeliverables?.length > 0 ? (
                            <Button
                              variant="outlined"
                              component="label"
                              sx={{
                                borderRadius: "8px",
                                position: "absolute",
                                right: 0,
                                top: 0,
                                minWidth: "36px !important",
                                borderColor: "lightgrey",
                                width: "36px",
                                height: "36px",
                                backgroundColor: "white",
                              }}
                              color="inherit"
                            >
                              <FileUploadRoundedIcon
                                sx={{ color: "rgba(0, 0, 0, 0.50)" }}
                              />
                              <input
                                multiple
                                type="file"
                                onChange={uploadDeliverableFile}
                                hidden
                              />
                            </Button>
                          ) : null}
                          {taskDeliverables?.length > 0 &&
                            Object.keys(selectedDeliverables).filter(
                              (deliverable) =>
                                selectedDeliverables[deliverable] === true
                            ).length > 0 && (
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                gap={"6px 2px"}
                                sx={{
                                  position: "absolute",
                                  right: 0,
                                  top: "41px",
                                }}
                              >
                                {Object.keys(selectedDeliverables).length ===
                                  taskDeliverables?.length && (
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      borderRadius: "8px",
                                      minWidth: "36px !important",
                                      borderColor: "lightgrey",
                                      width: "36px",
                                      height: "36px",
                                      backgroundColor: "white",
                                    }}
                                    onClick={() => selectAllDeliverable(false)}
                                    color="inherit"
                                  >
                                    <CheckCircleIcon
                                      fontSize="small"
                                      sx={{ color: "rgba(0, 0, 0, 0.50)" }}
                                    />
                                  </Button>
                                )}
                                {Object.keys(selectedDeliverables).length !==
                                  taskDeliverables?.length && (
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      borderRadius: "8px",
                                      minWidth: "36px !important",
                                      borderColor: "lightgrey",
                                      width: "36px",
                                      height: "36px",
                                      backgroundColor: "white",
                                    }}
                                    onClick={() => selectAllDeliverable(true)}
                                    color="inherit"
                                  >
                                    <CheckCircleIcon
                                      fontSize="small"
                                      sx={{ color: "rgba(0, 0, 0, 0.50)" }}
                                    />
                                  </Button>
                                )}
                                <Button
                                  variant="outlined"
                                  sx={{
                                    borderRadius: "8px",
                                    minWidth: "36px !important",
                                    borderColor: "lightgrey",
                                    width: "36px",
                                    height: "36px",
                                    backgroundColor: "white",
                                  }}
                                  onClick={downloadDeliverable}
                                  color="inherit"
                                >
                                  <Download
                                    sx={{ color: "rgba(0, 0, 0, 0.50)" }}
                                  />
                                </Button>

                                <Button
                                  variant="outlined"
                                  sx={{
                                    borderRadius: "8px",
                                    minWidth: "36px !important",
                                    borderColor: "lightgrey",
                                    width: "36px",
                                    height: "36px",
                                    backgroundColor: "white",
                                  }}
                                  onClick={deleteDeliverableFile}
                                  color="inherit"
                                >
                                  <Delete
                                    sx={{ color: "rgba(0, 0, 0, 0.50)" }}
                                  />
                                </Button>
                              </Box>
                            )}
                        </Box>

                        {uploadingDeliverable && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: "33%",
                              left: "46%",
                            }}
                          >
                            <FadeLoader size="40" color="rgba(0, 0, 0, 0.5)" />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
          </Grid>
          {openComments ? (
            <Grid
              item
              xs={12}
              lg={openComments ? 3.4 : 12}
              sx={{ paddingLeft: "0px !important" }}
            >
              <CommentSection
                task={task}
                comments={task?.task_comments}
                height={"100%"}
                handleSubmitComment={handleSubmitComment}
                id="message-field-tasks"
                {...props}
              />
            </Grid>
          ) : null}
        </Grid>
      )}
      <TaskConfirmation
        open={statusAlert}
        setOpen={setStatusAlert}
        handleConfirm={handleConfirm}
        title="Pause Active Task"
        description=" You currently have one other task in “Working” Status. Would you like to pause it?"
      />
      <TaskConfirmation
        open={cancelConfirmation}
        setOpen={setCancelConfirmation}
        handleConfirm={handleConfirmCancel}
        title="Cancel Task"
        description="Are you sure you want to Cancel this task?"
      />{" "}
      <TaskConfirmation
        open={approveConfirmation}
        setOpen={setApproveConfirmation}
        handleConfirm={handleConfirmApprove}
        title=""
        description="Please provide proof of agent approval or reasoning for Admin Approval."
        comment={true}
      />
    </Dialog>
  );
}
