import axios from "axios";
import moment from "moment";

const pluralOrSingular = (count) => (count > 1 ? "s" : "");

export const deptColorsMapping = (deptName) => {
  switch (deptName) {
    case "Drafting":
      return "rgba(191, 151, 236, 0.46)";
    case "Listings":
      return "rgba(63, 82, 249, 0.25)";
    case "Transactions":
      return "rgba(54, 180, 251, 0.45)";
    case "Finance":
      return "rgba(102, 217, 107, 0.55)";
    case "Graphics":
      return "rgba(223, 164, 13, 0.45)";
    case "Valuation":
      return "rgba(153, 217, 102, 0.4)";
    case "Admin":
      return "rgba(239, 175, 80, 0.4)";
    case "IT-Support":
      return "rgba(204, 27, 165, 0.3)";
    case "Marketing":
      return "rgba(251, 246, 123, 0.79)";
    case "Front-line":
      return "rgba(209, 240, 122, 0.51)";
    default:
      return "#000";
  }
};

export const deptTextColorsMapping = (deptName) => {
  switch (deptName) {
    case "Drafting":
      return "#730D97";
    case "Listings":
      return "#4819CD";
    case "Transactions":
      return "#15445E";
    case "Finance":
      return "#0D490C";
    case "Graphics":
      return "#995D03";
    case "Valuation":
      return "#196F17";
    case "Admin":
      return "#CE5917";
    case "IT-Support":
      return "#AE159F";
    case "Marketing":
      return "#7C7E13";
    case "Front-line":
      return "#567400";
    default:
      return "#000";
  }
};

const statusPriority = {
  correction: 10,
  queued: 9,
  working: 8,
  review: 7,
  approved: 6,
  submitted: 5,
  onhold: 4,
  future: 3,
  completed: 2,
  canceled: 1,
};

export const statusComparator = (v1, v2) => {
  return statusPriority[v2.toLowerCase()] - statusPriority[v1.toLowerCase()];
};

export const capitalize = (s) => s && s[0] && s[0].toUpperCase() + s.slice(1);

export const statusColor = (status) =>
  taskStatus.find((item) => item.title === status)?.bgColor ||
  taskStatus.find((item) => item.value === status)?.bgColor ||
  projectStatus.find((item) => item.value === status)?.bgColor ||
  projectStatus.find((item) => item.title === status)?.bgColor;

export const calculateDueIn = (dateTime) => {
  let a = moment(new Date()); //now
  let b = moment(new Date(dateTime));
  let dueIn;

  let mins = b.diff(a, "minutes");
  let hrs = b.diff(a, "hours");
  let days = b.diff(a, "days");

  if (mins > 60) {
    if (hrs > 24) {
      dueIn = `${days} Day${pluralOrSingular(days)}`;
    } else {
      dueIn = `${hrs} Hour${pluralOrSingular(hrs)}`;
    }
  } else if (mins <= 0) {
    dueIn = "Overdue";
  } else {
    dueIn = `${mins} Minute${pluralOrSingular(mins)}`;
  }

  return dueIn;
};

export const calculateDueInShortForm = (dateTime) => {
  let a = moment(new Date()); //now
  let b = moment(new Date(dateTime));
  let dueIn;

  let mins = b.diff(a, "minutes");
  let hrs = b.diff(a, "hours");
  let ds = b.diff(a, "days");

  if (mins > 60) {
    if (hrs > 24) {
      dueIn = `${ds}d`;
    } else {
      dueIn = `${hrs}h`;
    }
  } else if (mins <= 0) {
    let m = Math.abs(mins);
    if (m >= 1 && m <= 60) {
      dueIn = `-${m}m`;
    } else {
      let hours = Math.floor(m / 60);
      if (hours > 24) {
        let days = Math.floor(hours / 24);
        dueIn = `-${days}d`;
      } else if (hours >= 1 && hours <= 24) {
        dueIn = `-${hours}h`;
      }
    }
  } else {
    dueIn = `${mins}m`;
  }

  return dueIn;
};

export const getFieldSize = (size) =>
  size === "xl" || size === "lg" ? 12 : size === "md" ? 6 : 3;

export const getSignedURL = async (deliverableSrc) => {
  const url = `${
    process.env.REACT_APP_BASE_URL
  }api/transactions/signed-url?src=${encodeURIComponent(deliverableSrc)}`;

  const resp = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + localStorage.token,
      "Content-Type": "application/json",
    },
  });
  return resp.data;
};

export const openDeliverable = async (deliverable) => {
  const signedURL = await getSignedURL(deliverable.file);
  window.open(signedURL.data, "_blank");
};

export const projectStatus = [
  {
    title: "Future",
    value: "future",
    bgColor: "#856FDF",
    color: "white",
  },
  {
    title: "Queued",
    value: "queued",
    bgColor: "#79BFFF",
    color: "white",
  },
  {
    title: "On Hold",
    value: "onhold",
    bgColor: "#BDBDBD",
    color: "white",
  },
  {
    title: "Pending",
    value: "pending",
    bgColor: "#3B877B",
    color: "white",
  },
  {
    title: "Ready to Complete",
    value: "ready_to_complete",
    bgColor: "#91C58A",
    color: "white",
  },
  {
    title: "Completed",
    value: "completed",
    bgColor: "#346A2B",
    color: "white",
  },
  {
    title: "Canceled",
    value: "canceled",
    bgColor: "#000",
    color: "white",
  },
];

export const taskStatus = [
  {
    title: "Future",
    value: "future",
    bgColor: "#856FDF",
    color: "white",
    approval_required: false,
  },
  {
    title: "Queued",
    value: "queued",
    bgColor: "#79BFFF",
    color: "white",
    approval_required: false,
  },
  {
    title: "On Hold",
    value: "onhold",
    bgColor: "#BDBDBD",
    color: "white",
    approval_required: false,
  },
  {
    title: "Working",
    value: "working",
    bgColor: "#FF9800",
    color: "white",
    approval_required: false,
  },
  {
    title: "Review",
    value: "review",
    bgColor: "#FFD740",
    color: "white",
    approval_required: true,
  },
  {
    title: "Correction",
    value: "correction",
    bgColor: "#C40E0E",
    color: "white",
    approval_required: true,
  },
  {
    title: "Submitted",
    value: "submitted",
    bgColor: "#A3C6A4",
    color: "white",
    approval_required: true,
  },
  {
    title: "Approved",
    value: "approved",
    bgColor: "#40C745",
    color: "white",
    approval_required: true,
  },
  {
    title: "Completed",
    value: "completed",
    bgColor: "#196C20",
    color: "white",
    approval_required: false,
  },
  {
    title: "Canceled",
    value: "canceled",
    bgColor: "#000",
    color: "white",
    approval_required: false,
  },
  {
    title: "Paused",
    value: "paused",
    bgColor: "#6D6D6D",
    color: "white",
    approval_required: false,
  },
];

export const handleReturnTaskStatus = (approval_required) => {
  if (approval_required) {
    return taskStatus;
  }

  return taskStatus.filter((item) => item.approval_required === false);
};

export const statusFilterData = [
  {
    title: "All Projects",
    value: "all",
    status: [
      "future",
      "queued",
      "onhold",
      "pending",
      "review",
      "correction",
      "submitted",
      "approved",
      "completed",
      "milestone completed",
      "canceled",
      "paused",
      "ready_to_complete",
    ],
  },
  {
    title: "Active",
    value: "active",
    status: [
      "future",
      "queued",
      "working",
      "pending",
      "review",
      "correction",
      "submitted",
      "approved",
      "paused",
      "ready_to_complete",
    ],
  },
  {
    title: "Queued",
    value: "queued",
  },
  {
    title: "Pending",
    value: "pending",
  },
  {
    title: "Ready to Complete",
    value: "ready_to_complete",
  },
  {
    title: "On Hold",
    value: "onhold",
  },
  {
    title: "Future",
    value: "future",
  },
  {
    title: "Completed",
    value: "completed",
  },
  {
    title: "Canceled",
    value: "canceled",
  },
];

export const statusFilterDataTasks = [
  {
    title: "All Tasks",
    value: "all",
    status: [
      "future",
      "queued",
      "onhold",
      "pending",
      "review",
      "correction",
      "submitted",
      "approved",
      "completed",
      "milestone completed",
      "canceled",
      "paused",
      "working",
    ],
  },
  {
    title: "Active",
    value: "active",
    status: [
      "future",
      "queued",
      "working",
      "pending",
      "review",
      "correction",
      "submitted",
      "approved",
      "paused",
      "working",
    ],
  },
  {
    title: "Queued",
    value: "queued",
  },
  {
    title: "Working",
    value: "working",
  },
  {
    title: "Paused",
    value: "paused",
  },
  {
    title: "Review",
    value: "review",
  },
  {
    title: "Submitted",
    value: "submitted",
  },
  {
    title: "Correction",
    value: "correction",
  },
  {
    title: "Approved",
    value: "approved",
  },
  {
    title: "On Hold",
    value: "onhold",
  },
  {
    title: "Future",
    value: "future",
  },
  {
    title: "Completed",
    value: "completed",
  },
  {
    title: "Canceled",
    value: "canceled",
  },
];
