import React, { useState } from "react";
import { Box, Typography, Button, Card } from "@mui/material";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import moment from "moment";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import { useNavigate } from "react-router-dom";

function CategoryPosts(props) {
  const { classes, category, categoryTitle, storedUsers, setSelectedCategory } =
    props;
  const [currentSlide, setCurrentSlide] = useState(0); // Track the current slide
  const navigate = useNavigate();

  const sliderSettings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: category.posts?.length < 3 ? category.posts?.length : 3,
    slidesToScroll: 1,
    nextArrow: currentSlide < category.posts?.length - 3 ? <NextArrow /> : null,
    prevArrow: currentSlide > 0 ? <PrevArrow /> : null,
    variableWidth: true,
    swipeToSlide: true,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  };

  return (
    <Box className={classes.categoriesPostsSection}>
      <Box className={classes.categoriesHeader}>
        <Typography className={classes.categoriesHeading}>
          {categoryTitle || category?.title}
          <Button
            className={classes.postViewButton}
            size="small"
            variant="outlined"
            color="inherit"
            onClick={() => setSelectedCategory(category)}
          >
            View All
          </Button>
        </Typography>
      </Box>
      <Box sx={{ position: "relative" }}>
        <Slider {...sliderSettings} className={classes.categoriesSlider}>
          {category.posts?.map((item, index) => (
            <Box
              onClick={() =>
                navigate("/knowledge-base/main/" + item?.id, { state: item })
              }
              key={index}
              className={classes.categoryPostItem}
            >
              <Card className={classes.categoryPostImg}>
                <img
                  src={item.thumbnail}
                  alt={item.title}
                  style={{ width: "100%", height: "180px", objectFit: "cover" }}
                />
                {item?.type === "video" ? (
                  <PlayCircleOutlineRoundedIcon
                    className={`${classes.categoryPostIcon} playIcon`}
                    sx={{ display: "none" }}
                  />
                ) : (
                  <TopicOutlinedIcon
                    className={`${classes.categoryPostIcon} playIcon`}
                    sx={{ display: "none" }}
                  />
                )}
                {item?.author_id ? (
                  <Typography
                    className={`${classes.createdBy} createdBy01`}
                    sx={{ display: "none" }}
                  >
                    By:{" "}
                    {item?.author_id
                      ? storedUsers?.find((it) => it.id === item?.author_id)
                          ?.full_name || ""
                      : ""}
                  </Typography>
                ) : null}
                <Typography
                  className={`${classes.postAuthor} postAuthor01`}
                  sx={{ display: "none" }}
                >
                  Added on {moment(item.createdAt).format("L")}
                </Typography>
              </Card>
              <Typography className={classes.categoryPostContent}>
                {item?.type === "video" ? (
                  <PlayArrowRoundedIcon sx={{ mt: "-2px" }} />
                ) : (
                  <DescriptionOutlinedIcon sx={{ mt: "-2px" }} />
                )}
                {item?.title}
              </Typography>
            </Box>
          ))}
        </Slider>

        {/* Blur Overlay */}
        {/* {(currentSlide > 0 || currentSlide < category?.posts?.length - 3) && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              height: "100%",
              width: "150px", // adjust as needed for the blur width
              background:
                "linear-gradient(to left, rgba(255, 255, 255, 0.8), transparent)",
              pointerEvents: "none",
              zIndex: 9,
            }}
          />
        )} */}
      </Box>
    </Box>
  );
}

// Custom Next Arrow Component
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Button
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center !important",
        alignItems: "center",
        position: "absolute",
        right: "-6px",
        top: "41%",
        transform: "translateY(-50%)",
        backgroundColor: "#fff",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        color: "#000",
        padding: "10px",
        zIndex: 10,
        width: "45px",
        minWidth: "45px",
        height: "40px",
        paddingLeft: "0px",
        borderRadius: "15px",
      }}
      onClick={onClick}
    >
      <ArrowForwardIosRoundedIcon sx={{ ml: "-6px" }} />
    </Button>
  );
};

// Custom Prev Arrow Component
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Button
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center !important",
        alignItems: "center",
        position: "absolute",
        left: "-6px",
        top: "41%",
        transform: "translateY(-50%)",
        backgroundColor: "#fff",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        color: "#000",
        zIndex: 10,
        width: "45px",
        minWidth: "45px",
        height: "40px",
        paddingLeft: "0px",
        borderRadius: "15px",
        "&:before": {
          display: "none",
        },
      }}
      onClick={onClick}
    >
      <ArrowBackIosNewRoundedIcon sx={{ ml: "-12px" }} />
    </Button>
  );
};

export default CategoryPosts;
