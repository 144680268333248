export const styleSheet = () => ({
  pageRoot: {
    minHeight: "100vh !important",
    padding: "70px 0px !important",
  },
  staffHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    marginBottom: "30px !important",
  },
  staffHeaderTitle: {
    fontSize: "16px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    fontWeight: "500 !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    gap: "10px !important",
    alignItems: "center !important",
    textTransform: "none !important",
  },
  staffUsersSection: {
    backgroundColor: "white !important",
    height: "720px !important",
    borderRadius: "20px !important",
    width: "100% !important",
    overflow: "hidden !important",
  },
  staffUserHeader: {
    backgroundColor: "black !important",
    color: "white !important",
    padding: "12px 16px !important",
  },
  staffUserHeading: {
    fontSize: "14px !important",
    textTransform: "uppercase !important",
  },
  staffUserList: {
    height: "calc(100% - 60px) !important",
    overflow: "auto !important",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
  },
  manageMemberSection: {
    backgroundColor: "white !important",
    height: "720px !important",
    borderRadius: "20px !important",
    width: "100% !important",
    overflow: "hidden !important",
    padding: "24px !important",
  },
  notSelectedSection: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    width: "100% !important",
    height: "100% !important",
  },
  notSelectedContent: {
    textAlign: "center !important",
    color: "#848484 !important",
    textTransform: "uppercase !important",
    fontSize: "14px !important",
  },
  manageUserHeading: {
    fontSize: "16px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    fontWeight: "500 !important",
    textTransform: "uppercase !important",
  },
  manageDepartmentView: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    marginTop: "20px !important",
    gap: "50px !important",
  },
  manageTrainedTaskButton: {
    background: "black !important",
    color: "white !important",
    fontSize: "14px !important",
    textTransform: "uppercase !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
  },
  departmentViewSection: {
    flex: 1,
    padding: "16px !important",
    backgroundColor: "#F6F6F6 !important",
    borderRadius: "20px !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    gap: "16px",
    flexWrap: "wrap !important",
  },
  departmentViewItem: {
    paddingLeft: "3px !important",
    paddingRight: "3px !important",
    color: "white !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  departmentViewChip: {
    paddingLeft: "6px !important",
    paddingRight: "6px !important",
    color: "white !important",
  },
  manageScheduleSection: {
    marginTop: "30px !important",
  },
  dropdownButton: {
    width: "130px  !important",
    boxShadow: "none  !important",
    background: "#d9d9d9  !important",
    borderRadius: "0px  !important",
  },
  departmentHeaderSection: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    gap: "20px !important",
  },
  headerLeft: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "20px !important",
  },
  headerUserDisplay: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "15px !important",
  },
  headerUserHeading: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  departmentManageSection: {
    backgroundColor: "white !important",
    boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.15) !important",
    borderRadius: "20px !important",
    overflow: "hidden !important",
    marginTop: "20px !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    height: "615px !important",
  },
  departmentManageSectionLeft: {
    width: "230px !important",
    borderRight: "1px solid rgba(0, 0, 0, 0.10)  !important",
  },
  departmentManageSectionRight: {
    flex: 1,
  },
  headerSection: {
    height: "45px !important",
    padding: "12px 16px !important",
    backgroundColor: "#F1F1F1 !important",
    width: "100% !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
  },
  headerSectionHeading: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    textTransform: "uppercase !important",
  },
  projectListing: {
    padding: "8px !important",
    backgroundColor: "white !important",
    height: "100% !important",
  },
  listSection: {
    "& .MuiTypography-root": {
      fontSize: "14px !important",
      fontWeight: "500 !important",
    },
    height: "100% !important",
    overflow: "auto !important",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
  },
  headerRight: {
    display: "flex !important",
    justifyContent: "flex-end  !important",
    alignItems: "center !important",
    gap: "12px !important",
  },
  headerRightSuccess: {
    fontSize: "14px !important",
    color: "#87C37D !important",
    fontFamily: "AvenirNext  !important",
    display: "flex !important",
    justifyContent: "flex-end  !important",
    alignItems: "center !important",
    gap: "2px !important",
  },
});
