import React, { useState } from "react";
import { Box, Typography, Button, Card, Grid } from "@mui/material";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import moment from "moment";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import { useNavigate } from "react-router-dom";
function SearchResultsArea(props) {
  const { classes, category, categoryTitle, storedUsers } = props;
  const navigate = useNavigate();
  return (
    <Box className={classes.categoriesPostsSection} sx={{ mt: "30px" }}>
      <Box className={classes.categoriesHeader}>
        <Typography className={classes.categoriesHeading}>
          {categoryTitle || category?.title}{" "}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {category.posts?.map((item, index) => (
          <Grid
            item
            xs={3}
            md={3}
            key={index}
            className={classes.categoryPostItemSearch}
            onClick={() =>
              navigate("/knowledge-base/main/" + item?.id, {
                state: item,
              })
            }
          >
            <Card className={classes.categoryPostImg}>
              <img
                src={item.thumbnail}
                alt={item.title}
                style={{ width: "100%", height: "180px", objectFit: "cover" }}
              />
              {item?.type === "video" ? (
                <PlayCircleOutlineRoundedIcon
                  className={`${classes.categoryPostIcon} playIcon`}
                  sx={{ display: "none" }}
                />
              ) : (
                <TopicOutlinedIcon
                  className={`${classes.categoryPostIcon} playIcon`}
                  sx={{ display: "none" }}
                />
              )}
              {item?.author_id ? (
                <Typography
                  className={`${classes.createdBy} createdBy01`}
                  sx={{ display: "none" }}
                >
                  By:{" "}
                  {item?.author_id
                    ? storedUsers?.find((it) => it.id === item?.author_id)
                        ?.full_name || ""
                    : ""}{" "}
                </Typography>
              ) : null}
              <Typography
                className={`${classes.postAuthor} postAuthor01`}
                sx={{ display: "none" }}
              >
                Added on {moment(item.createdAt).format("L")}
              </Typography>
            </Card>
            <Typography className={classes.categoryPostContent}>
              {item?.type === "video" ? (
                <PlayArrowRoundedIcon sx={{ mt: "-2px" }} />
              ) : (
                <DescriptionOutlinedIcon sx={{ mt: "-2px" }} />
              )}

              {item?.title}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default SearchResultsArea;
