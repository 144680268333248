import React, { useEffect, useState } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Box, Stack, Card, Typography, Grid, Skeleton } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import shield1 from "../../../../../assets/images/shield1.png";
import shield2 from "../../../../../assets/images/shield2.png";
import shield3 from "../../../../../assets/images/shield3.png";
import shield4 from "../../../../../assets/images/shield4.png";
import PointsHistory from "./pointsHistory";
import PointsStats from "./pointStats";
import { CustomTooltip } from "../../../../../components/shared/tooltip";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
function UserPoints(props) {
  let { classes, userId } = props;
  const [rank, setRank] = useState(false);
  const [currentMonthPoints, setCurrentMonthPoints] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const pointsData = useSelector(
    (state) => state.agentRoster.points.pointsData
  );
  useEffect(() => {
    if (pointsData?.leaderboard?.length && rank === false) {
      let newPoints = [...pointsData.leaderboard];
      let newLeaderboard = newPoints.sort((a, b) => b.point - a.point);
      let newPointsWithRank = [];
      let rankNumber = 0;
      newLeaderboard.map((item, index) => {
        if (index === 0 && item.point > 0) {
          newPointsWithRank.push({ ...item, rank: 1 });
          rankNumber = rankNumber + 1;
        } else {
          if (item.point === newLeaderboard[index - 1]?.point) {
            newPointsWithRank.push({ ...item, rank: rankNumber });
          } else {
            if (rankNumber > 0) {
              rankNumber = rankNumber + 1;
              newPointsWithRank.push({ ...item, rank: rankNumber });
            } else {
              newPointsWithRank.push({ ...item, rank: rankNumber });
            }
          }
        }
      });
      setRank(
        newPointsWithRank.find((item) => item.user_id == (id || userId))
          ?.point > 0
          ? newPointsWithRank.find((item) => item.user_id == (id || userId))
              ?.rank || "-"
          : "-"
      );
    }
    if (!currentMonthPoints) {
      if (userId == pointsData?.user?.id) {
        setCurrentMonthPoints(pointsData?._metadata?.total_point);
      }
      if (id == pointsData?.user?.id) {
        setCurrentMonthPoints(pointsData?._metadata?.total_point);
      }
    }
  }, [pointsData?.leaderboard]);

  const handleDeleteUpdate = (point) => {
    setCurrentMonthPoints(currentMonthPoints - point);
  };
  return (
    <Box sx={{ minHeight: "calc(100vh - 180px)" }}>
      <Card variant="outlined" className={classes.pointsTabRoot}>
        <Box className={classes.pointsTopArea}>
          <Box>
            {" "}
            <Typography className={classes.pointsTabHeading}>Points</Typography>
            <Stack
              direction={"row"}
              justifyContent={"flex-end"}
              alignItems="center"
              spacing={3}
            >
              {pointsData.isLoading && !currentMonthPoints ? (
                <Skeleton
                  variant="rounded"
                  className={classes.pointsTopLeftCard1}
                ></Skeleton>
              ) : (
                <Card className={classes.pointsTopLeftCard1}>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ lineHeight: "30px" }}
                    spacing={4}
                  >
                    <StarIcon fontSize="small" sx={{ mr: "7px" }} />{" "}
                    {currentMonthPoints || "0"}
                  </Stack>
                  <Typography sx={{ fontSize: "14px" }}>
                    Points Earned This Month
                  </Typography>
                </Card>
              )}

              {pointsData.isLoading && !pointsData?.user?.point ? (
                <Skeleton
                  variant="rounded"
                  className={classes.pointsTopLeftCard1}
                ></Skeleton>
              ) : (
                <Card variant="outlined" className={classes.pointsTopLeftCard2}>
                  {" "}
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ lineHeight: "30px" }}
                    spacing={4}
                  >
                    <StarIcon fontSize="small" sx={{ mr: "7px" }} />{" "}
                    {Number(pointsData?.user?.point) -
                      Number(pointsData?.user?.spent_point)}
                  </Stack>
                  <Typography sx={{ fontSize: "14px" }}>
                    Total Lifetime Points
                  </Typography>
                </Card>
              )}
            </Stack>
          </Box>
          <Box className={classes.pointsTopRIght}>
            {" "}
            {pointsData.isLoading && rank === false ? (
              <Skeleton
                variant="rounded"
                className={classes.pointsTopRightCard}
              ></Skeleton>
            ) : (
              <Card className={classes.pointsTopRightCard}>
                <Typography className={classes.pointsTopRightCardHeading}>
                  Current Rank
                </Typography>
                <CustomTooltip
                  title="Check back tomorrow for updated rankings."
                  placement="top"
                >
                  <Box className={classes.pointsTopRightShields}>
                    <Box className={classes.pointsTopRightShieldValue}>
                      {rank}
                    </Box>
                    <img
                      src={
                        rank === 1
                          ? shield3
                          : rank === 2
                          ? shield4
                          : rank === 2
                          ? shield2
                          : shield1
                      }
                      alt="point shield"
                    />
                  </Box>
                </CustomTooltip>
                <Box
                  className={classes.pointsTopRightCardLink}
                  // onClick={() => navigate(props.isUser ? `/users/${id}/leaderboard` : `/agent_roster/${id}/leaderboard`)}
                  onClick={() => navigate(`/dashboard/leaderboard`)}
                >
                  View Leaderboard
                </Box>
              </Card>
            )}
          </Box>
        </Box>
        <Grid container spacing={2} sx={{ mt: "30px" }}>
          <Grid item md={8}>
            <PointsHistory handleDeleteUpdate={handleDeleteUpdate} {...props} />
          </Grid>
          <Grid item md={4}>
            <PointsStats {...props} />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
export default withStyles(styleSheet, {
  name: "AgentPointsStyle",
})(UserPoints);
