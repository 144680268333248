import React, { useState } from "react";
import { Box, Container, Typography, Grid, Button } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import WestRoundedIcon from "@mui/icons-material/WestRounded";
import StaffUsers from "./staffUsers";
import ManageArea from "./manageArea";
import { useNavigate } from "react-router-dom";
function StaffMembersManagement(props) {
  const { classes } = props;
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState({});
  const [manageType, setManageType] = useState("user");
  return (
    <Box className={classes.pageRoot}>
      <Container>
        <Box className={classes.staffHeader}>
          <Button
            onClick={() => navigate("/staff_management")}
            variant="text"
            size="small"
          >
            <Typography className={classes.staffHeaderTitle}>
              <WestRoundedIcon sx={{ fontSize: "24px" }} /> Back to Staff HUB
            </Typography>
          </Button>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={3.3}>
            <StaffUsers
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
              setManageType={setManageType}
              manageType={manageType}
              {...props}
            />
          </Grid>
          <Grid item xs={8.7}>
            <ManageArea
              selectedUser={selectedUser}
              manageType={manageType}
              setManageType={setManageType}
              {...props}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default withStyles(styleSheet, { name: "StaffMembersManagementStyle" })(
  StaffMembersManagement
);
