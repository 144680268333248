import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Slider from "react-slick";
import ImgsViewer from "react-images-viewer";
import { useDispatch, useSelector } from "react-redux";
import {
  isArrayCheck,
  numberToCurrency,
  numberWithCommas,
  slider_settings_2,
} from "./helper.js";
import {
  addAdjustmentsInComparable,
  setComparableCustomValue,
} from "../../../redux/tools/cmas.js";
import moment from "moment";
import NoImage from "../../../assets/images/noimage.png";
import { getImageUrlWithHeightAndWidth } from "../../../utils/propertyData.js";

function AdjustmentsComparable(props) {
  const {
    cma,
    index,
    classes,
    adjustedDataInput,
    adjustedmentVisibility,
    handleAdjustmentInput,
    currentPage,
    customFieldsRef,
    subject,
    changedVisibility,
    forceUpdate,
  } = props;
  const dispatch = useDispatch();

  const [imageViewerOpen, setImageViewerOpen] = React.useState(false);
  const [imageViewerStartingImage, setImageViewerStartingImage] =
    React.useState(0);
  const [TotalAdj, setTotalAdj] = React.useState("");
  const [TotalAdjValue, setTotalAdjValue] = React.useState("");
  const [adjustedData, setAdjustedData] = React.useState({
    MlsStatus: 0.0,
    BedroomsTotal: 0.0,
    BathroomsFull: 0.0,
    BathroomsHalf: 0.0,
    Basement: 0.0,
    CountyOrParish: 0.0,
    BuildingAreaTotal: 0.0,
    pricePerSqFt: 0.0,
    OriginalListPrice: 0.0,
    ListPrice: 0.0,
    ClosePrice: 0.0,
    AssociationFee: 0.0,
    TaxAnnualAmount: 0.0,
    ListingContractDate: 0.0,
    PurchaseContractDate: 0.0,
    CloseDate: 0.0,
    DaysOnMarket: 0.0,
    LotSizeAcres: 0.0,
    PropertyType: 0.0,
    YearBuilt: 0.0,
    PoolFeatures: 0.0,
    ParkingTotal: 0.0,
    GarageSpaces: 0.0,
    ConstructionMaterials: 0.0,
    ArchitecturalStyle: 0.0,
    FireplacesTotal: 0.0,
    Levels: 0.0,
  });

  const { adjustmentData, customFieldLoading } = useSelector(
    (state) => state.allCmas
  );

  React.useEffect(() => {
    if (adjustmentData || changedVisibility === true) {
      if (isArrayCheck(adjustmentData?.cma_comparables)) {
        let obj = adjustmentData?.cma_comparables?.find(
          (dat) => dat["id"]?.value === cma?.id
        );
        if (!obj) return;
        let comparableAdjustedValues = { ...adjustedData };
        for (var key of Object.keys(comparableAdjustedValues)) {
          comparableAdjustedValues[key] = obj[key]?.adjustment;
        }
        let updatedInputStatus = [...adjustedDataInput];
        let objInputStatus = {};
        Object.keys(adjustedDataInput[index]).map((adj, i) => {
          objInputStatus[adj] = false;
        });
        subject?.cma_custom_fields.map((customField) => {
          comparableAdjustedValues[customField.name] = obj[customField.name];
        });
        setAdjustedData(comparableAdjustedValues);
        updatedInputStatus[index] = { ...objInputStatus };
        handleAdjustmentInput(updatedInputStatus);
      }
    }
  }, [
    subject?.cma_custom_fields,
    customFieldLoading,
    changedVisibility,
    forceUpdate,
  ]);

  React.useEffect(() => {
    let obj;
    if (isArrayCheck(adjustmentData?.cma_comparables)) {
      obj = adjustmentData?.cma_comparables?.find(
        (dat) => dat["id"]?.value === cma?.id
      );
    }
    setTotalAdj(obj?.TotalAdj?.value);
    setTotalAdjValue(
      obj?.TotalAdjValue?.value !== 0 || obj?.TotalAdjValue?.value
        ? obj?.TotalAdjValue?.value
        : cma.ClosePrice
    );
  }, [adjustmentData]);

  const onKeyDown = (e, currentIndex, customField = null) => {
    if (e.key === "Tab") {
      e.stopPropagation();
      e.preventDefault();

      let nextIndex;
      let updatedInputStatus = [...adjustedDataInput];

      // custom field starts
      if (customField) {
        if (customField === 1) {
          if (currentPage % 2 === 0) {
            if ((index + 1) % 2 === 0) {
              customFieldsRef.current[index + 1][currentIndex].focus();
            } else {
              customFieldsRef.current[index - 1][currentIndex + 1].focus();
            }
          } else {
            if ((index + 1) % 2 !== 0) {
              customFieldsRef.current[index + 1][currentIndex].focus();
            } else {
              customFieldsRef.current[index - 1][currentIndex + 1].focus();
            }
          }
        } else if (customField === 2) {
          let obj = {};
          Object.keys(adjustedDataInput[index]).map((adj, i) => {
            if (currentIndex === i) {
              obj[adj] = true;
            } else {
              obj[adj] = false;
            }
          });

          customFieldsRef.current[index][currentIndex].blur();
          updatedInputStatus[index] = { ...obj };
          handleAdjustmentInput(updatedInputStatus);
        }
      } else {
        // Checking current page, which indicates that the current page has even number of comparables or not
        // index = comparable index
        // currentIndex = field index value
        if (currentPage % 2 === 0) {
          if ((index + 1) % 2 !== 0) {
            // tab to 2nd to 1st on next row
            nextIndex = currentIndex + 1;

            let obj = {};
            Object.keys(adjustedDataInput[index - 1]).map((adj, i) => {
              if (nextIndex === i) {
                obj[adj] = true;
              } else {
                obj[adj] = false;
              }
            });

            updatedInputStatus[index - 1] = { ...obj };

            obj = {};
            Object.keys(adjustedDataInput[index]).map((adj, i) => {
              obj[adj] = false;
            });

            updatedInputStatus[index] = { ...obj };
            handleAdjustmentInput(updatedInputStatus);
          } else {
            // tab to 1st to 2nd on same row
            nextIndex = currentIndex;

            let obj = {};
            Object.keys(adjustedDataInput[index + 1]).map((adj, i) => {
              if (nextIndex === i) {
                obj[adj] = true;
              } else {
                obj[adj] = false;
              }
            });

            updatedInputStatus[index + 1] = { ...obj };

            obj = {};
            Object.keys(adjustedDataInput[index]).map((adj, i) => {
              obj[adj] = false;
            });

            updatedInputStatus[index] = { ...obj };
            handleAdjustmentInput(updatedInputStatus);
          }
        } else {
          if ((index + 1) % 2 === 0) {
            nextIndex = currentIndex + 1;

            let obj = {};
            Object.keys(adjustedDataInput[index - 1]).map((adj, i) => {
              if (nextIndex === i) {
                obj[adj] = true;
              } else {
                obj[adj] = false;
              }
            });

            updatedInputStatus[index - 1] = { ...obj };

            obj = {};
            Object.keys(adjustedDataInput[index]).map((adj, i) => {
              obj[adj] = false;
            });

            updatedInputStatus[index] = { ...obj };
            handleAdjustmentInput(updatedInputStatus);
          } else {
            nextIndex = currentIndex;

            let obj = {};
            Object.keys(adjustedDataInput[index + 1]).map((adj, i) => {
              if (nextIndex === i) {
                obj[adj] = true;
              } else {
                obj[adj] = false;
              }
            });

            updatedInputStatus[index + 1] = { ...obj };

            obj = {};
            Object.keys(adjustedDataInput[index]).map((adj, i) => {
              obj[adj] = false;
            });

            updatedInputStatus[index] = { ...obj };
            handleAdjustmentInput(updatedInputStatus);
          }
        }
      }
    }
  };

  const handleSliderImageClick = (imageIndex) => {
    setImageViewerOpen(true);
    setImageViewerStartingImage(imageIndex);
  };

  return (
    <>
      <div className={classes.cardHeading}>Comparable Sale {index + 1}</div>
      <Card>
        {(cma?.cma_media_urls?.length === 0 || !cma?.cma_media_urls) && (
          <CardMedia
            component="img"
            width="100%"
            height="240px"
            image={
              cma?.MediaURL
                ? getImageUrlWithHeightAndWidth(cma?.MediaURL)
                : NoImage
            }
            alt={cma?.City}
          />
        )}
        {cma?.cma_media_urls?.length > 0 && (
          <>
            <Slider {...slider_settings_2}>
              {cma?.cma_media_urls?.map((item, imageIndex) => {
                return (
                  <CardMedia
                    component="img"
                    width="100%"
                    height="240px"
                    onClick={() => handleSliderImageClick(imageIndex)}
                    image={getImageUrlWithHeightAndWidth(item?.MediaUrl)}
                  />
                );
              })}
            </Slider>
            <ImgsViewer
              imgs={cma?.cma_media_urls?.map((item) => ({
                src: getImageUrlWithHeightAndWidth(item?.MediaUrl),
              }))}
              isOpen={imageViewerOpen}
              currImg={imageViewerStartingImage}
              showThumbnails={true}
              enableKeyboardInput={true}
              onClickThumbnail={(e) => setImageViewerStartingImage(e)}
              onClickPrev={() =>
                imageViewerStartingImage - 1 >= 0
                  ? setImageViewerStartingImage(
                      (imageViewerStartingImage) => imageViewerStartingImage - 1
                    )
                  : null
              }
              onClickNext={() =>
                imageViewerStartingImage + 1 <= cma?.cma_media_urls.length
                  ? setImageViewerStartingImage(
                      (imageViewerStartingImage) => imageViewerStartingImage + 1
                    )
                  : null
              }
              onClose={() => setImageViewerOpen(false)}
            />
          </>
        )}

        <Typography
          className={classes.cardTitle}
          variant="body1"
          component="div"
        >
          {cma?.StreetName
            ? `${cma?.StreetNumber || ""} ${cma?.StreetName || ""}
           ${cma?.StreetSuffix || ""} ${cma?.UnitNumber || ""}`
            : `Undisclosed Address ${cma?.UnitNumber || ""}`}
        </Typography>
        <Typography className={classes.cardSubTitle}>
          {cma?.City || ""} {cma?.StateOrProvince || ""} {cma?.PostalCode || ""}
        </Typography>
        {Object.keys(adjustedData).map((adj, i) => {
          return (
            <div key={i} className={classes.descSection}>
              <div className={classes.houseArea}>
                {!adjustedData[adj]?.custom_field_id && (
                  <strong className={classes.houseAreaStrong}>
                    {
                      [
                        "BedroomsTotal",
                        "BathroomsFull",
                        "BathroomsHalf",
                        "BuildingAreaTotal",
                        "DaysOnMarket",
                        "LotSizeAcres",
                        "PropertyType",
                        "YearBuilt",
                        "PoolFeatures",
                        "ParkingTotal",
                        "GarageSpaces",
                        "FireplacesTotal",
                      ].includes(adj)
                        ? cma[adj] || ""
                        : [
                            "ListingContractDate",
                            "PurchaseContractDate",
                            "CloseDate",
                          ].includes(adj)
                        ? moment(cma[adj]).isValid()
                          ? moment(cma[adj]).format("MM/DD/YYYY")
                          : ""
                        : !isNaN(cma[adj]) // if a price
                        ? `$${numberWithCommas(cma[adj])}`
                        : adj === "MlsStatus"
                        ? cma[adj] === "Active Under Contract"
                          ? "ActiveUC"
                          : cma[adj]
                        : cma[adj]?.length > 0
                        ? cma[adj]
                        : "" // if a string
                    }
                  </strong>
                )}

                {/* Custom Field Title Input */}
                {adjustedData[adj]?.custom_field_id && (
                  <input
                    id={`${i}-${index}-field`}
                    style={{
                      padding: "0px!important",
                      margin: "0px!important",
                    }}
                    ref={(el) =>
                      customFieldsRef.current[index]
                        ? (customFieldsRef.current[index][i] = el)
                        : null
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Tab") {
                        onKeyDown(e, i, 2);
                      } else {
                        const updatedComparableAdjustedValues = JSON.parse(
                          JSON.stringify(adjustedData)
                        );
                        updatedComparableAdjustedValues[adj].value =
                          e.target.value;
                        setAdjustedData(updatedComparableAdjustedValues);
                      }
                    }}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    defaultValue={adjustedData[adj]?.value}
                    onBlur={(e) =>
                      dispatch(
                        setComparableCustomValue({
                          id: adjustedData[adj]?.custom_field_id,
                          value: e.target.value,
                          cmaId: cma?.cma_id,
                        })
                      )
                    }
                  />
                )}
              </div>

              {/* General field adjustment values */}
              {adjustedDataInput[index][adj] !== true &&
                !adjustedData[adj]?.custom_field_id &&
                adj !== "MlsStatus" && (
                  <Typography
                    onClick={() => {
                      let updatedInputStatus = [...adjustedDataInput];
                      updatedInputStatus[index][adj] = true;
                      handleAdjustmentInput(updatedInputStatus);
                    }}
                    className={
                      Number.parseFloat(adjustedData[adj]) > 0
                        ? classes.descSectionPositive
                        : Number.parseFloat(adjustedData[adj]) < 0
                        ? classes.descSectionNegative
                        : classes.descSectionZero
                    }
                  >
                    {numberToCurrency(adjustedData[adj])}
                  </Typography>
                )}

              {/* Custom field adjustment values */}
              {adjustedDataInput[index][adj] !== true &&
                adjustedData[adj]?.custom_field_id && (
                  <Typography
                    onClick={() => {
                      let updatedInputStatus = [...adjustedDataInput];
                      updatedInputStatus[index][adj] = true;
                      handleAdjustmentInput(updatedInputStatus);
                    }}
                    className={
                      Number.parseFloat(adjustedData[adj]?.adjustment) > 0
                        ? classes.descSectionPositive
                        : Number.parseFloat(adjustedData[adj]?.adjustment) < 0
                        ? classes.descSectionNegative
                        : classes.descSectionZero
                    }
                  >
                    {numberToCurrency(adjustedData[adj]?.adjustment)}
                  </Typography>
                )}

              {/* Both general and custom field adjustment values - Input fields */}
              {adjustedDataInput[index][adj] === true &&
                adj !== "MlsStatus" && (
                  <input
                    autoFocus
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Tab") {
                        let updatedInputStatus = [...adjustedDataInput];
                        updatedInputStatus[index][adj] = false;
                        handleAdjustmentInput(updatedInputStatus);

                        let obj;

                        if (adjustedData[adj]?.custom_field_id) {
                          obj = {
                            [adjustedData[adj].name]: {
                              id: adjustedData[adj]?.custom_field_id,
                              adjustment: Number.parseFloat(
                                adjustedData[adj]?.adjustment
                              ),
                            },
                          };
                        } else {
                          obj = {
                            [adj]: {
                              id: null,
                              adjustment: Number.parseFloat(adjustedData[adj]),
                            },
                          };
                        }

                        dispatch(
                          addAdjustmentsInComparable({
                            ...obj,
                            id: cma?.id,
                            cmaId: cma?.cma_id,
                          })
                        );

                        onKeyDown(
                          e,
                          i,
                          !adjustedData[adj]?.custom_field_id
                            ? i === 26 &&
                              ((currentPage % 2 !== 0 &&
                                (index + 1) % 2 === 0) ||
                                (currentPage % 2 === 0 && index % 2 === 0))
                              ? 1
                              : null
                            : 1
                        );
                      }
                    }}
                    defaultValue={
                      !adjustedData[adj]?.custom_field_id
                        ? adjustedData[adj]
                        : adjustedData[adj]?.adjustment
                    }
                    className={classes.descSectionInput}
                    onChange={(e) => {
                      if (!adjustedData[adj]?.custom_field_id) {
                        if (!adjustedmentVisibility[adj]) {
                          return 0;
                        }
                        let updatedComparableAdjustedValues = {
                          ...adjustedData,
                        };
                        updatedComparableAdjustedValues[adj] = e.target.value;
                        setAdjustedData(updatedComparableAdjustedValues);
                      } else {
                        let updatedComparableAdjustedValues = JSON.parse(
                          JSON.stringify({ ...adjustedData })
                        );
                        let l = updatedComparableAdjustedValues[adj];
                        l.adjustment = e.target.value;
                        setAdjustedData((val) => ({ ...val, [adj]: l }));
                      }
                    }}
                    onBlur={() => {
                      let updatedInputStatus = [...adjustedDataInput];
                      updatedInputStatus[index][adj] = false;
                      handleAdjustmentInput(updatedInputStatus);

                      let obj;

                      if (adjustedData[adj]?.custom_field_id) {
                        obj = {
                          [adjustedData[adj].name]: {
                            id: adjustedData[adj]?.custom_field_id,
                            adjustment: Number.parseFloat(
                              adjustedData[adj]?.adjustment
                            ),
                          },
                        };
                      } else {
                        obj = {
                          [adj]: {
                            id: null,
                            adjustment: Number.parseFloat(adjustedData[adj]),
                          },
                        };
                      }

                      dispatch(
                        addAdjustmentsInComparable({
                          ...obj,
                          id: cma?.id,
                          cmaId: cma?.cma_id,
                        })
                      );
                    }}
                    placeholder="$0"
                    type="text"
                  />
                )}

              {adj === "MlsStatus" && cma[adj] === "Closed" && (
                <Typography
                  className={
                    Number.parseFloat(cma?.ClosePrice) > 0
                      ? classes.descSectionPositive
                      : Number.parseFloat(cma?.ClosePrice) < 0
                      ? classes.descSectionNegative
                      : classes.descSectionZero
                  }
                >
                  {" "}
                  {"$"}
                  {numberWithCommas(cma.ClosePrice)}
                </Typography>
              )}
              {adj === "MlsStatus" && cma[adj] !== "Closed" && (
                <Typography
                  className={
                    Number.parseFloat(cma?.ListPrice) > 0
                      ? classes.descSectionPositive
                      : Number.parseFloat(cma?.ListPrice) < 0
                      ? classes.descSectionNegative
                      : classes.descSectionZero
                  }
                >
                  {" "}
                  {"$"}
                  {numberWithCommas(cma.ListPrice)}
                </Typography>
              )}
            </div>
          );
        })}

        <div className={classes.descSection}></div>
      </Card>
      <div className={classes.priceSection}>
        <div className={classes.priceDescTwo}>Total Adjustments</div>
        <div className={classes.housePriceTwo}>
          <span>{numberToCurrency(TotalAdj)}</span>
        </div>
      </div>
      <div className={classes.priceSection}>
        <div className={classes.priceDescTwo}>Adjusted Price</div>
        <div className={classes.housePriceTwo}>
          <span>{numberToCurrency(TotalAdjValue)}</span>
        </div>
      </div>
    </>
  );
}
export default AdjustmentsComparable;
