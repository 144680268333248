import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, CircularProgress, Typography, Card, Autocomplete, TextField, Stack, MenuItem, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ResponseAlert from "../responseAlert/index.js";
import { sendAdditionalPaymentForCloseContract } from "../../redux/transactions/sendAdditionalPaymentForCloseContract.js";

function DeductionRules({ classes, closeAdditionalPaymentBreakageModal, amountTextFieldRequired, amountTextFieldLabel }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [openPreview, setOpenPreview] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const Fragment = React.Fragment;
  const [grossCommisions, setGrossCommisions] = useState([]);
  const [companyDollars, setCompanyDollars] = useState([]);
  const [agentSplit, setAgentSplit] = useState([]);
  const [companyDollarsDeductions, setCompanyDollarsDeductions] = useState([]);
  const [agentSplitDeductions, setAgentSplitDeductions] = useState([]);
  const [grossCommisionsDeductions, setGrossCommisionsDeductions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reloadCalculation, setReloadCalculation] = useState(false);

  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [companyDollarsAmounts, setCompanyDollarsAmounts] = useState({
    gross: 0,
    net: 0,
  });
  const [agentSplitAmounts, setAgentSplitAmounts] = useState({
    gross: 0,
    net: 0,
  });
  const [grossCommisionsAmounts, setGrossCommisionsAmounts] = useState({
    gross: 0,
    net: 0,
  });

  const [assignedAgentSplitValue, setAssignedAgentSplitValue] = useState(0);
  const [assignedAgentSplitType, setAssignedAgentSplitType] = useState("%");

  const ContractData = useSelector((state) => state.transactions.TransactionsContract.ContractData?.data);

  const commissionPlans = useSelector((state) => state.finance.commissionPlans);
  const { data } = commissionPlans;

  const { vendorList, vendorsObj } = useSelector((item) => item.users.storedListOfUsers);

  useEffect(() => {
    let gciDeduction = [],
      agentDeduction = [],
      companyDeduction = [];
    let gciCommission = Math.abs(grossCommisionsAmounts.gross),
      contractPrice = Math.abs(ContractData.price),
      grossGci = gciCommission;

    for (let gci of grossCommisions) {
      if (gci.value && gci.paymentType && gci.acctCode && gci.payTo) {
        if (gci.paymentType === "%") {
          let value = Math.abs(gci.value);
          let deduction = 0;
          if (gci.percentageOf === "gci") {
            deduction = Math.abs(((gciCommission * value) / 100).toFixed(2));
            gciCommission -= deduction;
          } else if (gci.percentageOf === "contractPrice") {
            deduction = Math.abs(((contractPrice * value) / 100).toFixed(2));
            gciCommission -= deduction;
            contractPrice -= deduction;
          }
          if (deduction) {
            gciDeduction.push(deduction);
          }
        } else {
          let value = Math.abs(gci.value);
          let deduction = Math.abs(value.toFixed(2));
          gciCommission -= deduction;
          if (deduction) {
            gciDeduction.push(deduction);
          }
        }
      }
    }

    let agentCommission = Math.abs(assignedAgentSplitValue),
      companyDollarAmount = gciCommission - agentCommission;
    if (assignedAgentSplitType === "%") {
      agentCommission = Math.abs(((gciCommission * Math.abs(assignedAgentSplitValue)) / 100).toFixed(2));
      companyDollarAmount = gciCommission - agentCommission;
    }
    let grossAgentCommission = agentCommission;
    let grossCompanyDollar = companyDollarAmount;
    for (let agent of agentSplit) {
      if (agent.value && agent.paymentType && agent.acctCode && agent.payTo) {
        if (agent.paymentType === "%") {
          let value = Math.abs(agent.value);
          let deduction = 0;
          if (agent.percentageOf === "gci") {
            deduction = Math.abs(((gciCommission * value) / 100).toFixed(2));
            agentCommission -= deduction;
          } else if (agent.percentageOf === "contractPrice") {
            deduction = Math.abs(((contractPrice * value) / 100).toFixed(2));
            agentCommission -= deduction;
            contractPrice -= deduction;
          }
          if (deduction) {
            agentDeduction.push(deduction);
          }
        } else {
          let value = Math.abs(agent.value);
          let deduction = Math.abs(value.toFixed(2));
          agentCommission -= deduction;
          if (deduction) {
            agentDeduction.push(deduction);
          }
        }
      }
    }

    for (let company of companyDollars) {
      if (company.value && company.paymentType && company.acctCode && company.payTo) {
        if (company.paymentType === "%") {
          let value = Math.abs(company.value);
          let deduction = 0;
          if (company.percentageOf === "gci") {
            deduction = Math.abs(((gciCommission * value) / 100).toFixed(2));
            companyDollarAmount -= deduction;
          } else if (company.percentageOf === "contractPrice") {
            deduction = Math.abs(((contractPrice * value) / 100).toFixed(2));
            companyDollarAmount -= deduction;
            contractPrice -= deduction;
          }
          if (deduction) {
            companyDeduction.push(deduction);
          }
        } else {
          let value = Math.abs(company.value);
          let deduction = Math.abs(value.toFixed(2));
          companyDollarAmount -= deduction;
          if (deduction) {
            companyDeduction.push(deduction);
          }
        }
      }
    }
    setCompanyDollarsDeductions(companyDeduction);
    setAgentSplitDeductions(agentDeduction);
    setGrossCommisionsDeductions(gciDeduction);

    setCompanyDollarsAmounts({
      gross: grossCompanyDollar || 0,
      net: companyDollarAmount || 0,
    });
    setAgentSplitAmounts({
      gross: grossAgentCommission || 0,
      net: agentCommission || 0,
    });
    setGrossCommisionsAmounts({
      gross: grossGci || 0,
      net: gciCommission || 0,
    });
  }, [grossCommisions, agentSplit, companyDollars, assignedAgentSplitValue, assignedAgentSplitType, ContractData, reloadCalculation]);

  const renderTitle = (amount, title, bold = false) => {
    return (
      <Grid container className={classes.mainSectionTitle + " " + (bold ? classes.commissionBold : null)}>
        <Grid item xs={3.2}>
          <Typography variant="h3">{formatter.format(amount)}</Typography>
        </Grid>
        <Grid xs={8.5}>
          <Typography variant="h4">{!title.insert ? title : title.insert}</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderContent = (content, deductions) => {
    const render = (object, amount) => {
      return (
        <Grid container className={classes.mainSectionContent}>
          <Grid item xs={2.2}>
            <Typography variant="h5">
              {amount ? formatter.format(amount) : object.amountDeducted ? formatter.format(object.amountDeducted) : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={0.9}>
            -------
          </Grid>
          <Grid xs={8.5}>
            <Typography variant="h5">{object.name ? `To ${object.name} ${object.via ? `via ${object.via}` : ""} ` : "N/A"}</Typography>
          </Grid>
        </Grid>
      );
    };

    if (!content || content.length < 1) {
      return;
    }

    if (Array.isArray(content)) {
      return content.map((element, i) => {
        return render(element, deductions ? deductions[i] : null);
      });
    }
    return render(content);
  };

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const handleValidateRow = (obj) => {
    let isFormValid = true;
    if (obj) {
      Object.keys(obj).forEach((k) => {
        if (obj[k] === "") {
          isFormValid = false;
        }
      });
    }
    return isFormValid;
  };

  const handleGrossDeduction = () => {
    let commisions = [...grossCommisions];
    let valObj = commisions.slice(-1)[0];

    if (valObj && valObj.paymentType === "$") {
      delete valObj.percentageOf;
    }
    if (handleValidateRow(valObj)) {
      commisions.push({
        value: "",
        paymentType: "%",
        acctCode: "teamLeaderComm",
        percentageOf: "",
        payTo: "",
        via: "",
        name: "",
        user_id: "",
        tax_id: "",
      });
      setGrossCommisions(commisions);
    } else {
      setErrorAlert({
        errorMsg: "Pls fill  all fields first!",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  const handleDeleteGrossDeduction = (index) => {
    let commisions = [...grossCommisions];
    commisions.splice(index, 1);
    setGrossCommisions(commisions);
  };

  const handleAddAgentSplit = () => {
    let commisions = [...agentSplit];
    let valObj = commisions.slice(-1)[0];
    if (valObj && valObj.paymentType === "$") {
      delete valObj.percentageOf;
    }
    if (handleValidateRow(valObj)) {
      commisions.push({
        value: "",
        paymentType: "%",
        acctCode: "teamLeaderComm",
        percentageOf: "",
        payTo: "",
        via: "",
        name: "",
        user_id: "",
      });
      setAgentSplit(commisions);
    } else {
      setErrorAlert({
        errorMsg: "Pls fill  all fields first!",
        errorType: "warning",
        isOpen: true,
      });
    }
  };
  const handleDeleteAgentSplit = (index) => {
    let commisions = [...agentSplit];
    commisions.splice(index, 1);
    setAgentSplit(commisions);
  };

  const handleAddCompanyDollars = () => {
    let commisions = [...companyDollars];
    let valObj = commisions.slice(-1)[0];

    if (valObj && valObj.paymentType === "$") {
      delete valObj.percentageOf;
    }
    if (handleValidateRow(valObj)) {
      commisions.push({
        value: "",
        paymentType: "%",
        acctCode: "teamLeaderComm",
        percentageOf: "",
        payTo: "",
        via: "",
        name: "",
        user_id: "",
      });
      setCompanyDollars(commisions);
    } else {
      setErrorAlert({
        errorMsg: "Pls fill  all fields first!",
        errorType: "warning",
        isOpen: true,
      });
    }
  };
  const handleDeleteCompanyDollarsRow = (index) => {
    let commisions = [...companyDollars];
    commisions.splice(index, 1);
    setCompanyDollars(commisions);
  };
  const addAdditionalPaymentsForContract = () => {
    if (!grossCommisionsAmounts.gross || grossCommisionsAmounts.gross < 0) {
      setErrorAlert({
        errorMsg: "Pls fill the amount first!",
        errorType: "warning",
        isOpen: true,
      });
    } else {
      setLoading(true);
      let data = {
        companyDollarsAmounts,
        agentSplitAmounts,
        grossCommisionsAmounts,
        companySplitValue: assignedAgentSplitType === "%" ? 100 - assignedAgentSplitValue : grossCommisionsAmounts.net - assignedAgentSplitValue,
        assignedAgentSplitValue: assignedAgentSplitValue,
        assignedAgentSplitType: assignedAgentSplitType,
        gci: grossCommisions.map((gc, i) => {
          return { ...gc, amountDeducted: grossCommisionsDeductions[i] };
        }),
        agent_split: agentSplit?.map((agent, i) => {
          return { ...agent, amountDeducted: agentSplitDeductions[i] };
        }),
        company_dollars: companyDollars.map((company, i) => {
          return { ...company, amountDeducted: companyDollarsDeductions[i] };
        }),
      };
      if (id) {
        dispatch(sendAdditionalPaymentForCloseContract({ id, data })).then((result) => {
          setLoading(false);
          if (result.error) {
            setErrorAlert({
              errorMsg: "Something Went Wrong In API Calling!",
              errorType: "error",
              isOpen: true,
            });
          } else {
            closeAdditionalPaymentBreakageModal();
          }
        });
      }
    }
  };

  return (
    <Box>
      <Box>
        <Grid className={classes.mainCommission} container>
          {openPreview ? (
            <Grid className={classes.mainCommissionLeft} item xs={12}>
              <Box className={classes.mainCommissionSection} sx={{ marginBottom: "58px" }}>
                {/* {renderTitle(closingData.gci, "Gross Commission Received")} */}
                {renderContent(grossCommisions, grossCommisionsDeductions)}
              </Box>
              <Box className={classes.mainCommissionSection} sx={{ marginBottom: "78px" }}>
                {renderTitle(grossCommisionsAmounts.net, {
                  insert: (
                    <Fragment>
                      Remaining with{" "}
                      <strong>
                        {assignedAgentSplitValue}
                        {assignedAgentSplitType} (${agentSplitAmounts.gross})
                      </strong>{" "}
                      split to the agent
                    </Fragment>
                  ),
                })}

                {renderContent(agentSplit, agentSplitDeductions)}
              </Box>
              <Box className={classes.mainCommissionSection} sx={{ marginBottom: "70px" }}>
                {renderTitle(companyDollarsAmounts.gross, "Total Company Dollar")}
                {renderContent(companyDollars, companyDollarsDeductions)}
              </Box>
              <Box className={classes.mainCommission}>{renderTitle(companyDollarsAmounts.net, "Net Company Dollar", true)}</Box>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Card className={classes.closingReportArea} variant="outlined">
                <Box className={classes.closingReportAreaHeading}>
                  <Typography>Closing Report</Typography>

                  <Autocomplete
                    disableClearable
                    options={data}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, option) => {
                      setSelectedTemplate(option);
                      setAssignedAgentSplitValue(option.agentPer);
                      setAssignedAgentSplitType("%");
                      let { gci, agent_split, company_dollar } = option.conditions.reduce(
                        (prev, curr) => {
                          prev[curr.type].push({
                            value: curr.value,
                            paymentType: curr.paymentType,
                            acctCode: curr.acctCode,
                            percentageOf: curr.percentageOf,
                            payTo: curr.paidTo,
                            via: vendorsObj[curr.paidTo].payment_preference,
                            name: vendorsObj[curr.paidTo].name,
                            user_id: vendorsObj[curr.paidTo].user_id,
                            tax_id: vendorsObj[curr.paidTo].tax_id,
                          });
                          return prev;
                        },
                        {
                          gci: [],
                          agent_split: [],
                          company_dollar: [],
                        }
                      );
                      setGrossCommisions(gci);
                      setCompanyDollars(company_dollar);
                      setAgentSplit(agent_split);
                    }}
                    sx={{ minWidth: "280px" }}
                    value={selectedTemplate}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Quick Template"
                        aria-describedby="stepper-linear-event_types"
                        size="small"
                        variant="outlined"
                        fullWidth
                        required
                        value={selectedTemplate}
                      />
                    )}
                  ></Autocomplete>
                  {amountTextFieldRequired && (
                    <TextField
                      value={grossCommisionsAmounts.gross}
                      sx={{ ml: "15px" }}
                      placeholder={`Enter ${amountTextFieldLabel} value`}
                      size="small"
                      onChange={(e) => {
                        setGrossCommisionsAmounts({
                          gross: e.target.value,
                          net: e.target.value,
                        });
                      }}
                      onBlur={(e) => {
                        setReloadCalculation(!reloadCalculation);
                      }}
                    />
                  )}
                </Box>

                {/* start gross commission area */}
                <Box className={classes.grossCommissionArea}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={9}>
                      <Stack direction="row" spacing={2} className={classes.grossCommissionTopLeft} alignItems={"center"}>
                        <Typography className={classes.grossCommissionTopLeftHeading}>
                          {grossCommisionsAmounts.gross?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                        <Typography className={classes.grossCommissionTopLeftContent}>Gross Commission Recieved on 12/20/2021 via wire</Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      <Typography className={classes.grossCommissionTopLeftHeading}>
                        {grossCommisionsAmounts.gross?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.grossCommissionInputsArea}>
                  {grossCommisions.map((item, index) => (
                    <Grid container spacing={2} key={index}>
                      <Grid item sm={12} md={9}>
                        <Stack direction="row" spacing={1} alignItems={"center"} sx={{ mb: "10px" }}>
                          <TextField
                            placeholder="value"
                            size="small"
                            sx={{ width: "80px" }}
                            defaultValue={item.value}
                            type="number"
                            onBlur={(e) => {
                              let gcTemp = [...grossCommisions];
                              gcTemp[index].value = e.target.value;
                              setGrossCommisions([...gcTemp]);
                            }}
                          />
                          <TextField
                            defaultValue={"%"}
                            select
                            size="small"
                            value={item.paymentType}
                            onChange={(e) => {
                              let gcTemp = [...grossCommisions];
                              gcTemp[index].paymentType = e.target.value;
                              setGrossCommisions([...gcTemp]);
                            }}
                          >
                            <MenuItem value="$">$</MenuItem>
                            <MenuItem value="%">%</MenuItem>
                          </TextField>
                          {item.paymentType === "%" && (
                            <>
                              <Typography>of</Typography>
                              <TextField
                                select
                                size="small"
                                sx={{ width: "150px" }}
                                value={item.percentageOf}
                                onChange={(e) => {
                                  let gcTemp = [...grossCommisions];
                                  gcTemp[index].percentageOf = e.target.value;
                                  setGrossCommisions([...gcTemp]);
                                }}
                              >
                                <MenuItem value="gci">GCI</MenuItem>
                                <MenuItem value="contractPrice">Contact Price</MenuItem>
                              </TextField>
                            </>
                          )}

                          <Typography>to</Typography>
                          <TextField
                            select
                            size="small"
                            sx={{ width: "150px" }}
                            value={item.payTo}
                            onChange={(e) => {
                              let gciTemp = [...grossCommisions];
                              gciTemp[index].payTo = e.target.value;
                              gciTemp[index].name = e.target.value === 0 ? "Tab Repayment" : vendorsObj[e.target.value].name;
                              gciTemp[index].user_id = e.target.value === 0 ? closingData.agent_id : vendorsObj[e.target.value].user_id;
                              gciTemp[index].via = e.target.value === 0 ? "tab_repayment" : vendorsObj[e.target.value].payment_preference;
                              if (e.target.value === 0) {
                                gciTemp[index].acctCode = "tabRepayment";
                              }
                              setGrossCommisions([...gciTemp]);
                            }}
                          >
                            {vendorList.map((vendor, i) => {
                              return (
                                <MenuItem key={i} value={vendor.id}>
                                  {vendor.name + " - " + vendor.id}
                                </MenuItem>
                              );
                            })}
                            <MenuItem value={0}>Tab Repayment</MenuItem>
                          </TextField>
                          <Typography>Code</Typography>
                          <TextField
                            select
                            size="small"
                            sx={{ width: "150px" }}
                            value={item.acctCode}
                            onChange={(e) => {
                              let gciTemp = [...grossCommisions];
                              gciTemp[index].acctCode = e.target.value;
                              setGrossCommisions([...gciTemp]);
                            }}
                          >
                            {[
                              { value: "referralFee", label: "Referral Fee" },
                              { value: "mlsFee", label: "Mls Fee" },
                              {
                                value: "teamLeaderComm",
                                label: "Team Leader Comm",
                              },
                              { value: "other", label: "Other" },
                              { value: "tabRepayment", label: "Tab Repayment" },
                            ].map((item, index) => (
                              <MenuItem key={index} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          <Button variant="outlined" className={classes.saveButton}>
                            Via {item.via ? item.via : "Check"}
                          </Button>
                          <IconButton onClick={() => handleDeleteGrossDeduction(index)} size="small">
                            <DeleteForeverIcon />
                          </IconButton>
                        </Stack>
                        <Stack></Stack>
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <Typography className={classes.grossCommissionTopLeftHeading}>
                          -(
                          {grossCommisionsDeductions[index]
                            ? grossCommisionsDeductions[index].toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : ""}
                          )
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid container spacing={2}>
                    <Grid item sm={12} md={9}>
                      <Button onClick={() => handleGrossDeduction()} variant="contained" size="small" className={classes.addDeductionButton}>
                        Add Deduction
                      </Button>
                    </Grid>
                    <Grid item sm={12} md={3}></Grid>
                  </Grid>
                </Box>
                {/* end gross commission area */}
                {/* start remaining with area */}
                <Box className={classes.grossCommissionArea}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={9}>
                      <Stack direction="row" spacing={2} className={classes.grossCommissionTopLeft} alignItems={"center"}>
                        <Typography className={classes.grossCommissionTopLeftHeading}>
                          {grossCommisionsAmounts.net?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                        <Typography className={classes.grossCommissionTopLeftContent} sx={{ display: "flex", alignItems: "center" }}>
                          Remaining, with
                          <TextField
                            placeholder="value"
                            size="small"
                            sx={{
                              width: "80px",
                              ml: "15px",
                              mr: "10px",
                              mb: "4px",
                            }}
                            value={assignedAgentSplitValue}
                            type="number"
                            inputProps={{
                              maxLength: 3,
                              step: "1",
                            }}
                            onChange={(e) => {
                              setAssignedAgentSplitValue(e.target.value);
                            }}
                          />
                          <TextField
                            defaultValue={"%"}
                            sx={{ mr: "15px", mb: "4px" }}
                            select
                            size="small"
                            value={assignedAgentSplitType}
                            onChange={(e) => {
                              setAssignedAgentSplitType(e.target.value);
                            }}
                          >
                            <MenuItem value="$">$</MenuItem>
                            <MenuItem value="%">%</MenuItem>
                          </TextField>{" "}
                          <b>
                            {" "}
                            (
                            {agentSplitAmounts.gross?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                            ){" "}
                          </b>{" "}
                          split to the agent:
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      <Typography className={classes.grossCommissionBottomLeftHeadingBorder}>
                        {agentSplitAmounts.gross?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.grossCommissionInputsArea}>
                  {agentSplit?.map((item, index) => (
                    <Grid container spacing={2} key={index}>
                      <Grid item sm={12} md={9}>
                        <Stack direction="row" spacing={1} alignItems={"center"} sx={{ mb: "10px" }}>
                          <TextField
                            placeholder="value"
                            size="small"
                            sx={{ width: "80px" }}
                            defaultValue={item.value}
                            type="number"
                            onBlur={(e) => {
                              let agentTemp = [...agentSplit];
                              agentTemp[index].value = e.target.value;
                              setAgentSplit([...agentTemp]);
                            }}
                          />
                          <TextField
                            defaultValue={"%"}
                            select
                            size="small"
                            value={item.paymentType}
                            onChange={(e) => {
                              let agentTemp = [...agentSplit];
                              agentTemp[index].paymentType = e.target.value;
                              setAgentSplit([...agentTemp]);
                            }}
                          >
                            <MenuItem value="$">$</MenuItem>
                            <MenuItem value="%">%</MenuItem>
                          </TextField>
                          {item.paymentType === "%" && (
                            <>
                              <Typography>of</Typography>
                              <TextField
                                select
                                size="small"
                                sx={{ width: "150px" }}
                                value={item.percentageOf}
                                onChange={(e) => {
                                  let agentTemp = [...agentSplit];
                                  agentTemp[index].percentageOf = e.target.value;
                                  setAgentSplit([...agentTemp]);
                                }}
                              >
                                <MenuItem value="gci">GCI</MenuItem>
                                <MenuItem value="contractPrice">Contact Price</MenuItem>
                              </TextField>
                            </>
                          )}

                          <Typography>to</Typography>
                          <TextField
                            select
                            size="small"
                            sx={{ width: "150px" }}
                            value={item.payTo}
                            onChange={(e) => {
                              let agentTemp = [...agentSplit];
                              agentTemp[index].payTo = e.target.value;
                              agentTemp[index].name = e.target.value === 0 ? "Tab Repayment" : vendorsObj[e.target.value].name;
                              agentTemp[index].user_id = e.target.value === 0 ? closingData.agent_id : vendorsObj[e.target.value].user_id;
                              agentTemp[index].via = e.target.value === 0 ? "tab_repayment" : vendorsObj[e.target.value].payment_preference;

                              if (e.target.value === 0) {
                                agentTemp[index].acctCode = "tabRepayment";
                              }
                              setAgentSplit([...agentTemp]);
                            }}
                          >
                            {vendorList.map((vendor, i) => {
                              return (
                                <MenuItem key={i} value={vendor.id}>
                                  {vendor.name + " - " + vendor.id}
                                </MenuItem>
                              );
                            })}
                            <MenuItem value={0}>Tab Repayment</MenuItem>
                          </TextField>
                          <Typography>Code</Typography>
                          <TextField
                            select
                            size="small"
                            sx={{ width: "150px" }}
                            value={item.acctCode}
                            onChange={(e) => {
                              let agentTemp = [...agentSplit];
                              agentTemp[index].acctCode = e.target.value;
                              setAgentSplit([...agentTemp]);
                            }}
                          >
                            {[
                              { value: "referralFee", label: "Referral Fee" },
                              { value: "mlsFee", label: "Mls Fee" },
                              {
                                value: "teamLeaderComm",
                                label: "Team Leader Comm",
                              },
                              { value: "other", label: "Other" },
                              { value: "tabRepayment", label: "Tab Repayment" },
                            ].map((item, index) => (
                              <MenuItem key={index} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          <Button variant="outlined" className={classes.saveButton}>
                            Via {item.via ? item.via : "Check"}
                          </Button>
                          <IconButton onClick={() => handleDeleteAgentSplit(index)} size="small">
                            <DeleteForeverIcon />
                          </IconButton>
                        </Stack>
                        <Stack></Stack>
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <Typography className={classes.grossCommissionTopLeftHeading}>
                          -(
                          {agentSplitDeductions[index]
                            ? agentSplitDeductions[index].toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : ""}
                          )
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid container spacing={2}>
                    <Grid item sm={12} md={9}>
                      <Stack direction={"row"} spacing={2}>
                        <Button onClick={() => handleAddAgentSplit()} variant="contained" size="small" className={classes.addDeductionButton}>
                          Add Deduction
                        </Button>
                        {/* <Button
                            onClick={() => {
                              // let commisions = [...remainingWith]
                              // commisions.push({ id: 2 });
                              // setRemainingWith(commisions);
                            }}
                            variant="contained"
                            size="small"
                            className={classes.addDeductionButton}
                            sx={{ backgroundColor: "#B70E0E !important" }}
                          >
                            Agent Outstanding Tab: ${closingData?.agent_data?.balance}
                          </Button> */}
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      <Typography className={classes.grossCommissionTopLeftHeadingBorder}>
                        {agentSplitAmounts.net?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {/* end  remaining with area */}
                {/* start total company dollar */}
                <Box className={classes.grossCommissionArea}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={9}>
                      <Stack direction="row" spacing={2} className={classes.grossCommissionTopLeft} alignItems={"center"}>
                        <Typography className={classes.grossCommissionTopLeftHeading}>
                          {companyDollarsAmounts.gross?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                        <Typography className={classes.grossCommissionTopLeftContent}>Total Company Dollar</Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      <Typography className={classes.grossCommissionTopLeftHeading}></Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.grossCommissionInputsArea}>
                  {companyDollars.map((item, index) => (
                    <Grid container spacing={2} key={index}>
                      <Grid item sm={12} md={9}>
                        <Stack direction="row" spacing={1} alignItems={"center"} sx={{ mb: "10px" }}>
                          <TextField
                            placeholder="value"
                            size="small"
                            sx={{ width: "80px" }}
                            defaultValue={item.value}
                            type="number"
                            onBlur={(e) => {
                              let companyTemp = [...companyDollars];
                              companyTemp[index].value = e.target.value;
                              setCompanyDollars([...companyTemp]);
                            }}
                          />
                          <TextField
                            defaultValue={"%"}
                            select
                            size="small"
                            value={item.paymentType}
                            onChange={(e) => {
                              let companyTemp = [...companyDollars];
                              companyTemp[index].paymentType = e.target.value;
                              setCompanyDollars([...companyTemp]);
                            }}
                          >
                            <MenuItem value="$">$</MenuItem>
                            <MenuItem value="%">%</MenuItem>
                          </TextField>
                          {item.paymentType === "%" && (
                            <>
                              <Typography>of</Typography>
                              <TextField
                                select
                                size="small"
                                sx={{ width: "150px" }}
                                value={item.percentageOf}
                                onChange={(e) => {
                                  let companyTemp = [...companyDollars];
                                  companyTemp[index].percentageOf = e.target.value;
                                  setCompanyDollars([...companyTemp]);
                                }}
                              >
                                <MenuItem value="gci">GCI</MenuItem>
                                <MenuItem value="contractPrice">Contact Price</MenuItem>
                              </TextField>
                            </>
                          )}

                          <Typography>to</Typography>
                          <TextField
                            select
                            size="small"
                            sx={{ width: "150px" }}
                            value={item.payTo}
                            onChange={(e) => {
                              let companyTemp = [...companyDollars];
                              companyTemp[index].payTo = e.target.value;
                              companyTemp[index].name = e.target.value === 0 ? "Tab Repayment" : vendorsObj[e.target.value].name;
                              companyTemp[index].user_id = e.target.value === 0 ? closingData.agent_id : vendorsObj[e.target.value].user_id;
                              companyTemp[index].via = e.target.value === 0 ? "tab_repayment" : vendorsObj[e.target.value].payment_preference;
                              if (e.target.value === 0) {
                                companyTemp[index].acctCode = "tabRepayment";
                              }
                              setCompanyDollars([...companyTemp]);
                            }}
                          >
                            {vendorList.map((vendor, i) => {
                              return (
                                <MenuItem key={i} value={vendor.id}>
                                  {vendor.name + " - " + vendor.id}
                                </MenuItem>
                              );
                            })}
                            <MenuItem value={0}>Tab Repayment</MenuItem>
                          </TextField>
                          <Typography>Code</Typography>
                          <TextField
                            select
                            size="small"
                            sx={{ width: "150px" }}
                            value={item.acctCode}
                            onChange={(e) => {
                              let companyTemp = [...companyDollars];
                              companyTemp[index].acctCode = e.target.value;
                              setCompanyDollars([...companyTemp]);
                            }}
                          >
                            {[
                              { value: "referralFee", label: "Referral Fee" },
                              { value: "mlsFee", label: "Mls Fee" },
                              {
                                value: "teamLeaderComm",
                                label: "Team Leader Comm",
                              },
                              { value: "other", label: "Other" },
                              { value: "tabRepayment", label: "Tab Repayment" },
                            ].map((item, index) => (
                              <MenuItem key={index} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          <Button variant="outlined" className={classes.saveButton}>
                            Via {item.via ? item.via : "Check"}
                          </Button>
                          <IconButton onClick={() => handleDeleteCompanyDollarsRow(index)} size="small">
                            <DeleteForeverIcon />
                          </IconButton>
                        </Stack>
                        <Stack></Stack>
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <Typography className={classes.grossCommissionTopLeftHeading}>
                          -(
                          {companyDollarsDeductions[index]
                            ? companyDollarsDeductions[index].toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : ""}
                          )
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid container spacing={2}>
                    <Grid item sm={12} md={9}>
                      <Button onClick={() => handleAddCompanyDollars()} variant="contained" size="small" className={classes.addDeductionButton}>
                        Add Deduction
                      </Button>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      <Typography className={classes.grossCommissionTopLeftHeadingBorder}>
                        {companyDollarsAmounts.net?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {/* end total company dollar */}
                <Grid container spacing={2} sx={{ mt: "30px", mb: "20px" }}>
                  <Grid item sm={12} md={5}>
                    <Stack direction="row" spacing={2} className={classes.grossCommissionTopLeft} alignItems={"center"}>
                      <Typography className={classes.grossCommissionTopLeftHeading}>
                        {companyDollarsAmounts.net?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </Typography>
                      <Typography className={classes.grossCommissionTopLeftContent}>Net Company Dollar</Typography>
                    </Stack>
                  </Grid>
                  <Grid item sm={12} md={7}>
                    <Stack direction={"row"} spacing={4}>
                      {confirmSave ? (
                        <>
                          <Button
                            onClick={() => {
                              addAdditionalPaymentsForContract();
                            }}
                            variant="outlined"
                            className={classes.confirmButton}
                            sx={{ fontSize: "22px", minWidth: "120px" }}
                            disabled={loading}
                          >
                            Confirm
                          </Button>
                          <Button
                            onClick={() => {
                              setConfirmSave(false);
                            }}
                            variant="outlined"
                            className={classes.cancelButton}
                            sx={{ fontSize: "22px", minWidth: "120px" }}
                            disabled={loading}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            setConfirmSave(true);
                          }}
                          variant="outlined"
                          className={classes.saveButton}
                          disabled={loading}
                          sx={{ fontSize: "22px", minWidth: "120px" }}
                        >
                          Save
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
        </Grid>
      </Box>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Box>
  );
}

export default withStyles(styleSheet, { name: "AdditionalPaymentBreakdown" })(DeductionRules);
