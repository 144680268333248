import React, { useState, useRef, useEffect } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Box, IconButton, Card, CardHeader, Typography } from "@mui/material";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { useSelector } from "react-redux";
import Avatar from "../../uiElements/avatar";
import moment from "moment";
import Linkify from "react-linkify";
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "./mentionsStyle/defaultStyle.js";
import defaultMentionStyle from "./mentionsStyle/defaultMentionStyle";
import reactStringReplace from "react-string-replace";
import { Link } from "react-router-dom";
import { CustomTooltip } from "../../shared/tooltip";
import logo2Icon from "../../../assets/images/header/logo.png";
function CommentSection(props) {
  let { classes, height, comments, id, handleSubmitComment, innerHeight } =
    props;
  const [buttonTop, setButtonTop] = useState(23);
  const [userIds, setUserIds] = useState([]);
  const [messageValue, setMessageValue] = useState("");
  const scrollTo = useRef();
  const adminAuth = useSelector((state) => state.adminAuth);
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const handleSendComment = () => {
    handleSubmitComment({
      value: messageValue,
      userIds: userIds.filter((item, index) => userIds.indexOf(item) === index),
    });
    setMessageValue("");
    setUserIds([]);
    setButtonTop(23);

    setTimeout(() => {
      scrollTo.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
    }, 1000);
  };
  useEffect(() => {
    let element = document.getElementById(id || "message-field-projects");
    if (element.clientHeight) {
      if (element.clientHeight !== buttonTop) {
        setButtonTop(element.clientHeight - 5);
      }
    }
  }, [messageValue]);
  return (
    <Box
      className={classes.commentSectionBox}
      sx={{ height: height, paddingTop: "5px" }}
    >
      <Box className={classes.commentSectionTop}>Comments</Box>
      <Box
        className={classes.messageSection}
        sx={{
          height: `calc(${innerHeight ? innerHeight : "813px"} - ${
            0 + buttonTop
          }px)`,
          overflow: "auto",
        }}
      >
        {comments?.length
          ? comments.map((item, index) => (
              <Card
                key={index}
                sx={{ boxShadow: "none", backgroundColor: "transparent" }}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      src={
                        item?.is_additional_notes
                          ? logo2Icon
                          : item.user?.profile_images?.profile_img ||
                            item.user?.profile_images?.profile_img_thumbnail
                      }
                      sx={{
                        width: "25px",
                        height: "25px",
                        fontSize: "12px",
                        mr: "-7px",
                      }}
                      aria-label="recipe"
                    >
                      {`${item.user?.first_name?.slice(
                        0,
                        1
                      )}${item.user?.last_name?.slice(0, 1)}`}
                    </Avatar>
                  }
                  classes={{
                    title: classes.messageSectionProfileName,
                  }}
                  sx={{ paddingLeft: "0px" }}
                  action={
                    <Typography className={classes.commentTime}>
                      {" "}
                      {moment(item.createdAt || "").format("LT")}{" "}
                      {moment(item.createdAt || "").format("MM/DD/YY")}{" "}
                    </Typography>
                  }
                  title={
                    item?.is_additional_notes ? (
                      <Box>Additional Notes</Box>
                    ) : (
                      <Box>
                        {item.user?.first_name ||
                          item.user?.full_name?.split(" ")[0]}{" "}
                        <Box
                          sx={{ fontWeight: "400", display: "inline-block" }}
                        >
                          commented
                        </Box>
                      </Box>
                    )
                  }
                />
                <Card variant="outlined" className={classes.commentContent}>
                  <Typography
                    className={classes.commentContentText}
                    variant="body2"
                    color="inherit"
                  >
                    <Linkify
                      properties={{ target: "_blank" }}
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a target="blank" href={decoratedHref} key={key}>
                          {decoratedText}
                        </a>
                      )}
                    >
                      {reactStringReplace(
                        item.comment || item.Comment,
                        /@\[(.*?)]/g,
                        (match, i) => (
                          <CustomTooltip
                            title={match?.split("^")?.[0]}
                            placement="top"
                          >
                            <Box
                              sx={{
                                color:
                                  match?.split("^")?.[1] ==
                                  adminAuth?.adminDetail.id
                                    ? "#007EA6 !important"
                                    : "#434343 !important",
                                background:
                                  match?.split("^")?.[1] ==
                                  adminAuth?.adminDetail.id
                                    ? "#CCF3FF !important"
                                    : "#ECECEC !important",
                                display: "inline-block",
                                textDecoration: "none",
                                fontWeight: "500",
                              }}
                              key={i}
                              to={`/agent_roster/${
                                match?.split("^")?.[1] || 69
                              }/overview`}
                              component={Link}
                            >
                              @{match?.split(" ")?.[0]}
                            </Box>
                          </CustomTooltip>
                        )
                      )}
                    </Linkify>
                  </Typography>
                </Card>
              </Card>
            ))
          : null}
        <div ref={scrollTo} />
      </Box>
      <Box className={classes.writeCommentSection}>
        <Box className={classes.commentFieldBox}>
          <Box className={classes.commentFieldBoxLeft}>
            <Avatar
              sx={{
                width: "25px",
                height: "25px",
                fontSize: "12px",
                mr: "7px",
                ml: "-5px",
              }}
              alt="Remy Sharp"
              src={adminAuth?.adminDetail?.profile_img}
            />

            <Box
              sx={{
                width: "92%",
                "& textarea": { outline: "none" },
                ml: "5px",
              }}
              id={id || "message-field-projects"}
            >
              <MentionsInput
                value={messageValue}
                onChange={(event, newValue, newPlainTextValue, mentions) => {
                  setUserIds(mentions?.map((item) => item.id));
                  setMessageValue(event.target.value);
                }}
                allowSuggestionsAboveCursor={true}
                forceSuggestionsAboveCursor={true}
                allowSpaceInQuery
                style={defaultStyle}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !event.shiftKey) {
                    handleSendComment();
                    setMessageValue("");
                  }
                }}
              >
                <Mention
                  trigger="@"
                  data={
                    agentRosterList?.list?.length
                      ? agentRosterList?.list
                          ?.filter((it) => it.id !== adminAuth?.adminDetail?.id)
                          ?.filter((it) => it?.role?.name === "Admin")
                          ?.map((item) => ({
                            id: item.id,
                            display: item?.first_name + " " + item.last_name,
                          }))
                      : []
                  }
                  markup={"@[__display__^__id__]"}
                  style={defaultMentionStyle}
                  // renderSuggestion={this.renderUserSuggestion}
                />
              </MentionsInput>
            </Box>
          </Box>
          <Box className={classes.commentFieldActions}>
            <IconButton
              onClick={handleSendComment}
              aria-label="toggle password visibility"
              edge="end"
              sx={{ padding: "4px 3px 4px 5px", mr: "0px" }}
            >
              <SendRoundedIcon
                fontSize="small"
                sx={{ color: "rgba(0, 0, 0, 0.25)", fontSize: "18px" }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, {
  name: "CommentSectionTaskStyle",
})(CommentSection);
