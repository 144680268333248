import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import ManageUser from "./manageUser";
import ManageDepartment from "./manageDepartment";

function ManageArea(props) {
  const { classes, selectedUser, manageType, setManageType } = props;

  return (
    <Box className={classes.manageMemberSection}>
      {selectedUser?.id ? (
        manageType === "user" ? (
          <ManageUser setManageType={setManageType} {...props} />
        ) : (
          <ManageDepartment {...props} />
        )
      ) : (
        <Box className={classes.notSelectedSection}>
          <Typography className={classes.notSelectedContent}>
            Select a staff member to manage
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default ManageArea;
