import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Stack,
  Skeleton,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import {
  handleResetSearchValue,
  updateSearchQuery,
} from "../../../redux/leads/savedSearches";
import { getPropertiesWithoutSearchId } from "../../../redux/leads/savedSearches/getPropertiesWithoutId.js";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveSearch from "./saveSearch.jsx";
import { formatAddress, getAcres } from "../../../utils/propertyData.js";
import { NumberFormat } from "../../../utils/numberFormat.js";
import { updateSearch } from "../../../redux/leads/savedSearches/updateSaveSearch.js";
function UpdateCollection(props) {
  let { classes, handleUpdateView, setErrorAlert, actionType, width } = props;
  const collectionTitle = React.useRef(null);
  const [isAND, setIsAND] = useState(false);
  const [selectedListing, setSelectedListing] = useState([]);
  const dispatch = useDispatch();
  const searchParams = useSelector(
    (item) => item.leads.savedSearches.searchParams
  );
  const updateQuery = useSelector(
    (item) => item.leads.savedSearches.updateQuery
  );
  const propertiesList = useSelector(
    (item) => item.leads.savedSearches.propertiesList
  );

  const saveSearch = useSelector((item) => item.leads.savedSearches.saveSearch);

  const searchDetail = useSelector(
    (item) => item.leads.savedSearches.searchDetail
  );
  const searchList = useSelector((item) => item.leads.savedSearches.searchList);
  const handleGetProperties = (start, reset) => {
    dispatch(getPropertiesWithoutSearchId({ start: start || 0, reset, isAND }));
  };

  useEffect(() => {
    handleGetProperties(0, true);
  }, [searchParams]);

  useEffect(() => {
    if (actionType === "update" && searchDetail?.searchId) {
      collectionTitle.current.value = searchDetail.title;
    }
  }, [actionType, searchDetail?.searchId]);

  useEffect(() => {
    if (
      searchDetail?.advanceSearchId?.query?.q1?.ListingId &&
      !selectedListing?.length
    ) {
      setSelectedListing(
        searchDetail?.advanceSearchId?.query?.q1?.ListingId?.replace(")", "")
          ?.replace("(", "")
          ?.split(" OR ")
      );
    }
  }, [searchDetail?.advanceSearchId?.query?.q1?.ListingId]);

  const handleSuccess = () => {
    handleUpdateView("search-list", "");
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(
      updateSearch({
        title: collectionTitle?.current?.value,
        searchId: searchDetail?.searchId,
        handleSuccess,
        handleError,
      })
    );
    setSelectedListing([]);
    setErrorAlert({
      errorMsg: "Your have successfully updated the search!",
      errorType: "success",
      isOpen: true,
    });
    dispatch(handleResetSearchValue());
  };
  const handleSubmit = () => {
    if (collectionTitle?.current?.value) {
      dispatch(
        updateSearchQuery({
          searchId: searchDetail?.searchId,
          handleError,
          handleSuccess: handleSuccessUpdate,
          title: collectionTitle?.current?.value,
          isAND: isAND,
          agentId: searchList?.data?.assignedAgent?._id,
          fq: searchDetail?.advanceSearchId?.query?.fq,
          newQuery: `ListingId:(${selectedListing?.join(" OR ")}))`,
        })
      );
    } else {
      setErrorAlert({
        errorMsg: "Please enter the collection Title",
        errorType: "warning",
        isOpen: true,
      });
    }
  };
  const handleScrollEvent = (e) => {
    var bottom =
      Math.floor(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) === 0;
    if (
      bottom &&
      !propertiesList.isLoading &&
      propertiesList.data?.data?.length < propertiesList.data?.meta?.numFound
    ) {
      handleGetProperties(propertiesList.data?.data?.length, false);
    }
  };

  const handleUpdateListing = (ListingId) => {
    let newListings = [...selectedListing];
    if (newListings.includes(ListingId)) {
      setSelectedListing(newListings?.filter((id) => id !== ListingId));
    } else {
      setSelectedListing([...newListings, ListingId]);
    }
  };

  return (
    <Box sx={{ pt: "10px" }}>
      <Typography sx={{ fontSize: "18px", fontWeight: "500", mb: "6px" }}>
        Update Collection
      </Typography>

      <TextField
        placeholder="Enter Collection Name"
        variant="standard"
        fullWidth
        size="small"
        inputRef={collectionTitle}
        onChange={(e) => {
          if (e.target.value) {
            collectionTitle.current.value = e.target.value;
          }
        }}
      />
      <Accordion sx={{ mt: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography sx={{ fontWeight: "500" }}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {" "}
          <SaveSearch
            handleUpdateView={handleUpdateView}
            actionType={actionType}
            width={width}
            setErrorAlert={setErrorAlert}
            allowFiltersOnly={true}
            setOperator={setIsAND}
            {...props}
          />
        </AccordionDetails>
      </Accordion>
      <Typography sx={{ fontSize: "14px", mt: "20px" }}>
        Please select from the properties below to add to the search.
      </Typography>
      <Box
        className={classes.propertiesListingArea}
        onScroll={handleScrollEvent}
      >
        {propertiesList?.data?.data?.length
          ? propertiesList?.data?.data?.map((item, index) => (
              <Card
                variant="outlined"
                className={classes.propertyItem}
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "100px",
                  background: "white",
                  transition: "0.3s",
                  "&:hover": {
                    backgroundColor: "#f5f5f5", // Change background color on hover
                    border: "1px solid gray", // Add border on hover
                  },
                }}
                onClick={() => handleUpdateListing(item?.ListingId)}
              >
                {selectedListing?.includes(item?.ListingId) ? (
                  <CheckBoxRoundedIcon
                    style={{
                      position: "absolute",
                      left: "6px",
                      top: "6px",
                      color: "white",
                      fontSize: "22px",
                    }}
                  />
                ) : null}

                <img
                  src={item?.MediaURL?.[0] || item?.Media?.[0]?.MediaURL}
                  alt={"property"}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />
                <Box className={classes.propertyItemDes}>
                  <Typography className={classes.propertyItemDesHeading}>
                    {formatAddress(item)}
                  </Typography>
                  <Typography className={classes.propertyItemDesContent}>
                    {item.City}, {item.StateOrProvince} {item.PostalCode}
                  </Typography>
                  <Typography className={classes.propertyResultsItemPrice}>
                    {item?.ListPrice
                      ? NumberFormat({
                          number: item?.ListPrice,
                          currency: "USD",
                        })
                      : null}
                  </Typography>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"space-around"}
                  >
                    <Box>
                      <Typography
                        className={classes.propertyResultsItemStatsValue}
                      >
                        {item.BedroomsTotal}
                      </Typography>
                      <Typography
                        className={classes.propertyResultsItemSubTitle}
                      >
                        Beds{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        className={classes.propertyResultsItemStatsValue}
                      >
                        {item.BathroomsFull || item?.BathroomsTotalInteger}
                      </Typography>
                      <Typography
                        className={classes.propertyResultsItemSubTitle}
                      >
                        Baths{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        className={classes.propertyResultsItemStatsValue}
                      >
                        {(item?.BuildingAreaTotal &&
                          item?.BuildingAreaTotal > 0) ||
                        (item?.AboveGradeFinishedArea &&
                          item?.AboveGradeFinishedArea > 0)
                          ? NumberFormat({
                              number:
                                item?.BuildingAreaTotal ||
                                item?.AboveGradeFinishedArea,
                            })
                          : getAcres(item)}
                      </Typography>
                      <Typography
                        className={classes.propertyResultsItemSubTitle}
                      >
                        {(item?.BuildingAreaTotal &&
                          item?.BuildingAreaTotal > 0) ||
                        (item?.AboveGradeFinishedArea &&
                          item?.AboveGradeFinishedArea > 0)
                          ? "Sq.Ft."
                          : "Acres"}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Card>
            ))
          : null}
        {propertiesList?.isLoading
          ? Array.from(Array(10).keys())?.map((_, i) => (
              <Card
                variant="outlined"
                className={classes.propertyItem}
                sx={{
                  width: "100%",
                  height: "100px",
                }}
              >
                {" "}
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  style={{ height: "100px" }}
                />
              </Card>
            ))
          : null}
      </Box>
      <Box className={classes.saveButtonArea}>
        <Button
          onClick={() => {
            handleUpdateView("search-list", "");
            dispatch(handleResetSearchValue());
            setSelectedListing([]);
          }}
          color="inherit"
          variant="outlined"
          sx={{ fontSize: "13px" }}
        >
          Cancel
        </Button>

        <LoadingButton
          className={classes.searchSaveButton}
          onClick={() => handleSubmit()}
          loading={Boolean(updateQuery.isLoading || saveSearch?.isLoading)}
          loadingPosition="start"
          fullWidth
        >
          Update Collection
        </LoadingButton>
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "UpdateCollectionStyle" })(
  UpdateCollection
);
