import React from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  IconButton,
  Divider,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { useSelector, useDispatch } from "react-redux";
import { handleUpdateSearchDetail } from "../../../redux/leads/savedSearches";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import { formateKeys } from "../../../utils/propertyData.js";
import { NumberFormat } from "../../../utils/numberFormat";
import { CopyToClipboard } from "react-copy-to-clipboard";

const colors = {
  daily: "#3CB465",
  instant: "#FD9536",
  disabled: "#C0B9A8",
  never: "#C0B9A8",
  weekly: "#FD9536",
  monthly: "#FDC536",
};

function SavedSearchList(props) {
  let { classes, handleUpdateView, setErrorAlert, width, disableIframe } =
    props;
  const dispatch = useDispatch();
  const searchList = useSelector((item) => item.leads.savedSearches.searchList);

  const handleCopy = (data) => {
    let Url = `${searchList?.data?.site_id?.site_url}/member/quick_login/${searchList?.data?.token}?next=property-search/results?searchId=${data?.searchId}`;
    navigator.clipboard.writeText(Url);
    setErrorAlert({
      errorMsg: "copied Successfully!",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleParseQuery = (data) => {
    let newSearchParam = {};
    let statsArray = [];
    let filterString = data.advanceSearchId?.query?.q?.replaceAll("q=", "");
    let newArray = filterString?.split(" AND ");
    if (newArray?.length) {
      newArray?.map((item) => {
        if (item.includes("(") && item.includes(")")) {
          let newItem = item
            ?.replaceAll("(", "")
            ?.replaceAll(")", "")
            ?.split(" OR ");
          let filteredArray = newItem?.map(
            (it) => it?.split(":")[1]?.trim() || it?.split(":")[0]?.trim()
          );
          newSearchParam[
            newItem[0]?.split(":")[0]?.trim()
          ] = `(${filteredArray?.join(" OR ")})`;
        } else {
          newSearchParam[item?.split(":")[0]?.trim()] = item
            ?.split(":")[1]
            ?.trim();
        }
      });
    }
    Object.keys(newSearchParam).forEach((k) => {
      if (
        newSearchParam[k]?.includes("TO") ||
        newSearchParam[k]?.includes("[")
      ) {
        let title = formateKeys[k] ? formateKeys[k] : k;
        let newValue = newSearchParam[k]
          ?.replaceAll("[", "")
          ?.replaceAll("]", "")
          ?.split(" TO ");
        newValue.map((item, index) => {
          if (item !== "*") {
            statsArray.push({
              title: `${index == 0 ? "Min" : "Max"} ${title}`,
              value:
                k === "ListPrice"
                  ? NumberFormat({
                      number: item,
                      maximumFractionDigits: 1,
                      notation: "compact",
                    })
                  : item,
            });
          }
        });
      } else if (
        newSearchParam[k]?.includes("(") ||
        newSearchParam[k]?.includes(")")
      ) {
        let newValue = newSearchParam[k]
          ?.replaceAll("(", "")
          ?.replaceAll(")", "")
          ?.split(" OR ");
        statsArray.push({
          title: k,
          value:
            newValue?.length > 2
              ? `${newValue?.slice(0, 2)?.join(", ")}, ${
                  newValue?.length - 2
                } More`
              : `${newValue?.join(", ")}`,
        });
      } else {
        statsArray.push({
          title: formateKeys[k] ? formateKeys[k] : k,
          value: newSearchParam[k],
        });
      }
    });
    return statsArray;
  };
  return (
    <Box className={classes.searchListArea}>
      {
        searchList?.data?.savedSearches?.length && searchList?.isUserFound
          ? searchList?.data?.savedSearches.map((item, index) => (
              <Box
                key={index}
                className={classes.searchListItemArea}
                sx={{
                  backgroundColor: colors[item?.emailNotification]
                    ? `${colors[item?.emailNotification]} !important`
                    : "#C0B9A8 !important",
                }}
              >
                <Card
                  sx={{
                    width: disableIframe
                      ? "calc(100% - 20px) !important"
                      : "calc(100% - 42px) !important",
                  }}
                  className={classes.searchListItem}
                >
                  <Box className={classes.searchListItemHeader}>
                    <Typography
                      component={"a"}
                      target="_black"
                      href={
                        searchList?.data?.site_id?.site_url
                          ? `${searchList?.data?.site_id?.site_url}/property-search/results?searchId=${item?.searchId}`
                          : item.shortUrl
                      }
                      className={classes.searchListItemHeading}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      className={
                        width >= 390
                          ? classes.copyLinkAreaLg
                          : classes.copyLinkAreaLgHide
                      }
                    >
                      <CopyToClipboard
                        onCopy={() => {
                          setErrorAlert({
                            errorMsg: "copied Successfully!",
                            errorType: "success",
                            isOpen: true,
                          });
                        }}
                        text={`${item.shortUrl}`}
                      >
                        <Button
                          size="small"
                          variant="text"
                          className={`${classes.copyLinkArea}  copyItemLink`}
                          // onClick={() => handleCopy(item)}
                        >
                          <CopyAllRoundedIcon
                            sx={{ fontSize: "16px", mt: "2px" }}
                          />
                          <Typography className={classes.copyLinkAreaContent}>
                            Copy Link
                          </Typography>
                        </Button>
                      </CopyToClipboard>
                    </Box>
                  </Box>
                  <Box className={classes.searchSubHeadingArea}>
                    {handleParseQuery(item)?.map((it, index) => (
                      <Typography
                        key={index}
                        className={classes.searchSubHeading}
                      >
                        {it.title}: {it.value?.replaceAll(`"`, "")}
                      </Typography>
                    ))}
                    {item?.advanceSearchId?.query?.fq ? (
                      <Typography
                        key={index}
                        className={classes.searchSubHeading}
                      >
                        Polygon also exists
                      </Typography>
                    ) : null}
                  </Box>
                  <Box className={classes.StatsAreaOne}>
                    <Typography className={classes.searchContent}>
                      {item?.newPropertyCount || "0"} New{" "}
                      {width >= 390 ? "Listings" : ""}
                    </Typography>
                    <Typography className={classes.searchContent}>
                      {item?.updatedPropertyCount || "0"} Updated{" "}
                      {width >= 390 ? "Listings" : ""}
                    </Typography>
                    <Typography className={classes.searchContent}>
                      {item?.totalPropertyCount || "0"} Total{" "}
                      {width >= 390 ? "Listings" : ""}
                    </Typography>
                  </Box>
                  <Box
                    className={
                      width < 390
                        ? classes.copyLinkAreaMb
                        : classes.copyLinkAreaMbHide
                    }
                  >
                    <CopyToClipboard
                      onCopy={() => {
                        setErrorAlert({
                          errorMsg: "copied Successfully!",
                          errorType: "success",
                          isOpen: true,
                        });
                      }}
                      text={`${item.shortUrl}`}
                    >
                      <Button
                        size="small"
                        variant="text"
                        className={`${classes.copyLinkAreaBottom}  copyItemLinkBottom`}
                        // onClick={() => handleCopy(item)}
                        sx={{ mt: "5px" }}
                      >
                        <CopyAllRoundedIcon
                          sx={{ fontSize: "16px", mt: "2px" }}
                        />
                        <Typography className={classes.copyLinkAreaContent}>
                          Copy Link
                        </Typography>
                      </Button>
                    </CopyToClipboard>
                  </Box>
                  <IconButton
                    onClick={() => {
                      if (item?.type === "collection") {
                        handleUpdateView("update-collection", "update");
                      } else {
                        handleUpdateView("add-search", "update");
                      }
                      dispatch(
                        handleUpdateSearchDetail({ searchDetail: item })
                      );
                    }}
                    size="small"
                    className={`${classes.editIconArea} editIconAreaHover`}
                  >
                    <EditRoundedIcon
                      sx={{ color: "#99999", fontSize: "20px" }}
                    />
                  </IconButton>
                </Card>
                <Typography className={classes.searchListItemContent}>
                  {item?.emailNotification}
                </Typography>
              </Box>
            ))
          : null /* (
        <DataNotFound
          color="#BDBDBD"
          fontSize="16px"
          minHeight="300px"
          icon={
            <ContentPasteSearchRoundedIcon
              sx={{ fontSize: "90px", color: "#BDBDBD" }}
              fontSize="large"
            />
          }
          title="No Saved Search Found"
        />
      ) */
      }
      {searchList?.isUserFound ? (
        <Box>
          <Box className={classes.addNewSearchButton}>
            <Button
              sx={{ paddingLeft: "15px", paddingRight: "15px" }}
              size="small"
              className={classes.searchListItemButton}
              onClick={() => handleUpdateView("add-search", "add")}
            >
              Add New Search
            </Button>
          </Box>
          {/* <br />
          <Divider>Or</Divider>

          <Box className={classes.addNewSearchButton}>
            <Button
              sx={{ paddingLeft: "15px", paddingRight: "15px" }}
              size="small"
              className={classes.searchListItemButton}
              onClick={() => handleUpdateView("add-collection", "add")}
            >
              Create Collection
            </Button>
          </Box> */}
        </Box>
      ) : (
        <Box className={classes.addNewSearchButton}>
          <Button
            sx={{ paddingLeft: "15px", paddingRight: "15px" }}
            size="small"
            className={classes.searchListItemButton}
            onClick={() => handleUpdateView("create-account", "")}
          >
            Create Account
          </Button>
        </Box>
      )}
    </Box>
  );
}
export default withStyles(styleSheet, { name: "SavedSearchListStyle" })(
  SavedSearchList
);
