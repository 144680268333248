import React from "react";
import { Box, Skeleton } from "@mui/material";
function CategoriesPostSkeleton() {
  return (
    <Box sx={{ paddingBottom: "30px" }}>
      {Array.from(Array(7).keys())?.map((item, index) => (
        <Box sx={{ paddingBottom: "30px" }}>
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: "10px",
              width: "350px",
              height: "40px",
              mb: "30px",
              mt: "15px",
            }}
          />
          <Box
            sx={{
              width: "100%",
              overflow: "hidden",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {Array.from(Array(7).keys())?.map((item, index) => (
              <Box sx={{ minWidth: "300px" }}>
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "180px", width: "100%", borderRadius: "10px" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem", mt: "10px" }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default CategoriesPostSkeleton;
