import React, { useState } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Typography,
  TextareaAutosize,
} from "@mui/material";
export default function TaskConfirmation(props) {
  const { setOpen, open, handleConfirm, title, description, comment } = props;
  const [commentDes, setCommentDes] = useState("");
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      disablePortal
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogContent sx={{ padding: comment ? "25px" : "50px" }}>
        <Typography
          sx={{
            color: "black",
            fontSize: "20px",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ margin: "15px 0px", fontSize: "15px", textAlign: "center" }}
        >
          {description}
        </Typography>
        {comment ? (
          <TextareaAutosize
            value={commentDes}
            aria-label="minimum height"
            minRows={6}
            maxRows={6}
            placeholder="Start typing..."
            style={{ width: "100%", padding: "5px" }}
            onChange={(e) => setCommentDes(e.target.value)}
          />
        ) : null}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
            marginTop: "30px",
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{ color: "black", borderColor: "black !important" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (comment) {
                handleConfirm(commentDes);
              } else {
                handleConfirm();
              }
            }}
            variant="contained"
            color="info"
            sx={{ background: "black", color: "white" }}
          >
            Confirm
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
