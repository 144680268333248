import React from "react";
import {
  Grid,
  Typography,
  Container,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCmaData,
  resetSingleCMA,
  setCMAPdfUpdated,
} from "../../../redux/tools/cmas";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import Avatar from "../../../components/uiElements/avatar/index";
import { numberWithCommas } from "./helper.js";
import NoImage from "../../../assets/images/noimage.png";
import { debounce } from "lodash";
import { Search, Clear } from "@mui/icons-material";
import moment from "moment";
import CreateCma from "./CreateCma.js";
import TableSkeleton from "../../../components/loader/tableLoader";
import { getImageUrlWithHeightAndWidth } from "../../../utils/propertyData.js";

function List(props) {
  /**
   * Variables and States
   */
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [columns] = React.useState([
    {
      field: "MediaURL",
      headerName: "Image",
      width: 120,
      renderCell: (row) => {
        return (
          <img
            className="mediaImage"
            src={row.value || NoImage}
            alt="cmaimage"
          />
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      width: 275,
      renderCell: (params) => {
        return (
          <div>
            <span
              style={{
                marginBottom: "2px",
                fontWeight: "600",
                display: "block",
                fontSize: "15px",
                lineHeight: "20.02px",
                letterSpacing: "0.5px",
                fontFamily: "AvenirNext!important",
              }}
            >
              {params.value.title}
            </span>
            <span
              style={{
                fontWeight: "400",
                display: "block",
                fontSize: "12px",
                lineHeight: "20.02px",
                fontFamily: "AvenirNext!important",
              }}
            >
              {params.value.subtitle}
            </span>
          </div>
        );
      },
    },
    {
      field: "ListingPrice",
      headerName: "Price",
      renderCell: (params) => {
        return (
          <Typography
            sx={{ fontSize: "20px!important", fontWeight: "500!important" }}
          >{`$${numberWithCommas(Math.round(params.value))}`}</Typography>
        );
      },
      width: 170,
    },
    {
      field: "BedroomsTotal",
      headerName: "Beds",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "17px!important" }}>
          {params.value}
        </Typography>
      ),
      width: 70,
    },
    {
      field: "BathroomsFull",
      headerName: "Baths",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "17px!important" }}>
          {params.value}
        </Typography>
      ),
      width: 70,
    },
    {
      field: "BuildingAreaTotal",
      headerName: "Sqft",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "17px!important" }}>
          {numberWithCommas(params.value)}
        </Typography>
      ),
      width: 70,
    },
    {
      field: "YearBuilt",
      headerName: "Yr Built",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "16px!important" }}>
          {params.value}
        </Typography>
      ),
      width: 70,
    },
    {
      field: "SubdivisionName",
      headerName: "Subdivison/Bldg",
      width: 175,
    },
    {
      field: "DaysOnMarket",
      headerName: "DOM",
      width: 70,
    },
    {
      field: "agent",
      headerName: "Listing Agent",
      sortComparator: (v1, v2) => v1.first_name.localeCompare(v2.first_name),
      renderCell: (params) => {
        return (
          <ListItem
            alignItems="flex-start"
            sx={{ width: "100%", maxWidth: 360, padding: 0, margin: 0 }}
          >
            <ListItemAvatar>
              <Avatar
                sx={{
                  marginRight: "10px",
                  borderRadius: "4px",
                  fontWeight: "400",
                }}
                src={params.value?.profile_images?.profile_img_thumbnail}
                variant="square"
              >{`${params.value?.first_name?.slice(
                0,
                1
              )}${params.value?.last_name?.slice(0, 1)}`}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={params.value?.first_name}
              secondary={
                <React.Fragment>{params.value?.last_name}</React.Fragment>
              }
            />
          </ListItem>
        );
      },
      width: 185,
      sortable: true,
    },
    {
      field: "created",
      headerName: "Created By",
      width: 185,
      renderCell: (params) => {
        return (
          <div>
            <p style={{ padding: 0, margin: 0, fontWeight: "600" }}>
              {params?.value?.createdBy}
            </p>
            <p style={{ padding: 0, margin: 0, fontWeight: "500" }}>
              {moment(params.value.createdDate).format("MM/DD/YYYY")}
            </p>
          </div>
        );
      },
    },
  ]);

  const { loading, cmaData, cmaCount } = useSelector((state) => state.allCmas);
  /**
   * End Region: Variables and States
   */
  /**
   * useEffets Hooks
   */
  React.useEffect(() => {
    dispatch(resetSingleCMA());
    dispatch(setCMAPdfUpdated({ flag: true }));
  }, []);

  React.useEffect(() => {
    let r =
      cmaData?.map((d) => {
        return {
          ...d,
          MediaURL:
            d?.cma_media_urls?.length > 0
              ? getImageUrlWithHeightAndWidth(d?.cma_media_urls[0]?.MediaUrl)
              : null,
          address: {
            title: `${
              d.StreetName
                ? `${d.StreetNumber || ""} ${d.StreetName || ""} ${
                    d.StreetSuffix || ""
                  }  ${d.UnitNumber || ""}`
                : `Undisclosed Address ${d.UnitNumber || ""}`
            }`,
            subtitle: `${d.City || ""}, ${d.StateOrProvince || ""} ${
              d.PostalCode || ""
            }`,
          },
          created: {
            createdBy: d.CreatedBy,
            createdDate: d?.createdAt,
          },
        };
      }) || [];
    setRows(r);
  }, [cmaData]);

  React.useEffect(() => {
    if (searchText.length === 0) dispatch(getAllCmaData());
  }, [searchText]);
  /**
   * End Region: useEffets Hooks
   */
  /**
   * Helpers
   */
  const debouncedSearch = React.useRef(
    debounce(async (val) => {
      dispatch(getAllCmaData({ search: val }));
    }, 500)
  ).current;

  const onChangeSearchText = (e) => {
    debouncedSearch.cancel();
    setSearchText(e.target.value);
    if (e.target.value.length > 0) debouncedSearch(e.target.value);
  };

  const handleClearClick = () => setSearchText("");
  /**
   * End Region: Helpers
   */
  return (
    <Container maxWidth="xl" sx={{ height: "100vh", width: "100%" }}>
      <Grid container spacing={2} className={classes.tableCointainer} mt={5}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={1}
          >
            <Grid item>
              <Typography variant="h5">
                {cmaCount || ""} Comparative Market Analysis (CMA)
              </Typography>
            </Grid>
            <Grid item display={"flex"} gap={2}>
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                sx={{
                  color: "#ffffff",
                  width: "98px",
                  height: "39px",
                  background: "#000000",
                  "&:hover": {
                    color: "white !important",
                    background: "black !important",
                    border: "1px solid black !important",
                  },
                }}
                variant="outlined"
              >
                Add CMA
              </Button>

              <TextField
                fullWidth
                size="small"
                placeholder="Search CMAs"
                onChange={onChangeSearchText}
                value={searchText}
                sx={{
                  fontFamily: "AvenirNext !important",
                  height: "42px",
                  width: "250px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      sx={{
                        visibility:
                          searchText.length > 0 ? "visible" : "hidden",
                      }}
                      onClick={handleClearClick}
                    >
                      <Clear />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ height: "70vh", width: "100%" }}>
                <DataGridPro
                  rows={rows || []}
                  rowHeight={80}
                  loading={loading}
                  columns={columns}
                  components={{
                    LoadingOverlay: TableSkeleton,
                  }}
                  onCellClick={(row) => navigate(`/tools/cmas/${row.id}`)}
                  sx={{
                    fontFamily: "AvenirNext !important",
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "600",
                    },
                  }}
                  onRowsScrollEnd={(e) => {
                    if (cmaCount > e.virtualRowsCount && !loading) {
                      dispatch(
                        getAllCmaData({
                          pageNumber: Number(e.virtualRowsCount / 20) + 1,
                          pageSize: 20,
                        })
                      );
                    }
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CreateCma open={open} handleClose={() => setOpen(false)} />
    </Container>
  );
}
export default withStyles(styleSheet, { name: "listStyle" })(List);
