import React, { useState } from "react";
import { Button, Box, Dialog, DialogContent, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
export default function SendConfirmation(props) {
  const {
    setOpen,
    open,
    handleConfirm,
    title,
    description,
    makeOverDetail,
    isLoading,
  } = props;

  const onClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      onClose={onClose}
      open={open}
      disablePortal
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogContent sx={{ padding: "50px" }}>
        <Typography
          sx={{
            color: "black",
            fontSize: "20px",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ margin: "15px 0px", fontSize: "15px", textAlign: "center" }}
        >
          {description}
        </Typography>
        {makeOverDetail?.seller0_email ? (
          <Box>
            <Typography
              sx={{
                margin: "5px 0px",
                fontSize: "15px",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Seller 1
            </Typography>
            <Typography
              sx={{ margin: "0px", fontSize: "15px", textAlign: "center" }}
            >
              Name: {makeOverDetail?.seller0_first_name}{" "}
              {makeOverDetail?.seller0_last_name}
            </Typography>
            <Typography
              sx={{ margin: "0px", fontSize: "15px", textAlign: "center" }}
            >
              Email: {makeOverDetail?.seller0_email}
            </Typography>
          </Box>
        ) : null}
        {makeOverDetail?.seller1_email ? (
          <Box>
            <Typography
              sx={{
                margin: "5px 0px",
                fontSize: "15px",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Seller 2
            </Typography>
            <Typography
              sx={{ margin: "0px", fontSize: "15px", textAlign: "center" }}
            >
              Name: {makeOverDetail?.seller1_first_name}{" "}
              {makeOverDetail?.seller1_last_name}
            </Typography>
            <Typography
              sx={{ margin: "0px", fontSize: "15px", textAlign: "center" }}
            >
              Email: {makeOverDetail?.seller1_email}
            </Typography>
          </Box>
        ) : null}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
            marginTop: "30px",
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{ color: "black", borderColor: "black !important" }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleConfirm}
            variant="contained"
            color="info"
            sx={{ background: "black", color: "white", minWidth: "90px" }}
            loadingPosition="start"
            loading={isLoading}
          >
            Send
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
