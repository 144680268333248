import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const markAsUnreadAlert = createAsyncThunk(
  "MessagesAndAlerts/markAsUnreadAlert",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/notifications/mark-as-unread`,
        {
          notification_id: data?.id1 ? data?.id1 : undefined,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return { ...resp.data };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
