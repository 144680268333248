import React, { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { getAllKnowledgeBasedDataListUsingSearch } from "../../../../redux/knowledge/knowledgeSearch";
import Typography from "@mui/material/Typography";

function TrainingRequirementsSelect(props) {
  let { open, setOpen, classes, trainingReq, setTrainingReq } = props;
  const [searchText, setSearchText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const dispatch = useDispatch();

  const allKnowledgeBaseData = useSelector(
    (state) => state.knowledgeBase.search.allKnowledgeBaseData
  );
  const searchData = useSelector(
    (state) => state.knowledgeBase.search.searchData
  );
  const loading = useSelector((state) => state.knowledgeBase.search.loading);
  const handleReturnPosts = () => {
    if (searchText) {
      return [...searchData];
    } else {
      if (selectedCategory?.posts) {
        return selectedCategory?.posts?.map((it) => ({
          ...it,
          categoryName: selectedCategory.title,
        }));
      } else {
        let neePosts = [];
        allKnowledgeBaseData?.categories?.map((category) => {
          neePosts = [
            ...neePosts,
            ...category.posts?.map((it) => ({
              ...it,
              categoryName: category.title,
            })),
          ];
        });
        return neePosts;
      }
    }
  };
  const debouncedSearch = useRef(
    debounce(async (val) => {
      if (val?.length > 0) {
        dispatch(getAllKnowledgeBasedDataListUsingSearch(val));
      }
    }, 300)
  ).current;

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.length > 0) debouncedSearch(e.target.value);
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className={classes.modalHeader} id="alert-dialog-title">
          Training Requirements
          <Chip
            label={`${trainingReq?.length} Selected`}
            size="small"
            sx={{ backgroundColor: "#D9D9D9" }}
          />
        </DialogTitle>
        <DialogContent>
          <Box className={classes.topFilterSection}>
            <OutlinedInput
              onChange={onChangeSearchText}
              value={searchText}
              placeholder="Search Knowledge Base"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ bgcolor: "white" }}
              size="small"
              fullWidth
            />
            <Box>
              <ButtonGroups
                id="all-category-button-menu"
                bgColor="#5B5B5B"
                bgColorHover="#5B5c5c"
                placement={"bottom"}
                height="40px"
                color="white"
                minWidth={"170px"}
                fontSize="12px"
                options={
                  allKnowledgeBaseData?.categories?.length
                    ? [
                        { title: "All Categories", value: "all" },
                        ...allKnowledgeBaseData?.categories?.map((it) => ({
                          value: it.title,
                          ...it,
                        })),
                      ]
                    : []
                }
                onChangeMenu={(value, data) => {
                  if (value === "all") {
                    setSelectedCategory("");
                  } else {
                    setSelectedCategory(data);
                  }
                }}
                placeholder="All Categories"
                value={selectedCategory}
                {...props}
              />
            </Box>
          </Box>
          <Card variant="outlined" className={classes.postsListView}>
            {loading ? (
              <Box
                sx={{
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={30} />
              </Box>
            ) : (
              <List className={classes.postsListViewList} disablePadding>
                {handleReturnPosts()?.length ? (
                  handleReturnPosts().map((item) => {
                    return (
                      <ListItem
                        key={item.id}
                        secondaryAction={
                          <Box>
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setTrainingReq((pre) => [...pre, item]);
                                } else {
                                  setTrainingReq((pre) =>
                                    [...pre]?.filter((it) => it.id !== item.id)
                                  );
                                }
                              }}
                              checked={
                                trainingReq?.find((it) => it.id === item.id)
                                  ? true
                                  : false
                              }
                            />
                          </Box>
                        }
                        disablePadding
                        divider
                      >
                        <ListItemButton
                          sx={{ paddingTop: "14px", paddingBottom: "14px" }}
                        >
                          <ListItemAvatar sx={{ minWidth: "120px" }}>
                            <Avatar
                              alt={`Avatar n°${item.title}`}
                              src={item?.thumbnail}
                              variant="rounded"
                              sx={{
                                height: "58px",
                                width: "103px",
                                border:
                                  "0.5px solid rgba(0, 0, 0, 0.50) !important",
                              }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={item?.title}
                            secondary={
                              <Chip
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                }}
                                size="small"
                                label={item.categoryName}
                              />
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Not Found</Typography>
                  </Box>
                )}
              </List>
            )}
          </Card>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <LoadingButton
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            onClick={() => setOpen(false)}
          >
            OK
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default withStyles(styleSheet, {
  name: "TrainingRequirementsSelectStyle",
})(TrainingRequirementsSelect);
