import React, { useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Chip,
  Box,
  useMediaQuery,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { getCmaPrice, updateCma } from "../../../redux/tools/cmas.js";
import DataGridComponent from "../../projects/DataGridComponent.js";
import { numberWithCommas } from "./helper.js";
import { getImageUrlWithHeightAndWidth } from "../../../utils/propertyData.js";

function Pricing(props) {
  let { classes } = props;
  const dispatch = useDispatch();

  const [fairMarketprice, setfairMarketprice] = useState("");
  const [listPrice, setlistPrice] = useState("");

  const [lowestValue, setLowestValue] = useState("");
  const [highestValue, setHighestValue] = useState("");

  const [cmaData, setCmadata] = useState({
    ArchitecturalStyle: "",
    AssociationFee: null,
    Basement: "",
    BathroomsFull: "",
    BathroomsHalf: "",
    BedroomsTotal: "",
    BuildingAreaTotal: "",
    City: "",
    CloseDate: null,
    ClosePrice: null,
    ConstructionMaterials: "",
    DaysOnMarket: 105,
    FairMarketPrice: null,
    FireplacesTotal: 2,
    GarageSpaces: "",
    Levels: "",
    ListPrice: "",
    ListingContractDate: "",
    ListingId: "",
    ListingPrice: null,
    ListingType: "",
    LotSizeAcres: "",
    MajorChangeTimestamp: "",
    MediaURL:
      "https://dvvjkgh94f2v6.cloudfront.net/7f422b9d/256319575/83dcefb7.jpeg",
    MlsStatus: "",
    OnMarketDate: "",
    OriginalListPrice: "",
    ParkingTotal: 2,
    PoolFeatures: "",
    PostalCode: "",
    PropertyType: "",
    PurchaseContractDate: "",
    StateOrProvince: "",
    StreetNumber: "",
    StreetSuffix: "",
    SubdivisionName: "",
    TaxAnnualAmount: "",
    YearBuilt: 2000,
    agent_id: 1,
    client0_email: null,
    client0_firstName: null,
    client0_lastName: null,
    client1_email: null,
    client1_firstName: null,
    client1_lastName: null,
    cma_comparables: [],
    cma_custom_fields: [],
    cma_media_urls: [],
    createdAt: "",
    deletedAt: null,
    distance: "",
    id: 2,
    last_activity: null,
    last_activity_by: null,
    latitude: "",
    longitude: "",
    pricePerSqFt: "",
    sqftTolerance: 20,
    timeFrame: 6,
    updatedAt: "",
    users_v2: null,
    vintageTolerance: 30,
  });
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([
    {
      field: "MlsStatus",
      headerName: "Status",
      align: "center",
      renderCell: (row) => {
        return (
          <Chip
            label={
              row.value === "Active Under Contract" ? "ActiveUC" : row.value
            }
            sx={{
              color: row.value === "Pending" ? "#0B0909!important" : "#fff",
              backgroundColor:
                row.value === "Closed"
                  ? "red"
                  : row.value === "Active"
                  ? "green"
                  : row.value === "Pending"
                  ? "yellow"
                  : row.value === "Active Under Contract"
                  ? "#4CC7CF"
                  : "grey",
              width: "120px",
              padding: "0",
            }}
          />
        );
      },
    },
    {
      field: "MediaURL",
      headerName: "Image",
      width: 120,
      renderCell: (row) => {
        return (
          <img
            className="mediaImage"
            src={getImageUrlWithHeightAndWidth(row.value)}
            alt="cmaimage"
          />
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
      renderCell: (params) => {
        return (
          <div>
            <span
              style={{
                marginBottom: "2px",
                fontWeight: "600",
                display: "block",
                fontSize: "15px",
                lineHeight: "20.02px",
                letterSpacing: "0.5px",
                fontFamily: "AvenirNext!important",
              }}
            >
              {params.value.title}
            </span>
            <span
              style={{
                fontWeight: "400",
                display: "block",
                fontSize: "12px",
                lineHeight: "20.02px",
                fontFamily: "AvenirNext!important",
              }}
            >
              {params.value.subtitle}
            </span>
          </div>
        );
      },
    },
    {
      field: "adjPrice",
      headerName: "Adj. Price",
      renderCell: (params) => {
        return (
          <Typography
            sx={{ fontSize: "20px!important", fontWeight: "500!important" }}
          >
            {params.value
              ? `$${numberWithCommas(Math.round(params.value))}`
              : ""}
          </Typography>
        );
      },
      width: 130,
    },
    {
      field: "BedroomsTotal",
      headerName: "Beds",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "17px!important" }}>
          {params.value}
        </Typography>
      ),
      width: 50,
    },
    {
      field: "BathroomsFull",
      headerName: "Baths",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "17px!important" }}>
          {params.value}
        </Typography>
      ),
      width: 50,
    },
    {
      field: "BuildingAreaTotal",
      headerName: "Sqft",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "17px!important" }}>
          {params.value ? numberWithCommas(params.value) : ""}
        </Typography>
      ),
      width: 90,
    },
    {
      field: "YearBuilt",
      headerName: "Yr Built",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "16px!important" }}>
          {params.value}
        </Typography>
      ),
      width: 100,
    },
    {
      field: "DaysOnMarket",
      headerName: "DOM",
      width: 50,
    },
  ]);

  const { adjustmentData, singleCma, cmaPrice } = useSelector(
    (state) => state.allCmas
  );

  const addPrice = () => {
    let url = window.location.pathname;
    let cmaId = url.split("/")[3];

    setTimeout(() => {
      let updatedCMAValue = {
        agent_id: cmaData?.agent_id,
        StreetNumber: cmaData?.StreetNumber,
        FairMarketPrice: fairMarketprice,
        ListingPrice: listPrice,
        EstimatedHighestPrice: highestValue,
        EstimatedLowestPrice: lowestValue,
        cmaId: cmaId,
      };
      dispatch(
        updateCma({
          cmaData: updatedCMAValue,
        })
      );
    }, 500);
  };

  React.useEffect(() => {
    if (singleCma) {
      setCmadata(singleCma);
    }
  }, [singleCma]);

  React.useEffect(() => {
    if (singleCma) {
      setlistPrice(singleCma?.ListingPrice);
    }
  }, [singleCma?.ListingPrice]);

  React.useEffect(() => {
    if (singleCma) {
      setfairMarketprice(singleCma?.FairMarketPrice);
    }
  }, [singleCma?.FairMarketPrice]);

  React.useEffect(() => {
    if (singleCma) {
      setHighestValue(singleCma?.EstimatedHighestPrice);
    }
  }, [singleCma?.EstimatedHighestPrice]);

  React.useEffect(() => {
    if (singleCma) {
      setLowestValue(singleCma?.EstimatedLowestPrice);
    }
  }, [singleCma?.EstimatedLowestPrice]);

  React.useEffect(() => {
    let url = window.location.pathname;
    let cmaId = url.split("/")[3];
    dispatch(getCmaPrice({ id: cmaId }));
  }, []);

  React.useEffect(() => {
    let r =
      singleCma?.cma_comparables?.map((d) => {
        let adjPrice = 0;
        adjustmentData?.cma_comparables?.find((adj) => {
          if (adj.id.value === d.id) {
            adjPrice = adj.TotalAdjValue.value;
          }
        });

        return {
          ...d,
          adjPrice: adjPrice,
          address: {
            title: `${
              d.StreetName
                ? `${d.StreetNumber || ""} ${d.StreetName || ""} ${
                    d.StreetSuffix || ""
                  }  ${d.UnitNumber || ""}`
                : `Undisclosed Address ${d.UnitNumber || ""}`
            }`,
            subtitle: `${d.City || ""}, ${d.StateOrProvince || ""} ${
              d.PostalCode || ""
            }`,
          },
        };
      }) || [];
    setRows(r);
  }, [singleCma]);

  return (
    <Container maxWidth="xl" sx={{ height: "100%" }}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Box
            className={classes.tableCointainer}
            style={{
              width: "100%",
              marginTop: "30px",
              height:
                rows.length > 0
                  ? 100 + 100 * rows.length > 500
                    ? 500
                    : 100 + 100 * rows.length
                  : 200,
            }}
          >
            <DataGridComponent
              rows={rows || []}
              columns={columns}
              rowHeight={80}
              pageSize={100}
              hideFooter
              sx={{
                fontFamily: "AvenirNext !important",
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Card className={classes.pageMarginTop}>
        <CardContent>
          <div className={classes.pricingRange}>
            Average adjusted value of {cmaPrice?.soldComparable} sold
            comparables is
            <strong>
              {" "}
              ${numberWithCommas(cmaPrice?.averageSoldComparable)}
            </strong>
          </div>
          <div className={classes.pricingRange}>
            <strong>{cmaPrice?.averageSoldComparableDOM} Days on market</strong>{" "}
            average for all sold comparables.
          </div>
          <div className={classes.pricingRange}>
            Average for active and pending listings is{" "}
            <strong>
              {cmaPrice?.averageActiveAndPendingComparableDOM} days on market
            </strong>
          </div>
          <div className={classes.pricingRange}>
            Range of adjusted values for the subject property is between{" "}
            <strong>
              ${numberWithCommas(cmaPrice?.minRangeSubjectProperty)} - $
              {numberWithCommas(cmaPrice?.maxrangeSubjectProperty)}
            </strong>
          </div>
          <Box
            sx={{
              display: "flex",
              gap: "40px",
            }}
          >
            <div className={classes.pricingRangeInput}>
              Display Estimated Lowest Value
              <TextField
                id="standard-basic"
                variant="standard"
                onBlur={() => {
                  addPrice();
                }}
                value={lowestValue}
                onChange={(e) => setLowestValue(e.target.value)}
              />
            </div>
            <div className={classes.pricingRangeInput}>
              To Highest Value
              <TextField
                id="standard-basic"
                variant="standard"
                onBlur={() => {
                  addPrice();
                }}
                value={highestValue}
                onChange={(e) => setHighestValue(e.target.value)}
              />
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "40px",
              mt: "1rem",
            }}
          >
            <div className={classes.pricingRangeInput}>
              Fair market value of the property is
              <TextField
                id="standard-basic"
                variant="standard"
                onBlur={() => {
                  addPrice();
                }}
                value={fairMarketprice}
                onChange={(e) => setfairMarketprice(e.target.value)}
              />
            </div>
            <div className={classes.pricingRangeInput}>
              Suggested List Price
              <TextField
                id="standard-basic"
                variant="standard"
                onBlur={() => {
                  addPrice();
                }}
                value={listPrice}
                onChange={(e) => setlistPrice(e.target.value)}
              />
            </div>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}
export default withStyles(styleSheet, { name: "PricingStyle" })(Pricing);
