import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  departmentsLoading: true,
  dutyUsersLoading: true,

  clockingOutLoading: false,
  clockingInLoading: false,

  onDutyDepartments: [],
  onDutyUsers: [],
  errMsg: null,
};

// for getting all on-duty department list
export const getOnDutyDepartmentList = createAsyncThunk(
  "getDepartmentList/getOnDutyDepartmentList",
  async (data, thunkAPI) => {
    try {
      const respDepts = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/users/staff-on-off-duty`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      await thunkAPI.dispatch(getOnDutyUsers());
      return { departments: respDepts.data.data };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getOnDutyUsers = createAsyncThunk(
  "getDutyUsersList/getOnDutyUsers",
  async (data, thunkAPI) => {
    try {
      const respAllUsers = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/users/all-users-on-duty`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );

      return { users: respAllUsers.data.data };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for updating all on-duty department list
export const clockInStaff = createAsyncThunk(
  "updateList/clockInStaff",
  async (data, thunkAPI) => {
    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}api/users/staff-management/clock-in`,
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        data: {},
      };
      const resp = await axios(config);

      await thunkAPI.dispatch(getOnDutyDepartmentList());
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for clocking out
export const clockOutStaff = createAsyncThunk(
  "updateList/clockOutStaff",
  async (data, thunkAPI) => {
    try {
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_BASE_URL}api/users/staff-management/clock-out`,
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      };

      const resp = await axios(config);
      await thunkAPI.dispatch(getOnDutyDepartmentList());
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const userStaffDepartment = createSlice({
  name: "userStaffDepartment",
  initialState,
  extraReducers: {
    // for getting all on-duty departments
    [getOnDutyDepartmentList.pending]: (state) => {
      state.departmentsLoading = true;
    },
    [getOnDutyDepartmentList.fulfilled]: (state, action) => {
      state.departmentsLoading = false;
      state.onDutyDepartments = [...action.payload.departments];
    },
    [getOnDutyDepartmentList.rejected]: (state, action) => {
      state.departmentsLoading = false;
      state.errMsg = action.payload;
    },

    // for getting all on-duty users
    [getOnDutyUsers.pending]: (state) => {
      state.dutyUsersLoading = true;
    },
    [getOnDutyUsers.fulfilled]: (state, action) => {
      state.dutyUsersLoading = false;
      state.onDutyUsers = [...action.payload.users];
    },
    [getOnDutyUsers.rejected]: (state, action) => {
      state.dutyUsersLoading = false;
      state.errMsg = action.payload;
    },

    // updating on duty departments
    [clockInStaff.pending]: (state) => {
      state.clockingInLoading = true;
    },
    [clockInStaff.fulfilled]: (state, action) => {
      state.clockingInLoading = false;
    },
    [clockInStaff.rejected]: (state, action) => {
      state.clockingInLoading = false;
      state.errMsg = action.payload;
    },

    // clocking out
    [clockOutStaff.pending]: (state) => {
      state.clockingOutLoading = true;
    },
    [clockOutStaff.fulfilled]: (state, action) => {
      state.clockingOutLoading = false;
    },
    [clockOutStaff.rejected]: (state, action) => {
      state.clockingOutLoading = false;
      state.errMsg = action.payload;
    },
  },
});

export default userStaffDepartment.reducer;
