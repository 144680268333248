import React from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

function ConfirmModal(props) {
  const { open, setOpen, handleConfirm, title, content, loading } = props;
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog disablePortal open={open} fullWidth={true} maxWidth={"xs"}>
      <DialogContent>
        <Typography
          sx={{
            color: "black",
            fontSize: "20px",
            fontWeight: 600,
            textAlign: "center",
            marginTop: "15px",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ margin: "15px 0px", fontSize: "15px", textAlign: "center" }}
        >
          {content}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
            marginTop: "30px",
            marginBottom: "15px",
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{ color: "black", borderColor: "black !important" }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleConfirm()}
            variant="contained"
            color="info"
            sx={{ background: "black", color: "white" }}
            loadingPosition="start"
            loading={loading}
          >
            Confirm
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmModal;
