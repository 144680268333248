import React, { useEffect } from "react";
import { Box, Typography, AvatarGroup, Skeleton } from "@mui/material";
import Avatar from "../../../../components/uiElements/avatar";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { departmentsColors } from "../../utils/department";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { CustomTooltip } from "../../../../components/shared/tooltip";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { getCoverage } from "../../../../redux/staff";
import { useDispatch, useSelector } from "react-redux";
function Coverage(props) {
  const { classes } = props;
  const dispatch = useDispatch();
  const coverage = useSelector((item) => item.Staff.coverage);
  const storedListOfUsers = useSelector(
    (item) => item.users.storedListOfUsers?.list
  );
  useEffect(() => {
    dispatch(getCoverage({}));
  }, []);
  return (
    <Box className={classes.coverageSection}>
      <Typography className={classes.coverageSectionHeading}>
        Current Coverage
      </Typography>
      <Box className={classes.departmentsSection}>
        {coverage?.isLoading
          ? Array.from(Array(12).keys())?.map((item, index) => (
              <Box key={index} className={classes.loadingItem}>
                <Skeleton
                  variant="rectangular"
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            ))
          : coverage?.data?.currentCoverage?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor:
                    departmentsColors?.find((it) => it.name === item.name)
                      ?.backgroundColor || "#DDC441CC",
                }}
                className={classes.departmentsSectionItem}
              >
                <Typography className={classes.departmentsSectionItemTitle}>
                  {item.name}
                </Typography>
                <Box className={classes.departmentsSectionItemBottom}>
                  {item?.onduty_staff_departments?.length ? (
                    <AvatarGroup
                      sx={{
                        flexDirection: "row-reverse",
                        "& .MuiAvatarGroup-avatar": {
                          width: "30px",
                          height: "30px",
                          fontSize: "14px",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          color: "black",
                        },
                      }}
                      variant="circular"
                      total={item?.onduty_staff_departments?.length}
                    >
                      {item.onduty_staff_departments
                        ?.slice(0, 2)
                        .map((staff, index) => {
                          let user =
                            staff?.user ||
                            storedListOfUsers?.find(
                              (it) => it.id === staff.user_id
                            );
                          return (
                            <CustomTooltip
                              title={user?.full_name}
                              placement="top"
                              key={user?.id}
                            >
                              <Avatar
                                title={user?.full_name}
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  fontSize: "14px",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                }}
                                variant="circular"
                                alt={user?.full_name}
                                src={
                                  user?.profile_images?.profile_img_thumbnail ||
                                  user?.profile_images?.profile_img
                                }
                              />
                            </CustomTooltip>
                          );
                        })}
                    </AvatarGroup>
                  ) : (
                    <Typography sx={{ color: "red" }}>No Coverage</Typography>
                  )}

                  {item?.onduty_staff_departments?.length ? (
                    <CheckCircleOutlineRoundedIcon
                      sx={{ fontSize: "38px", color: "white" }}
                    />
                  ) : (
                    <ErrorOutlineRoundedIcon
                      sx={{ fontSize: "38px", color: "red" }}
                    />
                  )}
                </Box>
              </Box>
            ))}
      </Box>
    </Box>
  );
}

export default withStyles(styleSheet, { name: "CoverageStyle" })(Coverage);
