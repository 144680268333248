export const styleSheet = () => ({
  pageRoot: {
    minHeight: "100vh",
    padding: "70px 0px !important",
  },
  staffHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  staffHeaderTitle: {
    fontSize: "26px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    fontWeight: "600 !important",
  },
  staffHeaderDate: {
    color: "#8B8B8B !important",
    fontSize: "16px !important",
    fontWeight: 400,
    lineHeight: "123.5% !important" /* 24.7px */,
    letterSpacing: "0.25px !important",
  },
  staffHeaderButton: {
    backgroundColor: "black !important",
    color: "white !important",
    fontWeight: "500  !important",
    "&:hover": {
      backgroundColor: "black",
    },
  },
});
