import React, { useState } from "react";
import { Box, Typography, Button, Select, MenuItem } from "@mui/material";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ReorderRoundedIcon from "@mui/icons-material/ReorderRounded";
import SearchResultsArea from "./searchDetail";
import PostsListView from "./postsListView";
function CategoryDetail(props) {
  const [viewType, setViewType] = useState("grid");
  const [filterType, setFilterType] = useState("");
  const { classes, storedUsers, selectedCategory } = props;

  const filterByYear = (data) => {
    const currentYear = new Date().getFullYear();
    return data.filter(
      (item) => new Date(item.createdAt).getFullYear() === currentYear
    );
  };

  const filterByMonth = (data) => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth(); // getMonth returns 0 for January, 1 for February, etc.

    return data.filter((item) => {
      const createdAt = new Date(item.createdAt);
      return (
        createdAt.getFullYear() === currentYear &&
        createdAt.getMonth() === currentMonth
      );
    });
  };

  return (
    <Box sx={{ mt: "30px" }}>
      {" "}
      <Box className={classes.categoriesHeader}>
        <Typography className={classes.categoriesHeading}>
          {selectedCategory?.title}
        </Typography>
        <Box className={classes.categoryHeaderRight}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            sx={{ width: "200px", color: "rgba(0, 0, 0, 0.50)" }}
            placeholder="filter"
            defaultValue={"filter"}
            value={!filterType ? "filter" : filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <MenuItem
              sx={{ color: "rgba(0, 0, 0, 0.50)" }}
              value={!filterType ? "filter" : "all"}
              selected
            >
              {!filterType ? "filter" : "All"}
            </MenuItem>
            <MenuItem sx={{ color: "rgba(0, 0, 0, 0.50)" }} value={"month"}>
              Added This Month
            </MenuItem>
            <MenuItem sx={{ color: "rgba(0, 0, 0, 0.50)" }} value={"year"}>
              Added This Year
            </MenuItem>
          </Select>
          <Button
            className={
              viewType === "grid"
                ? classes.categoriesHeaderButtonActive
                : classes.categoriesHeaderButton
            }
            size="small"
            variant="contained"
            color="inherit"
            onClick={() => setViewType("grid")}
          >
            <GridViewRoundedIcon />
          </Button>
          <Button
            className={
              viewType === "list"
                ? classes.categoriesHeaderButtonActive
                : classes.categoriesHeaderButton
            }
            size="small"
            variant="contained"
            color="inherit"
            onClick={() => setViewType("list")}
          >
            <ReorderRoundedIcon />
          </Button>
        </Box>
      </Box>
      {viewType === "grid" ? (
        <SearchResultsArea
          category={{
            posts:
              filterType === "month"
                ? filterByMonth(selectedCategory?.posts || [])
                : filterType === "year"
                ? filterByYear(selectedCategory?.posts || [])
                : selectedCategory?.posts || [],
          }}
          {...props}
          categoryTitle=""
          storedUsers={storedUsers}
        />
      ) : (
        <PostsListView
          posts={
            filterType === "month"
              ? filterByMonth(selectedCategory?.posts || [])
              : filterType === "year"
              ? filterByYear(selectedCategory?.posts || [])
              : selectedCategory?.posts || []
          }
          {...props}
          categoryTitle=""
          storedUsers={storedUsers}
        />
      )}
    </Box>
  );
}

export default CategoryDetail;
