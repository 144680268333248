import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const sendMakeoverAgreementAsEnvelope = createAsyncThunk(
  "ListingMakeover/sendMakeoverAgreementAsEnvelope",
  async (data, thunkAPI) => {
    const { schema, handleSuccess, handleError } = data;
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/transactions/makeover/send-makeover-agreement-as-envelope/${data.id}`,
        { ...schema },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (resp?.data) {
        handleSuccess();
      }
      return resp.data;
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(
            error?.response?.data?.errorMessage ||
              error?.response?.data?.message
          );
        }
        return thunkAPI.rejectWithValue(
          error?.response?.data?.errorMessage || error?.response?.data?.message
        );
      } else {
        if (handleError) {
          handleError(error?.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
