import React from "react";
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  TextField,
  Grid,
  Card,
  Autocomplete,
} from "@mui/material";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import { useSelector } from "react-redux";
function ContractTYpe(props) {
  let { classes, updateContractDetail, contractDetail, errMsg, markets } =
    props;
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  return (
    <Grid>
      <Grid item xs={9}>
        <Card
          variant="outlined"
          className={classes.transactionFormCard}
          sx={{ overflow: "visible" }}
        >
          <Box className={classes.FormContractTypeBox}>
            <Typography classes={{ root: classes.transactionFormCardHeading }}>
              Contract Type
            </Typography>
            <Box className={classes.FormContractTypeBoxRight}>
              <FormControl sx={{ position: "relative !important" }}>
                <ButtonGroups
                  id="category-button-menu"
                  bgColor="#0B0909"
                  bgColorHover="#0B0909"
                  placement={"bottom"}
                  color="white"
                  width="160px"
                  minWidth="160px"
                  fontSize="12.5px"
                  value={contractDetail.contract_type}
                  options={[
                    { title: "Buyer Contract", value: "buyer" },
                    { title: "Seller Contract", value: "seller" },
                    { title: "Tenant Contract", value: "tenant" },
                    { title: "Landlord Contract", value: "landlord" },
                    { title: "Referral Contract", value: "referral" },
                  ]}
                  onChangeMenu={(value) =>
                    updateContractDetail(
                      "contract_type",
                      value,
                      "market_id",
                      ""
                    )
                  }
                  placeholder="Select Type"
                  {...props}
                />
                <Box sx={{ color: "red" }}>{errMsg?.contract_type}</Box>
              </FormControl>
              <Box className={classes.agentAndMarketSec}>
                <FormControl
                  className="selectA"
                  style={{
                    minWidth: "256px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="contract_type"
                    value={
                      contractDetail.agent_id
                        ? agentRosterList?.list?.find(
                            (item) => item?.id === contractDetail?.agent_id
                          )
                        : undefined
                    }
                    onChange={(event, value) =>
                      updateContractDetail(
                        "agent_id",
                        value?.id || "",
                        "agent_name",
                        value?.full_name || ""
                      )
                    }
                    getOptionLabel={(option) => option.full_name}
                    options={
                      agentRosterList?.list &&
                      agentRosterList?.list?.filter((it) => it.is_agent)
                        .length > 0
                        ? agentRosterList?.list
                            ?.filter((it) => it.is_agent)
                            .sort((a, b) =>
                              a.last_name.localeCompare(b.last_name)
                            )
                        : []
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select agent *"
                        variant="standard"
                        size="small"
                        fullWidth
                        error={errMsg.agent_id}
                        helperText={errMsg.agent_id}
                        classes={{ root: classes.agent_id }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl
                  className="selectA"
                  style={{
                    minWidth: "186px",
                    marginLeft: "15px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="market-select-dropdown-for-contract"
                    value={
                      contractDetail.market_id
                        ? markets?.find(
                            (item) => item.id === contractDetail.market_id
                          )
                        : ""
                    }
                    onChange={(event, value) =>
                      updateContractDetail("market_id", value?.id || "")
                    }
                    getOptionLabel={(option) => option?.name || ""}
                    options={markets?.length ? markets : []}
                    fullWidth
                    disabled={
                      contractDetail.contract_type === "referral" ? true : false
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Market *"
                        variant="standard"
                        size="small"
                        fullWidth
                        error={errMsg.market_id}
                        helperText={errMsg.market_id}
                        classes={{ root: classes.detailInput }}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
export default withStyles(styleSheet, { name: "ContractTYpeStyle" })(
  ContractTYpe
);
