export const styleSheet = () => ({
  dialog: {
    padding: "0px !important",
    paddingBottom: "20px !important",
    maxHeight: "75vh !important",
    paddingRight: "10px !important",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  projectTitle: {
    fontSize: "24px !important",
    color: "rgba(0, 0, 0, 0.87);",
    fontFamily: "AvenirNext !important",
    fontWeight: "500 !important",
    letterSpacing: "0.15px !important",
  },
  inputLabelArea: {
    marginBottom: "6px !important",
    fontWeight: "400 !important",
    color: "black !important",
  },
  modalHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    padding: "0px !important",
  },
  modalAction: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    alignItems: "center !important",
    gap: "12px !important",
  },
  field: {
    border: "none!important",
  },
  fieldError: {
    borderBottom: "1px solid #d32f2f!important",
  },
  textArea: {
    width: "100%!important",
    padding: "5px!important",
    height: "100px!important",
    border: "1px solid!important",
    borderColor: "#000000DE!important",
    borderRadius: "4px!important",
  },
  topfieldContainer: {
    marginTop: "1px!important",
  },
  createButton: {
    backgroundColor: "#0B0909!important",
    color: "#fff!important",
    minWidth: "120px",
    fontWeight: "400 !important",
  },
  draftButton: {
    color: "#0B0909 !important",
    fontWeight: "400 !important",
  },
  footerSection: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    marginTop: "20px !important",
  },
  footerSectionLeft: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "15px !important",
    "& .MuiInputBase-root": {
      height: "32px !important",
      width: "100px",
    },
  },
  footerSectionRight: {
    display: "flex !important",
    justifyContent: "flex-end!important",
    alignItems: "center !important",
    gap: "15px !important",
  },
  inputLabelWithToggle: {
    marginBottom: "6px !important",
    fontWeight: "400 !important",
    color: "black !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    gap: "15px !important",
  },
  clauseItem: {
    width: "100% !important",
    position: "relative",
  },
  clauseHeader: {
    backgroundColor: "#EEE !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    paddingLeft: "16px !important",
  },
  clauseHeading: {
    fontSize: "16px !important",
    color: "rgba(0, 0, 0, 0.87);",
    fontFamily: "AvenirNext !important",
    fontWeight: "500 !important",
  },
  fieldsArea: {
    padding: "20px !important",
    alignItems: "center !important",
  },
  fieldsTextItem: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "20px !important",
  },
  uploadedSection: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "16px !important",
    marginBottom: "16px !important",
  },
  attachmentItem: {
    position: "relative !important",
    "& img": {
      height: "140px !important",
      width: "110px !important",
      objectFit: "contain !important",
      border: "1px solid grey !important",
    },
  },
  attachmentICon: {
    width: "25px !important",
    height: "25px !important",
    backgroundColor: "black !important",
    borderRadius: "50% !important",
    color: "white !important",
    position: "absolute !important",
    right: "-10px !important",
    top: "-10px !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    outline: "5px solid white !important",
    cursor: "pointer !important",
  },
});
