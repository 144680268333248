import { createSlice } from "@reduxjs/toolkit";
import { AddAgentPoint } from "./addPoint";
import { getAgentPoints } from "./getPoints";
import { deleteAgentPoint } from "./deletePoint";
import { updateAgentPoint } from "./updatePoint";
import { getPointsGraph } from "./getPointsGraph";
import {
  getPointsLeaderBoards,
  getPointsLeaderBoardsPrevious,
} from "./getLeaderBoards";
import { getPointsPreviousRanking } from "./getPreviousRanking";
const initialState = {
  addPoint: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  pointsData: {
    success: false,
    allActivities: {},
    data: [],
    user: {},
    leaderboard: [],
    _metadata: {},
    isLoading: false,
    errMsg: null,
    isLimitReached: false,
  },
  deletePoint: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  updatePoint: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  graphStats: {
    success: false,
    data: undefined,
    isLoading: false,
    errMsg: null,
  },
  leaderBoards: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  leaderBoardsPrevious: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  previousRanking: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const AgentPoints = createSlice({
  name: "AgentPoints",
  initialState,
  extraReducers: {
    [AddAgentPoint.pending]: (state) => {
      state.addPoint.isLoading = true;
      state.addPoint.errMsg = null;
      state.addPoint.success = false;
    },
    [AddAgentPoint.fulfilled]: (state, action) => {
      state.addPoint.isLoading = false;
      state.addPoint.data = action.payload;
      state.addPoint.success = true;
      let newPoints = [...state.pointsData.data];
      state.pointsData.data = [action.payload, ...newPoints];
    },
    [AddAgentPoint.rejected]: (state, action) => {
      state.addPoint.isLoading = false;
      state.addPoint.success = false;
      state.addPoint.errMsg = action.payload;
    },
    [getAgentPoints.pending]: (state) => {
      state.pointsData.isLoading = true;
      state.pointsData.errMsg = null;
      state.pointsData.success = false;
    },
    [getAgentPoints.fulfilled]: (state, action) => {
      state.pointsData.isLoading = false;
      state.pointsData.success = true;
      if (action.payload?.pageNumber > 1) {
        state.pointsData.data = state.pointsData.data
          .concat(action.payload.data)
          .filter(
            (item, i, self) => self.findIndex((it) => it.id === item.id) == i
          );
      } else {
        state.pointsData.data =
          action?.payload?.data.filter(
            (item, i, self) => self.findIndex((it) => it.id === item.id) == i
          ) || [];
        state.pointsData._metadata = action.payload._metadata;
      }
      if (action.payload.data?.length) {
        state.pointsData.isLimitReached = false;
      } else {
        if (action.payload?.pageNumber > 1) {
          state.pointsData.isLimitReached = true;
        }
      }

      state.pointsData.allActivities = action.payload.allActivities;
      state.pointsData.leaderboard = action.payload.leaderboard;
      state.pointsData.user = action.payload.user;
    },
    [getAgentPoints.rejected]: (state, action) => {
      state.pointsData.isLoading = false;
      state.pointsData.success = false;
      state.pointsData.errMsg = action.payload;
    },
    // get graph data
    [getPointsGraph.pending]: (state) => {
      state.graphStats.isLoading = true;
      state.graphStats.errMsg = null;
      state.graphStats.success = false;
    },
    [getPointsGraph.fulfilled]: (state, action) => {
      state.graphStats.isLoading = false;
      state.graphStats.success = true;
      state.graphStats.data = action.payload;
    },
    [getPointsGraph.rejected]: (state, action) => {
      state.graphStats.isLoading = false;
      state.graphStats.success = false;
      state.graphStats.errMsg = action.payload;
    },
    // delete agent tab
    [deleteAgentPoint.pending]: (state) => {
      state.deletePoint.isLoading = true;
      state.deletePoint.errMsg = null;
      state.deletePoint.success = false;
    },
    [deleteAgentPoint.fulfilled]: (state, action) => {
      state.deletePoint.success = true;
      state.deletePoint.isLoading = false;
      state.deletePoint.data = action.payload;
      let newPoints = [...state.pointsData.data];
      let points = newPoints.find(
        (item) => item.id === action.payload.deletedId
      )?.point;
      state.pointsData.data = newPoints.filter(
        (item) => item.id !== action.payload.deletedId
      );
      state.pointsData._metadata.total_point =
        state.pointsData._metadata.total_point - points;
    },
    [deleteAgentPoint.rejected]: (state, action) => {
      state.deletePoint.isLoading = false;
      state.deletePoint.success = false;
      state.deletePoint.errMsg = action.payload;
    },
    // update agent point
    [updateAgentPoint.pending]: (state) => {
      state.updatePoint.isLoading = true;
      state.updatePoint.errMsg = null;
      state.updatePoint.success = false;
    },
    [updateAgentPoint.fulfilled]: (state, action) => {
      state.updatePoint.isLoading = false;
      state.updatePoint.data = action.payload;
      state.updatePoint.success = true;
      let newPoints = [...state.pointsData.data];
      let index = newPoints.findIndex((item) => item.id === action.payload.id);
      newPoints[index] = { ...newPoints[index], ...action.payload };
      state.pointsData.data = newPoints;
    },
    [updateAgentPoint.rejected]: (state, action) => {
      state.updatePoint.isLoading = false;
      state.updatePoint.success = false;
      state.updatePoint.errMsg = action.payload;
    },
    // leader board points
    [getPointsLeaderBoards.pending]: (state) => {
      state.leaderBoards.isLoading = true;
      state.leaderBoards.errMsg = null;
      state.leaderBoards.success = false;
    },
    [getPointsLeaderBoards.fulfilled]: (state, action) => {
      state.leaderBoards.isLoading = false;
      state.leaderBoards.data = action.payload;
      state.leaderBoards.success = true;
    },
    [getPointsLeaderBoards.rejected]: (state, action) => {
      state.leaderBoards.isLoading = false;
      state.leaderBoards.success = false;
      state.leaderBoards.errMsg = action.payload;
    },
    // leader board points previous month
    [getPointsLeaderBoardsPrevious.pending]: (state) => {
      state.leaderBoardsPrevious.isLoading = true;
      state.leaderBoardsPrevious.errMsg = null;
      state.leaderBoardsPrevious.success = false;
    },
    [getPointsLeaderBoardsPrevious.fulfilled]: (state, action) => {
      state.leaderBoardsPrevious.isLoading = false;
      state.leaderBoardsPrevious.data = action.payload;
      state.leaderBoardsPrevious.success = true;
    },
    [getPointsLeaderBoardsPrevious.rejected]: (state, action) => {
      state.leaderBoardsPrevious.isLoading = false;
      state.leaderBoardsPrevious.success = false;
      state.leaderBoardsPrevious.errMsg = action.payload;
    },
    // get previous ranking
    [getPointsPreviousRanking.pending]: (state) => {
      state.previousRanking.isLoading = true;
      state.previousRanking.errMsg = null;
      state.previousRanking.success = false;
    },
    [getPointsPreviousRanking.fulfilled]: (state, action) => {
      state.previousRanking.isLoading = false;
      state.previousRanking.data = {
        ...action.payload,
        monthlyRanking: action.payload?.monthlyRanking?.reverse(),
      };
      state.previousRanking.success = true;
    },
    [getPointsPreviousRanking.rejected]: (state, action) => {
      state.previousRanking.isLoading = false;
      state.previousRanking.success = false;
      state.previousRanking.errMsg = action.payload;
    },
  },
});
export default AgentPoints.reducer;
export * from "./addPoint";
export * from "./getPoints";
export * from "./deletePoint";
export * from "./updatePoint";
export * from "./getPointsGraph";
export * from "./getLeaderBoards";
export * from "./getPreviousRanking";
