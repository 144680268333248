import { uid } from "uid";
export const AdditionalClauses = [
  {
    title: "Require Disclosures within 3 days of binding",
    _id: `${uid()}-clauses`,
    field_key: "stipulate_disclosures",
    fields: [
      {
        type: "checkbox",
        label: "Sellers Property Disclosure",
        checked: false,
        cols: 12,
      },
      {
        type: "checkbox",
        label: "Community Associate Disclosure",
        checked: false,
        cols: 12,
      },
    ],
  },
  {
    title: "Buyer Will Cover Appraisal Gap",
    _id: `${uid()}-clauses`,
    fields: [
      {
        type: "textField",
        label: "Up to",
        startAdornment: true,
        value: "",
        cols: 12,
        placeholder: "0",
        width: "100px",
        field_key: "buyer_appr_gap_limit",
        fieldType: "number",
      },
    ],
  },
  {
    title: "Escalation Clause",
    _id: `${uid()}-clauses`,
    fields: [
      {
        type: "textField",
        label: "Beat Offers By",
        startAdornment: true,
        value: "",
        cols: 12,
        placeholder: "0",
        width: "120px",
        field_key: "escalation_clause_overage",
        labelWidth: "120px",
        fieldType: "number",
      },
      {
        type: "textField",
        label: "Up to Max Price",
        startAdornment: true,
        value: "",
        cols: 12,
        placeholder: "0",
        width: "120px",
        field_key: "escalation_clause_maxPrice",
        labelWidth: "120px",
        fieldType: "number",
      },
    ],
  },
  {
    title: "Temporary Occupancy by Seller",
    _id: `${uid()}-clauses`,
    fields: [
      {
        type: "textField",
        label: "",
        endLabel: "Days after Closing",
        value: "",
        cols: 12,
        placeholder: "0",
        width: "120px",
        field_key: "temp_occ_penalty",
        labelWidth: "10px",
        fieldType: "number",
      },
      {
        type: "textField",
        label: "",
        endLabel: "Per Day",
        startAdornment: true,
        value: "",
        cols: 12,
        placeholder: "0",
        width: "120px",
        field_key: "temp_occ_days",
        labelWidth: "10px",
        fieldType: "number",
      },
    ],
  },
  {
    title: "Seller to Pay Home Warranty",
    _id: `${uid()}-clauses`,
    fields: [
      {
        type: "textField",
        label: "Costs not to exceed",
        startAdornment: true,
        value: "",
        cols: 12,
        placeholder: "0",
        width: "100px",
        field_key: "seller_warranty_cost",
        fieldType: "number",
      },
    ],
  },
  {
    title: "Seller to Pay Closing Costs",
    _id: `${uid()}-clauses`,
    fields: [
      {
        type: "textField",
        label: "Costs not to exceed",
        startAdornment: true,
        value: "",
        cols: 12,
        placeholder: "0",
        width: "100px",
        field_key: "seller_closing_cost",
        fieldType: "number",
      },
    ],
  },
  {
    title: "Specify Common Items to Remain",
    _id: `${uid()}-clauses`,
    field_key: "common_items_remain",
    fields: [
      {
        type: "checkbox",
        label: "Washer and Dryer",
        checked: false,
        cols: 12,
      },
      {
        type: "checkbox",
        label: "Refrigerator",
        checked: false,
        cols: 12,
      },
      {
        type: "checkbox",
        label: "Other",
        checked: false,
        cols: 12,
      },
      {
        type: "textField",
        label: "",
        value: "",
        cols: 12,
        placeholder: "Specify...",
        id: "specify-other",
        visible: false,
        labelWidth: "0px",
      },
    ],
  },
  {
    title: "Make this a Backup Offer",
    _id: `${uid()}-clauses`,
    fields: [
      {
        type: "checkbox",
        label: "Incl. Backup Exhibit",
        checked: false,
        field_key: "backup_offer",
        cols: 12,
      },
    ],
  },
  {
    title: "Include Option Fee to Seller",
    _id: `${uid()}-clauses`,
    fields: [
      {
        type: "textField",
        label: "Amount",
        startAdornment: true,
        value: "",
        cols: 3,
        placeholder: "0",
        width: "120px",
        field_key: "seller_option_fee",
      },
      {
        type: "checkbox",
        label: "Applied towards purchase price",
        checked: false,
        field_key: "seller_option_applies",
        cols: 6,
      },
    ],
  },
  {
    title: "Include BBA with offer",
    _id: `${uid()}-clauses`,
    fields: [
      {
        type: "textField",
        label: "Agreement Term",
        value: "",
        cols: 12,
        placeholder: "0",
        width: "120px",
        field_key: "include_bba",
        labelEnd: "Days",
      },
    ],
  },
  {
    title: "Require Termite Letter/Bond",
    _id: `${uid()}-clauses`,
    fields: [
      {
        type: "checkbox",
        label: "Seller to provide clear termite letter",
        checked: false,
        field_key: "seller_termite_letter",
        cols: 12,
      },
      {
        type: "checkbox",
        label: "Seller to provide 1 year termite bond",
        field_key: "seller_termite_bond",
        checked: false,
        cols: 12,
      },
    ],
  },
  {
    title: "Include Home Sale Contingency",
    _id: `${uid()}-clauses`,
    fields: [
      {
        type: "textField",
        topLabel: "Address of Property to Sell",
        value: "",
        cols: 12,
        placeholder: "Enter Address",
        width: "100%",
        field_key: "homesale_contingency_address",
      },
      {
        type: "datePicker",
        label: "Contingency End Date",
        value: "",
        cols: 4,
        width: "100%",
        field_key: "homesale_contingency_date",
      },
    ],
  },
  {
    title: "Attach Proof of Funds/Prequal Letter",
    _id: `${uid()}-clauses`,
    fields: [
      {
        type: "attachments",
        uploadedAttachments: [],
        buttonText: "Upload Attachments",
        field_key: "attachment_1",
      },
    ],
  },
  {
    title: "Include Time Limit on Offer",
    _id: `${uid()}-clauses`,
    fields: [
      {
        type: "timePicker",
        label: "on",
        value: "",
        cols: 3,
        width: "100%",
        field_key: "limit_time",
        //moment(date).format("HH:mm");
      },
      {
        type: "datePicker",
        label: "",
        value: "",
        cols: 4,
        width: "100%",
        field_key: "limit_date",
      },
    ],
  },
];
