import React from "react";
import {
  Box,
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
function PostsListView(props) {
  const { classes, posts, storedUsers } = props;
  const navigate = useNavigate();
  return (
    <Card variant="outlined" className={classes.postsListView}>
      <List className={classes.postsListViewList} disablePadding>
        {posts.map((item) => {
          return (
            <ListItem
              onClick={() =>
                navigate("/knowledge-base/main/" + item?.id, { state: item })
              }
              key={item.id}
              secondaryAction={
                <Box className={classes.listViewAction}>
                  <Typography className={classes.listViewActionHeading}>
                    <span>Added on:</span> {moment(item?.createdAt).format("L")}
                  </Typography>
                  <Box className={classes.listViewActionBottom}>
                    {" "}
                    <Chip
                      sx={{
                        color: "",
                        backgroundColor:
                          item.type === "video" ? "#C5F8FF" : "#EBDCFF",
                        textTransform: "capitalize",
                      }}
                      size="small"
                      label={item.type}
                    />
                  </Box>
                </Box>
              }
              disablePadding
              divider
            >
              <ListItemButton
                sx={{ paddingTop: "14px", paddingBottom: "14px" }}
              >
                <ListItemAvatar sx={{ minWidth: "180px" }}>
                  <Avatar
                    alt={`Avatar n°${item.title}`}
                    src={item?.thumbnail}
                    variant="rounded"
                    sx={{
                      height: "90px",
                      width: "160px",
                      border: "0.5px solid rgba(0, 0, 0, 0.50) !important",
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={item?.title}
                  secondary={
                    item?.author_id
                      ? `By: ${
                          item?.author_id
                            ? storedUsers?.find(
                                (it) => it.id === item?.author_id
                              )?.full_name || ""
                            : ""
                        }`
                      : ""
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
}

export default PostsListView;
