import React, { useState, useEffect, Fragment } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { TextField, Grid, Autocomplete, Select, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewCategory,
  updateCategory,
} from "../../../../redux/knowledge/categories";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { getMarkets } from "../../../../redux/agents/addRecruitAgent";

function AddNewCategory(props) {
  const dispatch = useDispatch();
  let { open, setOpen, classes, editable, setEditable } = props;
  const [categoryDetail, setCategoryDetail] = useState({
    title: "",
    market_id: "",
    user_type: "All Users",
  });
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [errMsg, setErrMsg] = useState({});
  const addCategory = useSelector(
    (state) => state.knowledgeBase.categories.addCategory
  );
  const updateCategoryState = useSelector(
    (state) => state.knowledgeBase.categories.update
  );
  const markets = useSelector((state) => state.agentList.markets);
  useEffect(() => {
    if (editable?.id) {
      setCategoryDetail((preDet) => ({
        ...preDet,
        title: editable.title,
        market_id: editable?.market_id,
        user_type: editable?.user_type,
      }));
    }
  }, [editable?.id]);

  useEffect(() => {
    if (!markets || markets.length === 0) {
      dispatch(getMarkets());
    }
  }, []);

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully created the category for knowledge base",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };

  const handleUpdateSuccess = () => {
    setErrorAlert({
      errorMsg:
        "You have successfully updated  the category for knowledge base",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!categoryDetail?.title) {
      isFormValid = false;
      errorMsg["title"] = "Please enter the category title";
      setErrorAlert({
        errorMsg: "Please enter the category title",
        errorType: "warning",
        isOpen: true,
      });
    } else if (
      !categoryDetail?.market_id &&
      categoryDetail?.market_id !== null
    ) {
      isFormValid = false;
      errorMsg["market_id"] = "Please select company wide";
      setErrorAlert({
        errorMsg: "Please select company wide",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };

  const CreateCategory = () => {
    if (handleValidate()) {
      dispatch(
        createNewCategory({
          payload: {
            title: categoryDetail?.title,
            market_id: categoryDetail?.market_id || null,
            user_type: categoryDetail?.user_type || null,
          },
          handleSuccess,
          handleError,
        })
      );
    }
  };

  const UpdateCategory = () => {
    if (handleValidate()) {
      dispatch(
        updateCategory({
          id: editable.id,
          payload: {
            title: categoryDetail?.title,
            market_id: categoryDetail?.market_id || null,
            user_type: categoryDetail?.user_type || null,
          },
          handleError,
          handleUpdateSuccess,
        })
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCategoryDetail((preDetail) => ({
      ...preDetail,
      title: "",
      market_id: "",
      user_type: "All Users",
    }));
    setEditable(false);
  };
  const handleUpdateDetail = (field, value) => {
    setCategoryDetail((preDetail) => ({ ...preDetail, [field]: value }));
    setErrMsg({});
  };
  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{
          "& .MuiDialog-container": { mt: "30px" },
          "& .MuiPaper-root": { overflow: "visible" },
        }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <Grid container direction="row" spacing={1}>
          <Grid item lg={12} xs={12}>
            <DialogTitle
              className={classes.addItemModalHeading}
              id="alert-dialog-title"
            >
              {editable.id ? "Update" : "Create"} Category
            </DialogTitle>
          </Grid>
        </Grid>
        <DialogContent sx={{ width: "600px", minHeight: "40vh" }}>
          <Grid container direction="row" spacing={3}>
            <Grid item lg={12} xs={12} sx={{ zIndex: "1" }}>
              <FormControl fullWidth>
                <h4 className={classes.labels}>Title</h4>
                <TextField
                  onChange={(e) => handleUpdateDetail("title", e.target.value)}
                  value={categoryDetail.title}
                  fullWidth
                  classes={{ root: classes.detailInput }}
                  placeholder="Search or create tags"
                  variant="outlined"
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={6} sx={{ zIndex: "1" }}>
              <Autocomplete
                disablePortal
                id="Company-Wide-select-dropdown"
                value={
                  categoryDetail?.market_id
                    ? markets?.find(
                        (item) => item.id === categoryDetail?.market_id
                      )
                    : null
                }
                onChange={(event, value) =>
                  handleUpdateDetail("market_id", value?.id || null)
                }
                getOptionLabel={(option) => option.name || ""}
                options={
                  markets?.length
                    ? [{ id: null, name: "Company Wide" }, ...markets]
                    : []
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Company Wide"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!!errMsg.market_id} // Ensure error state is handled correctly
                    helperText={errMsg.market_id}
                    classes={{ root: classes.detailInput }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={6} xs={6} sx={{ zIndex: "1" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={categoryDetail?.user_type}
                onChange={(e) =>
                  handleUpdateDetail("user_type", e.target.value)
                }
                fullWidth
                size="small"
              >
                <MenuItem value={"All Users"}>All Users</MenuItem>
                <MenuItem value={"Agents"}>Agents</MenuItem>
                <MenuItem value={"Admin"}>Admin</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => handleClose(false)}
            sx={{
              color: "#000000",
            }}
            className={classes.btns}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => {
              if (!editable.id) {
                CreateCategory();
              } else {
                UpdateCategory();
              }
            }}
            classes={{ root: classes.AddInventoryButton }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={addCategory.isLoading || updateCategoryState?.isLoading}
          >
            {editable.id ? "Update" : "Save"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddNewCategoryStyle" })(
  AddNewCategory
);
