import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField, Autocomplete, Typography } from "@mui/material";
import { getData } from "../../../../utils/getData";
import { toast } from "react-toastify";

function SearchProperties(props) {
  let { setFormData } = props;
  const [options, setOptions] = useState([]);
  const [optionsMLS, setOptionsMLS] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermMLS, setSearchTermMLS] = useState("");
  const debounceTimeout = useRef(null);
  const debounceTimeoutMLS = useRef(null);

  const handleSearch = async (value) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}api/transactions/listings?pageNumber=1&search=${value}&pageSize=100&sortOrder=desc`;

    const result = await getData(url);
    if (result?.status === 200) {
      setOptions(result.data?.listings);
    } else {
      toast.error("Not found any record", { theme: "colored" });
    }
    setLoading(false);
  };

  useEffect(() => {
    // Clear the timeout if the effect re-runs (i.e., new input)
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout
    debounceTimeout.current = setTimeout(() => {
      if (searchTerm) {
        handleSearch(searchTerm);
      }
    }, 300); // Adjust the debounce delay here (e.g., 300ms)

    // Cleanup timeout on unmount or new search term
    return () => clearTimeout(debounceTimeout.current);
  }, [searchTerm]);

  /* 
  
https://testv2api.ourmethod.com/api/cma/mls-records?ListingId=4147707&ListingType=
  */

  const handleSearchMLS = async (value) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}api/cma/mls-records?ListingId=${value}`;
    const result = await getData(url);
    if (result?.status === 200) {
      setOptionsMLS([...result.data]);
    } else {
      toast.error("Not found any record", { theme: "colored" });
    }
    setLoading(false);
  };

  useEffect(() => {
    // Clear the timeout if the effect re-runs (i.e., new input)
    if (debounceTimeoutMLS.current) {
      clearTimeout(debounceTimeoutMLS.current);
    }

    // Set a new timeout
    debounceTimeoutMLS.current = setTimeout(() => {
      if (searchTermMLS) {
        handleSearchMLS(searchTermMLS);
      }
    }, 300); // Adjust the debounce delay here (e.g., 300ms)

    // Cleanup timeout on unmount or new search term
    return () => clearTimeout(debounceTimeoutMLS.current);
  }, [searchTermMLS]);

  return (
    <Grid container spacing={2.5}>
      <Grid item xs={7.5}>
        <Autocomplete
          options={options}
          fullWidth
          loading={loading}
          value={selectedProperty && searchTerm ? selectedProperty : null}
          onInputChange={(event, value) => {
            setSearchTerm(value); // Update search term to trigger debounce
          }}
          onChange={(e, value) => {
            setSelectedProperty(value);
            setFormData((preFormData) => ({
              ...preFormData,
              "Property Address": value?.property_address,
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              placeholder="Enter Address..."
            />
          )}
          getOptionLabel={(option) => option.property_address || ""}
          freeSolo
        />
      </Grid>
      <Grid item xs={0.5}>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40px",
          }}
        >
          OR
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          options={optionsMLS}
          fullWidth
          loading={loading}
          value={selectedProperty || null}
          onInputChange={(event, value) => {
            setSearchTermMLS(value); // Update search term to trigger debounce
          }}
          onChange={(e, value) => {
            setSelectedProperty(value);
            setFormData((preFormData) => ({
              ...preFormData,
              "Property Address": value?.UnparsedAddress || "",
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              placeholder="Enter MLS..."
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.UnparsedAddress}
            </li>
          )}
          filterOptions={(options) => options} // Always return all options
          getOptionLabel={(option) => option?.UnparsedAddress || ""}
          freeSolo
        />
      </Grid>
    </Grid>
  );
}

export default SearchProperties;
