import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";

import {
  handleResetSearchValue,
  getPropertyList,
} from "../../../redux/leads/savedSearches";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";

function AddCollection(props) {
  let { classes, handleUpdateView, setErrorAlert } = props;
  const collectionTitle = React.useRef(null);
  const dispatch = useDispatch();

  const propertyList = useSelector(
    (item) => item.leads.savedSearches.propertyList
  );
  const saveSearch = useSelector((item) => item.leads.savedSearches.saveSearch);

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "Your have successfully saved the search!",
      errorType: "success",
      isOpen: true,
    });
    dispatch(handleResetSearchValue());
    setTimeout(() => {
      handleUpdateView("search-list", "");
    }, 1500);
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSubmit = () => {
    if (collectionTitle?.current?.value) {
      dispatch(
        getPropertyList({
          name: collectionTitle?.current?.value,
          handleSuccess,
          handleError,
          type: "collection",
        })
      );
    } else {
      setErrorAlert({
        errorMsg: "Please enter the collection Title",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  return (
    <Box className={classes.saveSearchArea}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            placeholder="Enter Collection Name"
            variant="standard"
            fullWidth
            size="small"
            inputRef={collectionTitle}
            onChange={(e) => {
              if (e.target.value) {
                collectionTitle.current.value = e.target.value;
              }
            }}
          />
        </Grid>
      </Grid>

      <Box className={classes.saveButtonArea}>
        <Button
          onClick={() => {
            handleUpdateView("search-list", "");
            dispatch(handleResetSearchValue());
          }}
          color="inherit"
          variant="outlined"
          sx={{ fontSize: "13px" }}
        >
          Cancel
        </Button>

        <LoadingButton
          className={classes.searchSaveButton}
          onClick={() => handleSubmit()}
          loading={Boolean(propertyList.isLoading || saveSearch?.isLoading)}
          loadingPosition="start"
          fullWidth
        >
          Save Collection
        </LoadingButton>
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "AddCollectionStyle" })(
  AddCollection
);
