import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, ListItemText, Button, Chip, TextField, Box, CircularProgress, Backdrop, Typography } from "@mui/material";
import { styleSheet } from "../documents/style";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { uploadDocument, updateDocument, ResetDocumentState, generateMakeoverDoc } from "../../../../redux/listings/makeover";
import { sendMakeoverAgreementAsEnvelope } from "../../../../redux/listings/makeover/sendNotificaiton";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../../../utils/getData";
import ResponseAlert from "../../../../components/responseAlert";
import Dropzone from "react-dropzone";
import moment from "moment";
import dragIcon from "../../../../assets/dragIcon.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddInvoiceMakeOver from "../../modals/addInvoice";
import processingIcon from "../../../../assets/images/processingIcon.png";
import docSample from "../../../../assets/images/docSample.png";
import SendConfirmation from "../../modals/sendConfirmation";
const MakeoverDocuments = ({ classes, projectDetail }) => {
  const [openAddInvoice, setOpenAddInvoice] = useState(false);
  const [processing, setProcessing] = useState([]);
  const [sendOpen, setSendOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [makeoverDocument, setMakeoverDocument] = useState(null);
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const uploadDoc = useSelector((item) => item.listings.makeover.uploadDoc);
  const updateDoc = useSelector((item) => item.listings.makeover.updateDoc);
  const generateDoc = useSelector((item) => item.listings.makeover.generateDoc);
  useEffect(() => {
    if (uploadDoc.success && !uploadDoc.isLoading) {
      setErrorAlert({
        errorMsg: "You have successfully added the document",
        errorType: "success",
        isOpen: true,
      });
      dispatch(ResetDocumentState());
    } else if (!uploadDoc.isLoading && uploadDoc.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(uploadDoc.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [uploadDoc.success]);

  useEffect(() => {
    if (updateDoc.success && !updateDoc.isLoading) {
      setErrorAlert({
        errorMsg: updateDoc.success === "comment" ? "Comment is save successfully" : "You have successfully update the document status",
        errorType: "success",
        isOpen: true,
      });
      dispatch(ResetDocumentState());
    } else if (!updateDoc.isLoading && updateDoc.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(updateDoc.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [updateDoc.success]);

  const downLoadFile = async (src) => {
    const result = await getData(`${process.env.REACT_APP_BASE_URL}api/transactions/signed-url` + "?src=" + encodeURIComponent(src));
    if (result.status === 440) {
      window.location.href = "/login";
    }
    window.open(result.data, "_blank");
  };

  const handleFileChange = (file, data) => {
    setProcessing([...processing, data.id]);
    const Data = new FormData();
    Data.append("document", file);
    dispatch(
      uploadDocument({
        formData: Data,
        makeover_document_id: data.id,
        makeover_id: projectDetail.id,
      })
    );
  };
  const handleUpdateDoc = (data, index) => {
    dispatch(updateDocument({ ...data, type: "status" }));
  };

  const handleDragEnter = (index) => {
    document.getElementById(`listings-documents-drag-${index}`).style.display = "flex";
  };
  const handleDragLeave = (index) => {
    document.getElementById(`listings-documents-drag-${index}`).style.display = "none";
  };
  const handleFileChangeDrop = (Files, data) => {
    if (Files[0]?.type?.split("/")[1] === "pdf") {
      handleFileChange(Files[0], data);
    } else {
      setErrorAlert({
        errorMsg: "Only Pdf file allow",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  const handleSuccess = () => {
    setLoading(false);
    setSendOpen(false);
    setErrorAlert({
      errorMsg: "Sent Successfully",
      errorType: "error",
      isOpen: true,
    });
  };
  const handleError = (error) => {
    setLoading(false);
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleUpdateComment = (data, value) => {
    if (value) {
      dispatch(updateDocument({ ...data, comment: value, type: "comment" }));
    }
  };

  const handleConfirm = () => {
    setLoading(true);
    dispatch(
      sendMakeoverAgreementAsEnvelope({
        schema: {
          seller0_email: projectDetail?.seller0_email || undefined,
          seller1_email: projectDetail?.seller1_email || undefined,
          seller0_name: projectDetail?.seller0_first_name ? `${projectDetail?.seller0_first_name} ${projectDetail?.seller0_last_name}` : undefined,
          seller1_name: projectDetail?.seller1_first_name ? `${projectDetail?.seller1_first_name} ${projectDetail?.seller1_last_name}` : undefined,
        },
        id: makeoverDocument.id,
        handleSuccess,
        handleError,
      })
    );
  };

  return (
    <React.Fragment>
      <Card sx={{ backgroundColor: "white" }} className={classes.TransactionSalesRoot}>
        <CardHeader classes={{ title: classes.pageHeading }} title={"Makeover Documents"} />
        <CardContent>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Document Title</TableCell>
                  <TableCell align="left"> Document Status </TableCell>
                  <TableCell align="center">Doc Thumbnail</TableCell>
                  <TableCell align="center">Document Notes Fields</TableCell>
                  <TableCell align="center">Approved By </TableCell>
                  <TableCell align="center"> Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {console.log(projectDetail, "projectDetail")}
                {projectDetail?.makeover_documents?.length &&
                  projectDetail?.makeover_documents?.map((row, index) => (
                    <Dropzone
                      accept={".pdf"}
                      noClick={true}
                      noKeyboard={true}
                      onDragEnter={() => handleDragEnter(index)}
                      onDragLeave={() => handleDragLeave(index)}
                      disabled={row.status === "rejected" || row.status === "missing" ? false : true}
                      onDrop={(acceptedFiles) => {
                        handleFileChangeDrop(acceptedFiles, row);
                        handleDragLeave(index);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <TableRow
                          {...getRootProps()}
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            position: "relative",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Box id={`listings-documents-drag-${index}`} className={classes.dropzoneArea}>
                              <Box className={classes.dropzoneAreaInner}>
                                <img src={dragIcon} alt="dragIcon" style={{ width: "25px", marginRight: "10px" }} /> <Box>Drop files to upload.</Box>
                              </Box>
                            </Box>
                            {row.document_name}
                          </TableCell>
                          <TableCell align="left">
                            {row.status === "submitted" && (
                              <Chip
                                size="small"
                                sx={{
                                  backgroundColor: "#0B0909",
                                  color: "white",
                                  minWidth: "100px",
                                  textTransform: "capitalize",
                                }}
                                label={row.status}
                              />
                            )}
                            {row.status === "no_upload_req" && (
                              <Chip
                                size="small"
                                sx={{
                                  backgroundColor: "white",
                                  color: "#0B0909",
                                  minWidth: "100px",
                                  textTransform: "capitalize",
                                  border: "1px solid grey",
                                }}
                                label={"No Upload Req."}
                              />
                            )}
                            {row.status === "missing" && (
                              <Chip
                                size="small"
                                variant="outlined"
                                color="error"
                                sx={{
                                  minWidth: "100px",
                                  textTransform: "capitalize",
                                }}
                                label={row.status}
                              />
                            )}
                            {row.status === "approved" && (
                              <Chip
                                size="small"
                                sx={{
                                  backgroundColor: "#66BB6A",
                                  color: "white",
                                  minWidth: "100px",
                                  textTransform: "capitalize",
                                }}
                                label={row.status}
                              />
                            )}
                            {row.status === "rejected" && (
                              <Chip
                                size="small"
                                sx={{
                                  backgroundColor: "red",
                                  color: "white",
                                  minWidth: "100px",
                                  textTransform: "capitalize",
                                }}
                                label={row.status}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <center>
                              {row.file_src || row.signed_file_src ? (
                                <Box
                                  sx={{
                                    position: "relative",
                                    width: "60px",
                                    height: "78px",
                                    "&:hover": {
                                      "& .download-and-update-button": {
                                        display: "block",
                                      },
                                    },
                                  }}
                                >
                                  <img
                                    src={
                                      processing.includes(row.id) || projectDetail.processing
                                        ? processingIcon
                                        : row.file_src
                                        ? process.env.REACT_APP_DOCUMENT_IMG +
                                          encodeURIComponent((row.file_src || row.signed_file_src)?.replace(".pdf", ".png"))
                                        : docSample
                                    }
                                    width="60px"
                                    height="78px"
                                    style={{
                                      cursor: "pointer",
                                      border: "0.4px solid #000000",
                                      objectFit: "contain",
                                    }}
                                    alt="doc"
                                  />
                                  {row.status === "submitted" || row.status === "approved" ? <CheckCircleIcon className={classes.checkIcon} /> : null}
                                  <Box
                                    className="download-and-update-button"
                                    sx={{
                                      position: "absolute",
                                      top: "25px",
                                      right: "-15px",
                                      display: "none",
                                    }}
                                  >
                                    <Button
                                      className={classes.downloadButton}
                                      variant="contained"
                                      size="small"
                                      onClick={() => downLoadFile(row.signed_file_src ? row.signed_file_src : row.file_src)}
                                    >
                                      Download
                                    </Button>
                                  </Box>
                                  {row.file_src &&
                                    !row.signed_file_src &&
                                    row.document_name.includes("Makeover Agreement") &&
                                    !row.sign_envelope_id && (
                                      <Box
                                        className="download-and-update-button"
                                        sx={{
                                          position: "absolute",
                                          top: "55px",
                                          right: "-15px",
                                          display: "none",
                                        }}
                                      >
                                        <Button
                                          className={classes.downloadButton}
                                          variant="contained"
                                          size="small"
                                          onClick={() => {
                                            setMakeoverDocument(row);
                                            setSendOpen(true);
                                          }}
                                        >
                                          Send
                                        </Button>
                                      </Box>
                                    )}
                                </Box>
                              ) : (
                                <Chip
                                  size="small"
                                  variant="outlined"
                                  onClick={() => {
                                    dispatch(generateMakeoverDoc({ id: row.id }));
                                    setProcessing([...processing, row.id]);
                                  }}
                                  color="success"
                                  sx={{
                                    minWidth: "100px",
                                    cursor: "pointer",
                                    textTransform: "capitalize",
                                  }}
                                  label={"GENERATE"}
                                />
                              )}
                            </center>
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              multiline
                              onBlur={(e) => handleUpdateComment({ id: row.id }, e.target.value)}
                              rows={2}
                              id={`makeoverDocumentComment${index}`}
                              defaultValue={row.comment}
                              placeholder="label"
                            />
                          </TableCell>
                          <TableCell align="right">
                            {row.status !== "missing" && row.status === "approved" && (
                              <ListItemText primary={row.approved_by} secondary={row.approval_time ? moment(row.approval_time).format("L") : null} />
                            )}
                          </TableCell>
                          {row.sign_envelope_status === "complete" ? (
                            <TableCell align="center" sx={{ width: "200px" }}>
                              <ListItemText
                                classes={{ primary: classes.makeOverEnvelopeStatusHeading, secondary: classes.makeOverEnvelopeStatusSecondary }}
                                primary={row.sign_envelope_status.charAt(0).toUpperCase() + row.sign_envelope_status.slice(1)}
                                secondary={"Envelope Signed!"}
                              />
                            </TableCell>
                          ) : row.sign_envelope_status ? (
                            <TableCell align="center" sx={{ width: "200px" }}>
                              <ListItemText
                                classes={{ primary: classes.makeOverEnvelopeStatusHeading, secondary: classes.makeOverEnvelopeStatusSecondary }}
                                primary={row.sign_envelope_status.charAt(0).toUpperCase() + row.sign_envelope_status.slice(1)}
                                secondary={
                                  <>
                                    <p style={{ padding: 0, margin: 0 }}>
                                      {row.seller0_signed_envelope ? "Seller 1 Signed" : "Seller 1 Sign Pending"}{" "}
                                    </p>
                                    {projectDetail.seller1_email && (
                                      <p style={{ padding: 0, margin: 0 }}>
                                        {row.seller1_signed_envelope ? "Seller 2 Signed" : "Seller 2 Sign Pending"}
                                      </p>
                                    )}
                                  </>
                                }
                              />
                            </TableCell>
                          ) : (
                            <TableCell align="center" sx={{ width: "100px" }}>
                              {row.status === "submitted" && (
                                <Box>
                                  <Button
                                    onClick={() => handleUpdateDoc({ id: row.id, status: "approved" }, index)}
                                    variant="outlined"
                                    size="small"
                                    color="success"
                                    classes={{
                                      root: classes.documentTableButton,
                                    }}
                                  >
                                    Approve
                                  </Button>
                                  <br />
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="error"
                                    onClick={() => handleUpdateDoc({ id: row.id, status: "rejected" }, index)}
                                    classes={{
                                      root: classes.documentTableButton,
                                    }}
                                    sx={{ marginTop: "10px" }}
                                  >
                                    Reject
                                  </Button>
                                </Box>
                              )}{" "}
                              {(row.status === "missing" || row.status === "approved") && (
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="inherit"
                                  classes={{ root: classes.documentTableButton }}
                                  startIcon={<UploadFileIcon />}
                                  component="label"
                                >
                                  {row.status === "approved" ? "Replace" : "Upload"}
                                  <input onChange={(e) => handleFileChange(e.target.files[0], row)} hidden accept=".pdf" multiple type="file" />
                                </Button>
                              )}
                              {row.status === "rejected" && (
                                <Button
                                  sx={{ marginTop: "8px" }}
                                  variant="outlined"
                                  size="small"
                                  component="label"
                                  color="inherit"
                                  classes={{ root: classes.documentTableButton }}
                                >
                                  Re upload
                                  <input onChange={(e) => handleFileChange(e.target.files[0], row)} hidden accept=".pdf" multiple type="file" />
                                </Button>
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Dropzone>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Backdrop color="inherit" invisible={true} open={uploadDoc.isLoading || updateDoc.isLoading || generateDoc.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <AddInvoiceMakeOver open={openAddInvoice} setOpen={setOpenAddInvoice} />
      <SendConfirmation
        open={sendOpen}
        setOpen={setSendOpen}
        title="Send Makeover Agreement as Envelope"
        description="Are you sending the makeover agreement as an envelope to the following senders again via email? Please confirm before clicking the send button."
        makeOverDetail={projectDetail}
        handleConfirm={handleConfirm}
        isLoading={loading}
      />
    </React.Fragment>
  );
};

export default withStyles(styleSheet, {
  name: "MakeoverDocumentsStyle",
})(MakeoverDocuments);
