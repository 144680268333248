import React from "react";
import ReactLoading from "react-loading";

// Type = [blank, balls, bars, bubbles, cubes, cylon, spin, spinningBubbles, spokes]

const Loading = ({ type, color, height, width }) => (
  <ReactLoading
    type={type ? type : "spin"}
    color={color ? color : "#b5b5c3"}
    height={height ? height : 64}
    width={width ? width : 64}
  />
);

export default Loading;
