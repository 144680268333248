import React, { createContext, useReducer, useEffect } from "react";
import { getData } from "../../utils/getData";
import { Device } from "@twilio/voice-sdk";
import { useLocation, useParams } from "react-router-dom";
import { getRecentCalls } from "../../redux/calls/getRecentCalls";
import { useDispatch } from "react-redux";
import axios from "axios";
const SET_ADMIN_DETAIL = "SET_ADMIN_DETAIL";
const SET_DEVICE = "SET_DEVICE";
const SET_INCOMING_CALL = "SET_INCOMING_CALL";
const SET_INCOMING_CALL_NAME = "SET_INCOMING_CALL_NAME";

const SET_OUT_GOING_CALL = "SET_OUT_GOING_CALL";
const SET_CALL_TIME = "SET_CALL_TIME";
const SET_ACTIVE = "SET_ACTIVE";
const SET_DISPLAY_ON_CALL_PANEL = "SET_DISPLAY_ON_CALL_PANEL";
const SET_DISPLAY_LIVE_CHAT_PANEL = "SET_DISPLAY_LIVE_CHAT_PANEL";
const SET_MUTE = "SET_MUTE";
const SET_OUT_GOING_USER = "HANDLE_COUNTRY_INFO";
const SET_MISSED_CALL_FLAG = "SET_MISSED_CALL_FLAG";
const SET_ENQUEUED = "SET_ENQUEUED";
const SET_CALL_ENQUEUED = "SET_CALL_ENQUEUED";
const SET_OUTGOING_CALL_NAME = "SET_OUTGOING_CALL_NAME";

const initialState = {
  adminDetail: {},
  device: null,
  incomingCall: null,
  secondIncomingCall: null,
  outgoingCall: null,
  callTime: 0,
  isActive: false,
  displayOnCallPanel: false,
  displayLiveChatPanel: false,
  mute: false,
  enqueued: false,
  enqueuedCall: null,
  selectedOutgoingUser: null,
  missedCallFlag: false,
  incomingCallerName: "",
};

const checkNumberLookUpInMPAndSaveDetails = async (number, call_id) => {
  try {
    let response = await axios.post(process.env.REACT_APP_TWILIO_SERVER + "number-look-up-mp", { number: number, call_id: call_id });
    return response.data.data;
  } catch (error) {
    return null;
  }
};

const sendLogsToBackendAndSave = async (body) => {
  try {
    let response = await axios.post(process.env.REACT_APP_BASE_URL + "api/logs/twilio-client-logs", body);
    return response.data.data;
  } catch (error) {
    console.log("Error in saving logs", error);
    return null;
  }
};

const appReducer = (state, action) => {
  switch (action.type) {
    case SET_DEVICE:
      return {
        ...state,
        device: action.payload,
      };
    case SET_ADMIN_DETAIL:
      return {
        ...state,
        adminDetail: action.payload,
      };
    case SET_INCOMING_CALL: {
      // when incoming calls set to null (or after calls end)
      if (!action.payload.call) {
        /**  if two calls in progress we need to check which one we have set to null so compare the outboundConnectionId ids with 
      saved incoming call **/
        if (state.incomingCall && state.incomingCall.outboundConnectionId === action.payload.prevStatusCall.outboundConnectionId) {
          return {
            ...state,
            incomingCall: null,
          };
        } else if (state.secondIncomingCall && state.secondIncomingCall.outboundConnectionId === action.payload.prevStatusCall.outboundConnectionId) {
          /**  if two calls in progress we need to check which one we have set to null so compare the outboundConnectionId ids with 
      saved second incoming call **/
          return {
            ...state,
            secondIncomingCall: null,
          };
        }
      }
      // when incoming calls intialized or accept
      else {
        /**  if there is not any call (incomingCall, enqueuedCall, outgoingCall, secondIncomingCall) saved in our state
        then treated as first call or update if first call outboundConnectionId is equal to call passed in params **/
        if (
          (!state.incomingCall && !state.enqueuedCall && !state.outgoingCall && !state.secondIncomingCall) ||
          (state.incomingCall && action.payload.call.outboundConnectionId === state.incomingCall.outboundConnectionId)
        ) {
          if (action.payload.event === "accept") {
            console.log("First Call Accepted>>>>>>>>>>>>>>>>>>>>>");
            // state.device.updateOptions({
            //   sounds: {
            //     incoming: "https://method-platform.s3.us-east-1.amazonaws.com/phone-configuration/audios/call%20disconnect.mp3",
            //     disconnect: "https://method-platform.s3.us-east-1.amazonaws.com/phone-configuration/audios/call%20connect.mp3",
            //     outgoing: "https://method-platform.s3.us-east-1.amazonaws.com/phone-configuration/audios/call%20disconnect.mp3",
            //   },
            // });
          }
          return {
            ...state,
            incomingCall: action.payload.call,
          };
        } else {
          /**  if we cancel/end the first call then we treate the second call as first  **/
          if (!state.incomingCall && !state.outgoingCall) {
            console.log("dscdsfdsfs");
            return {
              ...state,
              incomingCall: action.payload.call,
              secondIncomingCall: null,
            };
          } else {
            /**  else save the call obj in second call and use for the UI  **/
            return {
              ...state,
              secondIncomingCall: action.payload.call,
            };
          }
        }
      }

      // return {
      //   ...state,
      //   incomingCall: action.payload.call,
      // };
    }
    case SET_INCOMING_CALL_NAME: {
      if (state.incomingCall) {
        return {
          ...state,
          incomingCallerName: action.payload.callerName,
          incomingCallerLeadId: action.payload.lead_id,
          incomingCallerRealtorAgentId: action.payload.realtor_agent_id,
        };
      } else if (state.secondIncomingCall) {
        return {
          ...state,
          secondIncomingCallerName: action.payload.callerName,
          secondIncomingCallerLeadId: action.payload.lead_id,
          secondIncomingCallerRealtorAgentId: action.payload.realtor_agent_id,
        };
      }
    }
    case SET_MISSED_CALL_FLAG:
      return {
        ...state,
        missedCallFlag: action.payload,
      };
    case SET_OUT_GOING_CALL: {
      if (action.payload.event === "accept") {
        console.log("First Call Accepted>>>>>>>>>>>>>>>>>>>>>");
      }
      return {
        ...state,
        outgoingCall: action.payload.call,
      };
    }
    case SET_CALL_TIME:
      return {
        ...state,
        callTime: action.payload === 0 ? 0 : state.callTime + action.payload,
      };
    case SET_ACTIVE:
      return {
        ...state,
        isActive: action.payload,
      };
    case SET_DISPLAY_LIVE_CHAT_PANEL:
      return {
        ...state,
        displayLiveChatPanel: action.payload,
      };
    case SET_DISPLAY_ON_CALL_PANEL:
      return {
        ...state,
        displayOnCallPanel: action.payload,
      };
    case SET_MUTE:
      return {
        ...state,
        mute: action.payload,
      };
    case SET_ENQUEUED:
      return {
        ...state,
        enqueued: action.payload,
      };
    case SET_CALL_ENQUEUED:
      return {
        ...state,
        enqueuedCall: action.payload,
        enqueued: action.payload ? true : false,
      };

    case SET_OUT_GOING_USER:
      return {
        ...state,
        selectedOutgoingUser: action.payload,
      };

    default:
      return state;
  }
};
export const AppContext = createContext(initialState);

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const location = useLocation();
  const dispatchFn = useDispatch();
  function setAdminDetail(payload) {
    dispatch({ type: SET_ADMIN_DETAIL, payload: payload });
  }
  function setDevice(payload) {
    dispatch({ type: SET_DEVICE, payload: payload });
  }
  function setIncomingCall(payload) {
    dispatch({ type: SET_INCOMING_CALL, payload: payload });
  }

  function setIncomingCallName(payload) {
    dispatch({ type: SET_INCOMING_CALL_NAME, payload: payload });
  }
  function setOutgoingCallName(payload) {
    dispatch({ type: SET_OUTGOING_CALL_NAME, payload: payload });
  }
  function setMissedCallFlag(payload) {
    dispatch({ type: SET_MISSED_CALL_FLAG, payload: payload });
  }
  function setOutgoingCall(payload) {
    dispatch({ type: SET_OUT_GOING_CALL, payload: payload });
  }

  function setCallTime(payload) {
    dispatch({ type: SET_CALL_TIME, payload: payload });
  }
  function setIsActive(payload) {
    dispatch({ type: SET_ACTIVE, payload: payload });
  }
  function setDisplayOnCallPanel(payload) {
    dispatch({ type: SET_DISPLAY_ON_CALL_PANEL, payload: payload });
  }
  function setDisplayLiveChatPanel(payload) {
    dispatch({ type: SET_DISPLAY_LIVE_CHAT_PANEL, payload: payload });
  }
  function setMute(payload) {
    dispatch({ type: SET_MUTE, payload: payload });
  }
  function setCallEnqueue(payload) {
    dispatch({ type: SET_CALL_ENQUEUED, payload: payload });
  }

  function setEnqueue(payload) {
    dispatch({ type: SET_ENQUEUED, payload: payload });
  }

  function setSelectedOutgoingUser(payload) {
    dispatch({ type: SET_OUT_GOING_USER, payload: payload });
  }

  const setUpDevice = async (adminDetail) => {
    const result = await getData(
      `${process.env.REACT_APP_TWILIO_SERVER}token?extension_name=${adminDetail?.twilio_call_extension?.name}&extension_id=${adminDetail?.twilio_call_extension?.id}`
    );
    const newDevice = new Device(result.token, {
      sounds: {
        disconnect: "https://method-platform.s3.us-east-1.amazonaws.com/phone-configuration/audios/call%20connect.mp3",
        outgoing: "https://method-platform.s3.us-east-1.amazonaws.com/phone-configuration/audios/call%20disconnect.mp3",
      },
      logLevel: 1,
      allowIncomingWhileBusy: true,
    });
    newDevice.register();
    newDevice.on("registered", (twilioError, call) => {
      console.log("registered: ", twilioError);
    });
    newDevice.on("error", (twilioError, call) => {
      console.log("An error has occurred: ", twilioError);
    });
    newDevice.audio.on("", (twilioError, call) => {
      console.log("An error has occurred: ", twilioError, call);
    });
    newDevice.on("incoming", async (call) => {
      console.log(call);
      setMissedCallFlag(true);
      setDisplayLiveChatPanel(false);
      call.on("accept", (call) => {
        setIncomingCall({ call, event: "accept" });
        setIsActive(true);
      });
      call.on("cancel", () => {
        setDisplayOnCallPanel(false);
        setIncomingCall({ call: null, prevStatusCall: call });
        setIsActive(false);
        setCallTime(0);
        setIncomingCallName({ callerName: "", lead_id: "", realtor_agent_id: "" });
        dispatch(
          getRecentCalls({
            id: adminDetail.twilio_call_extension.id,
            offset: 0,
          })
        );
      });
      call.on("error", (error) => {
        console.log("An error has occurred: ", error);

        setIncomingCallName({ callerName: "", lead_id: "", realtor_agent_id: "" });
        sendLogsToBackendAndSave({
          message: JSON.stringify(error),
          call_type: "incoming",
          from_number: call?.parameters?.From,
          to_number: call?.parameters?.To,
        });
      });
      call.on("disconnect", (call) => {
        setDisplayOnCallPanel(false);
        setIncomingCall({ call: null, prevStatusCall: call, event: "disconnect" });
        setIsActive(false);
        setIncomingCallName({ callerName: "", lead_id: "", realtor_agent_id: "" });
        setCallTime(0);
        dispatch(
          getRecentCalls({
            id: adminDetail.twilio_call_extension.id,
            offset: 0,
          })
        );
      });
      call.on("reject", () => {
        setDisplayOnCallPanel(false);
        console.log("The call was rejected.");
        setIncomingCall({ call: null, prevStatusCall: call });
        setIsActive(false);
        setCallTime(0);
        setIncomingCallName({ callerName: "", lead_id: "", realtor_agent_id: "" });

        dispatch(
          getRecentCalls({
            id: adminDetail.twilio_call_extension.id,
            offset: 0,
          })
        );
      });
      call.on("reconnected", () => {
        console.log("The call has regained connectivity.");
      });

      setIncomingCall({ call });
      let data = await checkNumberLookUpInMPAndSaveDetails(call.customParameters.get("from"), call.customParameters.get("call_id"));
      if (data) {
        setIncomingCallName({ callerName: data.name, lead_id: data.lead_id, realtor_agent_id: data.realtor_agent_id });
      } else {
        console.log(call);
        setIncomingCallName({ callerName: call.customParameters.get("name") });
      }
    });
    newDevice.on("tokenWillExpire", async () => {
      let updatedToken = await getData(
        `${process.env.REACT_APP_TWILIO_SERVER}token?extension_name=${adminDetail?.twilio_call_extension?.name}&extension_id=${adminDetail?.twilio_call_extension?.id}`
      );
      newDevice.updateToken(updatedToken.token);
      setDevice(newDevice);
    });
    setDevice(newDevice);
  };

  const setCallOnMute = async () => {
    let { incomingCall, outgoingCall, mute } = state;
    let muted = !mute;
    if (incomingCall) {
      incomingCall.mute(muted);
      setMute(muted);
    } else if (outgoingCall) {
      outgoingCall.mute(muted);
      setMute(muted);
    }
  };

  const enqueueCall = async (call_id) => {
    const result = await getData(`${process.env.REACT_APP_TWILIO_SERVER}enqueue-call/${call_id}`);
    if (result.status === 200) {
      console.log(result);
      setCallEnqueue(result.data);
      setEnqueue(true);
      return result.data;
    } else {
      return null;
    }
  };

  const dialNumber = async (number, id, name, realtor_agent_id, lead_id) => {
    console.log(number, id, name, realtor_agent_id, lead_id);
    let { adminDetail, device } = state;
    let From = adminDetail?.twilio_call_extension?.direct_number;
    let params = {
      To: number,
      From: From,
      FromNumber: From,
      outboundcall: "true",
      extension_id: adminDetail?.twilio_call_extension?.id,
      createdBy: adminDetail?.id,
    };
    if (realtor_agent_id) {
      params.realtor_agent_id = realtor_agent_id;
    }
    if (lead_id) {
      params.lead_id = lead_id;
    }
    params.userId = adminDetail?.id;
    if (name) {
      params.CallerName = name;
      params.tempName = name;
    }

    const call = await device.connect({
      params: params,
    });
    setOutGoingCallState(call);
    setDisplayLiveChatPanel(false);
  };
  const setOutGoingCallState = async (call) => {
    setOutgoingCall({ call: null });
    setIsActive(true);
    setMute(false);
    setOutgoingCall({ call: call });
    // console.log(call);
    // let data = await checkNumberLookUpInMPAndSaveDetails(call.customParameters.get("To"), call.customParameters.get("call_id"));
    // if (data) {
    //   setOutgoingCallName({ callerName: data.name, lead_id: data.lead_id, realtor_agent_id: data.realtor_agent_id });
    // }

    call.on("disconnect", (nest_call) => {
      setDisplayOnCallPanel(false);
      console.log("disconnect");
      setOutgoingCall({ call: null });
      setIsActive(false);
      setMute(false);
      setSelectedOutgoingUser(null);
      setCallTime(0);
      dispatch(
        getRecentCalls({
          id: state.adminDetail.twilio_call_extension.id,
          offset: 0,
        })
      );
    });
    call.on("accept", (nest_call) => {
      setOutgoingCall({ call: nest_call, event: "accept" });
    });
    call.on("error", (error) => {
      console.log("An error has occurred: ", error);
      sendLogsToBackendAndSave({
        message: JSON.stringify(error),
        call_type: "outgoing",
        from_number: call?.parameters?.From,
        to_number: call?.parameters?.To,
      });
    });
    call.on("cancel", (nest_call) => {
      setDisplayOnCallPanel(false);
      setOutgoingCall({ call: null });
      setIsActive(false);
      setMute(false);
      setSelectedOutgoingUser(null);
      setCallTime(0);
    });
  };

  useEffect(() => {
    let interval = null;
    if (state.isActive) {
      interval = setInterval(() => {
        setCallTime(1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [state.isActive]);

  // reset optons when there is no active call
  useEffect(() => {
    if (state.device && !state.incomingCall && !state.secondIncomingCall && !state.enqueuedCall && !state.outgoingCall) {
      console.log("Both Calls Ends >>>>>>>>>>>>>>>>>>>>>");
      state.device.updateOptions({
        sounds: {
          disconnect: "https://method-platform.s3.us-east-1.amazonaws.com/phone-configuration/audios/call%20connect.mp3",
          outgoing: "https://method-platform.s3.us-east-1.amazonaws.com/phone-configuration/audios/call%20disconnect.mp3",
        },
      });
    }
  }, [state.incomingCall, state.secondIncomingCall, state.enqueuedCall, state.outgoingCall]);

  const dialEnqueuedCall = async (callDequ) => {
    let { device, adminDetail } = state;
    let params = {
      from: callDequ.fromNumber,
      to: callDequ.toNumber,
      dequeueCall: true,
      isIncoming: callDequ.isIncoming,
      name: callDequ.name,
      extension_id: callDequ.extension_id,
      createdBy: callDequ.createdById || null,
      userId: callDequ.userId || null,
      call_id: callDequ.callerSID,
      prefix_label: callDequ.number_prefix_label,
    };
    const call = await device.connect({
      params: params,
    });
    call.on("cancel", () => {
      setDisplayOnCallPanel(false);
      setIncomingCall({ call: null, prevStatusCall: call });
      setIsActive(false);
      setCallTime(0);
      dispatch(
        getRecentCalls({
          id: adminDetail.twilio_call_extension.id,
          offset: 0,
        })
      );
    });
    call.on("error", (error) => {
      console.log("An error has occurred: ", error);
      sendLogsToBackendAndSave({
        message: JSON.stringify(error),
        call_type: "enqueue",
        from_number: callDequ?.fromNumber,
        to_number: callDequ?.toNumber,
      });
    });
    call.on("disconnect", () => {
      setDisplayOnCallPanel(false);
      setIncomingCall({ call: null, prevStatusCall: call });
      setIsActive(false);
      setCallTime(0);
      dispatch(
        getRecentCalls({
          id: adminDetail.twilio_call_extension.id,
          offset: 0,
        })
      );
    });
    call.on("reject", () => {
      setDisplayOnCallPanel(false);
      console.log("The call was rejected.");
      setIncomingCall({ call: null, prevStatusCall: call });
      setIsActive(false);
      setCallTime(0);
      dispatch(
        getRecentCalls({
          id: adminDetail.twilio_call_extension.id,
          offset: 0,
        })
      );
    });
    call.on("reconnected", () => {
      console.log("The call has regained connectivity.");
    });
    setIncomingCall({ call });
    setDisplayLiveChatPanel(false);
  };

  useEffect(() => {
    if (!state.incomingCall && !state.secondIncomingCall) {
      if (state.enqueuedCall) {
        dialEnqueuedCall(state.enqueuedCall);
        setCallEnqueue(null);
      }
    }
  }, [state.incomingCall]);

  return (
    <AppContext.Provider
      value={{
        device: state.device,
        incomingCall: state.incomingCall,
        incomingCallerName: state.incomingCallerName,
        incomingCallerLeadId: state.incomingCallerLeadId,
        incomingCallerRealtorAgentId: state.incomingCallerRealtorAgentId,
        secondIncomingCallerName: state.secondIncomingCallerName,
        secondIncomingCallerLeadId: state.secondIncomingCallerLeadId,
        secondIncomingCallerRealtorAgentId: state.secondIncomingCallerRealtorAgentId,
        outgoingCall: state.outgoingCall,
        secondIncomingCall: state.secondIncomingCall,
        callTime: state.callTime,
        isActive: state.isActive,
        displayOnCallPanel: state.displayOnCallPanel,
        displayLiveChatPanel: state.displayLiveChatPanel,
        mute: state.mute,
        selectedOutgoingUser: state.selectedOutgoingUser,
        adminDetail: state.adminDetail,
        missedCallFlag: state.missedCallFlag,
        enqueuedCall: state.enqueuedCall,
        setDevice,
        setIncomingCall,
        setOutgoingCall,
        setCallTime,
        setIsActive,
        setDisplayLiveChatPanel,
        setDisplayOnCallPanel,
        setMute,
        setSelectedOutgoingUser,
        setAdminDetail,
        setUpDevice,
        setCallOnMute,
        enqueueCall,
        dialNumber,
        setMissedCallFlag,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
