import React, { useEffect, useState, memo } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import SavedSearchList from "./searchList.jsx";
import SaveSearch from "./saveSearch.jsx";
import {
  getLeadSavedSearches,
  handleUpdateActiveSite,
  handleSelectAccount,
} from "../../../redux/leads/savedSearches";
import { useDispatch, useSelector } from "react-redux";
import momentTZ from "moment-timezone";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import ResponseAlert from "../../../components/responseAlert";
import CreateAccount from "./createAccount.jsx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getSearchSuggestions } from "../../../redux/leads/savedSearches/getSuggestions.js";
import { getLastActivities } from "../../../redux/leads/savedSearches/getLastActivities.js";
import sendGridIcon from "../../../assets/images/sendGridIcon.png";
import awsIcon from "../../../assets/images/awsIcon.png";
import mailGunIcon from "../../../assets/images/mailGunIcon.png";
import AddCollection from "./saveCollection.jsx";
import UpdateCollection from "./updateCollection.jsx";
function SavedSearches(props) {
  let { classes, userDetail, disableIframe } = props;
  const [activeView, setActiveView] = useState("search-list");
  const [parentWidth, setParentWidth] = useState(342);
  const [actionType, setActionType] = useState("");
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const searchList = useSelector((item) => item.leads.savedSearches.searchList);
  const selectedAccount = useSelector(
    (item) => item.leads.savedSearches.selectedAccount
  );
  const lastActivities = useSelector(
    (item) => item.leads.savedSearches.lastActivities
  );
  const adminAuth = useSelector((state) => state.adminAuth);

  useEffect(() => {
    // Parse the query parameters from the URL
    const params = new URLSearchParams(location.search);
    const context = params.get("context");
    const signature = params.get("signature");
    if (context && signature && !disableIframe) {
      dispatch(getLeadSavedSearches({ context, signature }));
    }

    // console.log(atob(context), "atob(context);");
  }, [location.search]);

  useEffect(() => {
    if (userDetail?.id && disableIframe && userDetail.id === Number(id)) {
      let obj = {
        context: "person",
        account: {
          secret_token:
            window.location.host === "app.ourmethod.com"
              ? process.env.REACT_APP_MIDX_ACCOUNT_SECRET_TOKEN_PROD
              : process.env.REACT_APP_MIDX_ACCOUNT_SECRET_TOKEN,
        },
        user: {
          id: adminAuth?.adminDetail?.id,
          name:
            adminAuth?.adminDetail?.first_name +
            " " +
            adminAuth?.adminDetail?.last_name,
          email: adminAuth?.adminDetail?.email,
        },
        person: {
          id: userDetail?.id,
          firstName: userDetail?.firstName,
          lastName: userDetail?.lastName,
          phones: userDetail?.phones?.map((item) => {
            if (item.value) {
              return item;
            }
          }),
          emails: userDetail?.emails,
          stage: { id: userDetail?.stageId, name: userDetail?.stage },
        },
      };
      let jsonString = JSON.stringify(obj);
      // Convert the JSON string to a Base64 string
      try {
        const latin1Regex = /[^\x00-\xFF]/g;

        let base64String = btoa(jsonString.replace(latin1Regex, ""));
        dispatch(
          getLeadSavedSearches({
            context: base64String,
            type: "MP",
          })
        );
      } catch (error) {
        console.log(error, "error");
      }
    }
  }, [userDetail?.id]);

  const handleUpdateView = (viewType, actionType) => {
    if (viewType) {
      setActiveView(viewType);
    }
    if (actionType) {
      setActionType(actionType);
    }
  };

  useEffect(() => {
    const parentElement = document.querySelector(".parent-element");

    if (parentElement) {
      const parentWidth = parentElement.clientWidth;
      setParentWidth(parentWidth || 342);
    }
    const handleResize = () => {
      if (parentElement) {
        const parentWidth = parentElement.clientWidth;
        setParentWidth(parentWidth || 342);
      }
    };
    // Add event listener on component mount
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChangeSite = (data) => {
    setActiveView("search-list");
    dispatch(handleUpdateActiveSite({ ...data }));
    dispatch(getSearchSuggestions({ dataset: data?.site_id?.dataset }));
    dispatch(
      getLastActivities({ token: data?.token, site_id: data?.site_id?._id })
    );
  };
  return (
    <Box
      sx={{
        height: searchList?.isComingSoon
          ? "45px"
          : disableIframe
          ? "600px"
          : "100vh",
        overflow: disableIframe ? "auto !important" : "",
        display: "block !important",
      }}
      className={`${classes.savedSearchArea} parent-element`}
    >
      {searchList?.isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: disableIframe ? "600px" : "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : searchList?.isComingSoon ? (
        <Box>
          <Typography className={classes.comingSoonHeading}>
            {" "}
            Coming Soon!{" "}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            padding: disableIframe ? "10px" : "0px",
            paddingRight: disableIframe ? "10px" : "5px",
          }}
        >
          <Card className={classes.topSearchCard}>
            <Typography
              component={"a"}
              target="_black"
              href={`${searchList?.data?.shortQuickLoginUrl}`}
              className={classes.searchHeading}
            >
              {searchList?.data?.firstName ||
                searchList?.meta?.payload?.person?.firstName}{" "}
              {searchList?.data?.lastName ||
                searchList?.meta?.payload?.person?.lastName}
            </Typography>
            <Box className={classes.SearchSubHeader}>
              <EmailRoundedIcon
                sx={{ color: "#0B0909", fontSize: "15px", mt: "2px" }}
              />
              {searchList?.meta?.emails?.filter((it) => it?.value)?.length ? (
                <TextField
                  defaultValue={
                    selectedAccount?.value ||
                    searchList?.meta?.emails?.find(
                      (it) => it?.value === searchList?.data.email
                    )?.value
                  }
                  select
                  size="small"
                  fullWidth
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "2px 5px",
                      fontSize: "12px",
                    },
                  }}
                >
                  {searchList?.meta?.emails
                    ?.filter((it) => it?.value)
                    ?.map((item) => (
                      <MenuItem
                        onClick={() => dispatch(handleSelectAccount(item))}
                        value={item?.value}
                      >
                        {item?.value}
                      </MenuItem>
                    ))}
                </TextField>
              ) : (
                <Typography className={classes.SearchSubHeaderContent}>
                  {searchList?.data?.email ||
                    searchList?.meta?.payload?.person?.emails?.find(
                      (item) => item.isPrimary
                    )?.value}
                </Typography>
              )}
            </Box>

            {searchList.isUserFound ? (
              <Box className={classes.SearchSubHeader}>
                <Typography className={classes.SearchSubHeaderContent}>
                  {searchList?.data?.site_id?.site_domain}
                </Typography>
              </Box>
            ) : (
              <Typography className={classes.accountNotFound}>
                No Account Found
              </Typography>
            )}
            {searchList.isUserFound ? (
              <Box
                className={
                  parentWidth >= 390
                    ? classes.lastVisitArea
                    : classes.lastVisitAreaMb
                }
              >
                <Box>
                  {lastActivities?.lastVisit ? (
                    <Typography className={classes.lastVisitItem}>
                      <span>Last Visit:</span>{" "}
                      {momentTZ
                        .tz(
                          lastActivities?.lastVisit?.updatedAt ||
                            lastActivities?.lastVisit,
                          process.env.REACT_APP_TIMEZONE
                        )
                        .format("L")}{" "}
                      {momentTZ
                        .tz(
                          lastActivities?.lastVisit?.updatedAt ||
                            lastActivities?.lastVisit,
                          process.env.REACT_APP_TIMEZONE
                        )
                        .format("LT")}
                    </Typography>
                  ) : null}
                  {lastActivities?.lastEmailOpenedAt ? (
                    <Typography className={classes.lastVisitItem}>
                      <span>Last Open:</span>{" "}
                      {momentTZ
                        .tz(
                          lastActivities?.lastEmailOpenedAt?.updatedAt ||
                            lastActivities?.lastEmailOpenedAt,
                          process.env.REACT_APP_TIMEZONE
                        )
                        .format("L")}{" "}
                      {momentTZ
                        .tz(
                          lastActivities?.lastEmailOpenedAt?.updatedAt ||
                            lastActivities?.lastEmailOpenedAt,
                          process.env.REACT_APP_TIMEZONE
                        )
                        .format("LT")}
                    </Typography>
                  ) : null}
                  {lastActivities?.lastEmailSentAt ? (
                    <Typography className={classes.lastVisitItem}>
                      <span>Last Sent:</span>{" "}
                      {momentTZ
                        .tz(
                          lastActivities?.lastEmailSentAt?.updatedAt ||
                            lastActivities?.lastEmailSentAt,
                          process.env.REACT_APP_TIMEZONE
                        )
                        .format("L")}{" "}
                      {momentTZ
                        .tz(
                          lastActivities?.lastEmailSentAt?.updatedAt ||
                            lastActivities?.lastEmailSentAt,
                          process.env.REACT_APP_TIMEZONE
                        )
                        .format("LT")}
                    </Typography>
                  ) : null}
                  {searchList?.data?.lastPropertyUpdateEmailSentSource &&
                  lastActivities?.lastEmailSentAt ? (
                    <Box className={classes.sentViaArea}>
                      <Typography className={classes.sentViaAreaLabel}>
                        sent via:{" "}
                      </Typography>
                      <img
                        src={
                          searchList?.data
                            ?.lastPropertyUpdateEmailSentSource === "SES"
                            ? awsIcon
                            : searchList?.data
                                ?.lastPropertyUpdateEmailSentSource === "SG"
                            ? sendGridIcon
                            : mailGunIcon
                        }
                        alt={"sendGridIcon  "}
                      />
                    </Box>
                  ) : null}
                </Box>
                <CopyToClipboard
                  onCopy={() => {
                    setErrorAlert({
                      errorMsg: "copied Successfully!",
                      errorType: "success",
                      isOpen: true,
                    });
                  }}
                  text={`${searchList?.data?.shortQuickLoginUrl}`}
                >
                  <Button
                    size="small"
                    variant="text"
                    className={`${classes.copyLinkAreaTop} copyLinkArea1`}
                    // onClick={handleCopy}
                  >
                    <CopyAllRoundedIcon sx={{ fontSize: "16px", mt: "2px" }} />
                    <Typography className={classes.copyLinkAreaContent}>
                      Copy Link
                    </Typography>
                  </Button>
                </CopyToClipboard>
              </Box>
            ) : null}
          </Card>
          {searchList?.otherAccounts?.length ? (
            <Box className={classes.multipleSiteArea}>
              <Typography className={classes.multipleSiteAreaContent}>
                This Account exists over multiple sites, You can select any
                other site from following to view saved searches.
              </Typography>
              <TextField
                onChange={(e) => handleChangeSite(e.target.value)}
                size="small"
                defaultValue={searchList?.data}
                select
                fullWidth
              >
                {searchList?.otherAccounts?.map((item, index) => (
                  <MenuItem value={item} key={item._id}>
                    {item.site_id?.site_domain}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          ) : null}

          {activeView === "search-list" ? (
            <SavedSearchList
              handleUpdateView={handleUpdateView}
              setErrorAlert={setErrorAlert}
              width={parentWidth}
              disableIframe={disableIframe}
              {...props}
            />
          ) : null}
          {activeView === "add-search" ? (
            <SaveSearch
              handleUpdateView={handleUpdateView}
              actionType={actionType}
              width={parentWidth}
              setErrorAlert={setErrorAlert}
              {...props}
            />
          ) : null}
          {activeView === "add-collection" ? (
            <AddCollection
              handleUpdateView={handleUpdateView}
              actionType={actionType}
              width={parentWidth}
              setErrorAlert={setErrorAlert}
              {...props}
            />
          ) : null}
          {activeView === "update-collection" ? (
            <UpdateCollection
              handleUpdateView={handleUpdateView}
              actionType={actionType}
              width={parentWidth}
              setErrorAlert={setErrorAlert}
              {...props}
            />
          ) : null}
          {activeView === "create-account" ? (
            <CreateAccount
              handleUpdateView={handleUpdateView}
              setErrorAlert={setErrorAlert}
              userDetail={userDetail}
              {...props}
            />
          ) : null}
        </Box>
      )}
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "SavedSearches" })(
  memo(SavedSearches)
);
