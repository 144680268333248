export const departmentsColors = [
  {
    name: "Drafting",
    backgroundColor: "#53CB6DCC",
  },
  {
    name: "Listings",
    backgroundColor: "#DDC441CC",
  },
  {
    name: "Transactions",
    backgroundColor: "#41B8DDCC",
  },
  {
    name: "Marketing",
    backgroundColor: "#DD41C4CC",
  },
  {
    name: "Admin",
    backgroundColor: "#6764E2",
  },
  {
    name: "Valuation",
    backgroundColor: "#DD8C41CC",
  },
  {
    name: "Graphics",
    backgroundColor: "#8641DDCC",
  },
  {
    name: "IT-Support",
    backgroundColor: "#B5DD41CC",
  },
  {
    name: "Front-line",
    backgroundColor: "#41DDB8CC",
  },

  {
    name: "Finance",
    backgroundColor: "#DD4141CC",
  },
];
