import React from "react";
import { Routes, Route } from "react-router-dom";
import StaffManagementView from "./managementView";
import StaffMembersManagement from "./staffMembers";
function StaffManagement(props) {
  return (
    <Routes>
      <Route path="/" element={<StaffManagementView />} />
      <Route path="/manage" element={<StaffMembersManagement />} />
    </Routes>
  );
}
export default StaffManagement;
