import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Grid,
  Card,
  CardHeader,
  Box,
  Stack,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import HistoryIcon from "@mui/icons-material/History";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ResponseAlert from "../../../../components/responseAlert";
import { getInventorySpecificLogHistory } from "../../../../redux/agents/agentRosterDetail/inventory/getSpecificLogs";
import { updateInventoryItem } from "../../../../redux/inventory";
import checkIcon from "../../../../assets/checkIcon.png";
import { updateData } from "../../../../utils/updateData";
import { useParams } from "react-router-dom";
import { getInventoryLogHistory } from "../../../../redux/agents/agentRosterDetail/inventory/getLogsHistory";
import { getAgentInventories } from "../../../../redux/agents/agentRosterDetail/inventory/getAgentInventory";
import CircularProgress from "@mui/material/CircularProgress";
function SignageDetailModal(props) {
  let { classes, open, setOpen, signageDetail, IsAgent } = props;
  const [manage, setManage] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [totalInventory, setTotalInventory] = useState(0);
  const [errorAlert, setErrorAlert] = useState({
    message: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const logsHistory = useSelector(
    (state) => state.agentRoster.agentInventory.specificLogsHistory
  );
  const updateInventory = useSelector(
    (state) => state.inventory.InventoryData.updateInventory
  );

  useEffect(() => {
    if ((signageDetail.InventoryId || signageDetail.id) && open) {
      dispatch(
        getInventorySpecificLogHistory({
          id: signageDetail.InventoryId || signageDetail.id,
        })
      );
      setTotalInventory(
        Number(signageDetail?.SignageCount || 0) +
          Number(
            signageDetail["check-out"]
              ? signageDetail["check-out"]?.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.signageCount || 0,
                  0
                ) || 0
              : "0"
          )
      );
    }
  }, [signageDetail.InventoryId, signageDetail.id]);

  const handleSuccess = () => {
    setErrorAlert({
      message: "You have successfully updated the inventory item",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };

  const handleError = (error) => {
    setErrorAlert({
      message: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setManage("");
  };

  const handleUpdate = () => {
    if (signageDetail?.SignageCount !== totalInventory) {
      let obj = {
        id: signageDetail.id,
        schema: {
          SignageCount:
            Number(totalInventory) -
            Number(
              signageDetail["check-out"]
                ? signageDetail["check-out"]?.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue.signageCount || 0,
                    0
                  ) || 0
                : "0"
            ),
          AssetType: "signage",
        },
        handleError,
        handleSuccess,
      };
      dispatch(updateInventoryItem(obj));
    } else {
      setErrorAlert({
        message: "Please the update the total inventories before save",
        errorType: "warn",
        isOpen: true,
      });
    }
  };

  const handleIncrement = () => {
    setTotalInventory((total) => Number(total) + 1);
  };
  const handleDecrement = () => {
    setTotalInventory((total) => Number(total) - 1);
  };
  const handleCheckIN = async () => {
    setLoading(true);
    const result = await updateData(
      `${process.env.REACT_APP_BASE_URL}api/inventory/check-in/${signageDetail["check-out"][0].id}`,
      {}
    );
    if (result.status === 200) {
      setErrorAlert({
        message: "You have successfully checked in",
        errorType: "success",
        isOpen: true,
      });
      setLoading(false);
      if (IsAgent && id) {
        dispatch(getAgentInventories({ id }));
        dispatch(getInventoryLogHistory({ id: id }));
      }
      handleClose();
    } else {
      setLoading(false);
      setErrorAlert({
        errorMsg: JSON.stringify(result.message),
        errorType: "error",
        isOpen: true,
      });
    }
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        style={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        {manage === "history" ? null : (
          <DialogTitle
            className={classes.appointmentModalHeading}
            id="alert-dialog-title"
          >
            <Box> Item Detail</Box>
            <Stack direction="row" spacing={2} alignItems={"center"}>
              {loading ? <CircularProgress color="inherit" size={25} /> : ""}
              <ButtonGroups
                id="Manage-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="36px"
                size="medium"
                color="white"
                options={
                  IsAgent
                    ? [
                        {
                          title: "CHECK IN",
                          value: "check_in",
                          icon: (
                            <img
                              style={{ width: "18px", marginLeft: "4px" }}
                              src={checkIcon}
                              alt={"checkIcon"}
                            />
                          ),
                        },
                      ]
                    : [
                        {
                          title: "Edit Inventory",
                          value: "edit",
                          icon: <BorderColorIcon />,
                        },
                        {
                          title: "View History",
                          value: "history",
                          icon: <HistoryIcon />,
                        },
                      ]
                }
                onChangeMenu={(value) => {
                  if (value === "check_in") {
                    handleCheckIN();
                  }
                  setManage(value);
                }}
                value={manage}
                placeholder={"Manage"}
                {...props}
              />
            </Stack>
          </DialogTitle>
        )}
        <DialogContent sx={{ width: "600px", mt: "20px" }}>
          {manage === "history" ? (
            <Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={7} sx={{ zIndex: "1" }}>
                  <Card
                    sx={{
                      minWidth: "250px",
                      padding: "5px 15px",
                      boxShadow: "none",
                    }}
                  >
                    <CardHeader
                      className={classes.EquipmentDetailSubBox}
                      classes={{
                        title: classes.signageModalCardHistoryTitle,
                      }}
                      avatar={
                        <img
                          style={{ width: "26px" }}
                          src={signageDetail.Icon}
                          alt="agent Method Icon White"
                        />
                      }
                      title={signageDetail?.AssetName}
                    />
                  </Card>
                </Grid>
              </Grid>
              <TextField
                fullWidth
                readOnly
                label="History"
                sx={{ mt: "30px" }}
                value={
                  logsHistory.data?.length
                    ? `${logsHistory.data.map(
                        (item) =>
                          `${moment(item.createdAt).format("L")} @ ${moment(
                            item.createdAt
                          ).format("LT")} - ${item.Description} \n`
                      )}`
                    : "Not Exists!"
                }
                multiline
                rows={
                  logsHistory.data?.length
                    ? Number(logsHistory.data?.length)
                    : 14
                }
              />
            </Box>
          ) : (
            <React.Fragment>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={7} sx={{ zIndex: "1" }}>
                  <Card sx={{ minWidth: "250px", padding: "5px 15px" }}>
                    <CardHeader
                      className={classes.EquipmentDetailSubBox}
                      classes={{
                        title: classes.agentInventoryModalCardHeader,
                        subheader: classes.agentInventoryModalCardDes,
                      }}
                      avatar={
                        <img
                          style={{ width: "35px" }}
                          src={signageDetail?.Icon}
                          title="signage icon"
                        />
                      }
                      title={signageDetail?.AssetName}
                    />
                  </Card>
                </Grid>
              </Grid>
              <br />
              <Grid container direction="row" spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  hidden={IsAgent ? true : false}
                  sx={{ zIndex: "1" }}
                >
                  <Card sx={{ padding: "15px" }}>
                    <CardHeader
                      className={classes.EquipmentDetailSubBox}
                      classes={{
                        title: classes.signageStatHeader,
                        subheader: classes.signageStatDes,
                      }}
                      title={
                        manage === "edit" ? (
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <IconButton
                              onClick={handleDecrement}
                              sx={{ padding: "0px" }}
                            >
                              <RemoveIcon />
                            </IconButton>
                            <Box>{totalInventory}</Box>
                            <IconButton
                              onClick={handleIncrement}
                              sx={{ padding: "0px" }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Stack>
                        ) : (
                          totalInventory
                        )
                      }
                      subheader="Total Inventory"
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} md={4} sx={{ zIndex: "1" }}>
                  <Card sx={{ padding: "15px" }}>
                    <CardHeader
                      className={classes.EquipmentDetailSubBox}
                      classes={{
                        title: classes.signageStatHeader,
                        subheader: classes.signageStatDes,
                      }}
                      sx={{ color: "#0288D1" }}
                      title={Number(
                        signageDetail["check-out"]
                          ? signageDetail["check-out"]?.reduce(
                              (accumulator, currentValue) =>
                                accumulator + currentValue.signageCount || 0,
                              0
                            ) || 0
                          : "0"
                      )}
                      subheader="Checked Out"
                    />
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  hidden={IsAgent ? true : false}
                  sx={{ zIndex: "1" }}
                >
                  <Card sx={{ padding: "15px" }}>
                    <CardHeader
                      className={classes.EquipmentDetailSubBox}
                      classes={{
                        title: classes.signageStatHeader,
                        subheader: classes.signageStatDes,
                      }}
                      sx={{ color: "#2E7D32" }}
                      title={
                        Number(totalInventory) -
                        Number(
                          signageDetail["check-out"]
                            ? signageDetail["check-out"]?.reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue.signageCount || 0,
                                0
                              ) || 0
                            : "0"
                        )
                      }
                      subheader="In Office"
                    />
                  </Card>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions sx={{ mb: "10px", mt: "25px", mr: "15px" }}>
          <Button
            onClick={() => {
              handleClose();
            }}
            color="inherit"
            autoFocus
            size="small"
            variant="outlined"
            sx={{ fontSize: "13px", border: "1px solid rgba(33, 33, 33, 0.4)" }}
          >
            Close
          </Button>
          {manage === "edit" ? (
            <LoadingButton
              classes={{ root: classes.deleteButTon }}
              color="inherit"
              autoFocus
              size="small"
              loadingPosition="start"
              loading={updateInventory.isLoading}
              onClick={handleUpdate}
            >
              Save
            </LoadingButton>
          ) : null}
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ message: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.message}
      />
    </React.Fragment>
  );
}
export default withStyles(styleSheet, {
  name: "SignageDetailModalStyle",
})(SignageDetailModal);
