import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Chip,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  styled,
  Menu,
  MenuItem,
  Checkbox,
  Skeleton,
} from "@mui/material";
import { updateUserStaff } from "../../../redux/staff";
import ResponseAlert from "../../../components/responseAlert";
import { useDispatch, useSelector } from "react-redux";
import { departmentsColors } from "../utils/department";
import { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment";
import { getTrainedTask } from "../../../redux/staff";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EDEDED",
    color: "black",
    textTransform: "uppercase",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#F9F9F9",
    fontWeight: "500",
    borderBottom: "none",
  },
}));

const timeOptions = [
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 PM",
  "09:00 PM",
  "09:30 PM",
].map((title) => ({
  title,
  value: moment(title, "hh:mm A").format("HH:mm:ss"),
}));

const weekABOptions = ["A", "B", "N/A"].map((title) => ({
  title,
  value: title,
}));

function ManageUser(props) {
  const { classes, setManageType, selectedUser } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [weekSchedule, setWeekSchedule] = useState([]);
  const [activeMenu, setActiveMenu] = useState({ day: "", type: "" });
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const open = Boolean(anchorEl);
  const specificStaff = useSelector((item) => item.Staff.specificStaff);

  useEffect(() => {
    if (specificStaff?.data?.staffSchedule) {
      setWeekSchedule(specificStaff?.data?.staffSchedule);
    }
  }, [specificStaff?.data?.staffSchedule]);
  const handleOpenMenu = (event, type, day) => {
    setActiveMenu({ type, day });
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setActiveMenu({ day: "", type: "" });
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully update",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSelectMenu = (option) => {
    let newSchedule = [...weekSchedule];
    let index = newSchedule.findIndex((it) => it.weekday === activeMenu.day);
    newSchedule[index] = {
      ...newSchedule[index],
      [activeMenu.type]: option?.value,
    };
    setWeekSchedule(newSchedule);
    handleClose();
    dispatch(
      updateUserStaff({
        id: newSchedule[index].id,
        handleSuccess,
        handleError,
        schema: {
          ...newSchedule[index],
          id: undefined,
        },
      })
    );
  };

  const handleCheckOff = (e, row) => {
    let newSchedule = [...weekSchedule];
    let index = newSchedule.findIndex((it) => it.weekday === row.weekday);
    if (e.target.checked) {
      newSchedule[index] = {
        ...newSchedule[index],
        day_off: e.target.checked,
        start_time: "09:00:00",
        end_time: "09:00:00",
        week_a_b: null,
      };
    } else {
      newSchedule[index] = {
        ...newSchedule[index],
        day_off: e.target.checked,
        start_time: "09:00:00",
        end_time: "09:00:00",
        week_a_b: null,
      };
    }
    setWeekSchedule(newSchedule);
    dispatch(
      updateUserStaff({
        id: newSchedule[index].id,
        handleSuccess,
        handleError,
        schema: {
          ...newSchedule[index],
          id: undefined,
        },
      })
    );
  };

  return (
    <Box>
      {" "}
      <Typography className={classes.manageUserHeading}>
        TRAINED Departments
      </Typography>
      <Box className={classes.manageDepartmentView}>
        <Box className={classes.departmentViewSection}>
          {specificStaff?.isLoading
            ? Array.from(Array(8).keys())?.map((_, index) => (
                <Chip
                  key={index}
                  className={classes.departmentViewItem}
                  label={"testing df"}
                  size="small"
                  sx={{ "& .MuiChip-label": { color: "#e3e3e3" } }}
                />
              ))
            : specificStaff?.data?.trainedDepartments?.map((item, index) => (
                <Chip
                  key={index}
                  className={classes.departmentViewItem}
                  label={item.department_name}
                  size="small"
                  sx={{
                    backgroundColor:
                      departmentsColors?.find(
                        (it) => it.name === item.department_name
                      )?.backgroundColor || "#DDC441CC",
                  }}
                />
              ))}
        </Box>
        <Button
          color="inherit"
          size="small"
          onClick={() => {
            setManageType("departments");
            dispatch(getTrainedTask({ user_id: selectedUser?.id }));
          }}
          className={classes.manageTrainedTaskButton}
        >
          manage trained tasks
        </Button>
      </Box>
      <Box className={classes.manageScheduleSection}>
        <Typography className={classes.manageUserHeading}>Schedule</Typography>
        <TableContainer sx={{ borderRadius: "20px", mt: "20px" }}>
          <Table
            size="small"
            sx={{ minWidth: 600 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{
                    border: 0,
                    borderRight: "1px solid rgba(0, 0, 0, 0.20)",
                    lineHeight: "36px",
                  }}
                  align="center"
                >
                  weekday
                </StyledTableCell>
                <StyledTableCell sx={{ border: 0 }} align="center">
                  start time
                </StyledTableCell>
                <StyledTableCell sx={{ border: 0 }} align="center">
                  end time
                </StyledTableCell>
                <StyledTableCell sx={{ border: 0 }} align="center">
                  Week A / B
                </StyledTableCell>
                <StyledTableCell sx={{ border: 0 }} align="center">
                  off
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {specificStaff?.isLoading
                ? Array.from(Array(7)?.keys())?.map((_, index) => (
                    <TableRow key={index}>
                      <StyledTableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid rgba(0, 0, 0, 0.20)",
                          minWidth: "130px",
                        }} /* component="th" scope="row" */
                      >
                        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ paddingRight: "0px" }}
                      >
                        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ paddingRight: "0px" }}
                      >
                        {" "}
                        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: "100px" }}>
                        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
                      </StyledTableCell>
                    </TableRow>
                  ))
                : weekSchedule.map((row, index) => (
                    <TableRow key={row.name}>
                      <StyledTableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid rgba(0, 0, 0, 0.20)",
                          minWidth: "130px",
                        }} /* component="th" scope="row" */
                      >
                        {row.weekday}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ paddingRight: "0px" }}
                      >
                        <Button
                          color="inherit"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) =>
                            handleOpenMenu(e, "start_time", row.weekday)
                          }
                          size="small"
                          className={classes.dropdownButton}
                        >
                          {`${
                            weekSchedule[index]?.day_off
                              ? "-"
                              : weekSchedule[index]?.start_time
                              ? `${moment(
                                  weekSchedule[index]?.start_time,
                                  "HH:mm:ss"
                                ).format("hh:mm A")}`
                              : "-"
                          }`}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ paddingRight: "0px" }}
                      >
                        {" "}
                        <Button
                          color="inherit"
                          aria-controls={open ? "end-basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) =>
                            handleOpenMenu(e, "end_time", row.weekday)
                          }
                          size="small"
                          className={classes.dropdownButton}
                        >
                          {`${
                            weekSchedule[index]?.day_off
                              ? "-"
                              : weekSchedule[index]?.end_time
                              ? `${moment(
                                  weekSchedule[index]?.end_time,
                                  "HH:mm:ss"
                                ).format("hh:mm A")}`
                              : "-"
                          }`}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {row?.weekday === "Saturday" ||
                        row?.weekday === "Sunday" ? (
                          <Button
                            color="inherit"
                            aria-controls={open ? "end-basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={(e) =>
                              handleOpenMenu(e, "week_a_b", row.weekday)
                            }
                            size="small"
                            className={classes.dropdownButton}
                          >
                            {weekSchedule[index]?.day_off
                              ? "-"
                              : weekSchedule[index]?.week_a_b || "-"}
                          </Button>
                        ) : null}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: "100px" }}>
                        <Checkbox
                          color="default"
                          checked={weekSchedule[index]?.day_off}
                          onChange={(e) => handleCheckOff(e, row)}
                        />
                      </StyledTableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            maxHeight: "350px",
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(54, 131, 188, 0.6)",
            },
            "&::-webkit-scrollbar-track": {
              background: "#ccc",
            },
            "&::-webkit-scrollbar": {
              width: "6px",
            },
          },
        }}
      >
        {(activeMenu.type === "start_time" || activeMenu.type === "end_time"
          ? timeOptions
          : weekABOptions
        )?.map((option, index) => (
          <MenuItem
            onClick={() => handleSelectMenu(option)}
            sx={{ width: "130px", justifyContent: "center" }}
          >
            {option?.title}
          </MenuItem>
        ))}
      </Menu>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Box>
  );
}

export default ManageUser;
