import { createSlice } from "@reduxjs/toolkit";
import { getTextMessages } from "./getTextMessages";
import { getAlerts } from "./getAlerts";
import { markReadNotificationsAll } from "./markReadAll";
import { markAsUnreadAlert } from "./markAsUnread";
const initialState = {
  messages: {
    success: false,
    data: undefined,
    count: 0,
    isLoading: false,
    errMsg: null,
  },
  alerts: {
    success: false,
    data: undefined,
    count: 0,
    isLoading: false,
    errMsg: null,
  },
};
const MessagesAndAlerts = createSlice({
  name: "MessagesAndAlerts",
  initialState,
  reducers: {
    addNewMessage: (state, action) => {
      let messages = state.messages.data ? [...state.messages.data] : [];
      messages.unshift(action.payload);
      state.messages.data = messages;
    },
    addNewAlert: (state, action) => {
      let alerts = state.alerts.data ? [...state.alerts.data] : [];
      alerts.unshift(action.payload);
      state.alerts.data = alerts;
    },
    removeAlert: (state, action) => {
      let alerts = state.alerts.data ? [...state.alerts.data] : [];
      let index = alerts.findIndex((item) => item.id === action.payload.id);
      alerts[index] = {
        ...alerts[index],
        notified_users: alerts[index]?.notified_users?.map((it) => ({
          ...it,
          is_seen: true,
        })),
      };
      state.alerts.data = alerts;
    },
    removeMessage: (state, action) => {
      let messages = state.messages.data ? [...state.messages.data] : [];
      state.messages.data = messages.filter(
        (item) => item.id !== action.payload.id
      );
    },
  },
  extraReducers: {
    [getTextMessages.pending]: (state) => {
      state.messages.isLoading = true;
      state.messages.errMsg = null;
      state.messages.success = false;
    },
    [markReadNotificationsAll.pending]: (state, action) => {
      if (!action?.meta?.arg?.id) {
        let alerts = state.alerts.data ? [...state.alerts.data] : [];
        state.alerts.data = alerts?.map((item) => ({
          ...item,
          notified_users: item?.notified_users?.map((it) => ({
            ...it,
            is_seen: true,
          })),
        }));
      }
      if (action?.meta?.arg?.id) {
        let alerts = state.alerts.data ? [...state.alerts.data] : [];
        let index = alerts.findIndex(
          (item) => item.id === action?.meta?.arg?.id
        );
        alerts[index] = {
          ...alerts[index],
          notified_users: alerts[index]?.notified_users?.map((it) => ({
            ...it,
            is_seen: true,
          })),
        };
        state.alerts.data = alerts;
      }
    },
    [markAsUnreadAlert.pending]: (state, action) => {
      if (action?.meta?.arg?.id) {
        let alerts = state.alerts.data ? [...state.alerts.data] : [];
        let index = alerts.findIndex(
          (item) => item.id === action?.meta?.arg?.id
        );
        alerts[index] = {
          ...alerts[index],
          notified_users: alerts[index]?.notified_users?.map((it) => ({
            ...it,
            is_seen: false,
          })),
        };
        state.alerts.data = alerts;
      }
    },
    [getTextMessages.fulfilled]: (state, action) => {
      state.messages.success = true;
      state.messages.isLoading = false;
      if (action.payload?.pageNumber > 1 || action.payload?.isNewMessage) {
        state.messages.data = state.messages.data
          .concat(action.payload.data)
          .filter(
            (item, index, self) =>
              self.findIndex((it) => it.id === item.id) == index
          );
      } else {
        state.messages.data = action.payload.data;
      }
      state.messages.count = action.payload.count;
    },
    [getTextMessages.rejected]: (state, action) => {
      state.messages.isLoading = false;
      state.messages.success = false;
      state.messages.errMsg = action.payload;
    },
    [getAlerts.pending]: (state) => {
      state.alerts.isLoading = true;
      state.alerts.errMsg = null;
      state.alerts.success = false;
    },
    [getAlerts.fulfilled]: (state, action) => {
      state.alerts.success = true;
      state.alerts.isLoading = false;
      if (action.payload?.pageNumber > 1 || action.payload?.isNewAlert) {
        if (state.alerts.data?.length) {
          state.alerts.data = state.alerts.data
            .concat(action.payload.data)
            .filter(
              (item, index, self) =>
                self.findIndex((it) => it.id === item.id) == index
            );
        }
      } else {
        state.alerts.data = action.payload.data;
      }
      state.alerts.count = action.payload.count;
    },
    [getAlerts.rejected]: (state, action) => {
      state.alerts.isLoading = false;
      state.alerts.success = false;
      state.alerts.errMsg = action.payload;
    },
  },
});
export const { addNewMessage, addNewAlert, removeAlert, removeMessage } =
  MessagesAndAlerts.actions;
export default MessagesAndAlerts.reducer;
export * from "./getTextMessages";
export * from "./getAlerts";
