/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "../style.js";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Container,
  useMediaQuery,
  TextField,
  Box,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getRowAdjustment,
  isArrayCheck,
  numberWithCommas,
  slider_settings,
  slider_settings_2,
} from "../helper.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AdjustmentsComparable from "../AdjustmentsComparable.js";
import LeftArrow from "../../../../assets/leftArrow.svg";
import RightArrow from "../../../../assets/rightArrow.svg";
import ImgsViewer from "react-images-viewer";
import moment from "moment";
import {
  addAdjustmentsInComparable,
  autoAdjustmentComparable,
  changeAdjustmentVisibility,
  getadjustmentData,
} from "../../../../redux/tools/cmas.js";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PuffLoader } from "react-spinners";
import NoImage from "../../../../assets/images/noimage.png";
import { getImageUrlWithHeightAndWidth } from "../../../../utils/propertyData.js";

const inputs = {
  MlsStatus: false,
  BedroomsTotal: false,
  BathroomsFull: false,
  BathroomsHalf: false,
  Basement: false,
  CountyOrParish: false,
  BuildingAreaTotal: false,
  pricePerSqFt: false,
  OriginalListPrice: false,
  ListPrice: false,
  ClosePrice: false,
  AssociationFee: false,
  TaxAnnualAmount: false,
  ListingContractDate: false,
  PurchaseContractDate: false,
  CloseDate: false,
  DaysOnMarket: false,
  LotSizeAcres: false,
  PropertyType: false,
  YearBuilt: false,
  PoolFeatures: false,
  ParkingTotal: false,
  GarageSpaces: false,
  ConstructionMaterials: false,
  ArchitecturalStyle: false,
  FireplacesTotal: false,
  Levels: false,
};

function AdjustmentsDetailView(props) {
  const { classes } = props;
  const params = useParams();
  const cmaId = params.id;
  const dispatch = useDispatch();
  const mdScreen = useMediaQuery("(min-width:1023px)");
  const singleCma = useSelector((state) => state.allCmas.singleCma);
  const [changedVisibility, setChangedVisibility] = React.useState(false);
  const [forceUpdate, setForceUpdate] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageCounter, setPageCounter] = React.useState(1);
  const [slider, setSlider] = React.useState(null);
  const [adjustedDataInput, setAdjustedDataInput] = React.useState([]);
  const [adjustedmentVisibility, setAdjustedmentVisibility] = React.useState();
  const customFieldsRef = React.useRef([]);

  const handleNextClick = () => {
    slider.slickNext();
    currentPage < pageCounter && setCurrentPage(currentPage + 1);
  };

  const handlePrevClick = () => {
    slider.slickPrev();
    currentPage > 1 && setCurrentPage(currentPage - 1);
  };

  const handleAdjustmentInput = (statusOfInput) => {
    // update input status by comparable index
    setAdjustedDataInput(statusOfInput);
  };

  React.useEffect(() => {
    if (singleCma) {
      singleCma?.cma_comparables?.map((d, i) => {
        customFieldsRef.current[i] =
          customFieldsRef.current && customFieldsRef.current[i]
            ? customFieldsRef.current[i]
            : [];
      });
      setForceUpdate(forceUpdate + 1);
    }

    let allComparableInputStatus = [];
    singleCma?.cma_custom_fields?.map((customField) => {
      inputs[customField.name] = false;
    });

    singleCma?.cma_comparables?.map((f, i) => {
      allComparableInputStatus[i] = { ...inputs };
    });

    let tempAdjustmentVisibility = {};
    Object.keys(inputs)?.map((key, i) => {
      if (
        singleCma[`is${key}Hidden`] === true ||
        singleCma[`is${key}Hidden`] === false
      ) {
        tempAdjustmentVisibility = {
          ...tempAdjustmentVisibility,
          [key]: !singleCma[`is${key}Hidden`],
        };
      }
    });

    setAdjustedmentVisibility(tempAdjustmentVisibility);
    setAdjustedDataInput(allComparableInputStatus);
    setSingleCMAMediaURLs(singleCma?.cma_media_urls);
  }, [singleCma]);

  const [singleCMAMediaURLs, setSingleCMAMediaURLs] = React.useState([]);

  const [globalAdjBedroom, setGlobalAdjBedroom] = React.useState(null);
  const [globalAdjBathroom, setGlobalAdjBathroom] = React.useState(null);
  const [globalAdjHalfBathroom, setGlobalAdjHalfBathroom] =
    React.useState(null);
  const [golbalAdjPriceSqft, setGolbalAdjPriceSqft] = React.useState(null);
  const [updateAutoAdjustmentLoading, setUpdateAutoAdjustmentLoading] =
    React.useState(false);

  const [autoAdjustmentChanged, setAutoAdjustmentChanged] = React.useState({
    changedGlobalAdjBedroom: null,
    changedGlobalAdjBathroom: null,
    changedGlobalAdjHalfBathroom: null,
    changedGolbalAdjPriceSqft: null,
  });

  const [imageViewerOpen, setImageViewerOpen] = React.useState(false);
  const [imageViewerStartingImage, setImageViewerStartingImage] =
    React.useState(0);

  const handleSliderImageClick = (imageIndex) => {
    setImageViewerOpen(true);
    setImageViewerStartingImage(imageIndex);
  };

  const handleVisibilityChange = async (type) => {
    const currentVisibility = adjustedmentVisibility[type];
    setAdjustedmentVisibility((prevState) => {
      return { ...prevState, [type]: !prevState[type] };
    });

    const resolveCompFieldVisibilityChange = new Promise(async (resolve) => {
      await dispatch(
        changeAdjustmentVisibility({
          cmaId,
          formData: { [`is${type}Hidden`]: currentVisibility ? 1 : 0 },
        })
      );

      if (currentVisibility) {
        await Promise.all(
          singleCma?.cma_comparables.map(async (comparable) => {
            await dispatch(
              addAdjustmentsInComparable({
                [type]: {
                  id: null,
                  adjustment: Number.parseFloat(0),
                },
                id: comparable?.id,
                cmaId: comparable?.cma_id,
                dontFetchAdjustment: true,
              })
            );
          })
        );
        await dispatch(getadjustmentData({ id: cmaId }));

        setChangedVisibility(true);
        setTimeout(() => {
          setChangedVisibility(false);
        }, [1000]);
      }
      resolve();
    });

    toast.promise(resolveCompFieldVisibilityChange, {
      pending: `Changing Visibility of Comparable field (${type}) is in progress.`,
      success: `Visibility successfully updated!`,
      error: "Something went wrong!",
    });
  };

  const applyAutoAdjustment = async () => {
    const data = {};
    if (autoAdjustmentChanged.changedGlobalAdjBathroom) {
      data["BathroomsFull"] = [];
      singleCma?.cma_comparables?.map((comparable) => {
        let calculatedCMA =
          (singleCma?.BathroomsFull - comparable?.BathroomsFull) *
          globalAdjBathroom;
        data["BathroomsFull"].push({
          id: comparable.id,
          adjustment: calculatedCMA,
        });
      });
      setUpdateAutoAdjustmentLoading(true);
    }

    if (autoAdjustmentChanged.changedGlobalAdjHalfBathroom) {
      data["BathroomsHalf"] = [];
      singleCma?.cma_comparables?.map((comparable) => {
        let calculatedCMA =
          (singleCma?.BathroomsHalf - comparable?.BathroomsHalf) *
          globalAdjHalfBathroom;
        data["BathroomsHalf"].push({
          id: comparable.id,
          adjustment: calculatedCMA,
        });
      });
      setUpdateAutoAdjustmentLoading(true);
    }
    if (autoAdjustmentChanged.changedGlobalAdjBedroom) {
      data["BedroomsTotal"] = [];
      singleCma?.cma_comparables?.map((comparable) => {
        let calculatedCMA =
          (singleCma?.BedroomsTotal - comparable?.BedroomsTotal) *
          globalAdjBedroom;
        data["BedroomsTotal"].push({
          id: comparable.id,
          adjustment: calculatedCMA,
        });
      });
      setUpdateAutoAdjustmentLoading(true);
    }
    if (autoAdjustmentChanged.changedGolbalAdjPriceSqft) {
      data["BuildingAreaTotal"] = [];
      singleCma?.cma_comparables?.map((comparable) => {
        let calculatedCMA =
          (singleCma?.BuildingAreaTotal - comparable?.BuildingAreaTotal) *
          golbalAdjPriceSqft;
        data["BuildingAreaTotal"].push({
          id: comparable.id,
          adjustment: calculatedCMA,
        });
      });
      setUpdateAutoAdjustmentLoading(true);
    }

    if (
      autoAdjustmentChanged.changedGlobalAdjBathroom ||
      autoAdjustmentChanged.changedGlobalAdjBedroom ||
      autoAdjustmentChanged.changedGlobalAdjHalfBathroom ||
      autoAdjustmentChanged.changedGolbalAdjPriceSqft
    ) {
      const response = await dispatch(
        autoAdjustmentComparable({
          id: cmaId,
          putData: data,
          shouldFetchAdjustment: true,
        })
      );

      if (response.meta.requestStatus === "fulfilled") {
        setTimeout(() => {
          setForceUpdate(forceUpdate + 1);
          setUpdateAutoAdjustmentLoading(false);
        }, 2000);
      }
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl" sx={{ height: "100%" }}>
        <Grid container spacing={2} alignItems="center" mt={1} mb={4}>
          <Grid item md={8} sx={{ zIndex: 0 }} display={"flex"} gap={2}>
            <Card className={classes.card}>
              <Typography
                variant="h6"
                sx={{
                  flexShrink: "0",
                  fontSize: "20px !important",
                  fontWeight: "600 !important",
                  paddingBottom: "10px !important",
                  fontFamily: "AvenirNext!important",
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                {updateAutoAdjustmentLoading
                  ? "Applying auto adjustments..."
                  : "Auto Adjustments"}
                {updateAutoAdjustmentLoading && (
                  <PuffLoader color="rgba(0, 0, 0, 0.5)" size={"25"} />
                )}
              </Typography>
              <Box className={classes.cardContent}>
                <TextField
                  size="small"
                  autoComplete="off"
                  label="Bedroom Value"
                  onChange={(e) => setGlobalAdjBedroom(e.target.value)}
                  onBlur={() => {
                    if (globalAdjBedroom)
                      setAutoAdjustmentChanged((prev) => ({
                        ...prev,
                        changedGlobalAdjBedroom: true,
                      }));
                    else
                      setAutoAdjustmentChanged((prev) => ({
                        ...prev,
                        changedGlobalAdjBedroom: null,
                      }));
                  }}
                />

                <TextField
                  size="small"
                  autoComplete="off"
                  label="Bathroom Value"
                  onChange={(e) => setGlobalAdjBathroom(e.target.value)}
                  onBlur={() => {
                    if (globalAdjBathroom)
                      setAutoAdjustmentChanged((prev) => ({
                        ...prev,
                        changedGlobalAdjBathroom: true,
                      }));
                    else
                      setAutoAdjustmentChanged((prev) => ({
                        ...prev,
                        changedGlobalAdjBathroom: null,
                      }));
                  }}
                />

                <TextField
                  size="small"
                  autoComplete="off"
                  label="Half Bath Value"
                  onChange={(e) => setGlobalAdjHalfBathroom(e.target.value)}
                  onBlur={() => {
                    if (globalAdjHalfBathroom)
                      setAutoAdjustmentChanged((prev) => ({
                        ...prev,
                        changedGlobalAdjHalfBathroom: true,
                      }));
                    else
                      setAutoAdjustmentChanged((prev) => ({
                        ...prev,
                        changedGlobalAdjHalfBathroom: null,
                      }));
                  }}
                />

                <TextField
                  size="small"
                  autoComplete="off"
                  label="Per Sqft Value"
                  onChange={(e) => setGolbalAdjPriceSqft(e.target.value)}
                  onBlur={() => {
                    if (golbalAdjPriceSqft)
                      setAutoAdjustmentChanged((prev) => ({
                        ...prev,
                        changedGolbalAdjPriceSqft: true,
                      }));
                    else
                      setAutoAdjustmentChanged((prev) => ({
                        ...prev,
                        changedGolbalAdjPriceSqft: null,
                      }));
                  }}
                />

                <Button
                  size="small"
                  sx={{
                    backgroundColor: "#0B0909!important",
                    color: "#fff!important",
                    minWidth: "100px",
                    fontSize: "15px",
                  }}
                  variant="outlined"
                  onClick={applyAutoAdjustment}
                >
                  Apply
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            md={4}
            container
            justifyContent="flex-end"
            alignItems="center"
            className={classes.cardPagesMargin}
          >
            {singleCma?.cma_comparables?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  mt: "50px",
                }}
              >
                <div
                  onClick={handlePrevClick}
                  style={{
                    backgroundColor: "transparent",
                    position: "relative",
                    top: "2px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                    src={LeftArrow}
                    alt=""
                  />
                </div>
                <h2 className={classes.cardPages}>
                  {`Page ${currentPage} of ${
                    mdScreen
                      ? singleCma?.cma_comparables.length - 1 ||
                        singleCma?.cma_comparables.length
                      : Math.round(singleCma?.cma_comparables.length)
                  }`}
                </h2>
                <div
                  onClick={handleNextClick}
                  style={{
                    backgroundColor: "transparent",
                    position: "relative",
                    top: "2px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                    src={RightArrow}
                    alt=""
                  />
                </div>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl">
        <Grid container spacing={2} className={classes.AdjustmentsCards}>
          <Grid item xs={mdScreen ? 4 : 6}>
            <div className={classes.cardHeading}>Subject</div>
            <Card>
              {(singleCMAMediaURLs?.length === 0 || !singleCMAMediaURLs) &&
              singleCma?.MediaURL ? (
                <CardMedia
                  component="img"
                  width="100%"
                  height="240px"
                  image={getImageUrlWithHeightAndWidth(singleCma?.MediaURL)}
                  alt={singleCma?.City}
                />
              ) : singleCMAMediaURLs.length > 0 ? (
                <>
                  <Slider {...slider_settings_2}>
                    {singleCMAMediaURLs.map((item, imageIndex) => {
                      return (
                        <CardMedia
                          component="img"
                          width="100%"
                          height="240px"
                          onClick={() => handleSliderImageClick(imageIndex)}
                          image={getImageUrlWithHeightAndWidth(item?.MediaUrl)}
                        />
                      );
                    })}
                  </Slider>

                  <ImgsViewer
                    imgs={singleCMAMediaURLs?.map((item) => ({
                      src: getImageUrlWithHeightAndWidth(item?.MediaUrl),
                    }))}
                    isOpen={imageViewerOpen}
                    currImg={imageViewerStartingImage}
                    showThumbnails={true}
                    enableKeyboardInput={true}
                    onClickThumbnail={(e) => setImageViewerStartingImage(e)}
                    onClickPrev={() =>
                      imageViewerStartingImage - 1 >= 0
                        ? setImageViewerStartingImage(
                            (imageViewerStartingImage) =>
                              imageViewerStartingImage - 1
                          )
                        : null
                    }
                    onClickNext={() =>
                      imageViewerStartingImage + 1 <=
                      singleCma?.cma_media_urls.length
                        ? setImageViewerStartingImage(
                            (imageViewerStartingImage) =>
                              imageViewerStartingImage + 1
                          )
                        : null
                    }
                    onClose={() => setImageViewerOpen(false)}
                  />
                </>
              ) : (
                <CardMedia
                  component="img"
                  width="100%"
                  height="240px"
                  image={NoImage}
                  alt={singleCma?.City}
                />
              )}
              <Typography
                className={classes.cardTitle}
                variant="body1"
                component="div"
              >
                {singleCma?.StreetName
                  ? `${singleCma?.StreetNumber || ""} ${
                      singleCma?.StreetName || ""
                    }${" "}
                ${singleCma?.StreetSuffix || ""}`
                  : "Undisclosed Address"}
                {singleCma?.UnitNumber || ""}
              </Typography>
              <Typography className={classes.cardSubTitle}>
                {singleCma?.City || ""} {singleCma?.StateOrProvince || ""}
                {singleCma?.PostalCode || ""}
              </Typography>

              {getRowAdjustment(
                "Mls Status",
                singleCma?.MlsStatus === "Active Under Contract"
                  ? "ActiveUC"
                  : singleCma?.MlsStatus,
                adjustedmentVisibility?.MlsStatus,
                () => handleVisibilityChange("MlsStatus"),
                classes
              )}
              {getRowAdjustment(
                "Bedrooms Total",
                singleCma?.BedroomsTotal,
                adjustedmentVisibility?.BedroomsTotal,
                () => handleVisibilityChange("BedroomsTotal"),
                classes
              )}
              {getRowAdjustment(
                "Bathrooms Full",
                singleCma?.BathroomsFull,
                adjustedmentVisibility?.BathroomsFull,
                () => handleVisibilityChange("BathroomsFull"),
                classes
              )}
              {getRowAdjustment(
                "Bathrooms Half",
                singleCma?.BathroomsHalf,
                adjustedmentVisibility?.BathroomsHalf,
                () => handleVisibilityChange("BathroomsHalf"),
                classes
              )}
              {getRowAdjustment(
                "Basement",
                singleCma?.Basement,
                adjustedmentVisibility?.Basement,
                () => handleVisibilityChange("Basement"),
                classes
              )}
              {getRowAdjustment(
                "CountyOrParish",
                singleCma?.CountyOrParish,
                adjustedmentVisibility?.CountyOrParish,
                () => handleVisibilityChange("CountyOrParish"),
                classes
              )}
              {getRowAdjustment(
                "Square Footage",
                singleCma?.BuildingAreaTotal,
                adjustedmentVisibility?.BuildingAreaTotal,
                () => handleVisibilityChange("BuildingAreaTotal"),
                classes
              )}
              {getRowAdjustment(
                "Price Per SqFt",
                !isNaN(singleCma?.pricePerSqFt)
                  ? `$${numberWithCommas(singleCma?.pricePerSqFt)}`
                  : "",
                adjustedmentVisibility?.pricePerSqFt,
                () => handleVisibilityChange("pricePerSqFt"),
                classes
              )}
              {getRowAdjustment(
                "Original List Price",
                !isNaN(singleCma?.OriginalListPrice)
                  ? `$${numberWithCommas(singleCma?.OriginalListPrice)}`
                  : "",
                adjustedmentVisibility?.OriginalListPrice,
                () => handleVisibilityChange("OriginalListPrice"),
                classes
              )}
              {getRowAdjustment(
                "List Price",
                !isNaN(singleCma?.ListPrice)
                  ? `$${numberWithCommas(singleCma?.ListPrice)}`
                  : "",
                adjustedmentVisibility?.ListPrice,
                () => handleVisibilityChange("ListPrice"),
                classes
              )}
              {getRowAdjustment(
                "Close Price",
                !isNaN(singleCma?.ClosePrice)
                  ? `$${numberWithCommas(singleCma?.ClosePrice)}`
                  : "",
                adjustedmentVisibility?.ClosePrice,
                () => handleVisibilityChange("ClosePrice"),
                classes
              )}
              {getRowAdjustment(
                "Association Fee",
                !isNaN(singleCma?.AssociationFee)
                  ? `$${numberWithCommas(singleCma?.AssociationFee)}`
                  : "",
                adjustedmentVisibility?.AssociationFee,
                () => handleVisibilityChange("AssociationFee"),
                classes
              )}
              {getRowAdjustment(
                "Tax Annual Amount",
                !isNaN(singleCma?.TaxAnnualAmount)
                  ? `$${numberWithCommas(singleCma?.TaxAnnualAmount)}`
                  : "",
                adjustedmentVisibility?.TaxAnnualAmount,
                () => handleVisibilityChange("TaxAnnualAmount"),
                classes
              )}
              {getRowAdjustment(
                "Listing Contract Date",
                moment(singleCma?.ListingContractDate).isValid()
                  ? moment(singleCma?.ListingContractDate).format("MM/DD/YYYY")
                  : "",
                adjustedmentVisibility?.ListingContractDate,
                () => handleVisibilityChange("ListingContractDate"),
                classes
              )}
              {getRowAdjustment(
                "Purchase Contract",
                moment(singleCma?.PurchaseContractDate).isValid()
                  ? moment(singleCma?.PurchaseContractDate).format("MM/DD/YYYY")
                  : "",
                adjustedmentVisibility?.PurchaseContractDate,
                () => handleVisibilityChange("PurchaseContractDate"),
                classes
              )}
              {getRowAdjustment(
                "Close Date",
                moment(singleCma?.CloseDate).isValid()
                  ? moment(singleCma?.CloseDate).format("MM/DD/YYYY")
                  : "",
                adjustedmentVisibility?.CloseDate,
                () => handleVisibilityChange("CloseDate"),
                classes
              )}
              {getRowAdjustment(
                "Days On Market",
                singleCma?.DaysOnMarket,
                adjustedmentVisibility?.DaysOnMarket,
                () => handleVisibilityChange("DaysOnMarket"),
                classes
              )}
              {getRowAdjustment(
                "Lot Size Acres",
                singleCma?.LotSizeAcres,
                adjustedmentVisibility?.LotSizeAcres,
                () => handleVisibilityChange("LotSizeAcres"),
                classes
              )}
              {getRowAdjustment(
                "Property Type",
                singleCma?.PropertyType,
                adjustedmentVisibility?.PropertyType,
                () => handleVisibilityChange("PropertyType"),
                classes
              )}
              {getRowAdjustment(
                "Year Built",
                singleCma?.YearBuilt,
                adjustedmentVisibility?.YearBuilt,
                () => handleVisibilityChange("YearBuilt"),
                classes
              )}
              {getRowAdjustment(
                "Pool Features",
                singleCma?.PoolFeatures,
                adjustedmentVisibility?.PoolFeatures,
                () => handleVisibilityChange("PoolFeatures"),
                classes
              )}
              {getRowAdjustment(
                "Parking Total",
                singleCma?.ParkingTotal,
                adjustedmentVisibility?.ParkingTotal,
                () => handleVisibilityChange("ParkingTotal"),
                classes
              )}
              {getRowAdjustment(
                "Garage Spaces",
                singleCma?.GarageSpaces,
                adjustedmentVisibility?.GarageSpaces,
                () => handleVisibilityChange("GarageSpaces"),
                classes
              )}
              {getRowAdjustment(
                "Construction Material",
                singleCma?.ConstructionMaterials,
                adjustedmentVisibility?.ConstructionMaterials,
                () => handleVisibilityChange("ConstructionMaterials"),
                classes
              )}
              {getRowAdjustment(
                "Architectural Style",
                singleCma?.ArchitecturalStyle,
                adjustedmentVisibility?.ArchitecturalStyle,
                () => handleVisibilityChange("ArchitecturalStyle"),
                classes
              )}
              {getRowAdjustment(
                "Fireplaces Total",
                singleCma?.FireplacesTotal,
                adjustedmentVisibility?.FireplacesTotal,
                () => handleVisibilityChange("FireplacesTotal"),
                classes
              )}
              {getRowAdjustment(
                "Levels",
                singleCma?.Levels,
                adjustedmentVisibility?.Levels,
                () => handleVisibilityChange("Levels"),
                classes
              )}

              {singleCma?.cma_custom_fields?.map((customField) => {
                return getRowAdjustment(
                  customField.name,
                  customField.value,
                  false,
                  null,
                  classes
                );
              })}

              <div className={classes.descSection}></div>
            </Card>
          </Grid>
          <Grid item xs={mdScreen ? 8 : 6} style={{ paddingLeft: "0px" }}>
            {isArrayCheck(singleCma?.cma_comparables) &&
              adjustedDataInput.length > 0 && (
                <Slider
                  ref={(c) => setSlider(c)}
                  {...{
                    ...slider_settings,
                    customPaging: function (i) {
                      setPageCounter(i + 1);
                      return <span style={{ display: "none" }}>{i + 1}</span>;
                    },
                  }}
                >
                  {isArrayCheck(singleCma?.cma_comparables) &&
                  adjustedDataInput.length > 0
                    ? singleCma?.cma_comparables?.map((cma, index) => (
                        <div>
                          <AdjustmentsComparable
                            key={cma.id}
                            index={index}
                            id={cma.id}
                            cma={cma}
                            subject={singleCma}
                            classes={classes}
                            currentPage={currentPage}
                            customFieldsRef={customFieldsRef}
                            adjustedDataInput={adjustedDataInput}
                            handleAdjustmentInput={handleAdjustmentInput}
                            adjustedmentVisibility={adjustedmentVisibility}
                            changedVisibility={changedVisibility}
                            forceUpdate={forceUpdate}
                          />
                        </div>
                      ))
                    : "No Data Found"}
                </Slider>
              )}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
export default withStyles(styleSheet, { name: "AdjustmentsDetailViewStyle" })(
  AdjustmentsDetailView
);
