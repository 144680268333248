import { createSlice } from "@reduxjs/toolkit";
import { getEmailTemplates } from "./getEmailTemplates";
import { deleteTemplate } from "./deleteTemplate";
const initialState = {
  emailsList: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
  delete: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
  },
};
const LeadEmailTemplates = createSlice({
  name: "LeadEmailTemplates",
  initialState,
  extraReducers: {
    [getEmailTemplates.pending]: (state) => {
      state.emailsList.isLoading = true;
      state.emailsList.errMsg = null;
      state.emailsList.success = false;
    },
    [getEmailTemplates.fulfilled]: (state, action) => {
      state.emailsList.success = true;
      state.emailsList.isLoading = false;
      if (state?.emailsList?.data?.templates && action.meta?.arg?.offset > 0) {
        state.emailsList.data.templates = [
          ...state?.emailsList?.data?.templates,
          ...action.payload.templates,
        ];
      } else {
        state.emailsList.data = action.payload;
      }
    },
    [getEmailTemplates.rejected]: (state, action) => {
      state.emailsList.isLoading = false;
      state.emailsList.success = false;
      state.emailsList.errMsg = action.payload;
    },

    [deleteTemplate.pending]: (state) => {
      state.delete.isLoading = true;
      state.delete.errMsg = null;
      state.delete.success = false;
    },
    [deleteTemplate.fulfilled]: (state, action) => {
      state.delete.success = true;
      state.delete.isLoading = false;
      state.delete.data = action.payload;
      state.emailsList.data.templates = [
        ...state?.emailsList?.data?.templates,
      ]?.filter((item) => item.id !== action.payload.templateId);
    },
    [deleteTemplate.rejected]: (state, action) => {
      state.delete.isLoading = false;
      state.delete.success = false;
      state.delete.errMsg = action.payload;
    },
  },
});
export default LeadEmailTemplates.reducer;
export * from "./getEmailTemplates";
export * from "./deleteTemplate";
