export const styleSheet = () => ({
  pageRoot: {
    marginTop: "50px !important",
  },
  loadingArea: {
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    backgroundColor: "white",
  },
  vendorTableHeading: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize",
  },
  vendorAddButton: {
    background: "#0B0909 !important",
    color: "white",
    fontWeight: "400 !important",
    marginLeft: "40px  !important",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
    borderRadius: "6px  !important",
  },
  vendorTableHeader: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "space-between !important",
  },
  vendorTableHeaderLeft: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "flex-start !important",
  },
});
